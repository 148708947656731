export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    start_time: {
      type: 'string',
      format: 'date-time',
    },
    end_time: {
      type: 'string',
      format: 'date-time',
    },
    workers_required: {
      type: 'integer',
    },
    area: {
      type: 'string',
    },
  },
  required: ['start_time', 'end_time'],
}
