import React, { FC, PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react'
import { datadogRum } from '@datadog/browser-rum-slim'
import { TrackingProvider as FlexTrackingProvider } from '@indeed/flex-tracking-context'
import { useTrackingGlobalContext } from './hooks/useTrackingGlobalContext'
import { useTrigger } from './utils/useTrigger'

type GlobalContextActiveCount = Record<string, number>
export const GlobalContextActiveCountContext = React.createContext<
  React.MutableRefObject<GlobalContextActiveCount>
>({ current: {} })

export const useGlobalContextActiveCount = (key: string) => {
  const context = useContext(GlobalContextActiveCountContext)

  useEffect(() => {
    context.current[key] = (context.current[key] ?? 0) + 1

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      context.current[key] = !context.current[key] ? 0 : context.current[key] - 1
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return useCallback(() => context.current[key] || 0, [context, key])
}

export const TrackingProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const globalContextKeyActiveCount = useRef<GlobalContextActiveCount>({})
  const { user, global, features } = useTrackingGlobalContext()

  useEffect(() => {
    if (user) {
      datadogRum.setUser(user)
    }

    return () => {
      if (!user) {
        datadogRum.clearUser()
      }
    }
  }, [user])

  useEffect(() => {
    const isLoggedIn = !!user
    const { id: userId, name, ...restUser } = user || {}

    const context = {
      ...global,
      ...restUser,
      logged_in: isLoggedIn,
      userId,
      features,
    }

    Object.entries(context).forEach(([property, val]) => {
      datadogRum.setGlobalContextProperty(property, val)
    })

    return () => {
      Object.entries(context).forEach(([property]) => {
        datadogRum.removeGlobalContextProperty(property)
      })
    }
  }, [features, global, user])

  const onTriggerError = useCallback((event: string) => {
    if (!event.match(/^legacy_/i)) {
      if (process.env.NODE_ENV !== 'production') {
        console.error(`Tracking "${event}" event not formatted correctly`)
      }

      datadogRum.addError('tracking-event-error', {
        event_name: event,
        error_message: `Tracking event not formatted correctly`,
      })
    }
  }, [])

  return (
    <GlobalContextActiveCountContext.Provider value={globalContextKeyActiveCount}>
      <FlexTrackingProvider value={{ useTrigger }} scoped onTriggerError={onTriggerError}>
        {children}
      </FlexTrackingProvider>
    </GlobalContextActiveCountContext.Provider>
  )
}
