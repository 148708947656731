import React from 'react'

const More = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <circle cx="8" cy="8" r="8" fill="black" />
    <circle cx="5" cy="8" r="1" fill="white" />
    <circle cx="8" cy="8" r="1" fill="white" />
    <circle cx="11" cy="8" r="1" fill="white" />
  </svg>
)

export default More
