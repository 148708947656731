import { intersection } from 'lodash'

import { containsArray } from 'lib/array'

const EMPLOYER_PERMISSION_LEVELS = {
  main: 'main',
  manager: 'manager',
}
const AGENCY_PERMISSION_LEVELS = {
  admin: 'admin',
  standard: 'standard',
}

/**
 * Employer Account's Permissions Docs
 * https://syftapp.atlassian.net/wiki/spaces/SV/pages/88277031/Employer+Account+s+Permissions
 */
export default (level, requiredPermissions, availablePermissions, options = {}) => {
  if (level === 'disabled') return false
  else if (level === EMPLOYER_PERMISSION_LEVELS.main || level === AGENCY_PERMISSION_LEVELS.admin) return true
  else if (
    (level === EMPLOYER_PERMISSION_LEVELS.manager || level === AGENCY_PERMISSION_LEVELS.standard) &&
    options.orCondition
  )
    return !!intersection(requiredPermissions, availablePermissions).length
  else if (
    (level === EMPLOYER_PERMISSION_LEVELS.manager || level === AGENCY_PERMISSION_LEVELS.standard) &&
    !options.orCondition
  )
    return containsArray(requiredPermissions, availablePermissions)
  else throw new Error(`Unknown level type ${level}. Please check the code`)
}
