/* eslint-disable func-names */
/* eslint-disable import/order */

import { put, select, race, take } from 'redux-saga/effects'
import { uniqBy, map, get } from 'lodash'

import * as types from 'actions/action-types'

// Respond to BATCH_API_ACTION_BEGIN when bulk deleting worker venues.
// See ./respondToBatchApiRequest
export function* bulkDeleteWorkerVenues(actions) {
  const workerId = actions[0].payload.workerId
  const deletedVenues = map(actions, 'payload')
  const existingVenues = yield select(
    rootState => get(rootState.workers, ['entityMap', workerId, 'workerVenues']) || [],
  )
  const newVenues = uniqBy([...deletedVenues, ...existingVenues], 'venueId')

  yield put({
    type: types.WORKER_UPDATE_BEGIN,
    payload: { id: workerId, workerVenues: newVenues },
  })

  const { success, failure } = yield race({
    success: take(types.WORKER_UPDATE_SUCCEEDED),
    failure: take(types.WORKER_UPDATE_FAILED),
  })

  return {
    successPayload: get(success, 'payload'),
    failurePayload: get(failure, 'payload'),
  }
}
