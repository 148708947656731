import React from 'react'

const FestivalStaff = props => (
  <svg version={1.1} x={0} y={0} viewBox="0 0 138 138" xmlSpace="preserve" {...props}>
    <path
      className="st0"
      d="M86.3 44.4h-8.6v6.4h7.8c.4 0 .7.3.7.7v39.2c0 .4-.3.7-.7.7H52.8c-.4 0-.7-.3-.7-.7V51.6c0-.4.3-.7.7-.7h7.8v-6.4H52c-3.5 0-6.4 2.9-6.4 6.4v40.6c0 3.5 2.9 6.4 6.4 6.4h34.2c3.5 0 6.4-2.9 6.4-6.4V50.9c.2-3.6-2.7-6.5-6.3-6.5z"
    />
    <path
      className="st0"
      d="M71.4 35.9h-4.3c-2.4 0-4.3 1.9-4.3 4.3V53c0 2.4 1.9 4.3 4.3 4.3h4.3c2.4 0 4.3-1.9 4.3-4.3V40.2c0-2.4-2-4.3-4.3-4.3zM69.2 45c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7zM80.2 75.6c-.4-.2-7.4-3.5-11-9.2-.2-.3-.6-.3-.8 0-3.4 5-8.9 8.3-11 9.2-.2.1-.3.2-.3.4 0 1.4.8 2.5 1.8 2.9l.7 8.6c0 .4.4.7.7.7h16.5c.4 0 .7-.3.7-.7l.7-8.5c1.7-.3 2.2-2.2 2.2-3.1.1-.1-.1-.3-.2-.3zM66.5 86.8h-3.6l-.4-7c.5.2 1.1.3 1.7.3.8 0 1.5-.2 2.2-.6l.1 7.3zm4.5-7.4c.7.4 1.5.7 2.3.7.4 0 .9-.1 1.3-.2l-.4 6.9h-3.3l.1-7.4zm4.7-3.6c.2.1.2.3.2.5 0 1.3-1.1 2.3-2.5 2.3-.8 0-1.5-.3-1.9-.8.4-.8.4-1.5.3-1.8 0 0-1.3-2.8-2.2-6.8 2.1 4.2 5.8 6.4 6.1 6.6zm-7.6-6.7c-.9 4.2-2.2 6.8-2.2 6.9-.1.2-.2.9.3 1.8-.5.5-1.2.8-1.9.8-1.4 0-2.5-1-2.5-2.3 0-.2.1-.4.2-.5 0 0 3.7-2.1 6.1-6.7zM68.7 60.8c0-.2.3-.4.5-.2 2.4 2.4 5.4 2.4 7 2.2.3 0 .4.3.2.5-2.9 2.1-6.5 1.9-7.5 1.9-.1 0-.2-.1-.2-.3v-4.1z"
    />
  </svg>
)

export default FestivalStaff
