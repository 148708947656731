import { takeLatest, all, put } from 'redux-saga/effects'
import * as types from 'actions/action-types'

export function* fetchDashboard(options, action) {
  yield all([
    // STAFF_AVERAGE_RATINGS_INVALIDATE is ommited here because it is handled through the entity form directly
    put({ type: types.VENUE_AVERAGE_RATINGS_INVALIDATE }),
    put({ type: types.BOOKING_STATS_INVALIDATE }),
    put({ type: types.TOTAL_WORKED_HOURS_INVALIDATE }),
    put({ type: types.TOTAL_FILLED_SHIFTS_INVALIDATE }),
    put({ type: types.WORKER_NO_SHOWS_INVALIDATE }),
    put({ type: types.REPEAT_STAFFS_INVALIDATE }),
    put({ type: types.COST_BREAKDOWNS_INVALIDATE }),
    put({ type: types.TOTAL_ESTIMATED_COSTS_INVALIDATE }),
  ])
}

export function* watchForFetchDashboard(options = {}) {
  yield takeLatest([types.STAFF_AVERAGE_RATING_FETCH_BEGIN], fetchDashboard, options)
}
