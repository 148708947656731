export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    total_shifts_posted: {
      type: 'integer',
    },
    total_workers_booked: {
      type: 'integer',
    },
    total_unfilled_shifts: {
      type: 'integer',
    },
  },
  required: ['total_shifts_posted', 'total_workers_booked', 'total_unfilled_shifts'],
}
