export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    id: {
      type: 'integer',
    },
    name: {
      type: 'string',
    },
    code: {
      type: 'string',
    },
    kind: {
      description: 'Kind',
      enum: ['employer', 'location'],
      type: 'string',
    },
  },
  required: [],
  type: 'object',
}
