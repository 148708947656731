import { mapValues, clamp } from 'lodash'
import tinycolor from 'tinycolor2'

// @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
// @param {number} hsl.h Hue
// @param {number} hsl.s Saturation 0-1
// @param {number} hsl.l Luminance 0-1
// @returns {string} A CSS color string
export function setHsl(color, hsl) {
  const decomposed = tinycolor(color).toHsl()
  const values = mapValues(decomposed, (value, prop) => (typeof hsl[prop] === 'number' ? hsl[prop] : value))
  return tinycolor.fromRatio(values).toHexString()
}

// @param {string} color - CSS color, i.e. one of: #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla()
// @param {number} hsl.s Saturation coefficient
// @param {number} hsl.l Luminance coefficient
// @param {number} hsl.a Alpha coefficient
// @param {'hsl'|null} opts.out
// @returns {string} A CSS color string
export function setHslCoeffs(color, hsl, opts = {}) {
  const decomposed = tinycolor(color).toHsl()
  const values = mapValues(decomposed, (value, prop) =>
    typeof hsl[prop] === 'number' ? clamp(value * hsl[prop], 0, 1) : value,
  )
  const newColor = tinycolor.fromRatio(values)
  if (opts.out === 'hsl') {
    return newColor.toHslString()
  } else {
    return newColor.toHexString()
  }
}

// Adapted from https://github.com/mbitson/mcg
export function multiplyColors(rgb1, rgb2) {
  const rgb = {}
  rgb.b = Math.floor((rgb1.b * rgb2.b) / 255)
  rgb.g = Math.floor((rgb1.g * rgb2.g) / 255)
  rgb.r = Math.floor((rgb1.r * rgb2.r) / 255)
  return tinycolor(`rgb ${rgb.r} ${rgb.g} ${rgb.b}`)
}

// Does light or dark text have more contrast with this color?
//
// See https://material.io/guidelines/usability/accessibility.html#accessibility-color-contrast
//
// Individual components can recompute the contrast default based on the specific
// colors they use
//
// @returns {'light' | 'dark'}
export const contrastDefaultForPalette = palette => {
  // 500 ~ middle of the palette
  const blackContrast = tinycolor.readability('#000', palette[500])
  const whiteContrast = tinycolor.readability('#fff', palette[500])
  return blackContrast > whiteContrast ? 'dark' : 'light'
}

// Adapted from https://github.com/mbitson/mcg
export function computePalette(base) {
  const baseLight = tinycolor('white')
  const baseDark = multiplyColors(tinycolor(base).toRgb(), tinycolor(base).toRgb())
  const baseTriad = tinycolor(base).tetrad()
  const tinyColorPalette = {
    50: tinycolor.mix(baseLight, base, 12),
    100: tinycolor.mix(baseLight, base, 30),
    200: tinycolor.mix(baseLight, base, 50),
    300: tinycolor.mix(baseLight, base, 70),
    400: tinycolor.mix(baseLight, base, 85),
    500: tinycolor.mix(baseLight, base, 100),
    600: tinycolor.mix(baseDark, base, 87),
    700: tinycolor.mix(baseDark, base, 70),
    800: tinycolor.mix(baseDark, base, 54),
    900: tinycolor.mix(baseDark, base, 25),
    1000: tinycolor.mix(baseDark, base, 1),
    A100: tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(65),
    A200: tinycolor.mix(baseDark, baseTriad[4], 15).saturate(80).lighten(55),
    A400: tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(45),
    A700: tinycolor.mix(baseDark, baseTriad[4], 15).saturate(100).lighten(40),
  }
  const palette = mapValues(tinyColorPalette, color => color.toHexString())
  return {
    ...palette,
    light: palette[300],
    default: palette[500], // Compatible with IDS
    main: palette[500],
    dark: palette[700],
    contrastDefaultColor: 'light',
  }
}
