import React from 'react'
import Typography from 'components/Typography'

import Link from 'components/Link'

const Error403 = () => (
  <div style={{ maxWidth: 500, alignSelf: 'center', margin: '5em 0' }}>
    <Typography type="title3" style={{ marginBottom: '1em' }}>
      Access denied (403)
    </Typography>

    <Typography type="display1" style={{ marginBottom: '1em' }}>
      Sorry, you don't have access to this page. Please contact your company staff manager.
    </Typography>

    <Typography type="display1">
      Go to the <Link to="/">homepage</Link>.
    </Typography>
  </div>
)

export default Error403
