export default {
  $schema: 'http://json-schema.org/draft-10/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    status: {
      type: 'string',
    },
    manager_name: {
      type: 'string',
    },
    reason: {
      type: 'string',
    },
    comment: {
      type: 'string',
    },
    jobs: {
      type: 'array',
    },
  },
  total: {
    type: 'object',
  },
}
