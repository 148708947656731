import userSchema from './user'

const permissionSchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    work_location_id: {
      type: 'string',
    },
    codes: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
}

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    user: userSchema,
    level: {
      description: 'Level',
      enum: ['main', 'manager', 'disabled'],
      type: 'string',
    },
    permissions: {
      type: 'array',
      $rel: 'permission',
      items: permissionSchema,
    },
  },
  required: [],
  type: 'object',
}
