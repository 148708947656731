import { mergeWith, flow } from 'lodash'
import platform from './platform'

/**
 * Helper which combines all migrations (grouped by reducer) into one suitable for makeMigration from 'redux-persist'
 *
 * All imported migrations must have the following shape
 * {
 *   [versionOfPersistedStoreToBeAppliedFor]: fn(store) => transformedStore
 * }
 *
 * All migrations will then be combined into the following shape
 * {
 *   [versionOfPersistedStoreToBeAppliedFor]: flow([migrationA, migrationB, migrationC])
 * }
 *
 * Exported only for TESTING!
 * @param migrations
 * @returns {*}
 */
export const combineMigrations = migrations =>
  mergeWith({}, ...migrations, (a, b) => {
    if (!a) return b
    if (a === b) return a
    return flow([a, b])
  })

export default combineMigrations([platform])
