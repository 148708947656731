import React from 'react'

const Delete = props => (
  <svg viewBox="0 0 11 13" {...props}>
    <g fill="none" fillRule="evenodd">
      <g fill="#FF5535" fillRule="nonzero">
        <g>
          <g>
            <path
              d="M.722 11.556c0 .794.65 1.444 1.445 1.444h5.777c.795 0 1.445-.65 1.445-1.444V2.889H.722v8.667zM10.112.722H7.582L6.861 0H3.25l-.722.722H0v1.445h10.111V.722z"
              transform="translate(-927 -647) translate(460 424) translate(467 223)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Delete
