import warning from 'warning'
import { startsWith } from 'lodash'

let generatorCounter = 0

// By default, Material UI will generate stylesheet class names in a format like this:
// '[stylesheetName]-[className]-[hash]' (e.g. '.MuiButton-root-34').
// Because MUI stylesheets are registered with JSS under a unique namespace (by typing
// `withStyles(stylesheet, { name: "UniqueName" })`) we can drop the hash.
// This way we can easily override any rules from namespaced stylesheets with JSS.
//
// All stylesheets registered under names 'Mui*' or 'Syft*' and used
// via material-ui/styles/withStyles or styles/jss#injectSheet will get this treatment.
//
// In future we may drop this functionality and favor correctness over ease
// of development.
//
// Example:
// ```
// const jssStyles = {
//   root: {
//     // This will override styles of all material-ui's <Typography /> components
//     // under `.root`
//     '& .MuiTypography-root': {
//       color: 'white'
//     }
//
//     // Without this extension we would have to write something like the following:
//     '& .MuiTypography-root-23': {
//       color: 'white'
//     }
//   }
// }
// ```
//
// Note also the difference in class name generation between production and development
// build. In development, component display names are used to generate the class name.
// In production these are dropped and only rule names remain. For example:
// - 'App-root-3' in dev becomes 'App-root-12' in prod (in case App
//    is a registered stylesheet name)
// - 'Navigation-root-3' in dev becomes 'root-23' in prod
// - 'MuiButton-root' in dev remains 'MuiButton-root' in prod
//
export default function createGenerateClassName(opts) {
  let ruleCounter = 0

  if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
    generatorCounter += 1

    if (generatorCounter > 2) {
      // eslint-disable-next-line no-console
      console.error(
        [
          'Material-UI: we have detected more than needed creation of the class name generator.',
          'You should only use one class name generator on the client side.',
          'If you do otherwise, you take the risk to have conflicting class names in production.',
        ].join('\n'),
      )
    }
  }

  return (rule, sheet) => {
    const sheetName = sheet && (sheet.options.name || sheet.options.meta)

    ruleCounter += 1
    warning(
      ruleCounter < 1e10,
      [
        'Material-UI: you might have a memory leak.',
        'The ruleCounter is not supposed to grow that much.',
      ].join(''),
    )

    if (sheet && sheetName) {
      if (startsWith(sheetName, 'Mui') || startsWith(sheetName, 'Syft') || opts?.noHashes) {
        // .MuiDialog-root-24359 becomes .MuiDialog-root
        return `${sheetName}-${rule.key}`
      } else {
        return `${sheetName}-${rule.key}-${ruleCounter}`
      }
    }

    return `${rule.key}-${ruleCounter}`
  }
}
