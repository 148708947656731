// Note: Disable joyride not remove for posible future reactivation https://syftapp.atlassian.net/browse/PTE-32178
import * as types from '../actions/action-types'

const initialState = {
  showDashboardJoyrideTour: false,
  showRotaJoyrideTour: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DASHBOARD_JOYRIDE_TOUR_PASSED:
      return {
        ...state,
        showDashboardJoyrideTour: false,
      }
    case types.SET_ROTA_JOYRIDE_TOUR_PASSED:
      return {
        ...state,
        showRotaJoyrideTour: false,
      }
    default:
      return state
  }
}

export default reducer
