import * as types from '../actions/action-types'

const initialState = {
  hasRehydrated: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REHYDRATION_COMPLETE:
      return {
        ...state,
        hasRehydrated: true,
      }
    default:
      return state
  }
}

export default reducer
