import payRateSchema from './payRate'
export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  definitions: {},
  id: 'http://example.com/example.json',
  properties: {
    availability: {
      items: {
        type: 'string',
      },
      type: 'array',
    },
    average_rating: {
      type: 'integer',
    },
    bio: {
      type: 'string',
    },
    completed_jobs_count: {
      type: 'integer',
    },
    display_name: {
      type: 'string',
    },
    first_name: {
      type: 'string',
    },
    id: {
      type: 'integer',
    },
    last_name: {
      type: 'string',
    },
    unique_number: {
      type: 'string',
      description: 'Employee unique number',
    },
    trusted_work_locations: {
      items: {
        type: 'object',
        properties: {
          id: {
            type: 'integer',
          },
        },
      },
      type: 'array',
    },
    minimum_rate: {
      properties: {
        amount: {
          type: 'number',
          description: 'Rate per hour',
        },
        currency: {
          type: 'string',
        },
      },
      required: ['currency', 'amount'],
      type: 'object',
    },
    pay_rate: payRateSchema,
    name: {
      type: 'string',
    },
    non_syft_experiences: {
      items: {},
      type: 'array',
      // id
      // company_name
      // job_role
      // started
      // ended
      // description
    },
    profile_picture: {
      properties: {
        uuid: {
          type: 'string',
        },
      },
      required: ['uuid'],
      type: 'object',
    },
    roles: {
      items: {
        properties: {
          average_rating: {
            type: 'integer',
          },
          role: {
            properties: {
              code: {
                type: 'string',
              },
              id: {
                type: 'integer',
              },
              title: {
                type: 'string',
              },
            },
            required: ['code', 'id', 'title'],
            type: 'object',
          },
          role_id: {
            type: 'integer',
          },
          skill_ids: {
            items: {},
            type: 'array',
          },
          verified: {
            type: 'boolean',
          },
        },
        required: ['verified', 'skill_ids', 'role', 'average_rating', 'role_id'],
        type: 'object',
      },
      type: 'array',
    },
    title: {
      type: 'null',
    },
    trusted: {
      type: 'boolean',
    },
    worker_roles: {
      items: {
        properties: {
          average_rating: {
            type: 'integer',
          },
          role: {
            properties: {
              code: {
                type: 'string',
              },
              id: {
                type: 'integer',
              },
              title: {
                type: 'string',
              },
            },
            required: ['code', 'id', 'title'],
            type: 'object',
          },
          role_id: {
            type: 'integer',
          },
          skill_ids: {
            items: {},
            type: 'array',
          },
          verified: {
            type: 'boolean',
          },
        },
        required: ['verified', 'skill_ids', 'role', 'average_rating', 'role_id'],
        type: 'object',
      },
      type: 'array',
    },
  },
  required: [
    'bio',
    'first_name',
    'last_name',
    'display_name',
    'name',
    'roles',
    'title',
    'non_syft_experiences',
    'completed_jobs_count',
    'availability',
    'average_rating',
    'profile_picture',
    'worker_roles',
    'id',
    'trusted',
    'minimum_rate',
  ],
  type: 'object',
}
