import { useContext } from 'react'
import { includes } from 'lodash'

import { GridContext } from '../Grid.context'
import { GridProps } from '../Grid.types'

const useGetGridTypeProps = ({
  columnSpacing: elementColumnSpacing,
  rowSpacing: elementRowSpacing,
  spacing: elementSpacing,
  direction,
  nested,
  style,
  container,
  item,
}: GridProps) => {
  const { rowSpacing: rootRowSpacing, columnSpacing: rootColumnSpacing } = useContext(GridContext)

  const rowSpacing = elementRowSpacing ?? elementSpacing ?? rootRowSpacing
  const columnSpacing = elementColumnSpacing ?? elementSpacing ?? rootColumnSpacing
  const spacing = includes(['column', 'column-reverse'], direction) ? columnSpacing : rowSpacing

  if (container) {
    return { spacing }
  }

  if (item && nested) {
    return {
      style: {
        padding: columnSpacing / 2,
        ...(style || {}),
      },
    }
  }

  return {}
}

export default useGetGridTypeProps
