import { upperFirst, last, camelCase, lowerCase, snakeCase, invert, includes, omit, tail } from 'lodash'
import pluralize from 'pluralize'

import { singularizePhrase } from 'lib/pluralize'
import { apiActions } from 'api/endpoints'

export const requestFlowStates = {
  begin: 'begin',
  succeeded: 'succeeded',
  failed: 'failed',
}

// @private
const methodNameForApiAction = {
  list: 'fetch',
  get: 'fetch',
  create: 'create',
  update: 'update',
  sync: 'sync',
  delete: 'delete',
}

// @param {String} entityType Type of entity, defined in api/schemas
// @param {String ~ api/endpoints#apiActions} apiAction One of the standard api actions
// @param {'begin' | 'succeeded' | 'failed' | *} requestFlowState
// @return {String} action type, e.g. "VENUES_FETCH_BEGIN"
export const serializeActionType = ({ entityType, apiAction, requestFlowState }) => {
  const translatedEntityType = includes([apiActions.list, apiActions.sync], apiAction)
    ? pluralize(entityType)
    : entityType
  return [snakeCase(translatedEntityType), methodNameForApiAction[apiAction], requestFlowState]
    .join('_')
    .toUpperCase()
}

// @param {String|Function} actionTypeOrCreator Redux action creator made
//   with redux-actions or a string action type
// @return {{ entityType, apiAction, requestFlowState }} where
//   entityType ~ {String}
//   apiAction ~ {}
//   requestFlowState ~ {}
// @return {String ~ api/endpoints#apiActions} One of the standard api actions
export const deserializeActionType = actionTypeOrCreator => {
  const actionType = actionTypeOrCreator.toString()
  // "WORKER_ROLES_FETCH_BEGIN" ~> ["WORKER_ROLES", "FETCH", "BEGIN"]
  const [typePart, actionPart, flowPart] = tail(actionType.match(/(.*)_([^_]*)_([^_]*)$/))
  // "WORKER_ROLES" ~> "worker roles"
  const lowerCaseType = lowerCase(typePart)
  // "WORKER_ROLES" ~> true, "WORKER_ROLE" ~> false
  const isPluralAction = pluralize.isPlural(last(lowerCaseType.split(' ')))
  // "WORKER_ROLES" ~> "workerRole"
  const entityType = camelCase(singularizePhrase(lowerCaseType))
  // { fetch: 'get', create: 'create'... }['fetch'] ~> apiActions.fetch
  const apiAction =
    actionPart === 'FETCH' && isPluralAction
      ? apiActions.list
      : invert(omit(methodNameForApiAction, 'list'))[camelCase(actionPart)]
  // SUCCEEDED ~> requestFlowStates.succeeded
  const requestFlowState = requestFlowStates[camelCase(flowPart)]
  return { entityType, apiAction, requestFlowState }
}

// Deprecated
export const actionTypeForRequest = (entityType, apiAction, requestFlowState) =>
  serializeActionType({ entityType, apiAction, requestFlowState })

// Deprecated
export const apiActionForActionCreator = (entityType, actionCreator) =>
  deserializeActionType(actionCreator).apiAction

// @param {String} entityType Type of entity, defined in api/schemas
// @param {String ~ api/endpoints#apiActions} apiAction One of the standard api actions
// @return {String} standard Redux action creator name, e.g. "createVenues"
export const actionCreatorNameForRequest = (entityType, apiAction) => {
  const translatedEntityType = apiAction === apiActions.list ? pluralize(entityType) : entityType
  return `${methodNameForApiAction[apiAction]}${upperFirst(translatedEntityType)}`
}
