/* eslint-disable func-names */
/* eslint-disable import/order */

import {
  get,
  map,
  find,
  without,
  compact,
  flatten,
  unionBy,
  isEqual,
  isInteger,
  pick,
  filter,
  isMatch,
} from 'lodash'
import { call, put, select, all } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { putFailureAction } from './entityCall'

const { allRequestTypesOfActionType } = types

const requestsForAreaUpdate = (oldArea, partialNewArea, venueId) => {
  const defaults = {
    entityType: 'area',
    entity: { id: get(oldArea, 'id'), venueId, ...partialNewArea },
  }
  if (oldArea && partialNewArea && !isEqual(oldArea, partialNewArea)) {
    return { ...defaults, apiAction: apiActions.update }
  } else if (!oldArea && partialNewArea) {
    return { ...defaults, apiAction: apiActions.create }
  } else if (oldArea && !partialNewArea) {
    return { ...defaults, apiAction: apiActions.delete }
  } else {
    return null
  }
}

// TODO Extract, refactor
const unionEntities = (left, right) => {
  const isNew = entity => !isInteger(entity.id)
  // updatedOrDeleted should not contain new entities (without ID)
  const updatedOrDeleted = filter(unionBy(left, right, 'id'), x => !isNew(x))
  const newEntities = filter(left, isNew).concat(filter(right, isNew))
  return updatedOrDeleted.concat(newEntities)
}

// TODO Generalize, use deep pick, lodash.isMatch
export const requestsForVenueUpdate = (oldVenue, partialNewVenue) => {
  const dirtyProperties = without(Object.keys(partialNewVenue), 'areas', 'id', 'location')
  const isVenueNew = !isInteger(get(partialNewVenue, 'id'))

  const isAddressDirty = !isMatch(get(oldVenue, 'address'), get(partialNewVenue, 'address'))

  // If the venue is new, never update it right after creating
  // Backend can sanitize or change any venue properties, which would result in an update
  // request right after the create request. See WEB2-230
  const shouldUpdateVenue =
    !isVenueNew &&
    (!isEqual(pick(oldVenue, dirtyProperties), pick(partialNewVenue, dirtyProperties)) || isAddressDirty)

  const venueRequest = shouldUpdateVenue && {
    apiAction: isInteger(get(oldVenue, 'id')) ? apiActions.update : apiActions.create,
    entityType: 'venue',
    entity: {
      ...pick(partialNewVenue, [...dirtyProperties, 'id']),
      ...(isAddressDirty ? pick(partialNewVenue, 'address') : {}),
    },
  }

  const unionedAreas = unionEntities(get(oldVenue, 'areas'), get(partialNewVenue, 'areas'))
  const areaRequests = map(unionedAreas, area =>
    requestsForAreaUpdate(
      find(get(oldVenue, 'areas'), { id: get(area, 'id') }),
      // !area.id ~> create, area === null ~> delete
      area.id ? find(get(partialNewVenue, 'areas'), { id: get(area, 'id') }) : area,
      get(oldVenue, 'id'),
    ),
  )

  return compact(flatten([venueRequest, areaRequests]))
}

// Create, remove, update areas as part of the PUT /venues/:id call
//
// TODO Handle location.geoLocation
export function* createOrUpdateVenue(options) {
  const originTypes = [types.VENUE_CREATE_BEGIN, types.VENUE_UPDATE_BEGIN]
  yield takeLatestDeep(originTypes, function* (action) {
    const actionTypes = allRequestTypesOfActionType(action.type)

    try {
      let oldVenue = null
      if (isInteger(action.payload.id)) {
        oldVenue = yield select(rootState => rootState.venues.entityMap[action.payload.id])
      } else {
        const result = yield call(options.entityApiRequest || entityApiRequest, {
          apiAction: apiActions.create,
          entityType: 'venue',
          entity: action.payload,
          meta: options.context,
        })
        oldVenue = result.payload
      }

      const requests = requestsForVenueUpdate(oldVenue, action.payload)

      yield all(
        map(requests, requestOptions =>
          call(options.entityApiRequest || entityApiRequest, {
            ...requestOptions,
            meta: options.context,
          }),
        ),
      )

      const { payload, meta } = yield call(options.entityApiRequest || entityApiRequest, {
        apiAction: apiActions.get,
        entityType: 'venue',
        entity: { id: oldVenue.id },
        meta: options.context,
      })

      yield put({ type: actionTypes.succeeded, payload, meta })
    } catch (e) {
      return yield putFailureAction({
        type: actionTypes.failed,
        error: e,
        requestOptions: { entity: action.payload },
        beginAction: action,
      })
    }
  })
}
