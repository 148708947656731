/* eslint-disable func-names */
/* eslint-disable import/order */

import { delay } from 'redux-saga'
import { pickBy } from 'lodash'
import { format as formatDate } from 'lib/date-fns'
import { call, put, fork, takeEvery, cancel, select, take } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { getLastUpdated, getNetworkStatus } from 'lib/redux-crud/selectors'
import networkStatusEnum from 'lib/redux-crud/networkStatusEnum'
import { entityApiRequest } from 'api'
import { putFailureAction } from './entityCall'

function* updateThread(options, action) {
  const requestOptions = {
    apiAction: apiActions.update,
    entityType: 'thread',
    entity: action.payload,
    meta: pickBy({
      ...action.meta,
      ...options.context,
    }),
  }
  try {
    const { payload, meta: requestMeta } = yield call(
      options.entityApiRequest || entityApiRequest,
      requestOptions,
    )
    const meta = { ...requestMeta, requestOptions }
    yield put({ type: types.THREAD_UPDATE_SUCCEEDED, payload, meta })
  } catch (e) {
    return yield putFailureAction({
      type: types.THREAD_UPDATE_FAILED,
      error: e,
      requestOptions,
      beginAction: action,
    })
  }
}

// @param {String} options.entityType
// @param {String} options.entityApiRequest
// @param {(startAction.payload) -> String} options.syncIdentifier
// @param {Number} startAction.syncInterval
export function* markThreadAsRead(options) {
  yield takeEvery(types.THREAD_MARK_AS_READ_START, function* (startAction) {
    const markAsRead = function* () {
      const timeToReadUnreadMessages = 2 * 1000

      const task = yield fork(function* () {
        // Start with leading delay
        yield delay(timeToReadUnreadMessages)

        // TODO Fix to true last sync time
        // TODO Fix when lastUpdated doesn't exist
        const query = { threadId: startAction.payload.id }
        const threadUpdateTime = yield select(rootState => getLastUpdated(rootState.messages, { query }))
        const allThreadsUpdateTime = yield select(rootState => getLastUpdated(rootState.threads))
        const anyLastUpdated = threadUpdateTime || allThreadsUpdateTime
        if (!anyLastUpdated) return

        const lastSyncTime = formatDate(anyLastUpdated)

        yield call(updateThread, options, {
          ...startAction,
          payload: { ...startAction.payload, read: true, lastSyncTime },
        })
      })

      return yield takeEvery(types.THREAD_MARK_AS_READ_STOP, function* (stopAction) {
        if (startAction.payload.id === stopAction.payload.id) {
          yield cancel(task)
        }
      })
    }

    const networkStatus = yield select(rootState => getNetworkStatus(rootState.messages))
    if (networkStatus < networkStatusEnum.ready) {
      // Wait until we're done fetching new messages
      yield take(types.MESSAGES_FETCH_SUCCEEDED)
      yield call(markAsRead)
    } else {
      yield call(markAsRead)
    }
  })
}
