/* eslint-disable func-names */
/* eslint-disable import/order */

import { get, has, isEmpty, filter, concat, map, omit, find, includes } from 'lodash'
import { call, all, put, select, takeEvery } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { compose } from 'lodash/fp'

import networkStatusEnum from 'lib/redux-crud/networkStatusEnum'
import timeout from 'lib/sagaHelpers/timeout'
import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityActionRequest, entityApiRequest } from 'lib/redux-crud/entityActionRequest'
import { isNoEffectWorkerVenue } from 'api/entities/workerVenue'
import { convertToDailyShiftBookings } from 'api/entities/dailyShiftBooking'
import { normalizeToShiftBookings } from './booking'
import { putFailureAction } from './errorMessage'

const translateBlacklistProp = ({ workLocationIds, ...payload }) => ({
  ...payload,
  blacklistedLocationIds: workLocationIds === null ? [0] : workLocationIds,
})

// GOAL: we're using 2 routes for worker fetch request. They have different response models.
// This saga is used to unify payload.
// TODO: remove, when API response from 2 request will be synce
// https://github.com/Syft-Application/syft2webclient/blob/develop/src/api/endpoints.js#L342
export function* fetchWorker() {
  yield takeLatestDeep(types.WORKER_FETCH_BEGIN, function* (action) {
    const normalizePayload = !get(action, 'meta.useBlacklistApi')
      ? payload => ({
          ...payload,
          displayName: payload.displayName || payload.name,
        })
      : translateBlacklistProp
    yield call(entityActionRequest, { normalizePayload }, action)
  })
}

// * When fetching workers with { blacklisted: true } query, normalize the payload
//  {worker: {:id, :name, ... }} into standard { id, name, blacklistedLocationIds: [...] }
export function* fetchWorkers() {
  yield takeLatestDeep(types.WORKERS_FETCH_BEGIN, function* (action) {
    if (get(action, 'payload.blacklisted')) {
      const normalizePayload = payload => map(payload, translateBlacklistProp)
      yield entityActionRequest(
        { normalizePayload },
        {
          ...action,
          meta: { ...action.meta, useBlacklistApi: true },
        },
      )
    } else {
      yield entityActionRequest(action)
    }
  })
}

// Given a change of worker.workerVenues
function* updateWorkerVenuesAttribute(options, action) {
  const workerId = action.payload.id

  // Remove deleted venues from request
  // A worker venue with no areas selected will have no effect on the system and we can
  // delete it.
  const workerVenues = filter(
    action.payload.workerVenues,
    workerVenue => !workerVenue._deleted && !isNoEffectWorkerVenue(workerVenue),
  )

  try {
    const { meta } = yield call(entityApiRequest, {
      // With the current API it's not possible to both create and update
      // WorkerVenues (i.e. very complicated) because PATCH is not
      // upsert and requires exisiting workerVenues to also be passed :(
      // payload._new is used to simplify this saga
      apiAction: action.payload._new ? apiActions.create : apiActions.update,
      entityType: 'workerVenue',
      entity: { workerVenues, workerId },
    })

    // TODO: use premade selectors
    const worker = yield select(rootState => rootState.workers.entityMap[workerId])

    // Merge data from rootState.venueWorkers and rootState.workers if Add
    // Without this, calling WORKER_UPDATE_SUCCEEDED would append a blank entity
    // to rootState.workers
    const newWorkerVenues = action.payload._new ? concat(worker.workerVenues, workerVenues) : workerVenues

    const payload = { id: workerId, workerVenues: newWorkerVenues }
    yield put({ type: types.WORKER_UPDATE_SUCCEEDED, payload, meta })
  } catch (e) {
    return yield putFailureAction({
      type: types.WORKER_UPDATE_FAILED,
      error: e,
      requestOptions: { entity: action.payload },
      beginAction: action,
    })
  }
}

function* updateBlacklistAttribute(options, action) {
  try {
    yield call(entityApiRequest, {
      apiAction: apiActions.update,
      entityType: 'worker',
      // See endpoints#worker
      entity: {
        id: action.payload.id,
        workLocationIds: includes(action.payload.blacklistedLocationIds, 0)
          ? null
          : action.payload.blacklistedLocationIds,
      },
      requestMeta: { useBlacklistApi: true },
    })

    yield put({
      type: types.WORKER_UPDATE_SUCCEEDED,
      payload: action.payload,
      meta: { beginAction: action },
    })
  } catch (error) {
    return yield putFailureAction({
      type: types.WORKER_UPDATE_FAILED,
      error,
      beginAction: action,
    })
  }
}

function* updateTrustedAttribute(action) {
  return yield call(entityActionRequest, {
    ...action,
    meta: {
      ...action.meta,
      // When updating TN on Syft Force for Syft Pool, always use TN endpoint
      useTrustedNetworkEndpoint: true,
      useSyftForceWorkerEndpoint: false,
    },
  })
}

function* updateTrustedWorkLocationsAttribute(action) {
  return yield call(entityActionRequest, {
    ...action,
    payload: {
      ...action.payload,
      // https://syftapp.atlassian.net/wiki/spaces/SV/pages/17629188/Trusted+Network+API mismatch in property name
      workLocations: action.payload.trustedWorkLocations,
    },
    meta: {
      ...action.meta,
      // When updating TN on Syft Force for Syft Pool, always use TN endpoint
      useTrustedNetworkEndpoint: true,
      useSyftForceWorkerEndpoint: false,
    },
  })
}

// WEB2-233 Syft API doesn't accept worker.payRate if payRate.amount is unset
// @param {Object} workerPayload As per schema
function normalizeWorkerUpdatePaylaod(workerPayload) {
  const normalizePayRate = worker => {
    const hasUnsetRate = has(worker, 'payRate') && typeof get(worker, 'payRate.amount') !== 'number'
    if (hasUnsetRate) {
      return { ...worker, payRate: null }
    }
    return worker
  }

  const removeWorkerVenues = worker => {
    const { workerVenues, ...rest } = worker
    return rest
  }

  return compose(removeWorkerVenues, normalizePayRate)(workerPayload)
}

// This saga extends Syft API with the following:
// * Updating worker.workerVenues will use the Worker Venues API. All other updates
//   will be ignored if worker.workerVenues is set in action.payload
// * Updating worker.blacklistedLocationIds attribute will update the worker in employer blacklist.
//   This will call `POST /blacklist/{add,remove}`. When updating worker.blacklistedLocationIds
//   attribute, no other updates on the worker are allowed. See Employer Blacklist API.
// * If neither blacklistedLocationIds or workerVenues attributes are updated,
//   make a standard PATCH /worker/:id call
// * Update payload is slightly normalized to prevent a backend bug (WEB2-233)
export function* updateWorker(options) {
  yield takeEvery(types.WORKER_UPDATE_BEGIN, function* (action) {
    const oldEntity = yield select(rootState => rootState.workers.entityMap[action.payload.id])
    const isBlacklistPropDirty =
      get(oldEntity, 'blacklistedLocationIds') !== action.payload.blacklistedLocationIds
    const isTrustedPropDirty =
      typeof action.payload.trusted === 'boolean' && get(oldEntity, 'trusted') !== action.payload.trusted
    const isWorkerVenuesPresent = Array.isArray(action.payload.workerVenues)
    const isTrustedWorkLocationsPresent = isEmpty(omit(action.payload, '$type', 'id', 'trustedWorkLocations'))
    const useSyftForceWorkerEndpoint = get({ ...action.payload, ...oldEntity }, 'workerPlatform') !== 'syft'

    // We are assuming that we will always udpate *either* worker venues
    // *or* update any other property. This is because
    // we know how the UI works.
    if (isWorkerVenuesPresent) {
      yield call(updateWorkerVenuesAttribute, options, action)
    }
    if (isBlacklistPropDirty) {
      yield call(updateBlacklistAttribute, options, action)
    } else if (isTrustedWorkLocationsPresent) {
      yield call(updateTrustedWorkLocationsAttribute, action)
    } else if (isTrustedPropDirty) {
      yield call(updateTrustedAttribute, action)
    } else {
      yield call(entityActionRequest, {
        ...action,
        meta: { useSyftForceWorkerEndpoint, ...action.meta },
        payload: normalizeWorkerUpdatePaylaod(action.payload),
      })
    }
  })
}

// Interim solution. This will be removed when Rotas API is out.
// 'shiftBooking' as an entity is not defined in schema, it's usually just some
// intermediary data before sent to the API or converted into dailyShiftBooking
//
// `shiftBooking.shift.startTime` is needed to later time-index the shift bookings
// in convertToDailyShiftBookings. Ideally it would be always returned by the backend
// in addition to the shift ID. It is purposefully denormalized.
//
// IN: worker ~ { id: 1, shiftIds: [1,2,3] }
// OUT: [{ $type: 'shiftBooking', workerId: 1, shift: { id: 1, startTime, endTime },...]
function* convertWorkerWithShiftsToShiftBookings(worker) {
  return yield call(normalizeToShiftBookings, {
    roughShiftBookings: [
      {
        workerId: worker.id,
        shiftIds: worker.shiftIds,
        softShiftIds: worker.softShiftIds,
      },
    ],
  })
}

// Extract nested entities from WORKER_FETCH_SUCCEEDED payload:
// * worker venues – applicable for SF workers
// * daily shift bookings – shift bookings for a worker on a single day - applicable
//   when fetching workers for staff availability
export function* unpackWorker() {
  function* unpack(worker, action) {
    if (!isEmpty(worker.workerVenues)) {
      yield put({
        type: types.WORKER_VENUES_FETCH_SUCCEEDED,
        payload: worker.workerVenues,
      })
    }

    const start = get(action.meta, 'beginAction.payload.shiftEndTimeGte')
    const end = get(action.meta, 'beginAction.payload.shiftStartTimeLte')

    if ((!isEmpty(worker.shiftIds) || !isEmpty(worker.softShiftIds)) && start && end) {
      // Wait until listing query is fetched. Assumes that time interval query
      // for workers is always accompanied by listings query for the same
      // time interval. This is specific to Staff Availability page.
      // TODO Remove when Rotas API 2.0 is out
      yield timeout(
        function* () {
          let hasListingsData = false
          while (!hasListingsData) {
            const listingSets = yield select(state => state.listings.entitySets)
            const listingSet = find(
              listingSets,
              set => set.query.shiftEndTimeGte === start && set.query.shiftStartTimeLte === end,
            )
            hasListingsData = get(listingSet, 'pagination.pages[1].networkStatus') === networkStatusEnum.ready
            if (hasListingsData) {
              break
            } else {
              yield delay(100)
            }
          }
        },
        { maxDelay: 30 * 1000 },
      )
      const shiftBookings = yield call(convertWorkerWithShiftsToShiftBookings, worker)
      const dailyShiftBookings = convertToDailyShiftBookings(shiftBookings)

      yield put({
        type: types.DAILY_SHIFT_BOOKINGS_FETCH_SUCCEEDED,
        payload: dailyShiftBookings,
      })
    }
  }

  yield takeEvery(types.WORKER_FETCH_SUCCEEDED, function* (action) {
    yield unpack(action.payload, action)
  })
  yield takeEvery(types.WORKERS_FETCH_SUCCEEDED, function* (action) {
    yield all(
      map(action.payload, worker => {
        return unpack(worker, action)
      }),
    )
  })
}
