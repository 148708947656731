import React from 'react'

const TeamLeader = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <title>{`Team leader`}</title>
    <path
      d="M42.489 31.72h.406c4.102 0 7.499 2.59 8.086 6.467.06.39.102 1.982-1.95 1.982h-4.767c.056-.088.108-.178.157-.272a4.366 4.366 0 0 0 .428-2.642 11.683 11.683 0 0 0-2.36-5.536zm-18.083-2.635c-.27.173-.532.357-.786.55a4.05 4.05 0 0 1-1.912.475c-2.186 0-3.958-1.712-3.958-3.823 0-2.111 1.772-3.823 3.958-3.823s3.959 1.712 3.959 3.823c0 1.105-.486 2.1-1.261 2.798zm-2.903 2.634a11.684 11.684 0 0 0-2.36 5.54 4.436 4.436 0 0 0 .44 2.573c.06.117.125.23.194.337h-4.8c-2.048 0-2.008-1.697-1.965-1.98.586-3.878 3.983-6.47 8.087-6.47h.404zm17.226-5.432c0-2.111 1.772-3.823 3.959-3.823 2.186 0 3.958 1.712 3.958 3.823 0 2.111-1.772 3.823-3.959 3.823-2.186 0-3.958-1.712-3.958-3.823zm-11.875-4.225c0-2.89 2.304-5.231 5.146-5.231s5.146 2.342 5.146 5.23c0 2.89-2.304 5.232-5.146 5.232s-5.146-2.342-5.146-5.231zm4.61 6.84h1.064c5.343 0 9.766 3.455 10.531 8.624.077.52.133 2.643-2.54 2.643H23.49c-2.667 0-2.614-2.262-2.559-2.64.764-5.17 5.188-8.627 10.532-8.627z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default TeamLeader
