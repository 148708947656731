import { select, takeEvery } from 'redux-saga/effects'
import { get, reject, identity } from 'lodash'

import { getCurrentEmployerAccount } from 'selectors/auth'
import * as types from 'actions/action-types'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'

export function* fetchEmployerAccounts(options = {}) {
  yield takeEvery(types.EMPLOYER_ACCOUNTS_FETCH_BEGIN, function* (action) {
    const currentEmployerAccount = yield select(getCurrentEmployerAccount)

    // BE sends us the current user's own employerAccount here.
    // We do not want to include our own account in the list
    const removeCurrentEmployerAccount = employerAccounts =>
      reject(employerAccounts, { id: get(currentEmployerAccount, 'id') })

    const filterEmployerAccounts = action.payload.withoutCurrentUser ? removeCurrentEmployerAccount : identity

    yield entityActionRequest(
      {
        ...options,
        normalizePayload: filterEmployerAccounts,
      },
      action,
    )
  })
}
