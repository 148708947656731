import React from 'react'

const DoorSupervisor = props => (
  <svg viewBox="0 0 500 500" {...props}>
    <g>
      <circle className="st1" cx={249.7} cy={191.8} r={21.1} />
      <path
        className="st1"
        d="M309.9 245.5l-24.2-22.9c-3-2.8-7-4.4-11.2-4.4h-3.7l-11.2 22.2-6.1-8.7c1.3-.9 3.3-3.6 3.3-9 0-3.9-3-4.6-6.9-4.6h-.2c-3.9 0-6.9.7-6.9 4.6 0 5.4 2 8 3.3 9l-6.1 8.7-11.2-22.2H225c-4.2 0-8.2 1.6-11.2 4.4l-24.2 22.9c-3.6 3.6-4.1 9.1-1.3 13.3l18.2 24.3c2 2.9 5.3 4.5 8.7 4.5 2 0 4.1-.6 5.9-1.8 2.1-1.4 3.5-3.5 4.2-5.8L213 357.5c-.6 3 .1 6.1 1.9 8.6 1.8 2.5 4.4 4.2 7.4 4.8.7.1 1.4.2 2.1.2 5.6 0 10.4-4 11.4-9.4l13.9-69.3 13.9 69.3c1 5.4 5.8 9.4 11.4 9.4.7 0 1.4-.1 2.1-.2 3-.6 5.7-2.2 7.4-4.8 1.8-2.5 2.4-5.6 1.9-8.6L274.1 280c.6 2.3 2 4.4 4.2 5.8 1.8 1.2 3.9 1.8 5.9 1.8 3.4 0 6.7-1.6 8.7-4.5l18.2-24.3c2.9-4.2 2.4-9.8-1.2-13.3zm-100 9.3l12.7-13 3 34.3c-.2-1.7-.7-3.3-1.7-4.8l-14-16.5zm15.8 22.9v-.4.4zm48-.3c0 .1.1.2.1.4l-.1-.4zm1.9-6.1c-1 1.5-1.6 3.2-1.7 4.8l3-34.3 12.7 13-14 16.5z"
      />
      <g>
        <path
          className="st1"
          d="M356.4 364.6h-52.1c-5.2 0-9.5-4.3-9.5-9.5s4.3-9.5 9.5-9.5h42.6V149.5H160v196.1h36.1c5.2 0 9.5 4.3 9.5 9.5s-4.3 9.5-9.5 9.5h-45.6c-5.2 0-9.5-4.3-9.5-9.5V140c0-5.2 4.3-9.5 9.5-9.5h205.9c5.2 0 9.5 4.3 9.5 9.5v215.1c0 5.3-4.3 9.5-9.5 9.5z"
        />
      </g>
    </g>
  </svg>
)

export default DoorSupervisor
