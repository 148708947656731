/* eslint-disable func-names */
/* eslint-disable import/order */

import { put, takeLatest, select, call, all } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import actions from 'actions'
import { markAllNotificationsAsRead as markAllNotificationsAsReadApi } from 'api'
import { getEntityMap } from 'lib/redux-crud/selectors'
import { getEmployerId } from 'selectors/auth'
import { putFailureAction } from './entityCall'

export function* startSyncingNotifications() {
  yield put(
    actions.notification.sync.start(
      {},
      {
        syncId: 'global',
        syncInterval: 30 * 1000,
        nonVisibleSyncInterval: 300 * 1000,
      },
    ),
  )

  yield takeLatest(types.AUTH_LOG_OUT, function* () {
    yield put(actions.notification.sync.stop({}, { syncId: 'global' }))
  })
}

// Marking all existing notifications as read.
//
// Watch for NOTIFICATION_UPDATE_BEGIN with `payload.id === '[all]'.
// When such action is received we use a custom endpoint to mark all notifications
// as read. On success we manuallly update `read` property for each entity we've stored.
//
// Notes:
// Ideally we'd like tell redux-crud and entityForm to query all available
// notifications and change `read` to `true` (in SQL this would be
// `UPDATE notifications SET read = 1`). Currently, entityForm consumes
// an entity (or group of) instead of a query so this is not yet possible.
// Ideal interface might look like this:
// ```
// entityForm({
//   // Predicates
//   mapPropsToQuery: () => ({ $type: 'notification' }),
//   // Update statement
//   update: () => ({ read: true }),
// })
// ```
export function* markAllNotificationsAsRead(action) {
  try {
    const employerId = yield select(getEmployerId)

    yield call(markAllNotificationsAsReadApi, employerId)

    yield put({
      type: types.NOTIFICATION_MARK_ALL_AS_READ_SUCCEEDED,
    })

    yield put({
      type: types.SET_SCREEN_READER_ALERT,
      payload: {
        alert: 'All notifications successfully marked as read',
      },
    })

    const entityMap = yield select(rootState => getEntityMap(rootState.notifications))
    const ids = Object.keys(entityMap)

    yield all(
      ids.map(id =>
        put({
          type: types.NOTIFICATION_UPDATE_SUCCEEDED,
          payload: { read: true, id: parseInt(id, 10) },
        }),
      ),
    )
  } catch (e) {
    return yield putFailureAction({
      type: types.NOTIFICATION_MARK_ALL_AS_READ_FAILED,
      error: e,
      beginAction: action,
    })
  }
}

export function* watchForMarkAllNotificationsAsRead() {
  yield takeLatest(types.NOTIFICATION_MARK_ALL_AS_READ_BEGIN, markAllNotificationsAsRead)
}
