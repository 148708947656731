import React from 'react'

const WaitingStaff = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Waiting staff`}</title>
    <path
      d="M100.52 80.958l-3.967 7.936A2 2 0 0 1 94.763 90H43.237a2 2 0 0 1-1.789-1.106l-3.968-7.936H32a2 2 0 1 1 0-4h74a2 2 0 0 1 0 4h-5.48zm-37.252-35.39a6.5 6.5 0 1 1 11.465-.001C86.603 47.954 95.613 57.658 97 70.05a10.306 10.306 0 0 1 .056.95 2 2 0 0 1-2 2H42.943a2 2 0 0 1-2-2.046c.008-.338.027-.639.057-.904 1.396-12.38 10.404-22.09 22.268-24.483zM69 45a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
      fill="#003a9b"
      fillRule="nonzero"
    />
  </svg>
)

export default WaitingStaff
