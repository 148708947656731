// eslint-disable-next-line no-restricted-imports
import * as dateFns from 'date-fns'
import { convertTokens, legacyParse } from '@date-fns/upgrade/v2'
import { overArgs, isString, map, isArray, identity, defaultTo } from 'lodash'

// reexport all libraries that dont require wrapping
// eslint-disable-next-line no-restricted-imports
export { isDate, isValid } from 'date-fns'

export const parseISO = (date, opts) => {
  // date-fns v2 tries to parse Date instance and returns 'Invalid Date'. If its already a Date, return as is
  if (dateFns.isDate(date)) return date

  if (isString(date)) {
    const parsed = dateFns.parseISO(date, opts)

    // date-fns v2 parser is little bit different than v1, then lets return only valid dates and give legacy parser a try
    if (dateFns.isValid(parsed)) {
      return parsed
    }
  }

  // fallback to legacy parser in case we couldn't parse string
  return legacyParse(date)
}

export const parse = (date, dateFormat, dateReference, options = {}) => {
  const { useV1Tokens, ...opts } = options
  return dateFns.parse(date, useV1Tokens ? convertTokens(dateFormat) : dateFormat, dateReference, {
    useAdditionalDayOfYearTokens: useV1Tokens,
    useAdditionalWeekYearTokens: useV1Tokens,
    ...opts,
  })
}

const convertToDate = parseISO
const convertToDates = dates => {
  if (process.env.NODE_ENV !== 'production') {
    if (!dates || !isArray(dates)) {
      console.warn(`[date-fns] min/max received '${dates}' value. Please investigate`)
      console.error(new Error().stack)
    }
  }
  return map(dates, date => convertToDate(date))
}
const convertIntervalsToDate = interval =>
  Object.fromEntries(
    Object.entries(interval).map(([key, val]) => {
      if (key === 'start' || key === 'end') {
        return [key, convertToDate(val)]
      }
      return [key, val]
    }),
  )

const safeDateFnCall =
  (fn, defaultReturnValue) =>
  (...args) => {
    try {
      return fn(...args)
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        if (e instanceof RangeError || e instanceof TypeError) {
          console.warn(
            `[date-fns] ${fn.name || 'date-fns'} function received invalid params ${JSON.stringify(
              args,
            )}. Please investigate`,
          )
          console.error(new Error().stack)
        }
      }

      return defaultReturnValue
    }
  }

const withConvertedDateArgs = (fn, fallback, times = 1) =>
  safeDateFnCall(overArgs(fn, Array(times).fill(convertToDate)), fallback)
const withConvertedIntervalArgs = (fn, fallback, times = 1) =>
  safeDateFnCall(overArgs(fn, Array(times).fill(convertIntervalsToDate)), fallback)
const withZeroDefaultValue = fn => overArgs(fn, [identity, value => defaultTo(value, 0)])

export const format = withConvertedDateArgs((date, format, { useV2Tokens, ...opts } = {}) => {
  if (!isString(format)) return dateFns.formatISO(date, format)

  return dateFns.format(date, useV2Tokens ? format : convertTokens(format), opts)
}, new Date(NaN).toString())
export const formatISO = format

export const min = safeDateFnCall(overArgs(dateFns.min, [convertToDates]), new Date(NaN))
export const max = safeDateFnCall(overArgs(dateFns.max, [convertToDates]), new Date(NaN))

export const eachDayOfInterval = withConvertedIntervalArgs(dateFns.eachDayOfInterval, [])
export const isWithinInterval = safeDateFnCall(
  overArgs(dateFns.isWithinInterval, [convertToDate, convertIntervalsToDate]),
  false,
)
export const areIntervalsOverlapping = withConvertedIntervalArgs(dateFns.areIntervalsOverlapping, false, 2)
export const formatDistanceToNow = withConvertedDateArgs(dateFns.formatDistanceToNow, '')

/** ** ADD date helpers ****/
export const addDays = withConvertedDateArgs(dateFns.addDays)
export const addHours = withConvertedDateArgs(dateFns.addHours)
export const addMinutes = withConvertedDateArgs(dateFns.addMinutes)
export const addMonths = withConvertedDateArgs(dateFns.addMonths)
export const addSeconds = withConvertedDateArgs(dateFns.addSeconds)
export const addWeeks = withConvertedDateArgs(dateFns.addWeeks)
export const addYears = withConvertedDateArgs(dateFns.addYears)

/** ** COMPARE date helpers ****/
export const compareAsc = withConvertedDateArgs(dateFns.compareAsc, 0, 2)
export const compareDesc = withConvertedDateArgs(dateFns.compareDesc, 0, 2)

/** ** DIFF date helpers ****/
export const differenceInCalendarDays = withConvertedDateArgs(dateFns.differenceInCalendarDays, NaN, 2)
export const differenceInHours = withConvertedDateArgs(dateFns.differenceInHours, NaN, 2)
export const differenceInMinutes = withConvertedDateArgs(dateFns.differenceInMinutes, NaN, 2)

/** ** END date helpers ****/
export const endOfDay = withConvertedDateArgs(dateFns.endOfDay)
export const endOfHour = withConvertedDateArgs(dateFns.endOfHour)
export const endOfMonth = withConvertedDateArgs(dateFns.endOfMonth)
export const endOfWeek = withConvertedDateArgs(dateFns.endOfWeek)

/** ** GET date helpers ****/
export const getHours = withConvertedDateArgs(dateFns.getHours)
export const getISODay = withConvertedDateArgs(dateFns.getISODay)
export const getISOWeek = withConvertedDateArgs(dateFns.getISOWeek)
export const getMilliseconds = withConvertedDateArgs(dateFns.getMilliseconds)
export const getMinutes = withConvertedDateArgs(dateFns.getMinutes)
export const getSeconds = withConvertedDateArgs(dateFns.getSeconds)

/** ** IS date helpers ****/
export const isAfter = withConvertedDateArgs(dateFns.isAfter, false, 2)
export const isBefore = withConvertedDateArgs(dateFns.isBefore, false, 2)
export const isEqual = withConvertedDateArgs(dateFns.isEqual, false, 2)
export const isFuture = withConvertedDateArgs(dateFns.isFuture)
export const isPast = withConvertedDateArgs(dateFns.isPast)
export const isSameDay = withConvertedDateArgs(dateFns.isSameDay, false, 2)
export const isSameMonth = withConvertedDateArgs(dateFns.isSameMonth, false, 2)
export const isSameYear = withConvertedDateArgs(dateFns.isSameYear, false, 2)
export const isToday = withConvertedDateArgs(dateFns.isToday)
export const isTomorrow = withConvertedDateArgs(dateFns.isTomorrow)

/** ** SET date helpers ****/
export const setDate = withZeroDefaultValue(withConvertedDateArgs(dateFns.setDate))
export const setDay = withZeroDefaultValue(withConvertedDateArgs(dateFns.setDay))
export const setHours = withZeroDefaultValue(withConvertedDateArgs(dateFns.setHours))
export const setMilliseconds = withZeroDefaultValue(withConvertedDateArgs(dateFns.setMilliseconds))
export const setMinutes = withZeroDefaultValue(withConvertedDateArgs(dateFns.setMinutes))
export const setMonth = withZeroDefaultValue(withConvertedDateArgs(dateFns.setMonth))
export const setSeconds = withZeroDefaultValue(withConvertedDateArgs(dateFns.setSeconds))
export const setYear = withZeroDefaultValue(withConvertedDateArgs(dateFns.setYear))

/** ** START date helpers ****/
export const startOfDay = withConvertedDateArgs(dateFns.startOfDay)
export const startOfHour = withConvertedDateArgs(dateFns.startOfHour)
export const startOfMonth = withConvertedDateArgs(dateFns.startOfMonth)
export const startOfWeek = withConvertedDateArgs(dateFns.startOfWeek)
export const startOfToday = withConvertedDateArgs(dateFns.startOfToday)

/** ** SUB date helpers ****/
export const subDays = withZeroDefaultValue(withConvertedDateArgs(dateFns.subDays))
export const subMinutes = withZeroDefaultValue(withConvertedDateArgs(dateFns.subMinutes))
export const subHours = withZeroDefaultValue(withConvertedDateArgs(dateFns.subHours))
export const subMonths = withZeroDefaultValue(withConvertedDateArgs(dateFns.subMonths))
export const subWeeks = withZeroDefaultValue(withConvertedDateArgs(dateFns.subWeeks))
export const subYears = withZeroDefaultValue(withConvertedDateArgs(dateFns.subYears))

export const analogTime = date => `${getHours(date)}:${String(getMinutes(date)).padStart(2, '0')}`
