import PropTypes from 'prop-types'
import React from 'react'
import { omit } from 'lodash'
import classnames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'

import injectSheet from 'styles/jss'
import styles from './styles'

// Anchor (<a>) with Syft styles applied
// Don't confuse with react-router's Link component

const Link = props => {
  const { className, style, children } = props

  const textClassName = classnames({
    [props.classes.primary]: !props.black,
    [props.classes.black]: props.black,
  })

  return (
    <RouterLink
      {...omit(props, 'sheet', 'classes')}
      className={classnames(props.classes.root, className)}
      style={style}
    >
      {typeof children === 'string' ? <span className={textClassName}>{children}</span> : children}
    </RouterLink>
  )
}

Link.propTypes = {
  className: PropTypes.string,
  black: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
}

export const RawLink = injectSheet(styles)(({ classes, children, className, variant, ...props }) => (
  <a {...props} className={classnames(classes.root, className)} data-testid="raw-link">
    <span className={classes[variant] || classes.primary}>{children}</span>
  </a>
))

export default injectSheet(styles)(Link)
