import { REHYDRATE } from 'redux-persist'
import { put, take } from 'redux-saga/effects'

import { rehydrationComplete } from 'actions/rehydration'
import { fetchUserData } from './auth'

/**
 * Watching for persist/REHYDRATE action and dispatches REHYDRATION_COMPLETE action after all critical dependencies are loaded
 *
 * App is not rendering until app completed store rehydration (see App/index.js)
 *
 * @returns {Generator<SelectEffect|TakeEffect|PutEffect<*>|PutEffect<{type: string}>, void, *>}
 */
export const watchForPersistRehydration = function* () {
  yield take(REHYDRATE)
  yield fetchUserData()

  // Add here page blocking calls
  // EXAMPLE:
  //    const isUserLoggedIn = yield select(isLoggedIn)
  //
  //    if (!isUserLoggedIn) {
  //      yield put(actions.enabledFeature.fetchEnabledFeature({ isUnauthenticated: true }))
  //      yield take([types.ENABLED_FEATURE_FETCH_SUCCEEDED, types.ENABLED_FEATURE_FETCH_FAILED])
  //    }

  yield put(rehydrationComplete())
}
