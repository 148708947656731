import { styles as inputStyles } from '@material-ui/core/Input/Input'
import { styles as tableCellStyles } from '@material-ui/core/TableCell/TableCell'

// This module allows us to customize Material UI's JSS stylesheets
// to define our own theme.
//
// Note that you Material UI's JSS is often tied to application logic and you may
// deviate from the official Material UI spec.
//
// See also createGenerateClassName.js

export default theme => ({
  MuiFormControl: {
    root: {
      // Backwards compatibility, remove to stay up to date with MUI
      display: 'flex',
    },
  },

  MuiPaper: {
    rounded: {
      // According to designs for <Menu>s
      borderRadius: 15,
    },

    elevation8: theme.paper10.default,

    root: {
      '&:focus': {
        outline: 'none',
      },
    },
  },

  MuiList: {
    root: {
      WebkitOverflowScrolling: 'touch',
    },
  },

  MuiDialog: {
    paper: {
      // Fix for IE11. Without this, <Dialog> itself would be scrollable
      // instead of <DialogContent>
      overflowY: 'auto',
    },
  },

  MuiModal: {
    root: {
      '& .MuiPaper-root:not(.MuiDialog-paperFullScreen)': {
        maxWidth: 'calc(100vw - 32px)',
        maxHeight: 'calc(100vh - 32px)',
      },
    },
  },

  MuiTypography: {
    colorTextSecondary: {
      color: theme.palette.text.secondary,
    },
  },

  MuiListItem: {
    button: {
      '&:hover': {
        backgroundColor: theme.palette.action.hoverBackground,
      },
    },
  },

  MuiFormLabel: {
    root: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightRegular,
      color: theme.palette.text.secondary,
    },
    error: {
      color: theme.palette.text.secondary,
    },
    focused: {
      color: theme.palette.text.secondary,
    },
  },

  MuiInputLabel: {
    root: {
      transform: 'none',
    },
    shrink: {
      transform: 'none',
    },
  },

  MuiInput: (() => {
    const input = inputStyles(theme)

    return {
      root: {
        fontSize: theme.typography.pxToRem(14),
      },
      formControl: {
        'label + &': {
          // 20px from the baseline field label text to midline of the field text (or top
          // edge of the field content)
          marginTop: theme.spacing.unit * 3 - 2,
        },
      },
      underline: {
        '&:after': {
          backgroundColor: theme.palette.primary.main,
          borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '&:before': {
          transition: 'none',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderBottom: '0 none',
          height: '1px',
        },
        // Disable hover effects
        '&:hover:not($disabled):before': {
          height: input.underline['&:before'].height,
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        },
        '&:hover:not(.MuiInput-disabled):not(.MuiInput-focused):not(.MuiInput-error):before': {
          borderBottom: '0 none',
        },
      },
    }
  })(),

  MuiTextarea: {
    textarea: {
      cursor: 'text',
      minHeight: '15px',

      '&.MuiInput-disabled': {
        pointerEvents: 'none',
        cursor: 'default',
      },
    },
  },

  MuiButtonBase: {
    root: {
      '&.MuiIconButton-root': {
        padding: 0,
        width: '48px',
        height: '48px',
      },

      // Ripple is disabled by default as it is a signature element of Material UI,
      // to which people can have adverse reaction (we want the UI to be 100% likeable).
      //
      // Ripple is now preserved for form input focus where it helps recognizing
      // the focused button.
      '& .MuiTouchRipple-rippleVisible': {
        color: 'transparent',
      },
      '& .MuiTouchRipple-ripplePulsate': {
        color: 'inherit',
        opacity: 0.2,
      },
    },
    focusVisible: {
      outline: '5px auto -webkit-focus-ring-color',
    },
  },

  MuiTableRow: (() => {
    const borderColor = tableCellStyles(theme).root.borderBottom.match(/(rgb.*)$/)[0]
    return {
      hover: {
        '&:hover': {
          backgroundColor: borderColor,
        },
      },
      head: {
        height: 'auto',
      },
    }
  })(),

  MuiChip: (() => {
    return {
      root: {
        borderRadius: 100,
      },
      clickable: {
        backgroundColor: 'white',
        ...theme.paper0.default,
        '&:hover': theme.paper0.hover,
        '&:focus': theme.paper0.active,
        '&:active': theme.paper0.active,
      },

      label: {
        color: theme.palette.colors.grey,
      },
      outlined: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
      },
    }
  })(),

  MuiTableCell: {
    root: {
      borderBottom: `1px solid ${theme.palette.lightDivider}`,
      padding: '4px 36px 4px 12px',
      '&:first-of-type': {
        paddingLeft: 0,
      },
    },
  },

  MuiDivider: {
    light: {
      backgroundColor: theme.palette.lightDivider,
    },
  },

  MuiGrid: {
    item: {
      lineHeight: 1,
      flex: '0 0 auto',
    },
  },

  MuiSelect: {
    icon: {
      color: 'inherit',
      marginRight: theme.spacing.unit / 2,
    },
  },
})
