import React from 'react'

const Clock = props => (
  <svg width={32} height={32} viewBox="0 0 32 32" {...props}>
    <g fill="currentColor">
      <path d="M16 7c-4.962 0-9 4.038-9 9s4.038 9 9 9 9-4.038 9-9-4.038-9-9-9zm0 16.211c-3.977 0-7.212-3.234-7.212-7.211A7.22 7.22 0 0 1 16 8.788c3.977 0 7.211 3.235 7.211 7.212A7.218 7.218 0 0 1 16 23.211z" />
      <path d="M19.19 13.833l-2.938 1.128-3.135-3.527a.566.566 0 0 0-.887.697l3.067 4.425.012.018.007.008-2.008 4.045a.373.373 0 1 0 .668.331l2.005-4.04a.804.804 0 0 0 .401-.111l3.303-1.949a.57.57 0 0 0-.495-1.025z" />
    </g>
  </svg>
)

export default Clock
