import payRateSchema from './payRate'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    workers_booked: {
      type: 'number',
      description: 'Number of workers',
    },
    pay_rate: payRateSchema,
    agency_id: {
      type: 'integer',
      $rel: 'agency',
      description: 'Agency',
    },
  },
  required: ['workers_booked', 'pay_rate', 'agency_id'],
}
