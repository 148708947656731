import { select } from 'redux-saga/effects'
import { get } from 'lodash'
import TagManager from 'react-gtm-module'

import config from 'config'
import { getInternalResourcingStatus } from 'selectors/platform'
import { currentUser, getEmployerId } from 'selectors/auth'
import { history } from 'lib/history'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { gaTrackEvent, gtmTrackPageView } from 'lib/analytics/googleAnalytics'
import { customEvents, userProperties } from 'lib/analytics/googleAnalytics/constants'
import { AUTH_LOG_OUT, AUTH_LOGIN_SUCCEEDED, REHYDRATION_COMPLETE } from 'actions/action-types'

let gaInitialized = false
let userDataSet = false
let unlistenGA

function launchGaInitializer() {
  if (config.googleTagManagerID) {
    TagManager.initialize({
      gtmId: config.googleTagManagerID,
      events: customEvents,
    })
    gaInitialized = true
  }
}

function* setUserData() {
  const userData = yield select(rootState => currentUser(rootState))
  const employerID = yield select(rootState => getEmployerId(rootState))
  const isInternalResourcing = yield select(rootState => getInternalResourcingStatus(rootState))

  TagManager.dataLayer({
    dataLayer: {
      [userProperties.USER_ID]: userData.id,
      [userProperties.EMPLOYER_ID]: employerID,
      [userProperties.IS_INTERNAL_RESOURCING]: isInternalResourcing,
    },
  })
  userDataSet = true
}

export function* analyticsInitialize(options) {
  yield takeLatestDeep([REHYDRATION_COMPLETE], function* (action) {
    !gaInitialized && launchGaInitializer()
    if (!unlistenGA) unlistenGA = history.listen((location, action) => gtmTrackPageView({ location, action }))

    const isLoggedIn = yield select(rootState => get(rootState, 'auth.isLoggedIn'))
    if (isLoggedIn) yield setUserData()
  })

  yield takeLatestDeep([AUTH_LOGIN_SUCCEEDED], function* (action) {
    if (!userDataSet) yield setUserData()
    gaTrackEvent('authentication', 'login', 'login successful')
  })
}

// when the user logs out we should stop tracking
export function* analyticsTerminate(options) {
  yield takeLatestDeep([AUTH_LOG_OUT], function* (action) {
    yield unlistenGA && unlistenGA()
    userDataSet = false
    unlistenGA = null
  })
}
