import { brandKeys, countryCodes } from 'common/constants'
import { getItem as getLocalStorageItem } from 'lib/safeLocalStorage'
import type { InitialState } from '../server/utils/initialState'
import { getMaintenanceMode } from '../lib/maintenanceMode'

export default (initialState: InitialState) => {
  const { client, stagingLevel, reactAppOverrides, apiConfig, isLemma, ctk, isPrivileged } = initialState
  const IS_FLEX =
    getLocalStorageItem('TESTING_BRAND_KEY') === brandKeys.INDEED_FLEX ||
    apiConfig.apiRegion?.includes(countryCodes.US)
  const API_URL = apiConfig.apiBaseUrl
  const BRAND_KEY = IS_FLEX ? brandKeys.INDEED_FLEX : brandKeys.SYFT
  const REACT_APP_GTM_CONTAINER_ID_FALLBACK = IS_FLEX ? 'GTM-PL7NF2B' : 'GTM-TRZ4MGW' // staging container ID
  const isE2E = !!window.Cypress
  return {
    ctk,
    isPrivileged,
    // This is replaced by supportDetails.telephoneNumber from Profile API
    customerSupportPhone: IS_FLEX ? '+1 (737) 300 9696' : '+44 203 790 7900',
    customerSupportEmail: IS_FLEX ? 'customersuccess@indeedflex.com ' : 'clientsupport@indeedflex.com',
    // Frontend Maps API key (Syft Backend Google account)
    googleMapsApiKey: 'AIzaSyB1K7OI5oxevceGNBPc4HEb8i2Odc8pAR0',
    appLink: 'https://syft.app.link/download-app',
    googleTagManagerID: client.gtm_container_id || REACT_APP_GTM_CONTAINER_ID_FALLBACK,
    ADYEN_PUBLIC_CLIENT_KEY: client.adyen_public_client_key,
    IS_STAGING_ENV: stagingLevel === 'qa',
    IS_DEMO_ENV: apiConfig.apiRegion?.includes('DEMO'),
    IS_PROD_ENV: stagingLevel === 'prod',
    API_REGION: apiConfig.apiRegion,
    API_URL,
    IS_MANDATORY_CC_PAYMENT_FLOW: client.is_mandatory_cc_payment_flow === 'true',
    BRAND_KEY,
    IS_FLEX,
    USER_IDLE_TIMEOUT: client.user_idle_timeout,
    ENABLE_LIVEAGENT: client.enable_liveagent,
    LIVEAGENT_CONTENT_URL: client.liveagent_content_url,
    LIVEAGENT_BUTTON_ID: client.liveagent_button_id,
    LIVEAGENT_BASE_URL: client.liveagent_base_url,
    LIVEAGENT_ESW_DEV_NAME: client.liveagent_esw_dev_name,
    LIVEAGENT_ESW_NAME: client.liveagent_esw_name,
    GOOGLE_API_KEY: client.google_api_key,
    IS_E2E: isE2E,
    IS_LEMMA: isLemma,
    ...initialState.version,
    ...Object.entries(reactAppOverrides).reduce(
      (overrides, [key, value]) => ({
        ...overrides,
        [key.replace('REACT_APP_', '')]: value,
      }),
      {},
    ),
    SUPPORTED_LOCALES: (initialState.reactAppOverrides.REACT_APP_SUPPORTED_LOCALES || '').split(','),
    MAINTENANCE_MODE: getMaintenanceMode(initialState),
    featureFlags: initialState.featureFlags,
  } as const
}
