export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    company_name: {
      type: 'string',
    },
    job_role: {
      type: 'string',
    },
    desc: {
      type: 'string',
    },
    started: {
      type: 'string',
    },
    ended: {
      type: 'string',
    },
  },
}
