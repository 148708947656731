import { merge } from 'lodash'
import { fade } from '@material-ui/core/styles/colorManipulator'

import { pastelRed } from 'styles/colors'
import { computePalette, setHslCoeffs } from 'styles/colorManipulator'

export const buttonBorderRadius = 8

const buttonHeight = 40
const lightGrey = '#ededed'

const defaultCoeff = 0.12
const emphasizeHover = (color, useLightness, useAbsoluteValues, coeff = defaultCoeff) =>
  useAbsoluteValues
    ? fade(color, coeff)
    : setHslCoeffs(color, !useLightness ? { a: 1 + coeff } : { l: 0.95 }, { out: 'hsl' })
const emphasizeActive = (color, useLightness, useAbsoluteValues, coeff = defaultCoeff) =>
  useAbsoluteValues
    ? fade(color, coeff * 2)
    : setHslCoeffs(color, !useLightness ? { a: 1 + coeff * 3 } : { l: 0.9 }, { out: 'hsl' })

const actionStyleStructure = ({ prop, ...colors }) => ({
  [prop]: colors.default,
  '&$keyboardFocused:not($text)': {
    [prop]: colors.hover,
  },
  '&:hover:not($text)': {
    [prop]: colors.hover,
    // Reset on mouse devices
    '@media (hover: none)': {
      [prop]: colors.default,
    },
  },
  '&:active:not($text)': {
    [prop]: colors.active,
  },
})

const actionColorProgression = ({
  palette,
  color,
  backgroundColor,
  backgroundPalette,
  useLightness,
  useAbsoluteValues,
  defaultBackgroundValue,
}) => {
  const computedBackgroundColor = backgroundPalette ? backgroundPalette.main : backgroundColor
  const hoverBackgroundColor = backgroundPalette
    ? backgroundPalette[900]
    : emphasizeHover(computedBackgroundColor || color, useLightness, useAbsoluteValues)
  const activeBackgroundColor = backgroundPalette
    ? backgroundPalette[1000]
    : emphasizeActive(computedBackgroundColor || color, useLightness, useAbsoluteValues)

  return {
    default: defaultBackgroundValue || computedBackgroundColor || palette.common.white,
    hover: hoverBackgroundColor,
    active: activeBackgroundColor,
  }
}

const flatButtonStyle = ({ palette, color = palette.primary.main, ...rest }) => {
  const actionColors = actionColorProgression({ color, palette, ...rest })
  return {
    color: color || palette.getContrastText(actionColors.default),
    ...actionStyleStructure({ prop: 'backgroundColor', ...actionColors }),
  }
}

const raisedButtonStyle = ({
  borderColor,
  palette,
  color = palette.primary.main,
  matchBorderToBackground = false,
  ...rest
}) => {
  const actionColors = actionColorProgression({ color, palette, ...rest })
  const backgroundStyles = {
    color: color || palette.getContrastText(actionColors.default),
    ...actionStyleStructure({ prop: 'backgroundColor', ...actionColors }),
    '&$disabled': {
      backgroundColor: palette.divider,
      color: palette.action.disabled,
    },
  }
  const borderBaseStyles = {
    borderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    '&$disabled': {
      borderWidth: 0,
      borderColor: palette.action.disabled,
    },
  }
  const borderProgressionStyles = matchBorderToBackground
    ? actionStyleStructure({
        prop: 'borderColor',
        ...actionColors,
        default: borderColor || actionColors.default,
      })
    : actionStyleStructure({
        prop: 'borderColor',
        default: borderColor,
        hover: borderColor,
        active: borderColor,
      })
  return merge(backgroundStyles, borderBaseStyles, borderProgressionStyles)
}

export default ({ typography, transitions, spacing, palette }) => ({
  root: {
    ...typography.button,
    lineHeight: '1',
    boxSizing: 'border-box',
    minWidth: 80,
    height: buttonHeight,
    padding: `0 ${2 + spacing.unit * 2}px`,
    borderRadius: buttonBorderRadius,
    fontSize: typography.pxToRem(16),
    fontWeight: typography.fontWeightRegular,
    letterSpacing: '.02em',
    color: palette.primary.main,
    transition: transitions.create(['background-color', 'background-image', 'box-shadow'], {
      duration: transitions.duration.short,
    }),
    '&:hover': {
      textDecoration: 'none',
    },
    '& .MuiCircularProgress-root': {
      // TODO Remove
      width: '24px !important',
      height: '24px !important',
    },
  },

  square: {
    borderRadius: 0,
  },

  // Applicable to `color` variants (flatError, flatSecondary)
  text: {
    minWidth: 0,
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 'inherit',
    height: 'auto',
    fontWeight: typography.fontWeightMedium,
    letterSpacing: '0em',
    border: 0,
    '&:hover, &:active': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
    '&:active': {
      color: setHslCoeffs(palette.primary.main, { l: 1.2 }),
    },
    '&$disabled': {
      backgroundColor: 'transparent',
    },
  },

  fullWidth: {
    width: '100%',
  },

  textAlignLeft: {
    justifyContent: 'flex-start',
  },

  dense: {
    padding: `${spacing.unit - 1}px ${spacing.unit}px`,
    minWidth: 64,
    minHeight: 32,
    fontSize: typography.pxToRem(typography.fontSize - 1),
  },

  // There's no distinction between flat default / flat primary styles
  flatDefault: flatButtonStyle({
    palette,
    color: palette.primary.main,
    useAbsoluteValues: true,
    defaultBackgroundValue: 'transparent',
  }),
  flatPrimary: flatButtonStyle({
    palette,
    color: palette.primary.main,
    useAbsoluteValues: true,
    defaultBackgroundValue: 'transparent',
  }),
  flatError: flatButtonStyle({ palette, color: palette.error.main, useAbsoluteValues: true }),
  flatSecondary: flatButtonStyle({
    palette,
    color: palette.text.secondary,
    useAbsoluteValues: true,
    matchBorderToBackground: true,
  }),
  flatSuccess: flatButtonStyle({ palette, color: palette.text.success, useAbsoluteValues: true }),
  flatWarning: flatButtonStyle({
    palette,
    color: palette.text.warning,
    useAbsoluteValues: true,
    matchBorderToBackground: true,
  }),

  outlined: {
    '&$flatError, &$raisedError': {
      borderWidth: 1,
      borderColor: palette.error.main,
      backgroundColor: 'transparent',
    },
  },

  colorInherit: {
    color: 'inherit',
  },

  raised: {
    fontWeight: typography.fontWeightRegular,
    position: 'relative', // for <ProgressOverlay>
    '&:hover': {
      textDecoration: 'none',
    },
  },

  keyboardFocused: {},

  raisedDefault: raisedButtonStyle({
    borderColor: palette.divider,
    backgroundColor: palette.common.white,
    palette,
    useLightness: true,
    matchBorderToBackground: true,
    defaultBackgroundValue: 'transparent',
  }),
  raisedPrimary: raisedButtonStyle({
    backgroundPalette: palette.primary,
    useLightness: true,
    color: palette.common.white,
    palette,
    matchBorderToBackground: true,
  }),
  raisedRed: raisedButtonStyle({
    backgroundPalette: computePalette(pastelRed),
    useLightness: true,
    matchBorderToBackground: true,
    color: palette.common.white,
    palette,
  }),
  raisedError: raisedButtonStyle({
    color: palette.error.main,
    borderColor: palette.error.main,
    palette,
    useAbsoluteValues: true,
  }),
  raisedSecondary: raisedButtonStyle({
    color: palette.text.secondary,
    borderColor: lightGrey,
    palette,
    useAbsoluteValues: true,
    matchBorderToBackground: true,
  }),
  raisedWarning: raisedButtonStyle({
    backgroundPalette: palette.warning,
    useLightness: true,
    color: palette.common.white,
    palette,
    matchBorderToBackground: true,
  }),
  raisedSuccess: raisedButtonStyle({
    backgroundPalette: palette.success,
    useLightness: true,
    color: palette.common.white,
    palette,
    matchBorderToBackground: true,
  }),

  big: {
    height: 50,
    padding: `0px ${spacing.unit * 4}px`,
    fontWeight: typography.fontWeightMedium,
    letterSpacing: '0em',
    '&$raisedPrimary': {
      letterSpacing: '0.02em',
    },
  },

  small: {
    fontSize: typography.pxToRem(14),
    height: 30,
    padding: `0 ${spacing.unit * 2}px`,
    fontWeight: typography.fontWeightRegular,
    letterSpacing: '0em',
    borderRadius: buttonBorderRadius - 2,
    '&$raised': {
      letterSpacing: '0em',
    },
    '&$raisedDefault': raisedButtonStyle({
      borderColor: palette.primary.main,
      palette,
      useAbsoluteValues: true,
    }),
  },

  disabled: {
    color: palette.action.disabled,
  },
})
