import { call, race, take } from 'redux-saga/effects'
import { delay } from 'redux-saga'

// Throw exception if the task exceeds a timeout
export function* timeout(task, options = {}) {
  const { maxDelay = 300, throwOnTimeout = true } = options
  const { taskResult, timeout } = yield race({
    taskResult: call(task),
    timeout: call(delay, maxDelay),
  })
  if (timeout && throwOnTimeout) {
    throw new Error('Saga task has timed out')
  }
  return taskResult
}

export function* takeOrTimeout(actionMatcher) {
  return yield timeout(function* () {
    return yield take(actionMatcher)
  })
}

export default timeout
