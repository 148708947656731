/* eslint-disable func-names */
/* eslint-disable import/order */
import { takeLatest, select, put } from 'redux-saga/effects'
import { get, isEqual } from 'lodash'

import { imageURL } from 'api/endpoints'
import * as types from 'actions/action-types'
import { userTypes } from 'api/entities/user'
import { brandKeys } from 'common/constants'
import flexPlusLogoUrl from 'common/images/indeed-flex-plus-logo.svg'
import flexLogoUrlV2 from 'common/images/indeed-flex-logo-v2.svg'

const actionsWithNewPlatformData = [types.AUTH_LOGIN_SUCCEEDED, types.AUTH_REFRESH_SUCCEEDED]

// Extract CSS color from the backend palette value.
// Convert unprefixed hex into prefixed, e.g. "032d60" ~> "#032d60"

// Platform theme from the backend has a slighly different format for compatiblity
// with iOS and Android.
//
// We also don't let the backend to change the native Syft theme
export const extractThemeFromPlatform = (platform = { key: brandKeys.SYFT, isAgency: false }) => {
  if (platform.id === 1) {
    return {
      name: 'IndeedFlex',
      logo: {
        url: platform.isAgency ? flexPlusLogoUrl : flexLogoUrlV2,
      },
    }
  }

  return {
    name: platform.name,
    logo: {
      url: platform.theme.headerLogoV2
        ? imageURL(platform.theme.headerLogoV2.uuid)
        : imageURL(platform.theme.headerLogo.uuid),
    },
  }
}

/**
 * Every time we receive new platform data from user login or auth refresh,
 * change the theme if needed
 */
export function* applyPlatformTheme(action) {
  const platform = get(action.payload, 'user.platform')
  if (!platform) return

  const currentPlatform = yield select(rootState => get(rootState, 'platform'))
  const isAgencyUser = get(action.payload, 'user.mainProfileType') === userTypes.agency
  const oldTheme = yield get(currentPlatform, 'theme')
  const newTheme = extractThemeFromPlatform({
    key: currentPlatform.key,
    ...(isAgencyUser ? { id: 1, isAgency: true } : platform),
  })

  if (!isEqual(oldTheme, newTheme)) {
    yield put({ type: types.PLATFORM_THEME_CHANGE, payload: newTheme })
  }
}

export function* watchForApplyPlatformTheme() {
  yield takeLatest(actionsWithNewPlatformData, applyPlatformTheme)
}
