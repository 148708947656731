import { includes } from 'lodash'

// WEB2-166 offer_to: null is sent to /listing/:id/publish endpoint
// WEB2-1280 work_location_ds: null is sent to /employers/:employer_id/blacklists/manage_worker/:worker_id
// SLUG-96 pay_rate: null is sent to PUT /admin/workers/:worker_id/roles/:role_id
const nullableKeys = ['offer_to', 'work_location_ids', 'pay_rate']

/**
 * Returns the provided arguments object as a JSON string to be used as request body.
 * Undefined values are filtered out.
 *
 * @param args Key/value pairs containing request data
 */
export const jsonBody = args => {
  const postArgs = Object.keys(args)
    // TODO Remove both null undefined and null checks. See WEB2-184
    .filter(key => args[key] !== undefined && (args[key] !== null || includes(nullableKeys, key)))
    .reduce((acc, item) => ({ ...acc, [item]: args[item] }), {})
  return JSON.stringify(postArgs)
}

/**
 * Returns an encoded HTTP query string for making fetch requests.
 * Undefined values are filtered out.
 *
 * @param args Key/value pairs containing request data
 */
export const queryString = args => {
  // Use encodeURIComponent() on each value, as required for application/x-www-form-urlencoded.
  const encodedArgs = Object.keys(args)
    // Temporarily skip null values. <https://syftapp.atlassian.net/browse/API2-101>
    .filter(key => args[key] !== undefined && args[key] !== null)
    .map(key => `${key}=${encodeURIComponent(args[key])}`)
    .join('&')

  return `?${encodedArgs}`
}
