import React from 'react'

const KitchenPorter = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Kitchen porter`}</title>
    <path
      d="M93 48v11h8a2 2 0 0 1 0 4h-8v34a6 6 0 0 1-6 6H51a6 6 0 0 1-6-6V63h-8a2 2 0 1 1 0-4h8V48h-3a2 2 0 1 1 0-4h16c0-6.075 4.925-11 11-11s11 4.925 11 11h16a2 2 0 1 1 0 4h-3zm-17-4a7 7 0 0 0-14 0h14z"
      fill="#003a9b"
      fillRule="nonzero"
    />
  </svg>
)

export default KitchenPorter
