/* eslint-disable func-names */
/* eslint-disable import/order */

import { call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityApiRequest } from 'api'
import { entityCall } from './entityCall'

// `listingId` is mandatory identifier (primary key) of timesheetApprovalStat entity
export const normalizePayload = payload => ({
  ...payload,
  listingId: payload.id,
})

// Add listingId reference to each fetched timesheetApprovalStat
export function* fetchTimesheetApprovalStat(options) {
  yield takeLatestDeep(types.TIMESHEET_APPROVAL_STAT_FETCH_BEGIN, function* (action) {
    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.get,
        entityType: 'timesheetApprovalStat',
        context: options.context,
        normalizePayload,
      },
      action,
    )
  })
}
