import React from 'react'
import classnames from 'classnames'
import { flowRight } from 'lodash'

import IndeedFlexNetwork from './IndeedFlexNetwork'

import injectSheet from 'styles/jss'

const styles = ({ spacing }) => ({
  icon: {
    marginBottom: spacing.unit * 4,
  },
  indeedFLexNetworkIcon: {},
})

const Network = ({ key, classes, ...props }) => (
  <IndeedFlexNetwork {...props} className={classnames(classes.icon, classes.indeedFLexNetworkIcon)} />
)

export default flowRight(injectSheet(styles))(Network)
