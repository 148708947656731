/* eslint-disable func-names */
/* eslint-disable import/order */

import { call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'

// Invitations are always fetched by `token` instead of `id`.
// In this saga we will make sure that the `token` that we used in request params
// is saved into redux store along the rest of entity.
// This `token` will be used as a canonical entity identifier.
export function* fetchEmployerAccountInvitation(options = {}) {
  yield takeLatestDeep(types.EMPLOYER_ACCOUNT_INVITATION_FETCH_BEGIN, function* (action) {
    const token = action.payload.token
    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.get,
        entityType: 'employerAccountInvitation',
        context: options.context,
        normalizePayload: payload => ({ ...payload, token }),
      },
      action,
    )
  })
}

export function* updateEmployerAccountInvitation(options = {}) {
  yield takeLatestDeep(types.EMPLOYER_ACCOUNT_INVITATION_UPDATE_BEGIN, function* (action) {
    const token = action.payload.token
    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.create,
        entityType: 'employerAccountInvitation',
        context: options.context,
        normalizePayload: payload => ({ ...payload, token }),
      },
      action,
    )
  })
}
