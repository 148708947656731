import React from 'react'

const ArrowLeft = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <title>{`arrow-left`}</title>
    <path
      d="M4.414 9l5.293 5.293-1.414 1.414L.586 8 8.293.293l1.414 1.414L4.414 7H16v2H4.414z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default ArrowLeft
