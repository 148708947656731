import React from 'react'

const EventsSecurity = props => (
  <svg viewBox="0 0 500 500" {...props}>
    <g>
      <path
        className="st1"
        d="M249.7 212.899C261.353 212.899 270.8 203.452 270.8 191.799C270.8 180.146 261.353 170.699 249.7 170.699C238.046 170.699 228.6 180.146 228.6 191.799C228.6 203.452 238.046 212.899 249.7 212.899Z"
      />
      <path
        className="st1"
        d="M309.9 245.5L285.7 222.6C282.7 219.8 278.7 218.2 274.5 218.2H270.8L259.6 240.4L253.5 231.7C254.8 230.8 256.8 228.1 256.8 222.7C256.8 218.8 253.8 218.1 249.9 218.1H249.8H249.7C245.8 218.1 242.8 218.8 242.8 222.7C242.8 228.1 244.8 230.7 246.1 231.7L240 240.4L228.8 218.2H225C220.8 218.2 216.8 219.8 213.8 222.6L189.6 245.5C186 249.1 185.5 254.6 188.3 258.8L206.5 283.1C208.5 286 211.8 287.6 215.2 287.6C217.2 287.6 219.3 287 221.1 285.8C223.2 284.4 224.6 282.3 225.3 280L213 357.5C212.4 360.5 213.1 363.6 214.9 366.1C216.7 368.6 219.3 370.3 222.3 370.9C223 371 223.7 371.1 224.4 371.1C230 371.1 234.8 367.1 235.8 361.7L249.7 292.4L263.6 361.7C264.6 367.1 269.4 371.1 275 371.1C275.7 371.1 276.4 371 277.1 370.9C280.1 370.3 282.8 368.7 284.5 366.1C286.3 363.6 286.9 360.5 286.4 357.5L274.1 280C274.7 282.3 276.1 284.4 278.3 285.8C280.1 287 282.2 287.6 284.2 287.6C287.6 287.6 290.9 286 292.9 283.1L311.1 258.8C314 254.6 313.5 249 309.9 245.5ZM209.9 254.8L222.6 241.8L225.6 276.1C225.4 274.4 224.9 272.8 223.9 271.3L209.9 254.8ZM225.7 277.7C225.7 277.6 225.7 277.5 225.7 277.3V277.7ZM273.7 277.4C273.7 277.5 273.8 277.6 273.8 277.8L273.7 277.4ZM275.6 271.3C274.6 272.8 274 274.5 273.9 276.1L276.9 241.8L289.6 254.8L275.6 271.3Z"
      />
    </g>
  </svg>
)

export default EventsSecurity
