export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    job_id: {
      $rel: 'job',
      description: 'Job',
      type: 'string',
    },
    worker_id: {
      $rel: 'worker',
      description: 'To',
      type: 'string',
    },
    body: {
      type: 'string',
    },
  },
  required: ['job_id', 'body'],
  type: 'object',
}
