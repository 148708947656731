import React from 'react'
import { uniqueId } from 'lodash'

const ReplenishmentAssignment = props => (
  <svg width={86} height={92} viewBox="0 0 86 92" {...props}>
    <title>{`Replenishment Assignment`}</title>
    <g fill="#003a9b" fillRule="evenodd" transform="translate(21.000000, 23.000000)">
      <g id="Group-13" transform="translate(-0.000000, 30.000000)">
        <rect id={uniqueId('Rectangle-Copy-38')} x="0.5" y="14.5" width="42" height="1" />
        <path
          d="M4,0 L17,0 C18.6568542,0 20,1.34314575 20,3 L20,11 C20,11.5522847 19.5522847,12 19,12 L2,12 C1.44771525,12 1,11.5522847 1,11 L1,3 C1,1.34314575 2.34314575,0 4,0 Z"
          id={uniqueId('Rectangle-Copy-40')}
          fill="#003a9b"
        />
        <path
          d="M25,0 L38,0 C39.6568542,0 41,1.34314575 41,3 L41,11 C41,11.5522847 40.5522847,12 40,12 L23,12 C22.4477153,12 22,11.5522847 22,11 L22,3 C22,1.34314575 23.3431458,0 25,0 Z"
          id={uniqueId('Rectangle-Copy-41')}
          fill="#003a9b"
        />
      </g>
      <g id="Group-14" transform="translate(-0.000000, 0.000000)">
        <rect id={uniqueId('Rectangle-Copy-39')} x="0.5" y="24.5" width="42" height="1" />
        <g id="Group-11-Copy-6" transform="translate(9.000000, 0.000000)" fill="#003a9b">
          <g id="Group-49">
            <path
              d="M6,4 C7.1045695,4 8,4.8954305 8,6 L8,22 L0,22 L0,6 C0,4.8954305 0.8954305,4 2,4 L6,4 Z M7,14 L1,14 L1,21 L7,21 L7,14 Z"
              id={uniqueId('Combined-Shape')}
            />
            <path
              d="M3,0.714285714 L5,0.714285714 C5.55228475,0.714285714 6,1.16200096 6,1.71428571 L6,6.71428571 L6,6.71428571 L2,6.71428571 L2,1.71428571 C2,1.16200096 2.44771525,0.714285714 3,0.714285714 Z"
              id={uniqueId('Rectangle')}
            />
          </g>
          <g id="Group-49-Copy-2" transform="translate(9.000000, 0.000000)">
            <path
              d="M6,4 C7.1045695,4 8,4.8954305 8,6 L8,22 L-7.27595761e-12,22 L-7.27595761e-12,6 C-7.27595761e-12,4.8954305 0.8954305,4 2,4 L6,4 Z M7,14 L1,14 L1,21 L7,21 L7,14 Z"
              id={uniqueId('Combined-Shape')}
            />
            <path
              d="M3,0.714285714 L5,0.714285714 C5.55228475,0.714285714 6,1.16200096 6,1.71428571 L6,6.71428571 L6,6.71428571 L2,6.71428571 L2,1.71428571 C2,1.16200096 2.44771525,0.714285714 3,0.714285714 Z"
              id={uniqueId('Rectangle')}
            />
          </g>
          <g transform="translate(18.000000, 0.000000)">
            <path
              d="M6,4 C7.1045695,4 8,4.8954305 8,6 L8,22 L0,22 L0,6 C0,4.8954305 0.8954305,4 2,4 L6,4 Z M7,14 L1,14 L1,21 L7,21 L7,14 Z"
              id={uniqueId('Combined-Shape')}
            />
            <path
              d="M3,0.714285714 L5,0.714285714 C5.55228475,0.714285714 6,1.16200096 6,1.71428571 L6,6.71428571 L6,6.71428571 L2,6.71428571 L2,1.71428571 C2,1.16200096 2.44771525,0.714285714 3,0.714285714 Z"
              id={uniqueId('Rectangle')}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ReplenishmentAssignment
