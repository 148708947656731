import { map, get, uniq, isEqual, first, compact, isNil } from 'lodash'
import { put, race, all, take, takeEvery, call, select } from 'redux-saga/effects'

import { getSupportDetails } from 'selectors/platform'
import * as types from 'actions/action-types'
import { alert } from 'components/ConfirmationDialog'
import { humanizeErrors } from 'lib/syftApiErrors'

// TODO Remove doneActionType
const doesOriginateWithAction = (doneAction, beginAction) => {
  const linkedBeginAction = get(doneAction, 'meta.beginAction')
  // TODO Switch to ID comparison only
  const hasActionIdentifer = !isNil(get(beginAction, 'meta.actionId'))
  return hasActionIdentifer
    ? get(beginAction, 'meta.actionId') === get(linkedBeginAction, 'meta.actionId')
    : isEqual(beginAction, linkedBeginAction)
}

const takeCompletedApiAction = (beginAction, doneActionType) =>
  take(action => {
    const doesMatchType = action.type === doneActionType
    const linkedBeginAction = get(action, 'meta.beginAction')
    if (doesMatchType && !linkedBeginAction) {
      throw new Error(
        `To use batch API actions (BATCH_API_ACTION_BEGIN), all individual success and failure actions are required to link to meta.beginAction. See ${action.type}.`,
      )
    }
    // TODO In future, this begin–success action association should be tested
    // by UUID comparison
    return doesMatchType && doesOriginateWithAction(action, beginAction)
  })

// For every entity API action, wait until we all receive their corresponding
// success action.
function* takeAllSuccessActions(beginActions, beginActionTypes) {
  return yield all(
    beginActions.map((beginAction, idx) =>
      takeCompletedApiAction(beginAction, beginActionTypes[idx].succeeded),
    ),
  )
}

// For every entity API action, wait until we all receive their corresponding
// failure action.
function* takeAnyFailureAction(beginActions, beginActionTypes) {
  const result = yield race([
    ...beginActions.map((beginAction, idx) =>
      takeCompletedApiAction(beginAction, beginActionTypes[idx].failed),
    ),
  ])
  return first(compact(result))
}

function* defaultResponder(beginActions) {
  const beginActionTypes = beginActions.map(action => types.allRequestTypesOfActionType(action.type))
  yield all(beginActions.map(action => put(action)))
  const { allSuccessActions, firstFailedAction } = yield race({
    allSuccessActions: takeAllSuccessActions(beginActions, beginActionTypes),
    firstFailedAction: takeAnyFailureAction(beginActions, beginActionTypes),
  })
  return {
    successPayload: allSuccessActions && map(allSuccessActions, 'payload'),
    failurePayload: get(firstFailedAction, 'payload'),
  }
}

// By default, batch API request will result in firing individual
// create/update/delete requests per entity.
//
// This can be customized if the API provides a more efficient batch
// endpoint for a given operation.
//
// For usage, see batchApiRequest.test.js.
//
// Important: batch actions provided as payload are expected to be begin actions
// to create / update / delete entities. You need to make sure that
// the system will be able to correctly handle concurrent actions that are given.
//
// Note the motivation that the UI should be able to perform any feasible action
// over entities in the domain in whatever manner convenient. It's the role
// of API layer (typically sagas) to consume requests convenient for the UI and
// translate them to requests that are convenient for the API.
//
// In the current implementation, the custom responder is used only when all
// batch actions have the same action type. In the future we might want to implement
// a more complex grouping mechanism. Conceptually it might be better to remove
// BATCH_API_ACTION_BEGIN altogether and instead, queue (throttle) all recently
// dispatched API begin actions and group them into bulk API requests where applicable.
//
// @param {{ action: respond }} customResponders where
//   action ~ {String} action type which we want to handle
//   respond ~ {(beginActions) ~> { successPayload, failurePayload }}
export function* respondToBatchApiRequest(customResponders) {
  const supportDetails = yield select(getSupportDetails)

  yield takeEvery(types.BATCH_API_ACTION_BEGIN, function* (action) {
    let result
    const beginActions = action.payload.actions

    const commonAction = (() => {
      const types = uniq(map(beginActions, 'type'))
      return types.length === 1 ? types[0] : null
    })()

    if (commonAction && customResponders[commonAction]) {
      result = yield call(customResponders[commonAction], beginActions)
    } else {
      result = yield call(defaultResponder, beginActions)
    }
    if (result.successPayload) {
      yield put({
        type: types.BATCH_API_ACTION_SUCCEEDED,
        payload: result.successPayload,
        meta: { beginAction: action },
      })
    } else {
      // TODO Refactor (move to UI layer)
      if (get(action.meta, 'alertOnError')) {
        alert({ content: humanizeErrors([result.failurePayload], supportDetails) })
      }

      yield put({
        type: types.BATCH_API_ACTION_FAILED,
        payload: result.failurePayload,
        meta: { beginAction: action },
      })
    }
  })
}
