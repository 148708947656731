import * as actionTypes from 'actions/action-types'
import entityReducer from 'lib/redux-crud/entityReducer'
import { ENTITY_UPDATE_SUCCEEDED } from 'lib/redux-crud/actionTypes'

const baseReducer = entityReducer({
  name: 'timesheetAgencyEntries',
  entityTypes: actionTypes,
})

const { types } = baseReducer

const reducer = (state, action) => {
  switch (action.type) {
    case types[ENTITY_UPDATE_SUCCEEDED]:
      return action.payload.reduce(
        (computedState, payload) =>
          baseReducer(computedState, { ...action, payload: { saved: true, ...payload } }),
        state,
      )
    default:
      return baseReducer(state, action)
  }
}

export default reducer
