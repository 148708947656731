import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import React from 'react'
import JssProvider from 'react-jss/lib/JssProvider'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { compose, setDisplayName, withProps, renderNothing, branch } from 'recompose'

import createGenerateClassName from './createGenerateClassName'
import { createTheme } from './theme'

// Configure JSS
// const jss = create({ plugins: preset().plugins })
// jss.options.createGenerateClassName = createGenerateClassName
// jss.options.insertionPoint = 'insertion-point-jss'
const generateClassName = createGenerateClassName()
const generateClassNameNoHashes = createGenerateClassName({ noHashes: true })

const getStyleContext = baseTheme => ({
  theme: createTheme(baseTheme),
  // This is needed in order to deduplicate the injection of CSS in the page.
  sheetsManager: new Map(),
})

// Expose the latest used global theme
// TODO Refactor
const exposeLatestGlobalTheme = ({ styleContext }) => (window.__syft_themeProviderProps = { styleContext })

// Change application theme in response to redux store
// Modeled after material-ui/docs/src/modules/components/AppWrapper.js
class ThemeProvider extends React.Component {
  state = {}
  static propTypes = {
    baseTheme: PropTypes.object,
    children: PropTypes.node,
    styleContext: PropTypes.object,
    removeHashFromCSSClasses: PropTypes.bool,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const styleContext =
      get(prevState, 'styleContext') || nextProps.styleContext || getStyleContext(nextProps.baseTheme)
    if (get(nextProps, 'baseTheme.name') !== get(prevState, 'baseTheme.name')) {
      return {
        styleContext: {
          ...styleContext,
          theme: createTheme(nextProps.baseTheme),
        },
        baseTheme: nextProps.baseTheme,
      }
    }
    // When passing styleContext as a prop on mount
    else if (isEmpty(prevState)) {
      return {
        styleContext,
      }
    } else return null
  }

  componentDidMount() {
    exposeLatestGlobalTheme({ styleContext: this.state.styleContext })
  }

  componentDidUpdate() {
    exposeLatestGlobalTheme({ styleContext: this.state.styleContext })
  }

  render() {
    const { sheetsManager, theme } = this.state.styleContext
    const { removeHashFromCSSClasses } = this.props
    return (
      <JssProvider
        generateClassName={removeHashFromCSSClasses ? generateClassNameNoHashes : generateClassName}
      >
        <MuiThemeProvider theme={theme} sheetsManager={sheetsManager}>
          {this.props.children}
        </MuiThemeProvider>
      </JssProvider>
    )
  }
}

export default compose(
  setDisplayName('ThemeProvider'),
  branch(
    ({ baseTheme }) => !baseTheme,
    connect(rootState => ({
      baseTheme: rootState.platform.theme,
    })),
  ),
  // We don't want to render anything while baseTheme is not provided.
  // We also do want to continue rendering when styleContext is provided
  // from <LatestActiveThemeProvider>.
  branch(({ baseTheme, styleContext }) => !baseTheme && !styleContext, renderNothing),
)(ThemeProvider)

// Use theme from the latest active ThemeProvider
// Used by components/ConfirmationDialog
// Disconnected from the redux store
// TODO Refactor
export const LatestActiveThemeProvider = compose(
  // eslint-disable-next-line
  withProps(() => window.__syft_themeProviderProps),
)(ThemeProvider)
