import { createTransform } from 'redux-persist'
import { getOauthDataFromLocalStorage } from 'utils/oauth'
import { initialState } from '../reducers/auth'

type AuthState = typeof initialState

export const AuthTransform = createTransform<AuthState, AuthState>(
  inboundState => inboundState,
  // transform state being rehydrated
  outboundState => {
    const flexOauthData = getOauthDataFromLocalStorage()

    if (!flexOauthData) {
      return outboundState
    }

    const isCheckingLogin = flexOauthData.accessToken !== outboundState.oauthData?.accessToken

    return {
      ...outboundState,
      oauthData: flexOauthData,
      userData: isCheckingLogin ? {} : outboundState.userData,
      isAdmin: !isNaN(flexOauthData?.impersonatorId) ? true : outboundState?.isAdmin,
      isLoggedIn: true,
      hasEverBeenLoggedIn: true,
      isCheckingLogin,
    }
  },
  { whitelist: ['auth'] },
)
