/* eslint-disable func-names */
/* eslint-disable import/order */

import { get, pickBy, omit, map, isInteger } from 'lodash'
import { call, put } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import actions from 'actions'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityApiRequest } from 'api'
import { entityCall } from './entityCall'

// Translate entry from schema-defined format into simplified format
// for the update API request
export const translateEntryIntoSimplifiedForm = entry => {
  const simplifiedEntry = {
    shiftId: entry.shift.id,
    workerId: entry.worker.id,
    agencyShiftWorkerId: entry.worker.agencyShiftWorkerId,
    noShow: get(entry, 'noShow'),
    noShowReason: get(entry, 'noShowReason'),
    clockInTime: get(entry, 'clockIn.time'),
    clockOutTime: get(entry, 'clockOut.time'),
    breakDuration: get(entry, 'break.duration'),
  }

  Object.keys(simplifiedEntry).forEach(key => {
    if (simplifiedEntry[key] === null) {
      delete simplifiedEntry[key]
    }
  })

  return simplifiedEntry
}

// Add a direct reference to the associated listing.
// The composite primary key for timesheetEntry is shiftId+workerId.
// TODO Remove
const appendListingIdToEntries = (entries, listingId) =>
  map(entries, entry => ({
    ...entry,
    listingId,
  }))

// Fetch timesheet entries via the /timesheet endpoint. We're not using action
// to fetch a singular timesheet entity because the /timesheet endpoint returns a nested
// `entries` collection and we want to handle it as a first-class redux-crud entity
// colllection – not as a simple array inside the timesheet object.
//
// The timesheet.entries property in the response is paginated which is non-standard.
//
// Load all the timesheet pages at once so we don't have to paginate the timesheets UI
// (components/TimesheetPage). See WEB2-207. This is temporary.
export function* fetchTimesheetEntries(options) {
  yield takeLatestDeep(types.TIMESHEET_ENTRIES_FETCH_BEGIN, function* (action) {
    const listingId = action.payload.listingId

    // TODO Refactor
    let rawPayload
    const normalizePayload = payload => {
      rawPayload = payload
      return appendListingIdToEntries(payload.entries, listingId)
    }

    const timesheetEntityCall = function* (page) {
      return yield call(
        entityCall,
        options.entityApiRequest || entityApiRequest,
        {
          apiAction: apiActions.list,
          entityType: 'timesheetEntry',
          context: options.context,
          normalizePayload,
          // TODO
        },
        !page
          ? action
          : {
              ...action,
              meta: {
                ...action.meta,
                page,
              },
            },
      )
    }

    // eslint-disable-next-line no-unused-vars
    const [payload, meta] = yield call(timesheetEntityCall)

    yield put({
      type: types.TIMESHEET_FETCH_SUCCEEDED,
      // Entries are stored as first-class entity collection
      payload: { ...omit(rawPayload, 'entries'), listingId },
    })
  })
}

// Use bulk approval to approve a single timesheet entry
//
// To synchronize data with backend:
//   * process the updated entry
//   * refetch timesheet approval stats
export function* updateTimesheetEntry(options) {
  yield takeLatestDeep(types.TIMESHEET_ENTRY_UPDATE_BEGIN, function* (action) {
    const listingId = action.payload.listingId
    if (!isInteger(listingId)) throw new Error()

    const newAction = actions.timesheet.updateTimesheet(
      {
        entries: [translateEntryIntoSimplifiedForm(action.payload)],
      },
      action.meta,
    )

    // entityCall returns [payload, meta]
    const [payload] = yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.update,
        entityType: 'timesheet',
        context: options.context,
      },
      newAction,
    )

    const updatedEntity = get(payload, [0]) || action.payload

    // Refetch timesheet approval stats. See sagas/timesheet for more explanation
    yield put({
      type: types.TIMESHEET_APPROVAL_STAT_FETCH_BEGIN,
      payload: { listingId },
    })

    const meta = {
      requestOptions: {
        apiAction: apiActions.update,
        entityType: 'timesheetEntry',
        // TODO Refactor
        entity: action.payload,
        meta: pickBy({
          ...action.meta,
          ...options.context,
        }),
      },
    }
    yield put({ type: types.TIMESHEET_ENTRY_UPDATE_SUCCEEDED, payload: updatedEntity, meta })
  })
}
