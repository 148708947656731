import pluralize from 'pluralize'
import {
  pickBy,
  mapKeys,
  get,
  startsWith,
  snakeCase,
  invert,
  omit,
  find,
  pick,
  defaultsDeep,
  fromPairs,
} from 'lodash'
import { isEqual } from 'lodash/fp'
import { parseISO } from 'lib/date-fns'

import { idForEntity, isIdentifier, idProperties } from 'api/schemas'
import * as types from './actionTypes'
import networkStatusEnum from './networkStatusEnum'
import { getPaginationOrPage, getPageInfo, makeCollectionSelectors, keyForQuery } from './selectors'
import { entityListReducers, addTypeToEntities, updateEntityMap, mergeEntitySets } from './entityListReducers'

const { getPageIds } = makeCollectionSelectors()

export const getEntityTypeReducers = (entityTypes, actionTypes, customMaxAge) =>
  fromPairs(
    entityTypes.map(entityType => {
      const name = pluralize(entityType)
      return [
        name,
        entityReducer({
          name,
          entityTypes: actionTypes,
          maxAge: customMaxAge[entityType],
        }),
      ]
    }),
  )

export const initialState = {
  // All fetched entities mapped by their ID
  entityMap: {},

  // Meta about requests for single entities:
  //
  // - entityMeta.lastError Failure action payload that is set when an error occurred
  //     in the last request for this entity.
  //     Use lib/syftApiErrors#humanizeError to transform the error into a user-friendly
  //     message. See api/redux#errorFromActionFailure for an example of processing
  //     action failure errors
  // - entityMeta.lastStatus {Number} Response status from last request for this entity
  // - entityMeta.lastUpdated {Date} Time of last fetch response. Indicates recency
  //     of synchronization with the world.
  // - entityMeta.maxAge {Number} Time in seconds after the entity will be considered
  //     obsolete, requiring synchronization with the world.
  // - entityMeta.networkStatus {NetworkStatusEnum}
  // - entityMeta.isSavingData {Boolean} Deprecated.
  //
  // TODO Note that we currently conflate all fetch and save requests, i.e. we store
  // the request meta per-entity, not per-request
  entityMeta: {},

  // Result sets: these are sets of IDs returned by the API for a particular set of filters.
  entitySets: {},

  // Deprecated
  isSavingData: false,
}

export const getEntityTypeInitialValues = entityTypes =>
  fromPairs(
    entityTypes.map(entityType => {
      const name = pluralize(entityType)
      return [name, { ...initialState }]
    }),
  )

/**
 * Translate `{ VENUES_FETCH_BEGIN: 'VENUES_FETCH_BEGIN' }`
 * to `{ ENTITIES_FETCH_BEGIN: 'VENUES_FETCH_BEGIN' }`
 *
 * Given a list of all action types available for a given reducer, generate a map
 * from generic action types (ENTITIES_FETCH_BEGIN) to specific action types
 * (VENUES_FETCH_BEGIN). Omit action types that don't start with the name
 * of the reducer.
 *
 * TODO: validate inputs and check outputs are defined
 *
 * @param {*} entityType
 * @param {*} actionTypes
 */
const inferActionTypes = (entityType, actionTypes) => {
  // TODO Validate input
  const singularName = snakeCase(entityType).toUpperCase()
  const pluralName = pluralize(snakeCase(entityType)).toUpperCase()
  const mapped = mapKeys(actionTypes, (value, key) =>
    key.replace(pluralName, 'ENTITIES').replace(singularName, 'ENTITY'),
  )
  // TODO Test that the generic action type is defined in ./actionTypes
  return pickBy(mapped, value => startsWith(value, singularName) || startsWith(value, pluralName))
}

/**
 * Some endpoints (e.g. Page API) don't return IDs at all
 * In that case we'll just use the ID we used to fetch / update the entity
 *
 * @param {*} entityType
 * @param {*} action
 */
const entityIdFromAction = (entityType, action) => {
  const entityFromRequest = get(action, 'meta.requestOptions.entity')
  // TODO: Refactor as Payload may often be a filter query that doesn't include entity type
  const idProps = pick({ ...entityFromRequest, ...action.payload }, idProperties(entityType))

  const actionPayloadId = idForEntity({ $type: entityType, ...action.payload })
  const id = isIdentifier(actionPayloadId)
    ? actionPayloadId
    : idForEntity({ $type: entityType, ...entityFromRequest })

  return { id, idProps }
}

const entityFetchInfo = (state, action, entityType) => {
  const { updatedAt } = action.meta || {}
  const { id, idProps } = entityIdFromAction(entityType, action)
  const hasData = state.entityMap[id]
  const lastUpdated = updatedAt ? parseISO(updatedAt) : new Date()
  return { id, hasData, lastUpdated, idProps }
}

const reducers = {
  // See ./entityListReducers for handling of, ENTITIES_FETCH_BEGIN,
  // ENTITIES_FETCH_SUCCEEDED, ENTITIES_FETCH_FAILED
  ...entityListReducers,

  // TODO ENTITY_FETCH has a very naive implementation
  [types.ENTITY_FETCH_BEGIN]: (state, action, { entityType }) => {
    const { id, hasData } = entityFetchInfo(state, action, entityType)

    return {
      ...state,
      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: hasData ? networkStatusEnum.refetch : networkStatusEnum.loading,
          lastError: null,
          lastStatus: null,
        },
      },
    }
  },

  // TODO ENTITY_FETCH has a very naive implementation
  [types.ENTITY_FETCH_SUCCEEDED]: (state, action, { entityType, maxAge }) => {
    const { id, lastUpdated, idProps } = entityFetchInfo(state, action, entityType)
    // If action.payload doesn't contain the ID, fill it out
    const newEntities = addTypeToEntities(
      [
        // idProps can be the object like { worker: { id: 1 }}
        defaultsDeep({}, idProps, action.payload),
      ],
      entityType,
    )

    return {
      ...state,
      entityMap: updateEntityMap(state.entityMap, newEntities),
      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.ready,
          lastUpdated,
          maxAge,
        },
      },
    }
  },

  // TODO ENTITY_FETCH has a very naive implementation
  [types.ENTITY_FETCH_FAILED]: (state, action, { entityType }) => {
    const { id } = entityFetchInfo(state, action, entityType)

    return {
      ...state,
      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.error,
          lastError: action.payload,
          lastStatus: get(action.payload, 'response.status'),
        },
      },
    }
  },

  [types.ENTITY_UPDATE_BEGIN]: (state, action, { entityType }) => {
    const { id, hasData } = entityFetchInfo(state, action, entityType)

    return {
      ...state,

      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: hasData ? networkStatusEnum.refetch : networkStatusEnum.loading,
          isSavingData: true,
          lastError: null,
          lastStatus: null,
        },
      },

      // TODO Remove. For backwards compatibility
      isSavingData: true,
    }
  },

  [types.ENTITY_UPDATE_SUCCEEDED]: (state, action, { entityType, maxAge }) => {
    const { id, lastUpdated, idProps } = entityFetchInfo(state, action, entityType)

    // Some endpoint only return `{ success: true }` after PUT/PATCH
    // instead of returning the updated entity
    const didRespondWithSuccess =
      get(action.payload, 'success') === true && Object.keys(action.payload).length === 1
    const partialNewEntity = didRespondWithSuccess
      ? { ...get(action, 'meta.requestOptions.entity'), ...idProps }
      : // idProps can be the object like { worker: { id: 1 }}
        defaultsDeep({}, idProps, action.payload)
    const newEntities = addTypeToEntities([partialNewEntity], entityType)

    // If we just found a new entity, unshift the identifier to the first page
    // of the general entity set. Typically this ensures that when a POST request
    // succeeds, the UI showing all entities will update accordingly.
    // TODO Compatibility with pagination
    let entitySets = state.entitySets

    // Support for adding to a query set
    const querySetKeys = get(action, 'meta.requestOptions.meta.querySetKeys')
    const setKey = keyForQuery(
      querySetKeys ? pick(get(action, 'meta.requestOptions.entity'), querySetKeys) : {},
    )
    const allIds = getPageIds(state, { setKey })
    const isUnseenEntity = !find(allIds, isEqual(id))
    if (isUnseenEntity) {
      const page = 1
      const previousPageInfo = getPageInfo(state, { page, setKey })
      const pageInfo = { ...previousPageInfo, ids: [id] }
      const totalCount = getPaginationOrPage(state, { setKey }).totalCount + 1
      entitySets = mergeEntitySets({ state, setKey, page, pageInfo, shouldUnionResults: true, totalCount })
    }

    return {
      ...state,

      entityMap: updateEntityMap(state.entityMap, newEntities),

      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.ready,
          isSavingData: false,
          lastUpdated,
          maxAge,
        },
      },

      entitySets,

      // TODO Remove. For backwards compatibility
      isSavingData: false,
    }
  },

  [types.ENTITY_UPDATE_FAILED]: (state, action, { entityType }) => {
    const { id } = entityFetchInfo(state, action, entityType)

    return {
      ...state,

      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.error,
          isSavingData: false,
          lastError: action.payload,
          lastStatus: get(action.payload, 'response.status'),
        },
      },

      // TODO Remove. For backwards compatibility
      isSavingData: false,
    }
  },

  [types.ENTITY_DELETE_BEGIN]: (state, action, { entityType }) => {
    const { id } = entityFetchInfo(state, action, entityType)

    return {
      ...state,

      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.refetch,
          isSavingData: true,
          lastError: null,
          lastStatus: null,
        },
      },

      // TODO Remove. For backwards compatibility
      isSavingData: true,
    }
  },

  [types.ENTITY_DELETE_SUCCEEDED]: (state, action, { entityType, maxAge }) => {
    const { id, lastUpdated, idProps } = entityFetchInfo(state, action, entityType)
    const newEntities = addTypeToEntities([{ ...idProps, _deleted: true }], entityType)

    let entitySets = state.entitySets

    // Support for removing from a query set
    const querySetKeys = get(action, 'meta.requestOptions.meta.querySetKeys')
    const setKey = keyForQuery(
      querySetKeys ? pick(get(action, 'meta.requestOptions.entity'), querySetKeys) : {},
    )
    const totalCount = getPaginationOrPage(state, { setKey }).totalCount - 1
    entitySets = mergeEntitySets({ state, setKey, totalCount })

    return {
      ...state,

      entityMap: updateEntityMap(state.entityMap, newEntities),

      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.ready,
          isSavingData: false,
          lastUpdated,
          maxAge,
        },
      },

      entitySets,

      // TODO Remove. For backwards compatibility
      isSavingData: false,
    }
  },

  [types.ENTITY_DELETE_FAILED]: (state, action, { entityType }) => {
    const { id } = entityFetchInfo(state, action, entityType)

    return {
      ...state,

      entityMeta: {
        ...state.entityMeta,
        [id]: {
          ...state.entityMeta[id],
          networkStatus: networkStatusEnum.error,
          isSavingData: false,
          lastError: action.payload,
          lastStatus: get(action.payload, 'response.status'),
        },
      },

      // TODO Remove. For backwards compatibility
      isSavingData: false,
    }
  },

  [types.ENTITY_INVALIDATE]: (state, action, { entityType }) => {
    const id = idForEntity({ $type: entityType, ...action.payload })

    return {
      ...state,
      entityMap: omit(state.entityMap, id),

      // Proclaim the entity is not up to date (expired)
      entityMeta: {
        ...state.entityMeta,
        [id]: {
          lastUpdated: null,
        },
      },
    }
  },

  // Purge cache for everything. In future this might support a query.
  [types.ENTITIES_INVALIDATE]: () => {
    return initialState
  },
}

// Create reducers are the same as for update
reducers[types.ENTITY_CREATE_BEGIN] = reducers[types.ENTITY_UPDATE_BEGIN]
reducers[types.ENTITY_CREATE_SUCCEEDED] = reducers[types.ENTITY_UPDATE_SUCCEEDED]
reducers[types.ENTITY_CREATE_FAILED] = reducers[types.ENTITY_UPDATE_FAILED]

/**
 * Creates a new reducer for a specific type of entity, e.g. workers, employers.
 * Reducers of this type support the standard three action types (BEGIN, SUCCEEDED, FAILED)
 * for loading, saving, modifying and deleting data. Not all of these need to be passed;
 * it's fine to implement a reducer with only FETCH actions, for example.
 *
 * @param {String} options.name Plural entity name, e.g. "workers", "employers"
 * @param {Object[*{String}]} options.entityTypes Object of strings containing
 *   the action types
 * @param {Number} options.maxAge Default maximum age of cached entities in seconds.
 *   After that they'll be thought of as expired. Zero by default.
 * @throws {TypeError} In case any item in 'types' is falsy
 */
const entityReducer = options => {
  const entityType = pluralize.singular(options.name)
  const actionTypes = inferActionTypes(entityType, options.entityTypes)
  const invertedTypes = invert(actionTypes)
  const maxAge = options.maxAge || 0

  // Ensure that the caller didn't accidentally pass an invalid value to listen for.
  Object.getOwnPropertyNames(actionTypes).forEach(key => {
    if (!actionTypes[key]) {
      throw TypeError('entityReducer() called with invalid type(s)')
    }
  })

  const reducer = (state = initialState, action) => {
    // action.type ~ VENUES_FETCH_BEGIN
    const genericActionType = invertedTypes[action.type]
    // genericActionType ~ ENTITIES_FETCH_BEGIN
    return reducers[genericActionType]
      ? reducers[genericActionType](state, action, { entityType, maxAge })
      : state
  }

  reducer.types = actionTypes

  return reducer
}

export default entityReducer
