import React, { memo } from 'react'
import MuiGrid from '@material-ui/core/Grid'

import useGetGridTypeProps from './hooks/useGetGridTypeProps'

import { GridContextProvider } from './Grid.context'

import { GridProps } from './Grid.types'

const GridUI = memo((props: GridProps) => {
  const gridTypeProps = useGetGridTypeProps(props)
  const { rowSpacing, style, columnSpacing, root, nested, ...rest } = props

  return <MuiGrid {...gridTypeProps} style={style} {...rest} />
})

const Grid = (props: GridProps) => {
  if (!props.root) {
    return <GridUI {...props} />
  }

  return (
    <GridContextProvider
      rowSpacing={props.rowSpacing}
      columnSpacing={props.columnSpacing}
      spacing={props.spacing}
    >
      <GridUI {...props} />
    </GridContextProvider>
  )
}

export default Grid
