import React from 'react'

const HeadChef = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`Head chef`}</title>
    <defs>
      <path
        id="a"
        d="M92.852 448.948L91 445l-1.852 3.948-4.148.633 3.001 3.077-.707 4.342L91 454.95l3.706 2.05-.707-4.342L97 449.581z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.95 29.751c-2.78-.272-4.95-2.58-4.95-5.386 0-2.99 2.462-5.413 5.5-5.413.626 0 1.227.103 1.787.293A7.526 7.526 0 0 1 31.5 16a7.526 7.526 0 0 1 6.213 3.245 5.573 5.573 0 0 1 1.787-.293c3.038 0 5.5 2.424 5.5 5.413 0 2.807-2.17 5.114-4.95 5.386v10.606h-17.1V29.751zm0 12.378h17.1V44.3a2.7 2.7 0 0 1-2.7 2.7h-11.7a2.7 2.7 0 0 1-2.7-2.7v-2.171z"
        fill="#003a9b"
      />
      <g strokeLinecap="round" strokeLinejoin="round" transform="translate(-50 -410)">
        <use fill="#003a9b" xlinkHref="#a" />
        <path
          stroke="#FFF"
          strokeWidth={1.7}
          d="M93.429 448.176l5.334.815-3.856 3.954.92 5.646L91 455.922l-4.826 2.669.92-5.646-3.857-3.954 5.334-.815L91 442.999l2.429 5.177zm-6.336 4.769l.07-.424.23.73-.3-.306z"
        />
      </g>
    </g>
  </svg>
)

export default HeadChef
