export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    company_name: {
      type: 'string',
    },
    contact_name: {
      type: 'string',
    },
    contact_number: {
      description: 'Contact number',
      type: 'string',
    },
    contact_phone: {
      description: 'Company phone',
      type: 'string',
    },
    address: {
      $schema: 'http://json-schema.org/draft-04/schema#',
      type: 'object',
      properties: {
        line_1: {
          type: 'string',
          description: 'Address',
        },
        line_2: {
          type: 'string',
          description: 'Address line 2',
        },
        city: {
          type: 'string',
        },
        postcode: {
          type: 'string',
        },
      },
    },
    company_description: {
      description: 'Company description',
      type: 'string',
    },
  },
  required: [],
  type: 'object',
}
