import addressSchema from './address'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    company_name: {
      type: 'string',
    },
    contact_mobile: {
      description: 'Contact phone number',
      type: 'string',
    },
    contact_name: {
      type: 'string',
    },
    tier: {
      type: 'string',
    },
    expected_hires_per_week: {
      description: 'Hiring capacity',
      // Backend accepts formatted range: '10+' or '10-20'
      enum: ['1-2', '2-5', '5-10', '10-20', '20-50', '50+'],
      type: 'string',
    },
    user_id: {
      type: 'integer',
    },
    brand_name: {
      description: 'Brand / marketing name',
      type: 'string',
    },
    address: addressSchema,
    company_number: {
      description: 'Company number (CRN)',
      type: 'string',
    },
    vat_number: {
      type: 'string',
    },
    industry_ids: {
      description: 'Industry',
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    summary: {
      description: 'Company description',
      type: 'string',
    },
    picture: {
      type: 'object',
      description: 'Profile picture',
      properties: {
        uuid: {
          type: 'string',
        },
      },
    },
    approved: {
      type: 'string',
    },
    past_listings_count: {
      type: 'string',
    },
  },
  required: [
    'address.line_1',
    'address.city',
    'address.post_code',
    'expected_hires_per_week',
    'contact_name',
    'user_id',
    'company_name',
    'contact_mobile',
    'company_number',
    'summary',
    'picture',
  ],
  type: 'object',
}
