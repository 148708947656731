import { datadogRum } from '@datadog/browser-rum-slim'
import * as types from 'actions/action-types'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { findKey, get, isInteger } from 'lodash'
import { all, call, select } from 'redux-saga/effects'
import { getIndustriesMap } from 'selectors/industries'
import { isMatch, trackingTransformations } from '../logsFormatters'

const industrySizes = {
  small: 4,
  large: 3,
}

/**
 * For development purpose, log a tracking event and data sent to tracking server
 */
const mpLog = (event, data = {}) => {
  const type = get(data, 'type', '')
  const filteredData = trackingTransformations.reduce(
    (obj, filter) => (isMatch(filter.pattern, type) ? filter.transformation(obj) : obj),
    data,
  )
  console.log(`Tracking Event :: ${event}`, data ? JSON.stringify(filteredData) : '')
}

const getNewRoleData = (action, state) => {
  const actionJob = get(action, 'payload.jobs[0]')
  return {
    type: get(state, `roles.entityMap[${actionJob.roleId}].code`),
    workers: actionJob.workersRequired,
    allow_individual_shifts: actionJob.bookableIndividually,
    rate: get(actionJob, 'payRate.amount', null),
    break: actionJob.breakDuration,
    health_and_safety: actionJob.healthAndSafetyInformation !== null,
  }
}

const getNewJobData = (action, state) => {
  const actionJob = get(action, 'payload.jobs[0]')
  const containsEvent = !!get(action, 'payload.eventName', false)
  const numberOfJobs = get(actionJob, 'shifts', []).length
  // TODO: need to check with marketing about some of these naming conventions
  // as it is not clear from the key what some of the data relates to
  return {
    add_dates: numberOfJobs,
    event: containsEvent,
  }
}

export const getListingId = (action, path) => {
  const listingId = get(action, path)
  return isInteger(listingId) ? listingId : null
}

/**
 * Convert redux action data into tracking event data
 * @param {Object} action redux action
 * @param {Object} state redux store state
 */
const getTrackingEvents = (action, state) => {
  switch (action.type) {
    case types.WAGE_PREVIEW_FETCH_BEGIN: {
      const template = get(action, 'payload.templateName')
      return [{ key: 'new_role', data: template ? { template } : {} }]
    }
    case types.PAYMENT_SESSION_VERIFY_SUCCEEDED: {
      return [{ key: 'new_role', data: { credit_card: true } }]
    }
    case types.LISTING_CREATE_SUCCEEDED: {
      const newRoleData = getNewRoleData(action, state)
      const newJobData = getNewJobData(action, state)
      return [
        { key: 'new_role', data: newRoleData },
        { key: 'new_job', data: newJobData },
      ]
    }
    case types.VENUE_CREATE_SUCCEEDED: {
      const area = get(action, 'payload.areas[0]')
      return [{ key: 'new_venue', data: area ? { creation: true, area } : undefined }]
    }
    case types.EMPLOYER_CREATE_SUCCEEDED: {
      const industries = getIndustriesMap(state)
      const selectedIndustry = get(action, 'payload.industryIds.0')
      const industryName = get(industries, `${selectedIndustry}.code`)

      const tier = get(action, 'payload.tier')
      const industrySize = findKey(industrySizes, item => item === tier)

      return [
        { key: 'company_creation', data: { company_creation: true } },
        { key: 'employer_account_creation', data: { employer_account_creation: true } },
        { key: 'industry_size', data: industrySize ? { size: industrySize } : undefined },
        { key: 'industry_type_selected', data: industryName ? { type: industryName } : undefined },
      ]
    }
    case types.USER_CREATE_SUCCEEDED: {
      const gdprConsentGivenAt = get(action, 'payload.user.gdprConsentGivenAt')
      return [
        { key: 'mixpanel_employer_gdpr', data: { employer_gdpr: gdprConsentGivenAt !== undefined } },
        { key: 'profile_type_selected', data: { type: 'employer' } },
      ]
    }
    case types.TRACK_EMAIL_LINK: {
      const key = get(action, 'payload.trackingKey')
      return [{ key, data: {} }]
    }
    case types.INTERNAL_WORKER_INVITATION_CREATE_SUCCEEDED: {
      const interalWorker = get(action, 'payload', {})
      return [{ key: 'internal_user_invited', data: interalWorker }]
    }
    case types.EDIT_DELETE_SHIFT_DIALOG_OPEN: {
      const listingId = getListingId(action, 'payload.listingId')
      return [{ key: 'edit_delete_shift_dialog_open', data: { listing_id: listingId } }]
    }
    case types.LISTING_UPDATE_SUCCEEDED: {
      const listingId = getListingId(action, 'payload.id')
      const newRoleData = getNewRoleData(action, state)
      const newJobData = getNewJobData(action, state)
      return [
        { key: 'listing_update_succeeded', data: { listing_id: listingId } },
        { key: 'new_role', data: newRoleData },
        { key: 'new_job', data: newJobData },
      ]
    }
    case types.JOB_SUGGESTION_ACCEPTED: {
      return [{ key: 'job_suggestion_accepted', data: {} }]
    }
    case types.JOB_SUGGESTION_DISMISSED: {
      return [{ key: 'job_suggestion_desmissed', data: {} }]
    }
    case types.JOB_SUGGESTION_REOPENED: {
      return [{ key: 'job_suggestion_reopened', data: {} }]
    }
    case types.JOB_IDS_FETCH_SUCCEEDED: {
      const requestOptions = action?.meta?.requestOptions
      const trackingEventName = requestOptions?.meta?.trackingEventName
      const jobId = requestOptions?.entity?.jobId
      return [{ key: trackingEventName, data: { selection: jobId } }]
    }

    case types.AUTH_LOGIN_FAILED:
    case types.AUTH_LOGIN_SUCCEEDED: {
      return [
        {
          key: 'auth.login.completed',
          data: action.type === types.AUTH_LOGIN_FAILED ? { error: state.auth.message } : {},
        },
      ]
    }
    default:
      console.error('Tracking action transformation not suppported')
      return []
  }
}

/**
 * Visible for testing
 * @param {Object} features
 */
export const serializeFeatures = features => {
  return Object.keys(features).map(name => `${name}${features[name] ? '_enabled' : '_disabled'}`)
}

/**
 *
 * @param {Object} state redux store
 */
const getCommonTrackingData = state => {
  const features = get(state, 'config.features')

  return { features: serializeFeatures(features) }
}

/**
 * Convert redux action data into tracking event data
 * @param action redux action
 */
const getTrackingData = function* (action) {
  const state = yield select()
  const commonTrackingData = getCommonTrackingData(state)
  const events = getTrackingEvents(action, state)
  return events.map(({ key, data }) => ({ key, data: { ...commonTrackingData, ...data } }))
}

export const trackActions = function* () {
  const isProdEnv = process.env.NODE_ENV === 'production'

  yield takeLatestDeep(
    [
      types.WAGE_PREVIEW_FETCH_BEGIN,
      types.PAYMENT_SESSION_VERIFY_SUCCEEDED,
      types.LISTING_CREATE_SUCCEEDED,
      types.VENUE_CREATE_SUCCEEDED,
      types.EMPLOYER_CREATE_SUCCEEDED,
      types.USER_CREATE_SUCCEEDED,
      types.TRACK_EMAIL_LINK,
      types.INTERNAL_WORKER_INVITATION_CREATE_SUCCEEDED,
      types.EDIT_DELETE_SHIFT_DIALOG_OPEN,
      types.LISTING_UPDATE_SUCCEEDED,
      types.JOB_SUGGESTION_ACCEPTED,
      types.JOB_SUGGESTION_DISMISSED,
      types.JOB_SUGGESTION_REOPENED,
      types.JOB_IDS_FETCH_SUCCEEDED,
      types.AUTH_LOGIN_SUCCEEDED,
      types.AUTH_LOGIN_FAILED,
    ],
    function* (action) {
      const trackingData = yield getTrackingData(action)
      yield all(
        trackingData.map(({ key, data }) =>
          data && isProdEnv ? call([datadogRum, datadogRum.addAction], key, data) : call(mpLog, key, data),
        ),
      )
    },
  )
}
