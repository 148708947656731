import { FLEX_OAUTH_DATA_STORAGE_KEY } from 'common/constants'
import {
  getItem as getLocalStorageItem,
  removeItem as removeLocalStorageItem,
  setItem as setLocalStorageItem,
} from 'lib/safeLocalStorage'
import { invert, isEmpty, mapKeys } from 'lodash'

export const PORTAL_TO_FLEX_OAUTH_DATA_MAP = {
  accessToken: 'access_token',
  expiresIn: 'expires_in',
  tokenType: 'token_type',
  scope: 'scope',
  refreshToken: 'refresh_token',
  createdAt: 'created_at',
  impersonatorId: 'impersonatorId',
} as const

export function getOauthDataFromLocalStorage() {
  try {
    const storeOauthData = getLocalStorageItem(FLEX_OAUTH_DATA_STORAGE_KEY)

    if (!storeOauthData) {
      return null
    }

    const oauthData = JSON.parse(storeOauthData)

    if (!oauthData || isEmpty(oauthData)) {
      return null
    }

    const oauthDataToPortalKeys = invert(PORTAL_TO_FLEX_OAUTH_DATA_MAP)

    return mapKeys(oauthData, (val, key) => oauthDataToPortalKeys[key])
  } catch (e) {
    return null
  }
}

export const saveOauthDataToLocalStorage = function (
  oauth?: Record<keyof typeof PORTAL_TO_FLEX_OAUTH_DATA_MAP, string | number>,
) {
  const oauthData = oauth
    ? mapKeys(
        oauth,
        (val, key) => PORTAL_TO_FLEX_OAUTH_DATA_MAP[key as keyof typeof PORTAL_TO_FLEX_OAUTH_DATA_MAP],
      )
    : undefined

  if (!oauthData) {
    removeLocalStorageItem(FLEX_OAUTH_DATA_STORAGE_KEY)
    return
  }

  // Transform the oauth data and store in in the `flex.oauthData` property
  // This allows OneHost to read it and a users login session to remain seamless
  setLocalStorageItem(FLEX_OAUTH_DATA_STORAGE_KEY, JSON.stringify(oauthData))
}
