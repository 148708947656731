export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    dates: {
      type: 'array',
      maxItems: 2,
      items: {
        type: 'string',
        format: 'date-time',
      },
    },
    start_time: {
      type: 'string',
      format: 'date-time',
    },
    venue_name: {
      type: 'string',
    },
    role_ids: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    worker_required: {
      type: 'integer',
    },
    workers_booked: {
      type: 'integer',
    },
    reason: {
      type: 'string',
    },
    status: {
      type: 'string',
      enum: ['awaiting_approval', 'approved', 'rejected'],
    },
  },
  required: [
    'id',
    'dates',
    'start_time',
    'venue_name',
    'role_ids',
    'worker_required',
    'workers_booked',
    'reason',
    'status',
  ],
}
