import jobSchema from './job'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    employer_id: {
      type: 'string',
    },
    template_name: {
      type: 'string',
    },
    event_name: {
      type: 'string',
      description: 'Event name',
    },
    event_desc: {
      type: 'string',
      description: 'Event description',
    },
    venue_id: {
      description: 'Venue',
      type: 'integer',
      // TODO Rename, add foreign key
      $rel: 'venue',
      relationshipType: 'belongsTo',
    },
    jobs: {
      type: 'array',
      // TODO Rename
      $rel: 'job',
      // TODO Use %rel
      items: jobSchema,
    },
    cost_centre_code: 'string',
    reason: {
      type: 'object',
      properties: {
        id: { type: 'integer' },
        comment: { type: 'string' },
      },
    },
    post_on_syft: {
      type: 'boolean',
    },
  },
  required: ['event_name', 'venue_id', 'jobs'],
}
