export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  definitions: {},
  id: 'http://example.com/example.json',
  properties: {
    worker_id: {
      $rel: 'worker',
      type: 'integer',
    },
    venue_id: {
      $rel: 'venue',
      type: 'integer',
    },
    all_areas: {
      type: 'boolean',
    },
    worker_venue_areas: {
      items: {
        $rel: 'area',
        type: 'integer',
      },
      type: 'array',
    },
  },
}
