import * as types from 'actions/action-types'
import { call, takeLatest } from 'redux-saga/effects'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'

function* fetchJobDurationPreview(options, action) {
  yield call(entityActionRequest, options, action)
}

export function* watchForJobDurationPreview(options = {}) {
  yield takeLatest([types.JOB_DURATION_PREVIEW_CREATE_BEGIN], fetchJobDurationPreview, options)
}
