import React from 'react'

const DeliveryDriverClientCover = props => (
  <svg width={65} height={65} viewBox="0 0 65 65" {...props}>
    <title>{`Delivery driver`}</title>
    <g fill="none" fillRule="evenodd">
      <g fill="#003A9B" fillRule="nonzero">
        <g>
          <path
            d="M7.71 15.836c-1.545.287-2.787 1.53-3.074 3.075-.526 2.756 1.848 5.13 4.604 4.604 1.546-.287 2.788-1.53 3.075-3.075.526-2.74-1.848-5.114-4.604-4.604zm.43 5.48c-.652-.127-1.194-.653-1.321-1.322-.223-1.179.796-2.199 1.975-1.976.653.128 1.195.654 1.322 1.323.223 1.195-.796 2.214-1.975 1.975zM33.966 15.836c-1.546.287-2.772 1.53-3.075 3.075-.526 2.756 1.848 5.13 4.604 4.604 1.545-.287 2.788-1.53 3.075-3.075.526-2.756-1.848-5.13-4.604-4.604zm.43 5.48c-.653-.127-1.195-.669-1.322-1.322-.224-1.179.78-2.199 1.975-1.976.653.128 1.195.654 1.322 1.307.223 1.21-.796 2.23-1.975 1.991z"
            transform="translate(-829 -292) translate(829 292) translate(11.311 21.348)"
          />
          <path
            d="M42.362 15.772h-.367v-4.556c0-.606-.414-1.131-1.004-1.29l-3.473-.861-4.142-7.042C32.691.844 31.433.127 30.063.127H2.884C1.768.127 1.21.685 1.21 1.561v15.884H.62c-.286 0-.525.239-.525.526v.031c0 1.227 1.003 2.23 2.23 2.23h1.067c-.016-.19-.047-.366-.047-.557 0-2.836 2.31-5.146 5.145-5.146 2.836 0 5.146 2.31 5.146 5.146 0 .191-.032.367-.048.558H29.68c-.016-.191-.064-.367-.064-.558 0-2.836 2.31-5.146 5.146-5.146s5.146 2.31 5.146 5.146c0 .191-.032.367-.048.558h2.55c.414 0 .748-.335.748-.749v-2.98c-.048-.397-.382-.732-.796-.732zM33.95 8.937c-.048.08-.128.128-.223.128H27.72c-.144 0-.255-.112-.255-.255V3.744c0-.144.111-.255.255-.255h2.963c.191 0 .366.096.462.27l2.804 4.924c.048.08.048.175 0 .255z"
            transform="translate(-829 -292) translate(829 292) translate(11.311 21.348)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default DeliveryDriverClientCover
