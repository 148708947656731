import { isNil, set, unset, startsWith } from 'lodash/fp'
import { createBrowserHistory } from 'history'
import queryString from 'query-string'

export const history = createBrowserHistory()

export const replaceQueryParam = (path, value) => {
  const { location } = history
  const queryPrams = getQueryParams()

  const newQueryParams = !isNil(value) ? set(path, value, queryPrams) : unset(path, queryPrams)
  const newQueryString = queryString.stringify(newQueryParams)
  history.replace({ ...location, search: newQueryString ? `?${newQueryString}` : '' })
}

export const getAuthQueryParams = () => {
  const { access_token, code } = queryString.parse(history.location.search)
  return { accessToken: access_token, code }
}

export const getQueryParams = () => queryString.parse(history.location.search)

export const isActivePath = (path, indexOnly, routeMatch) => {
  const {
    location: { pathname },
  } = history

  if (indexOnly) {
    return pathname === path
  }

  return startsWith(path, pathname) || (routeMatch ? routeMatch === pathname : false)
}

export const getCurrentPath = ({ withSearch } = {}) => {
  if (!withSearch) {
    return history.location.pathname
  }

  return `${history.location.pathname}${history.location.search}`
}
