import { fork, take } from 'redux-saga/effects'

// Not start a new saga, until action corresponding to finishPattern is not dispatched.
export default (startPattern, finishPattern, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(startPattern)
      yield fork(saga, ...args.concat(action))
      yield take(finishPattern)
    }
  })
