import React from 'react'

const FoodPrepAssistant = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <title>{`Food preparation assistant`}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M40.05 33.477a1.626 1.626 0 0 0-1.473 1.618v1.13a1.625 1.625 0 0 0-2.502 1.369v1.132a1.625 1.625 0 0 0-2.502 1.369v.262H22.95V29.751c-2.78-.272-4.95-2.58-4.95-5.386 0-2.99 2.462-5.413 5.5-5.413.626 0 1.227.103 1.787.293A7.526 7.526 0 0 1 31.5 16a7.526 7.526 0 0 1 6.213 3.245 5.573 5.573 0 0 1 1.787-.293c3.038 0 5.5 2.424 5.5 5.413 0 2.807-2.17 5.114-4.95 5.386v3.726zm-6.476 8.652v1.052l-1.956 1.956a1.621 1.621 0 0 0-.31 1.863H25.65a2.7 2.7 0 0 1-2.7-2.7v-2.171h10.624zm10.65-6.41h2.232a.625.625 0 0 1 0 1.25h-3.483l-1.25 1.25h2.232a.625.625 0 0 1 0 1.25H40.47l-1.25 1.25h2.232a.625.625 0 0 1 0 1.25H37.97l-1.251 1.25h2.233a.625.625 0 0 1 0 1.25h-3.484l-2.259 2.258a.625.625 0 0 1-.884-.883l2.249-2.248v-3.502a.625.625 0 0 1 1.25 0v2.252l1.251-1.25v-3.501a.625.625 0 0 1 1.251 0v2.25l1.251-1.25v-3.5a.625.625 0 0 1 1.25 0v2.25l1.252-1.25v-3.5a.625.625 0 0 1 1.25 0v2.25l1.365-1.363a.627.627 0 0 1 .884.885l-1.354 1.353z"
        fill="#003a9b"
      />
    </g>
  </svg>
)

export default FoodPrepAssistant
