import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import classnames from 'classnames'
import { omit, get, noop } from 'lodash'
import { Link as RouterLink } from 'react-router-dom'

import injectSheet, { defaultIndex } from 'styles/jss'
import styles from './styles'

const Link = ({ children, link, ...rest }) => (
  <RouterLink to={link} {...rest}>
    {children}
  </RouterLink>
)

const Button = props => {
  const {
    children,
    className: classNameProp,
    primary,
    outlined,
    small,
    color: colorProp,
    dense = false,
    disabled = false,
    component: componentProp, // Pass 'button' if want to enable/disable function
    link,
    fullWidth,
    textAlignLeft,
    square,
    text,
    big,
    classes,
    onClick = noop,
    ...other
  } = props

  // `text` is never `raised`
  const raised = get(props, 'raised', true) && !text
  const color = primary ? 'primary' : colorProp || 'default'
  const component = link ? Link : componentProp ? componentProp : 'div'

  const className = classnames(
    {
      [classes.root]: true,
      [classes.raised]: raised,
      [classes.colorInherit]: color === 'inherit',
      [classes.small]: small,
      [classes.flatDefault]: !raised && color === 'default',
      [classes.raisedDefault]: raised && color === 'default',
      [classes.flatPrimary]: !raised && color === 'primary',
      [classes.raisedPrimary]: raised && color === 'primary',
      [classes.flatError]: !raised && color === 'error',
      [classes.raisedError]: raised && color === 'error',
      [classes.raisedRed]: raised && color === 'red',
      [classes.flatSecondary]: !raised && color === 'textSecondary',
      [classes.raisedSecondary]: raised && color === 'textSecondary',
      [classes.flatSuccess]: !raised && color === 'success',
      [classes.raisedSuccess]: raised && color === 'success',
      [classes.flatWarning]: !raised && color === 'warning',
      [classes.raisedWarning]: raised && color === 'warning',
      [classes.dense]: dense,
      [classes.disabled]: disabled,
      [classes.fullWidth]: fullWidth,
      [classes.square]: square,
      [classes.text]: text,
      [classes.big]: big,
      [classes.outlined]: outlined,
      [classes.textAlignLeft]: textAlignLeft,
    },
    classNameProp,
  )

  return (
    <ButtonBase
      className={className}
      disabled={disabled}
      focusRipple
      {...omit(other, 'primary', 'raised', 'entity', 'href')}
      link={link}
      component={component}
      aria-disabled={disabled}
      onClick={disabled || !onClick ? noop : onClick}
      {...(link ? { role: 'link' } : {})}
    >
      {children}
    </ButtonBase>
  )
}

Button.propTypes = {
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'error', 'red', 'textSecondary', 'success']),
  dense: PropTypes.bool,
  small: PropTypes.bool,
  big: PropTypes.bool,
  text: PropTypes.bool,
  disabled: PropTypes.bool,
  raised: PropTypes.bool,
  square: PropTypes.bool,
  fullWidth: PropTypes.bool,
  textAlignLeft: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  href: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
  // Use react-router/Link. The button will be rendered with <a> element in DOM
  // but it'll visually look like a button.
  link: PropTypes.string,
  // Stayed for backwards compatiblity after updating to MUI beta
  primary: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  outlined: PropTypes.bool,
  onClick: PropTypes.func,
}

// Index is decreased to allow for easy style override. See styles/jss for more comments.
export default injectSheet(styles, { name: 'SyftButton', index: defaultIndex - 1 })(Button)
