export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    job_id: {
      $rel: 'job',
      type: 'integer',
    },
    worker_ids: {
      type: 'array',
    },
    body: {
      type: 'string',
    },
  },
}
