import { put } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { takeLatestDeep } from 'lib/sagaHelpers'

const actionsAffectingAgencyListing = [
  types.AGENCY_LISTING_JOB_CARD_CREATE_SUCCEEDED,
  types.AGENCY_ALLOCATION_WORKER_DELETE_SUCCEEDED,
  types.AGENCY_ALLOCATION_WORKERS_CREATE_SUCCEEDED,
  types.AGENCY_ALLOCATION_WORKERS_UPDATE_SUCCEEDED,
]

export function* updateAgencyListingEmailStatus(options) {
  yield takeLatestDeep(actionsAffectingAgencyListing, function* (action) {
    const entity = action?.meta?.requestOptions?.entity
    const listingUuid = entity?.[0]?.listingUuid || entity?.listingUuid
    yield put({
      type: types.AGENCY_LISTING_FETCH_BEGIN,
      payload: {
        uuid: listingUuid,
      },
      meta: { isGETMethod: true }, // Make this endpoint a GET
    })
  })
}
