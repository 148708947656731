import { isArray, map, isObject, isDate, isRegExp, isFunction, extend, mapValues } from 'lodash'

// Version of deepMapValues from lodash-deep package that additionally:
//   * traverses other than leaf nodes
//   * is DFS (although primitives can be visited before arrays / objects on the same level)
//
// https://github.com/marklagendijk/lodash-deep/blob/master/lodash-deep.js
export const deepMapValues = (object, callback, propertyPath) => {
  let mapped = object
  propertyPath = propertyPath || ''

  if (isArray(object)) {
    mapped = map(object, deepMapValuesIteratee)
  } else if (isObject(object) && !isDate(object) && !isRegExp(object) && !isFunction(object)) {
    mapped = extend({}, object, mapValues(object, deepMapValuesIteratee))
  }

  return callback(mapped, propertyPath)

  function deepMapValuesIteratee(value, key) {
    const valuePath = propertyPath ? `${propertyPath}.${key}` : key
    return deepMapValues(value, callback, valuePath)
  }
}

export const deepMapValuesBreadthFirst = (object, callback, propertyPath) => {
  let mapped = object
  propertyPath = propertyPath || ''

  if (isArray(object)) {
    mapped = callback(object, propertyPath)
    return map(mapped, deepMapValuesIteratee)
  } else if (isObject(object) && !isDate(object) && !isRegExp(object) && !isFunction(object)) {
    mapped = callback(object, propertyPath)
    return extend({}, mapped, mapValues(object, deepMapValuesIteratee))
  } else {
    return callback(object, propertyPath)
  }

  function deepMapValuesIteratee(value, key) {
    const valuePath = propertyPath ? `${propertyPath}.${key}` : key
    return deepMapValuesBreadthFirst(value, callback, valuePath)
  }
}
