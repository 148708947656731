import { brandDefaults } from '../../../common/constants'
import config from '../../../config'
import { properties } from '../constants'

export const getGlobalTrackingProperties = () => {
  const region = brandDefaults[config.BRAND_KEY].countryCode
  return {
    [properties.app]: 'syft2webclient',
    [properties.region]: region,
    version: config.hash,
    [properties.device_id]: config.ctk,
    [properties.is_lemma]: config.IS_LEMMA,
    [properties.is_privileged]: config.isPrivileged,
    ...(config.IS_E2E
      ? {
          [properties.is_e2e]: config.IS_E2E,
          full_test_name: window.Cypress?.currentTestFullName,
        }
      : {}),
  }
}
