/* eslint-disable func-names */
/* eslint-disable import/order */

import { map } from 'lodash'
import { call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'

// Add workerId references to each fetched worker job
export function* fetchWorkerJobs(options) {
  yield takeLatestDeep(types.WORKER_JOBS_FETCH_BEGIN, function* (action) {
    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.list,
        entityType: 'workerJob',
        context: options.context,
        normalizePayload: payload => map(payload, item => ({ ...item, workerId: action.payload.workerId })),
      },
      action,
    )
  })
}
