import React from 'react'

const Sorter = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Sorter`}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M43 39h52a6 6 0 0 1 6 6v48a6 6 0 0 1-6 6H43a6 6 0 0 1-6-6V45a6 6 0 0 1 6-6zm40 17.757l3.657 3.657a2 2 0 0 0 2.828-2.828l-7.07-7.071a2 2 0 0 0-2.83 0l-7.07 7.07a2 2 0 0 0 2.828 2.83L79 56.756V76a2 2 0 1 0 4 0V56.757zm-24 0l3.657 3.657a2 2 0 0 0 2.828-2.828l-7.07-7.071a2 2 0 0 0-2.83 0l-7.07 7.07a2 2 0 0 0 2.828 2.83L55 56.756V76a2 2 0 1 0 4 0V56.757zM50 88h38a2 2 0 1 0 0-4H50a2 2 0 1 0 0 4z"
        fill="#003a9b"
      />
    </g>
  </svg>
)

export default Sorter
