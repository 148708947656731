import TagManager from 'react-gtm-module'
import { extractDimensionsGA } from 'lib/analytics/googleAnalytics/userProperties'
import { isEmpty } from 'lodash'
import { customEvents } from 'lib/analytics/googleAnalytics/constants'

export const gtmSendPageView = (pathname, dimensions) => {
  TagManager.dataLayer({
    dataLayer: {
      event: customEvents.PAGEVIEW,
      pathname,
      ...dimensions,
    },
  })
}

/**
 * GA page view tracker - Automatically invoked by useTracker HOC
 *
 * @param {object} history - browserHistory from either 'history' and 'react-router-dom' npm packages
 */
export const gtmTrackPageView = ({ pathname, search, action }) => {
  const dimensions = extractDimensionsGA(`${pathname}${search}`)
  if (['REPLACE', 'PUSH'].includes(action)) {
    gtmSendPageView(pathname, dimensions)
  }
}

/**
 * As opposite to gtmTrackPageView, here we can send an arbitrary URL and an arbitraty dimensions
 * object.
 *
 * @param {string} pathname - arbitrary url path
 * @param {object} dimensions - arbitrary diminsions object. If empty, dimensions will be extracted
 * automatically
 */
export const gtmTrackUrl = (pathname, dimensions = {}, location = window.location) => {
  const { pathname: currentPathname, search: queryString } = location
  const dimensionsObject = isEmpty(dimensions)
    ? extractDimensionsGA(`${currentPathname}${queryString}`)
    : dimensions
  gtmSendPageView(pathname, dimensionsObject)
}

/**
 * GA eventTracker
 *
 * @param {string} eventCategory - (required) Typically the object that was interacted with (e.g. 'Video')
 * @param {string} action - (required) The type of interaction (e.g. 'play')
 * @param {string} label - Useful for categorizing events (e.g. 'Fall Campaign')
 * @param {integer} value - A numeric value associated with the event (e.g. 42)
 * @param {object} fields - Extra fields and custom dimension {@link https://support.google.com/analytics/answer/1033068#Anatomy Anatomy of an event}
 */
export const gaTrackEvent = (eventCategory, action, label, value, fields = {}) => {
  const { pathname, search } = window.location
  const dimensions = extractDimensionsGA(`${pathname}${search}`)

  if (eventCategory && action) {
    TagManager.dataLayer({
      dataLayer: {
        event: action,
        category: eventCategory,
        ...(label ? { eventLabel: label } : null),
        ...(value ? { eventValue: value } : null),
        ...dimensions,
      },
    })
  }
}
