import pluralize from 'pluralize'
import { compact, some } from 'lodash'

import { parseISO, isValid } from 'lib/date-fns'
import {
  isShiftLong,
  SHORT_SHIFT_DURATION_HOURS,
  MIN_BREAK_TIME_FOR_LONG_SHIFT_MINUTES,
} from 'api/entities/job'
import { ShiftType } from 'api/types/agencyJobDetail'
import { locales } from 'common/constants'

export const agencyJobApprovalStatus = {
  awaitingApproval: 'awaiting_approval',
  rejected: 'rejected',
  aproved: 'approved',
  notRequired: 'not_required',
} as const

export const DEFAULT_LONG_SHIFT_BREAK_TIME = 30 * 60
export const DEFAULT_SHORT_SHIFT_BREAK_TIME = 0 * 60

export const DEFAULT_TIMES = [
  DEFAULT_SHORT_SHIFT_BREAK_TIME,
  15 * 60,
  20 * 60,
  DEFAULT_LONG_SHIFT_BREAK_TIME,
  45 * 60,
  60 * 60,
  90 * 60,
  120 * 60,
]

export const DEFAULT_CA_TIMES = [30 * 60, 45 * 60, 60 * 60, 90 * 60, 120 * 60]

// 90 -> '1 hour 30 minutes'
export const minsToHoursAndMinutes = (minutes: number) => {
  const [hours, minutesPast] = [Math.floor(minutes / 60), minutes % 60]
  const [hourLabel, minutesLabel] = [
    hours > 0 && pluralize('hours', hours, true),
    (hours === 0 || minutesPast > 0) && pluralize('minutes', minutesPast, true),
  ]

  return compact([hourLabel, minutesLabel]).join(' ')
}

export const anyShiftLong = (shifts: ShiftType[]) =>
  !!shifts.every(shift => isValid(parseISO(shift?.startTime)) && isValid(parseISO(shift?.endTime))) &&
  some(shifts, isShiftLong)

export const validateBreakTime = (breakTime: number, shifts: ShiftType[]) => {
  const jobContainsLongShift = some(shifts, isShiftLong)
  if (jobContainsLongShift && breakTime != null && breakTime / 60 < MIN_BREAK_TIME_FOR_LONG_SHIFT_MINUTES) {
    return `If a shift is ${SHORT_SHIFT_DURATION_HOURS}  hours or longer, workers must have a minimum break of ${minsToHoursAndMinutes(
      MIN_BREAK_TIME_FOR_LONG_SHIFT_MINUTES,
    )} minutes, paid/unpaid`
  }
}

export const validateBreakTimeForUK = (locale: string, breakTime: number, shifts: ShiftType[]) => {
  if (locale === locales.enGB) return validateBreakDuration(breakTime, shifts)
  return false
}

export const validateBreakDuration = (breakDuration: number, shifts: ShiftType[]) =>
  !!shifts.every(shift => isValid(parseISO(shift?.startTime)) && isValid(parseISO(shift?.endTime))) &&
  validateBreakTime(breakDuration, shifts)
