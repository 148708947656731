import { flatMap, flatten, get, isArray, map, omit, uniqBy } from 'lodash'
import pluralize from 'pluralize'
import { all, call, put } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { jobCandidateTypeEnum } from 'api/entities/jobCandidate'
import { idForEntity } from 'api/schemas'
import { entityActionRequest, entityApiRequest } from 'lib/redux-crud/entityActionRequest'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { getAgencyShiftWorkersFromCandidates } from './agencyShiftWorker'
import { putFailureAction } from './errorMessage'

// * Fetch job candidates for multiple shifts at once. This has limited support:
//     * incomplete error handling
//     * no pagination support (we'll fetch 10k results per page)
//     * agency shift workers are not included in results
// * Normalize result from IR endpoint to return an array of candidates
// * Side-load agency shift workers when fetching IR candidates
export function* fetchJobCandidates(options) {
  yield takeLatestDeep(types.JOB_CANDIDATES_FETCH_BEGIN, function* (action) {
    if (action.payload.shiftIds) {
      try {
        const requestOptions = {
          apiAction: apiActions.list,
          entityType: 'jobCandidate',
          meta: options.context,
        }
        const results = yield all(
          map(action.payload.shiftIds, shiftId =>
            call(entityApiRequest, {
              ...requestOptions,
              entity: { shiftId, ...omit(action.payload, 'shiftIds'), perPage: 10000 },
              normalizePayload: payload => normaliseCandidates(payload, shiftId),
            }),
          ),
        )
        // uniqBy to remove duplicated candidates in multiple shifts
        // This assumes that candidate IDs are worker IDs (as per /app/models/worker_profile_for_employer.rb at cd36cec)
        const payload = uniqBy(flatten(map(results, 'payload')), idForEntity)

        const meta = { requestOptions, beginAction: action }
        yield put({
          type: types.JOB_CANDIDATES_FETCH_SUCCEEDED,
          payload,
          meta,
        })
      } catch (e) {
        return yield putFailureAction({
          type: types.JOB_CANDIDATES_FETCH_FAILED,
          error: e,
          beginAction: action,
        })
      }
    } else {
      yield call(
        entityActionRequest,
        {
          normalizePayload: payload => normaliseCandidates(payload, action.payload.shiftId),
          *afterSuccess({ payload }) {
            yield call(getAgencyShiftWorkersFromCandidates, { payload })
          },
        },
        action,
      )
    }
  })
}

// Mock updating jobCandidate.shiftBooking by updating shfitBooking
export function* updateJobCandidate(options) {
  yield takeLatestDeep(types.JOB_CANDIDATE_UPDATE_BEGIN, function* (action) {
    try {
      let shiftBooking = {}
      if (action.payload.shiftBooking) {
        const { payload: shiftBookingResponse } = yield call(entityApiRequest, {
          apiAction: apiActions.update,
          entityType: 'shiftBooking',
          entity: action.payload.shiftBooking,
        })
        shiftBooking = shiftBookingResponse
      }
      yield put({
        type: types.JOB_CANDIDATE_UPDATE_SUCCEEDED,
        payload: {
          ...action.payload,
          shiftBooking: {
            ...action.payload.shiftBooking,
            ...shiftBooking,
          },
        },
      })
    } catch (error) {
      return yield putFailureAction({
        type: types.JOB_CANDIDATE_UPDATE_FAILED,
        error,
        beginAction: action,
      })
    }
  })
}

// See https://syftapp.atlassian.net/wiki/spaces/SV/pages/126418947/Agency+Assigning+API
export function normaliseCandidates(payload = {}, shiftId) {
  const agencyShiftWorkers = payload?.agencyShiftWorkers ? [...payload.agencyShiftWorkers] : []
  const tempPayload = payload.agencyShiftWorkers ? omit(payload, 'agencyShiftWorkers') : payload
  const candidates = isArray(tempPayload)
    ? // For non Syft Force user, candidates comes as array,
      map(tempPayload, candidate => ({ ...candidate, $isAgencyWorker: false }))
    : flatMap(tempPayload, (candidates = [], $candidateType) =>
        flatMap(candidates, candidate => {
          const isAgency = $candidateType === pluralize(jobCandidateTypeEnum.agencyShift)
          return isAgency
            ? // expand agencyShifts into agencyShiftWorkers
              candidate.workers.map(worker => {
                const agencyShiftWorker = agencyShiftWorkers?.find(({ id }) => id === worker.id)
                return {
                  ...worker,
                  ...agencyShiftWorker,
                  agencyShift: candidate,
                  agencyId: candidate.agencyId,
                  $candidateType: jobCandidateTypeEnum.agencyShift,
                  $isAgencyWorker: true,
                }
              })
            : {
                ...candidate,
                $type: 'jobCandidate',
                $candidateType: pluralize.singular($candidateType),
                $isAgencyWorker: false,
              }
        }),
      )

  // Associate shiftId to candidate
  // TODO: we should use bookings instead of jobCandidate to associate workers and shifts
  return map(candidates, c => ({ ...c, shiftId }))
}

/**
 * Removing a candidate from (multiple) shifts.
 * This dispatches a single "cancel booking" API call, and dispatches DELETE_SUCCEEDED
 * actions to remove the candidates from the store.
 * NOTES:
 * - Only works with 1 unique worker
 * - Only works with 1 unique job (multiple shifts allowed)
 * - `deleteSpots` uniform throughout all bookings
 */
export function* bulkDeleteJobCandidates(deleteCandidatesActions) {
  const bookings = map(deleteCandidatesActions, action => get(action, 'payload'))

  // Retrieve jobId, workerId and deleteSpot from the first booking
  const jobId = bookings[0].jobId
  const workerId = bookings[0].id
  const deleteSpot = bookings[0].deleteSpot
  const shiftIds = map(bookings, booking => booking.shiftId)

  try {
    yield call(entityApiRequest, {
      apiAction: apiActions.delete,
      entityType: 'booking',
      entity: {
        jobId,
        workerId,
        shiftIds: shiftIds.map(shiftId => parseInt(shiftId, 10)),
        deleteSpot: deleteSpot === true,
      },
    })

    yield all(
      map(deleteCandidatesActions, action =>
        put({
          type: types.JOB_CANDIDATE_DELETE_SUCCEEDED,
          payload: action.payload,
          meta: action.meta,
        }),
      ),
    )

    return { successPayload: true }
  } catch (error) {
    return { failurePayload: error }
  }
}

/**
 * Delete a jobCandidate.
 * Agency worker jobCandidate removal is done through removing
 * the agencyWorker.
 */
export function* deleteJobCandidate(options) {
  yield takeLatestDeep(types.JOB_CANDIDATE_DELETE_BEGIN, function* (action) {
    const jobCandidate = action.payload

    // Handle non-agency workers with default behaviour
    if (jobCandidate.$candidateType !== jobCandidateTypeEnum.agencyShift) {
      return yield call(entityActionRequest, options, action)
    }

    yield call(
      entityActionRequest,
      {
        ...options,
        *afterSuccess() {
          return yield put({
            type: types.JOB_CANDIDATE_DELETE_SUCCEEDED,
            payload: action.payload,
            meta: action.meta,
          })
        },
        *afterFailure(failureAction) {
          return yield put({
            ...failureAction,
            type: types.JOB_CANDIDATE_DELETE_FAILED,
          })
        },
      },
      {
        type: types.AGENCY_SHIFT_WORKER_DELETE_BEGIN,
        payload: jobCandidate,
      },
    )
  })
}
