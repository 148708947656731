import { REHYDRATE } from 'redux-persist'
import { agencyJobApprovalStatus } from 'api/entities/agencyJobDetail'
import { REMOVE_INFO_BANNER } from '../actions/action-types'

export const BANNER_FOR_JOB_APPROVALS = 'agencyJobApprovalBanners'
export const BANNER_FOR_JOB_STATUS = 'agenyJobStatusBanner'
export const OVERDUE_JOB = 'overdue'

export const initialState = {
  [BANNER_FOR_JOB_APPROVALS]: [agencyJobApprovalStatus.awaitingApproval, agencyJobApprovalStatus.rejected],
  [BANNER_FOR_JOB_STATUS]: [OVERDUE_JOB],
}

export type BannerForType = keyof typeof initialState
export type BannerType = typeof initialState[BannerForType][number]

type ActionType = {
  type?: typeof REMOVE_INFO_BANNER | typeof REHYDRATE
  payload: {
    bannerFor: BannerForType
    bannerName: BannerType
    infoBanner?: typeof initialState
  }
}

/**
 * This reducer has been created to show information banner to an user that has loged-in
 * There is a posibility to hide the information within the state until the user log-in again
 */
const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case REMOVE_INFO_BANNER:
      const { bannerFor, bannerName } = action.payload
      return {
        ...state,
        [bannerFor]: state[bannerFor]?.filter((curentType: BannerType) => bannerName !== curentType),
      }
    case REHYDRATE:
      return action.payload?.infoBanner || initialState
    default:
      return state
  }
}

export default reducer
