import React from 'react'
import { uniqueId } from 'lodash'

const Housekeeping = props => (
  <svg width={120} height={152} viewBox="0 0 120 152" {...props}>
    <title>{`Housekeeping`}</title>
    <g id="housekeeping" transform="translate(30.000000, 38.000000)" fill="#003a9b" fillRule="nonzero">
      <g id="Group-35">
        <path
          d="M14.6616113,46.1986186 C22.4053482,38.7894514 24.5745122,28.021043 25.1340088,24 C18.6583788,24.6818946 8.8321424,27.852767 3.77628254,39.7639452 C1.39682945,45.3685408 0.327541212,50.5072378 1.32427402e-12,54.9853888 C2.23288794,54.6061398 7.55766298,52.9949598 14.6616113,46.1986186"
          id={uniqueId('Fill-102')}
        />
        <path
          d="M50.5043232,15.9133844 C50.4482462,7.1379164 43.1582238,1.40687462e-12 34.2521616,1.40687462e-12 C25.2912968,1.40687462e-12 18,7.18312488 18,16.0125919 L18,30.436608 L22.5906748,29.077842 L22.5906748,16.0125919 C22.5906748,9.67712594 27.822413,4.52335942 34.2521616,4.52335942 C40.6424014,4.52335942 45.872865,9.64447536 45.9136484,15.9485465 L46.3890292,67.8240196 L50.979704,67.8240196 L50.5043232,15.9133844"
          id={uniqueId('Fill-103')}
        />
        <polyline
          id={uniqueId('Fill-104')}
          points="59.0387646 66 36.9265466 66 36 72.7310402 59.965311 72.7310402 59.0387646 66"
        />
        <path
          d="M15.089979,63.7558946 C15.7871192,59.3342542 19.362034,55.7389244 23.8341822,54.9628456 C26.9107756,54.4291344 29.793648,55.2014458 32.0456532,56.7900216 L32.0456532,27.4974392 C32.0456532,25.54594 30.3047144,23.988759 28.1725102,24.0000001 C28.0934926,24.0000001 28.00173,24.0050842 27.9201634,24.00634 C27.4498804,27.919385 25.338068,39.8632138 16.602786,48.2205034 C8.53023336,55.9436184 2.4637113,57.4832182 0.00269154004,57.779585 C-0.072502746,64.432766 1.44540225,69.3203048 2.35538056,71.6384952 C2.7122348,72.5501994 3.61201726,73.1404212 4.60356226,73.1404212 L18.3054905,73.1404212 C15.8674114,70.8435794 14.5075419,67.444153 15.089979,63.7558946"
          id={uniqueId('Fill-105')}
        />
        <path
          d="M34.5873496,66.1726882 C34.5873496,70.6860014 30.8747912,74.3441206 26.294312,74.3441206 C21.713833,74.3441206 18,70.6860014 18,66.1726882 C18,61.6593752 21.713833,58 26.294312,58 C30.8747912,58 34.5873496,61.6593752 34.5873496,66.1726882"
          id={uniqueId('Fill-106')}
        />
      </g>
    </g>
  </svg>
)

export default Housekeeping
