import React from 'react'
import Typography from 'components/Typography'

import Link from 'components/Link'
import { useAddComponentRumTag } from '../../lib/hooks/analytics/useGlobalTags'

const Error404 = () => {
  useAddComponentRumTag('pageStatus', '404')
  return (
    <div style={{ maxWidth: 500, alignSelf: 'center', margin: '5em 0' }}>
      <Typography type="title3" style={{ marginBottom: '1em' }}>
        Page Not Found (404)
      </Typography>

      <Typography type="display1" style={{ marginBottom: '1em' }}>
        Unfortunately, this page could not be found. This page has either been deleted, or its address might
        have been altered.
      </Typography>

      <Typography type="display1">
        Go to the <Link to="/">homepage</Link>.
      </Typography>
    </div>
  )
}

export default Error404
