// Proxy localstorage.getItem. Don't throw in case of inaccessible local storage
// or exceeded size limit
export function getItem(key) {
  try {
    return window.localStorage.getItem(key)
  } catch (e) {
    /* istanbul ignore next */
    return null
  }
}

export function setItem(key, value) {
  try {
    window.localStorage.setItem(key, value)
  } catch (e) {}
}

export function removeItem(key) {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {}
}

export const parseItem = key => JSON.parse(getItem(key) ?? null)
