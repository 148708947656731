import {
  compact,
  defaultTo,
  every,
  filter,
  find,
  flatten,
  get,
  map,
  negate,
  some,
  sumBy,
  trim,
  update,
  values,
} from 'lodash'
import {
  addDays,
  addHours,
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  isDate,
  isPast,
  isValid,
  setHours,
  setMinutes,
  parseISO,
} from 'lib/date-fns'
import { label as labelForRole } from './role'

// From https://syftapp.atlassian.net/wiki/display/SV/Candidates+API
export const candidateStatusEnum = {
  // currently booked on shift(s) in the job
  applied: 'applied',
  offered: 'offered',
  booked: 'booked',
}

export const candidateStatusLabel = {
  applied: 'Interested',
  offered: 'Pending',
  booked: 'Booked',
}

// @param {Object} job Job as per schema. Deserialized.
// @param {Boolean} options.excludeEndDates Set to true if you don't want to include
//   dates on which the shifts end. (Overnight shifts.)
// @return {[Date]}
export const datesOfAllShifts = (job, options = {}) =>
  compact(
    flatten(
      map(job.shifts, shift => [
        parseISO(shift.startTime),
        options.excludeEndDates ? null : parseISO(shift.endTime),
      ]),
    ),
  )

// @private
const findJobRole = (job, { roles }) => roles && roles[job.roleId]

// @private
const findJobVenue = (job, { venues }) => get(venues, get(job, 'listing.venueId'))

// @private
const findJobArea = (job, { venues }) => {
  const allAreas = venues && flatten(map(venues, v => values(v.areas)))
  return find(allAreas, { id: job.areaId })
}

// @private
export const findJobAssocEntities = (...args) => ({
  role: findJobRole(...args),
  venue: findJobVenue(...args),
  area: findJobArea(...args),
})

/**
 * Get customName label for job before reverting to default role name
 * @param {Object} job Job as per schema. Deserialized.
 * @param {Object} options options
 * @param {Object} options.role options of selected job role
 * @param {Object} options.roleLabelOptions options for api/entities/role:label method
 */
export const labelForJobRole = (job, { role, roleLabelOptions = {} }) => {
  const singularRoleLabel =
    typeof roleLabelOptions.singular !== 'undefined' ? roleLabelOptions.singular : job.workersRequired === 1
  return (
    get(job, 'customName') ||
    (role && labelForRole(role, { ...roleLabelOptions, singular: singularRoleLabel })) ||
    ''
  )
}

export const totalJobDurationInHours = job =>
  sumBy(
    job.shifts,
    shift =>
      differenceInHours(shift.endTime, shift.startTime) *
      get(shift, 'bookingStatus.totalSpots', job.workersRequired),
  )

// TODO: avoid separators comments. Consider splitting and refactoring

// --------------- Custom Names --------------
// Custom name begins life as role name.
// If the custom name entered is the same as the role name,
// or is unchanged, there's no need to pass it.
export const setJobCustomName = (job, { labelForRoleId }) => {
  const roleLabel = labelForRoleId(get(job, 'roleId'))
  const trimmedCustomName = trim(get(job, 'customName', ''))

  return {
    ...job,
    customName: trimmedCustomName !== roleLabel ? trimmedCustomName : undefined,
  }
}

// --------------- Break times ---------------

// A short shift allows an employer to apply no break time to it
export const SHORT_SHIFT_DURATION_HOURS = 6
export const isShiftShort = shift => {
  const { startDateTime, endDateTime } = getStartAndEndDateTime(shift)
  const thresholdMinutes = SHORT_SHIFT_DURATION_HOURS * 60
  const shiftDurationMinutes = Math.abs(
    differenceInMinutes(
      endDateTime < startDateTime && isDate(endDateTime) ? addDays(endDateTime, 1) : endDateTime,
      startDateTime,
    ),
  )

  return shiftDurationMinutes < thresholdMinutes
}
export const isShiftLong = negate(isShiftShort)

export const MIN_BREAK_TIME_FOR_LONG_SHIFT_MINUTES = 10
export const DEFAULT_BREAK_TIME_FOR_SHORT_SHIFT_MINUTES = 0
export const DEFAULT_BREAK_TIME_FOR_LONG_SHIFT_MINUTES = 20
export const DEFAULT_CA_BREAK_TIME_FOR_LONG_SHIFT_MINUTES = 30

export const setJobDefaultBreakDuration = (job, isCA) => {
  const jobWithCaDuration = {
    ...job,
    breakDurationSeconds: job.caBreakDurationSeconds || job.breakDurationSeconds,
  }

  const jobContainsLongShift = some(job.shifts, isShiftLong)
  const defaultLongBreakDuration = isCA
    ? DEFAULT_CA_BREAK_TIME_FOR_LONG_SHIFT_MINUTES
    : DEFAULT_BREAK_TIME_FOR_LONG_SHIFT_MINUTES
  const defaultBreakDuration = jobContainsLongShift
    ? defaultLongBreakDuration * 60
    : DEFAULT_BREAK_TIME_FOR_SHORT_SHIFT_MINUTES * 60

  // TODO: need to revert back to breakDuration once BE reimplements only seconds
  return update(jobWithCaDuration, 'breakDurationSeconds', breakDurationSeconds =>
    defaultTo(breakDurationSeconds, defaultBreakDuration),
  )
}

export const getStartAndEndDateTime = shift => {
  const { startTime, endTime } = shift
  const startDateTime = new Date(startTime)
  const endDateTime = new Date(endTime)
  if (isValid(startDateTime) && isValid(endDateTime)) {
    return { startDateTime, endDateTime }
  }
  const [startHours, startMinutes] = startTime?.split(':') ?? []
  const [endHours, endMinutes] = endTime?.split(':') ?? []

  return {
    startDateTime: setHours(setMinutes(new Date(), startMinutes), startHours),
    endDateTime: setHours(setMinutes(new Date(), endMinutes), endHours),
  }
}

export const areShiftsWithBreakLongerThanMinimumHours = (shifts, minimumHours = 0, breakInSeconds = 0) =>
  find(shifts, shift => {
    const breakInMinutes = breakInSeconds > 0 ? breakInSeconds / 60 : breakInSeconds
    const { startDateTime, endDateTime } = getStartAndEndDateTime(shift)
    const shiftDurationInHours = differenceInHours(endDateTime, addMinutes(startDateTime, breakInMinutes))

    if (shiftDurationInHours < 0) {
      return (
        differenceInHours(addHours(endDateTime, 24), addMinutes(startDateTime, breakInMinutes)) >=
        minimumHours
      )
    }
    return shiftDurationInHours >= minimumHours
  })

export const filterPastJobsOrBookedCandidates = jobs =>
  map(jobs, job => ({
    ...job,
    shifts: filter(
      job.shifts,
      shift => !isPast(get(shift, 'startTime')) || get(shift, 'candidateCounts.booked', 0) !== 0,
    ),
  }))

export const filterPastJobs = jobs =>
  map(jobs, job => ({
    ...job,
    shifts: filter(job.shifts, shift => !isPast(get(shift, 'startTime'))),
  }))

export const filterBookedCandidates = jobs =>
  map(jobs, job => ({
    ...job,
    shifts: filter(job.shifts, shift => get(shift, 'candidateCounts.booked', 0) !== 0),
  }))

export const hidePostOnSyftFromSyftForce = jobs => {
  const areShiftsPast = map(jobs, job => map(job.shifts, shift => isPast(get(shift, 'startTime'))))
  return every(flatten(areShiftsPast))
}
