export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    reason: {
      type: 'string',
    },
  },
}
