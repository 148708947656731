import React from 'react'

const VisaCard = props => (
  <svg xmlnsXlink="http://www.w3.org/2000/svg" width={74} height={41} {...props} viewBox="0 0 74 41">
    <title>{`Visa Card`}</title>
    <g fill="none" fillRule="evenodd">
      <rect width="74" height="41" fill="#FAFAFA" rx="3" />
      <path
        fill="#00579F"
        d="M33.307 28H29l2.694-15H36zM48 13.66a9.849 9.849 0 0 0-3.588-.66c-3.95 0-6.732 2.109-6.75 5.124-.032 2.225 1.992 3.46 3.507 4.202 1.547.758 2.073 1.253 2.073 1.928-.015 1.038-1.25 1.516-2.402 1.516-1.597 0-2.453-.246-3.753-.824l-.527-.247-.56 3.477c.938.428 2.667.807 4.461.824 4.198 0 6.93-2.076 6.963-5.29.016-1.763-1.053-3.114-3.358-4.218-1.399-.708-2.256-1.186-2.256-1.911.017-.66.725-1.335 2.304-1.335a6.79 6.79 0 0 1 2.98.593l.362.165.544-3.345z"
      />
      <path
        fill="#00579F"
        fillRule="nonzero"
        d="M52.036 22.686c.322-.862 1.558-4.197 1.558-4.197-.016.032.32-.877.513-1.436l.273 1.293s.739 3.59.9 4.34h-3.244zM56.852 13h-3.017c-.931 0-1.638.271-2.04 1.245L46 28h4.094l.819-2.25h5.009c.112.527.466 2.25.466 2.25H60l-3.148-15z"
      />
      <path
        fill="#00579F"
        d="M26.802 13l-3.888 10.24-.425-2.077c-.719-2.396-2.973-5-5.489-6.294L20.561 28h4.199L31 13h-4.198z"
      />
      <path
        fill="#FAA61A"
        d="M20.025 13h-5.964l-.061.297c4.653 1.221 7.734 4.165 9 7.703l-1.297-6.763c-.213-.94-.87-1.206-1.678-1.237z"
      />
    </g>
  </svg>
)

export default VisaCard
