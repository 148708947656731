import { omit, isRegExp, isArray, isString, isFunction } from 'lodash'

/**
 * Check the matching object with a predicate
 *
 * @param  {RegExp|Array|String|Function}  predicate matching rule
 * @param  {[type]}  object matching object
 * @return {Boolean} If true, the object is matched with the pattern
 */

export const isMatch = (predicate, object) =>
  (predicate === '*' && true) ||
  (isRegExp(predicate) && predicate.test(object)) ||
  (isArray(predicate) && predicate.indexOf(object) > -1) ||
  (isString(predicate) && predicate === object) ||
  (isFunction(predicate) && predicate(object))

export const trackingTransformations = [
  {
    pattern: /AUTH/,
    transformation: action =>
      omit(action, ['payload.oauth', 'meta.requestOptions', 'meta.beginAction.payload']),
  },
  {
    pattern: /USER_CREATE/,
    transformation: action =>
      omit(action, ['payload.oauth', 'meta.requestOptions', 'meta.beginAction.payload']),
  },
  {
    pattern: ['persist/REHYDRATE'],
    transformation: action => omit(action, 'payload'),
  },
]
