import { find, without, isNil } from 'lodash'

const identityComparator = item => x => item === x

// Adds item into an array if it's not there, otherwise removes it.
//
// @param {Array?} collection
// @param {*} item
// @param {item => candidateFromArray => Boolean} comparator
// @return {Array}
export default (array, item, comparator = identityComparator) => {
  const itemInArray = find(array, comparator(item))
  return !isNil(itemInArray) ? without(array, itemInArray) : array.concat([item])
}
