import React from 'react'
import PropTypes from 'prop-types'
import { upperFirst, camelCase } from 'lodash'
import classnames from 'classnames'

import injectSheet from 'styles/jss'
import availableIcons from './availableIcons'
import styles from './styles'

export { default as availableIcons } from './availableIcons'

const pascalCase = str => upperFirst(camelCase(str))

const Icon = ({ classes, name, className, inheritColor, ...rest }) => {
  const Component = require(`./components/${pascalCase(name)}`).default
  return <Component className={classnames(className, inheritColor && classes.inheritColor)} {...rest} />
}

Icon.propTypes = {
  name: PropTypes.oneOf(availableIcons),
  className: PropTypes.string,
  inheritColor: PropTypes.bool,
}

export default injectSheet(styles)(Icon)
