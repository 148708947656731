export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  definitions: {},
  id: 'http://example.com/example.json',
  properties: {
    worker_id: {
      $rel: 'worker',
      type: 'integer',
    },
    role_id: {
      $rel: 'role',
      type: 'integer',
    },
    verified: {
      type: 'boolean',
    },
    average_rating: {
      type: 'integer',
    },
    skill_ids: {
      items: {
        $rel: 'skill',
        type: 'integer',
      },
      type: 'array',
    },
    assigned_skill_ids: {
      items: {
        $rel: 'skill',
        type: 'integer',
      },
      type: 'array',
    },
    rated_skill_counts: {
      items: {},
      type: 'array',
    },
    rated_skill_ids: {
      items: {
        $rel: 'skill',
        type: 'integer',
      },
      type: 'array',
    },
  },
  required: ['role_id', 'verified'],
  type: 'object',
}
