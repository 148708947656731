import { mapValues, get } from 'lodash'

import { combineReducers } from 'redux'
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router'

import { entityTypes } from 'api/schemas'
import * as actionTypes from 'actions/action-types'
import { getEntityTypeReducers, getEntityTypeInitialValues } from 'lib/redux-crud/entityReducer'
import auth, { initialState as initialAuthState } from './auth'
import wagePreviews from './wagePreviews'
import images from './images'
import localstorage from './localstorage'
import platform from './platform'
import config from './config'
import joyride from './joyride'
import preserveFeatureVisibility from './preserveFeatureVisibility'
import jobPosting from './jobPosting'
import screenReaderAlert from './screenReaderAlert'
import timesheetAgencyEntries from './timesheetAgencyEntries'
import { extendedFormReducer } from './extendedFormReducer'
import infoBanner from './infoBanner'
import { history } from 'lib/history'

// Remember previous location
export const routerReducer = (state = {}, action) => {
  if (action.type === LOCATION_CHANGE) {
    const currentLocation = get(state, 'locationBeforeTransitions')
    const previousLocation = get(state, 'previousLocation')
    const newLocation = get(action, 'payload.location')

    return {
      ...state,
      previousLocation:
        get(currentLocation, 'pathname') === get(newLocation, 'pathname')
          ? previousLocation
          : currentLocation,
      locationBeforeTransitions: newLocation,
    }
  } else {
    return state
  }
}

// See options.maxAge in the entity reducer
// In seconds
const customMaxAge = {
  venue: 2 * 60,
  thread: 10,
  message: 10,
  notification: 10,
  role: 24 * 60 * 60,
  jobRate: 24 * 60 * 60,
  standardUniform: 24 * 60 * 60,
  skill: 24 * 60 * 60,
  workerJob: 60 * 60,
}

const entityTypeReducers = getEntityTypeReducers(entityTypes, actionTypes, customMaxAge)

const entityTypeInitialValues = getEntityTypeInitialValues(entityTypes)

const appReducers = combineReducers({
  auth,
  config,
  form: extendedFormReducer,
  localstorage,
  platform,
  routing: routerReducer,
  router: connectRouter(history),
  jobPosting,
  ...entityTypeReducers,
  // images and wagePreviews need to be at the bottom because they extend entityTypeReducers reducers
  infoBanner,
  images,
  wagePreviews,
  timesheetAgencyEntries,
  joyride,
  preserveFeatureVisibility,
  screenReaderAlert,
})

const globalReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.AUTH_LOG_OUT: {
      // Clear the store
      const clearedState = mapValues(state, () => undefined)

      // TODO Refactor
      // `hasEverBeenLoggedIn` should be persisted between multiple user sessions
      clearedState.auth = initialAuthState
      clearedState.auth.hasEverBeenLoggedIn = state.auth.hasEverBeenLoggedIn

      // Preserve routing and platform info
      clearedState.routing = state.routing
      clearedState.router = state.router
      clearedState.config = state.config
      clearedState.platform = state.platform
      clearedState.localstorage = state.localstorage

      // Preserve some custom features
      clearedState.preserveFeatureVisibility = state.preserveFeatureVisibility

      return {
        ...clearedState,
        ...entityTypeInitialValues,
      }
    }
    default:
      return state
  }
}

export const rootReducer = (state, action) => {
  const stateAfterAppReducers = appReducers(state, action)
  const stateAfterGlobalReducer = globalReducer(stateAfterAppReducers, action)
  return stateAfterGlobalReducer
}
