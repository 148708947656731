export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    desc: {
      type: 'string',
      description: 'Uniform description',
    },
    picture: {
      type: 'object',
      properties: {
        uuid: {
          type: 'string',
        },
      },
      required: ['uuid'],
    },
  },
}
