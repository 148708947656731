/* eslint-disable func-names */
/* eslint-disable import/order */

import { isInteger, get } from 'lodash'
import { call, put } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'
import { normalizePayload as normalizeApprovalStatPayload } from './timesheetApprovalStat'

// Pass request flag to the backend to request a response containing timesheet approval
// stats instead of the default `{ success }` response.
//
// See https://syftapp.atlassian.net/wiki/spaces/SV/pages/65011735/Timesheet+Listing+Approval+Stats+API
//
// @param {Object} action Timesheet update begin action
// @return {Object} action Action with updated meta
const requestApprovalStatsResponse = action => ({
  ...action,
  meta: {
    ...action.meta,
    requestHeaders: {
      ...get(action, 'meta.requestHeaders'),
      'X-Response-Entity': 'stats',
    },
  },
})

// @param {Object} responsePayload Response payload containing approval stats
function* consumeApprovalStats(responsePayload) {
  yield put({
    type: types.TIMESHEET_APPROVAL_STAT_FETCH_SUCCEEDED,
    payload: normalizeApprovalStatPayload(responsePayload),
  })
}

// To synchronize changes with backend after bulk approval:
//   * request timesheet approval stats in the update response
//   * invalidate cached for timesheet entries after success
export function* updateTimesheet(options) {
  yield takeLatestDeep(types.TIMESHEET_UPDATE_BEGIN, function* (action) {
    const listingId = action.payload.listingId
    const newAction = requestApprovalStatsResponse(action)

    const [payload] = yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.update,
        entityType: 'timesheet',
        context: options.context,
      },
      newAction,
    )

    yield consumeApprovalStats(payload)

    // After updating many timesheet entries, invalidate the timesheet entry cache
    if (isInteger(listingId)) {
      return yield put({
        type: types.TIMESHEET_ENTRIES_FETCH_BEGIN,
        payload: { listingId },
        meta: {
          refetch: true,
        },
      })
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn('Missing listingId in timesheet update call. Timesheets should be refreshed on success.')
    }
  })
}
