import { AreaItem, DateItem, ScheduleEntitySchemaItem, ShiftItem } from 'api/types/schedule'

export const enum View {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  All = 'All',
}

export type ScheduleStateType = {
  date: Date
  view: View
  venueIds: number[]
  areaIds: number[]
  roleIds: number[]
  countryCode: string
  timesheetApprovalNeeded: boolean
  unfilled: boolean
  rowsPerPage: number
  currentPage: number
}

export type FilterParams = {
  startDate: string
  endDate: string
  currentDate?: string
  viewMode: View
  venueIds: string[]
  areaIds: string[]
  roleIds: number[]
  timesheetApprovalNeeded: boolean
  unfilled: boolean
  perPage: number
  page: number
}

export type ShiftItemType = {
  roleName: string
  url: string
  multiplePayRates: boolean
  duplicateShiftCategory?: string
} & ShiftItem

export type AreaItemType = {
  name: string
  shifts: ShiftItemType[]
} & AreaItem

export type DateItemType = {
  areas: AreaItemType[]
} & DateItem

export type VenueItemType = {
  name: string
  location: string
  timezone: string
  dates: DateItemType[]
} & ScheduleEntitySchemaItem
