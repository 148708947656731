import { get } from 'lodash'

import { apiRequest, handleCall } from './call'
import { serialize } from './serialization'
import { getEntityEndpoint } from './endpoints'

// Expose the updateAuth function.
export { updateAuth } from './call'

/**
 * Perform a CRUD request on the given entity, i.e. one of:
 *  - fetch a list of all entities of a given type
 *  - fetch a single entity with a given type and ID
 *  - create a new entity on the server
 *  - send an updated data object for an entity to the server, overwriting that
 *    entity data
 *
 * Endpoints are derived based on entity type. You can provide exceptions from standard
 * endpoint format in `customEndpoints` in ./endpoints.
 *
 * Both the request payload and response will be serialized (deserialized)
 * per application rules (most notably, property names are camelcased).
 * See ./serialization for more.
 *
 * @param {Object} options:
 *   {String ~ api/endpoints#apiActions} apiAction One of the standard api actions
 *   {String} entityType Type of entity, defined in api/schemas
 *   {Object} entity new data for the entity matching its schema and containing
 *   identifier with type `{ id: Number }`
 *   {Object} meta
 */
export const entityApiRequest = ({ apiAction, entityType, entity, meta }) =>
  new Promise((resolve, reject) => {
    const { url, method, useBodyAsQueryParams } = getEntityEndpoint(entityType, entity, apiAction, meta)
    // TODO Clean up
    let requestBody = null
    let requestQuery = null
    if (entity && entity.file) {
      requestQuery = {
        ...serialize(entityType, apiAction, entity),
        $type: undefined,
        file: undefined,
      }
      requestBody = new FormData()
      requestBody.append('file', entity.file)
    } else if (entity) {
      requestBody = serialize(entityType, apiAction, entity)
    }
    // TODO Refactor. Include meta.params GET params
    if (method === 'GET' && get(meta, 'params')) {
      requestBody = { ...requestBody, ...serialize(null, null, meta.params) }
    }
    if (useBodyAsQueryParams) {
      requestQuery = requestBody
      requestBody = null
    }

    const req = apiRequest({
      path: url,
      body: requestBody,
      headers: get(meta, 'requestHeaders'),
      query: requestQuery,
      method,
    })
    handleCall(req, resolve, reject)
  })

/**
 * https://syftapp.atlassian.net/wiki/display/SV/Login+API
 */
export const loginUser = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/users/login', body: entity, method: 'POST' })
    handleCall(req, resolve, reject)
  })

export const logoutUser = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/oauth/revoke',
      body: entity,
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })

/**
 * https://syftapp.atlassian.net/wiki/display/SV/Forgotten+Password+API
 */
export const recoverPassword = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/users/recover_password', body: entity, method: 'POST' })
    handleCall(req, resolve, reject)
  })

export const resendInternalWorkerInvitation = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/internal_resourcing/invite_worker/${entity.importedWorkerId}/resend`,
      method: 'POST',
      ...(entity.quiet && { body: { quiet: true } }),
    })
    handleCall(req, resolve, reject)
  })

/**
 * https://syftapp.atlassian.net/wiki/display/SV/Forgotten+Password+API
 */
export const resetPassword = ({ entity }) =>
  new Promise((resolve, reject) => {
    const url = get(entity, 'isAgencyInvitation')
      ? '/agencies/agency_account_invitation'
      : '/users/recover_password'
    const method = get(entity, 'isAgencyInvitation') ? 'PATCH' : 'PUT'
    const req = apiRequest({
      path: url,
      body: serialize(null, null, entity),
      method,
    })
    handleCall(req, resolve, reject)
  })

/**
 * https://syftapp.atlassian.net/wiki/display/SV/Refresh+Token+API
 */
export const refreshAuth = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: '/users/refresh', body: entity, method: 'POST' })
    handleCall(req, resolve, reject)
  })

/**
 * https://syftapp.atlassian.net/wiki/display/SV/My+Info+API
 */
export const fetchCurrentUser = () =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/users/me',
      headers: {
        'X-Country-Code': '', // for token based authorization, we dont have a country code. Emptying it because it will fallback to GB further
      },
      body: {},
      method: 'GET',
    })
    handleCall(req, resolve, reject)
  })

/**
 * https://syftapp.atlassian.net/wiki/spaces/SV/pages/8224786/Change+Password+and+Email+API
 */
export const changeEmailPassword = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/users/registration',
      body: serialize(null, null, entity),
      method: 'PATCH',
    })
    handleCall(req, resolve, reject)
  })

export const markAllNotificationsAsRead = (employerId, isAgency) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: isAgency
        ? `/agencies/${employerId}/notifications/mark_read`
        : `/employers/${employerId}/notifications/mark_read`,
      body: {
        read: true,
      },
      method: 'PUT',
    })
    handleCall(req, resolve, reject)
  })

export const downloadRotaBooking = ({ entity: { employerId, rota } }) =>
  new Promise((resolve, reject) => {
    const toCsv = true
    const req = apiRequest({
      path: `/employers/${employerId}/rotas/bookings.csv`,
      method: 'GET',
      body: {
        gte_date: rota.dates.from,
        lte_date: rota.dates.to,
        work_location_id: rota.workLocationId,
        rota_id: rota.id,
      },
    })
    handleCall(req, resolve, reject, false, toCsv, 'rota')
  })

export const downloadAgencyTimesheet = ({
  entity: { agencyId, listingId, roleId, day, venueId, platformId },
  meta,
}) =>
  new Promise((resolve, reject) => {
    const toCsv = true
    const req = apiRequest({
      path: `/agency_portal/agencies/${agencyId}/timesheets.csv`,
      method: 'GET',
      body: {
        listing_id: listingId,
        role_id: roleId,
        day,
        venue_id: venueId,
      },
      headers: { 'X-Platform-Id': platformId },
    })
    handleCall(req, resolve, reject, false, toCsv, 'timesheets')
  })

export const agencyTimesheetCsvDownload = ({
  entity: { sortBy, startDate, endDate, agencyClientIds, venueIds, roleIds, statuses, jobId, workerNames },
  meta,
}) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/agency_portal/timesheets/entries.csv`,
      method: 'GET',
      body: {
        sort_by: sortBy,
        start_date: startDate,
        end_date: endDate,
        employer_ids: agencyClientIds,
        venue_ids: venueIds,
        role_ids: roleIds,
        statuses,
        jobId,
        worker_names: workerNames,
      },
    })
    handleCall(req, resolve, reject, false, true, 'timesheets')
  })

export const downloadEmployerTimesheet = ({
  entity: {
    startDate,
    endDate,
    workLocationIds,
    roleIds,
    workerKinds,
    workerNames,
    timesheetStatuses,
    hideRatedWorkers,
  },
}) =>
  new Promise((resolve, reject) => {
    const toCsv = true
    const req = apiRequest({
      path: '/employers/timesheet/entries.csv',
      method: 'GET',
      body: {
        start_date: startDate,
        end_date: endDate,
        work_location_ids: workLocationIds,
        role_ids: roleIds,
        worker_kinds: workerKinds,
        timesheet_statuses: timesheetStatuses,
        worker_names: workerNames,
        hide_rated_workers: hideRatedWorkers,
      },
    })
    handleCall(req, resolve, reject, false, toCsv, 'timesheets')
  })

export const redeemOauthCode = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: `/indeed_auth/oauth_redeem?code=${entity.indeedToken}&profile_type=${
        entity.profileType ?? 'employer'
      }`,
      method: 'GET',
    })
    handleCall(req, resolve, reject)
  })

export const getByResetToken = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({ path: `/users/get_by_reset_token/${entity.resetPasswordToken}`, method: 'GET' })
    handleCall(req, resolve, reject)
  })
