import addressSchema from './address'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    name: {
      type: 'string',
      description: 'Name',
    },
    external_branch_id: {
      type: 'number',
      description: 'Branch ID',
    },
    address: addressSchema,
    archived: {
      type: 'boolean',
      description: 'Archived branch',
    },
  },
  required: ['name', 'address.line_1', 'address.city', 'address.post_code'],
}
