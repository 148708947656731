export default ({ typography, palette }) => {
  return {
    root: {
      cursor: 'pointer',
      '&:focus': {
        color: palette.primary.main,
        textDecoration: 'underline',
      },
    },

    primary: {
      fontWeight: typography.fontWeightRegular,
      '&, &:link, &:visited': {
        color: palette.primary.main,
      },
      '&:focus, &:hover, &:active': {
        color: palette.primary.dark,
        textDecoration: 'underline',
      },
    },

    secondary: {
      '&:hover, &:active': {
        textDecoration: 'none',
      },
    },

    black: {
      fontWeight: typography.fontWeightRegular,
      '&, &:link, &:visited': {
        color: palette.common.black,
      },
    },
  }
}
