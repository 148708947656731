import workerSchema from './worker'
import payRateSchema from './payRate'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  definitions: {},
  id: 'http://example.com/example.json',
  properties: {
    ...workerSchema.properties,
    shift_booking: {
      type: 'object',
      properties: {
        worker_pay_rate: payRateSchema,
      },
    },
  },
  required: [...workerSchema.required],
  type: 'object',
}
