import { isEmpty, negate, includes, get } from 'lodash'

// See WorkerProfileForEmployer on the backend.
export const workerPlatforms = {
  syftForce: 'own',
  // Syft workers have always "workerPlatform:syft", even on Syft
  syft: 'syft',
  agency: 'agency',
}

export const workerKindsLabelIds = {
  [workerPlatforms.syft]: 'worker_kind_syft',
  [workerPlatforms.agency]: 'worker_kind_agency',
  [workerPlatforms.syftForce]: 'worker_kind_own',
}

export const workerPlatformMessageIds = {
  [undefined]: 'worker_type_my_staff',
  [workerPlatforms.syft]: 'brand_name',
  [workerPlatforms.syftForce]: 'worker_type_my_staff',
  [workerPlatforms.agency]: 'worker_type_agency',
}

export const isSyftForceWorker = (worker, isInternalResourcing) => {
  return !!isInternalResourcing && get(worker, 'workerPlatform') === workerPlatforms.syftForce
}

export const workerContractTypeEnum = {
  casual: 'casual_hours',
  fixed: 'fixed_hours',
}

export const workerContractTypeLabels = {
  [workerContractTypeEnum.casual]: 'Casual hours',
  [workerContractTypeEnum.fixed]: 'Fixed hours',
}

export const isWorkerUnblocked = worker => isEmpty(worker.blacklistedLocationIds)

export const isWorkerBlocked = negate(isWorkerUnblocked)
export const isWorkerBlockedAtEmployerLevel = worker => includes(worker.blacklistedLocationIds, 0)
