import uniformSchema from './uniform'
import shiftSchema from './shift'
import payRateSchema from './payRate'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    area_id: {
      $rel: 'area',
      type: 'integer',
      description: 'Area',
    },
    break_duration_seconds: {
      description: 'Break duration',
      enum: [0 * 60, 10 * 60, 15 * 60, 20 * 60, 30 * 60, 45 * 60, 60 * 60, 90 * 60, 120 * 60],
      type: 'integer',
    },
    ca_break_duration_seconds: {
      description: 'Meal duration',
      enum: [30 * 60, 45 * 60, 60 * 60, 90 * 60, 120 * 60],
      type: 'integer',
    },
    role_id: {
      type: 'integer',
    },
    shifts: {
      shiftSchema,
      // TODO Rename
      $rel: 'shift',
      type: 'array',
    },
    custom_name: {
      type: 'string',
      description: 'Job Title',
    },
    workers_required: {
      type: 'integer',
      description: 'How many people',
    },
    rotation_length: {
      type: 'integer',
      description: 'How many days in the rotation',
    },
    purchase_order_number: {
      type: 'string',
    },
    pay_rate: payRateSchema,
    skill_ids: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    essential_skill_ids: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    flexible_skill_ids: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    desc: {
      type: 'string',
      description: 'Job description',
    },
    uniform: uniformSchema,
    bookable_individually: {
      type: 'boolean',
    },
    health_and_safety_information: {
      type: 'string',
    },
    paid_break: {
      type: 'boolean',
    },
    paid_overtime: {
      type: 'boolean',
    },
    open_to_discuss_competencies: {
      type: 'boolean',
    },
    shift_rate_type_id: {
      type: 'integer',
    },
    temp_to_perm: {
      type: 'boolean',
    },
    externally_billable: {
      type: 'boolean',
    },
    overtime_rule_ids: {
      type: 'array',
      items: {
        type: 'integer',
      },
    },
    workday_boundary: {
      type: 'integer',
    },
  },
  required: [
    'role_id',
    'shifts',
    'workers_required',
    'pay_rate',
    'skill_ids',
    'desc',
    'uniform',
    'bookable_individually',
    'paid_break',
    'open_to_discuss_competencies',
  ],
}
