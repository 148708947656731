import { isEmpty, map, get } from 'lodash'
import { filterTree } from 'lib/tree'

// A worker venue with no areas selected will have no effect on the system
// – same as if it was deleted.
//
// @param {Object} workerVenue Worker venue as per schema. Deserialized.
// @return {Boolean}
export const isNoEffectWorkerVenue = workerVenue =>
  workerVenue.allAreas === false && isEmpty(workerVenue.workerVenueAreas)

const priorities = {
  primary: 1,
  secondary: 2,
}

// Translate our standard VenueInput redux field value to API's shape
export const workerVenuesFromVenuesSelection = (value, priority) =>
  map(value, ({ areas, ...rest }) => ({
    workerVenueAreas: areas.map(areaId => ({ areaId })),
    priority: priorities[priority],
    ...rest,
  }))

/**
 * Converts an array of location ids into the format for workerVenues.
 * Example:
 * [v1, v1_a1, v2_a2] -> [
 *  { venueId: 1, allAreas: true },
 *  { venueId: 2, allAreas: false, workerVenueAreas: [{ areaId: v2_a2 }]}
 * ]
 * @param {Number[]} workerLocationIds
 * @param {Object} locationTree
 */
export const workerVenuesFromLocationIds = (workerLocationIds, locationTree) => {
  const workerLocationIdsSet = new Set(workerLocationIds)
  const workerLocationTree = filterTree(node => workerLocationIdsSet.has(node.value.id))(locationTree)

  const workerVenues = map(get(workerLocationTree, 'children'), venueNode => ({
    venueId: venueNode.value.id,
    allAreas: !venueNode.isPartialMatch,
    ...(venueNode.isPartialMatch
      ? {
          areas: map(venueNode.children, areaNode => ({ areaId: areaNode.value.id })),
        }
      : {}),
  }))

  return workerVenues
}
