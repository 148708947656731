import * as types from '../actions/action-types'
import { REHYDRATE } from 'redux-persist'

export enum FEATURE_NAMES {
  'HARD_SKILLS_POP_UP' = 'hadSkillsPopup',
}

export interface InitialState {
  [FEATURE_NAMES.HARD_SKILLS_POP_UP]: number[]
}

const initialState: InitialState = {
  [FEATURE_NAMES.HARD_SKILLS_POP_UP]: [],
}

type FeaturesTypes = keyof typeof initialState

type ActionType = {
  type?: typeof types.TOGGLE_PRESERVE_FEATURE_VISIBILITY | typeof REHYDRATE
  payload: {
    featureName: FeaturesTypes
    employerId: number
    preserveFeatureVisibility?: InitialState
  }
}

const reducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case types.TOGGLE_PRESERVE_FEATURE_VISIBILITY:
      const { featureName, employerId } = action.payload
      const employerIds = state[featureName]
      const alreadyHidden = employerIds.includes(employerId)
      if (alreadyHidden) {
        return {
          ...state,
          [featureName]: employerIds.filter(id => id !== employerId),
        }
      }
      return {
        ...state,
        [featureName]: [...employerIds, employerId],
      }
    case REHYDRATE:
      return action.payload?.preserveFeatureVisibility || initialState
    default:
      return state
  }
}

export default reducer
