import React from 'react'

const Chef = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Chef`}</title>
    <path
      d="M50 65.05c-6.176-.612-11-5.823-11-12.161 0-6.75 5.472-12.222 12.222-12.222 1.39 0 2.727.232 3.972.66C58.191 36.905 63.256 34 69 34s10.809 2.905 13.806 7.327a12.206 12.206 0 0 1 3.972-.66c6.75 0 12.222 5.472 12.222 12.222 0 6.338-4.824 11.549-11 12.162V89H50V65.05zM50 93h38v5a6 6 0 0 1-6 6H56a6 6 0 0 1-6-6v-5z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default Chef
