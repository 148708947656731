import { fork, take, cancel } from 'redux-saga/effects'
import { isEqual, find, omit, get } from 'lodash'

// Determines whether two actions are indempotent and won't cause side effects.
// This means that whether they are both dispatched multiple times or just once,
// they will have the same effect on the Redux state.
// If this holds true it means that one of the two actions can be ignored.
//
// This was written with redux-crud ENTITIES_FETCH actions in mind. Fetching
// two collections with the exact same query in parallel doesn't make any sense
// (mostly, except next page loading), therefore we can ignore one of them.
export const areActionsEqual = (actionA, actionB) =>
  isEqual(omit(actionA, 'meta.actionId'), omit(actionB, 'meta.actionId')) &&
  get(actionB, 'meta.page') !== 'next'

// TODO Docs
export function* takeLatestDeepSaga(pattern, saga, comparator, ...args) {
  comparator = comparator || areActionsEqual

  let lastTasks = []
  const findTask = action => find(lastTasks, actionItem => comparator(actionItem.action, action))

  while (true) {
    const action = yield take(pattern)
    const existingTask = findTask(action)

    if (existingTask) {
      yield cancel(existingTask.task) // cancel is no-op if the task has already terminated
    }

    const task = yield fork(saga, ...args.concat(action))

    if (existingTask) {
      existingTask.task = task
    } else {
      lastTasks.push({
        task,
        action,
      })
    }

    // removing the finished tasks from the cache
    lastTasks = lastTasks.filter(item => item.task.isRunning())
  }
}

export default (...args) => fork(takeLatestDeepSaga, ...args)
