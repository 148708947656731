import { map } from 'lodash'

export const stringifyErrorContext = (message?: string, context?: Record<string, any>) =>
  map(Object.entries({ message, ...(context || {}) }), ([key, value]) =>
    [key, JSON.stringify(value)].join('='),
  ).join(' ')

export function flattenFormErrors(obj: { [key: string]: any }): string[] {
  const flat = []

  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      flat.push(...flattenFormErrors(obj[key]))
    } else {
      flat.push(obj[key])
    }
  }

  return flat
}
