import {
  flatMap,
  uniq,
  get,
  keyBy,
  unionBy,
  differenceBy,
  includes,
  find,
  compact,
  map,
  groupBy,
  pick,
  filter,
} from 'lodash'
import { all, put, select, takeEvery, call } from 'redux-saga/effects'

import { workerPlatforms } from 'api/entities/worker'
import { getEntityMap } from 'lib/redux-crud/selectors'
import { idForEntity } from 'api/schemas'
import { convertToDailyShiftBookings } from 'api/entities/dailyShiftBooking'
import { shiftBookingType } from 'common/constants'
import * as types from 'actions/action-types'

export const getSoftBooking = ({ workerId, shiftIds: softShiftIds, jobId }) => [
  { workerId, softShiftIds, jobId },
]

const getShiftWithType = ({ shifts, listinShifts, shiftBooking, type }) => {
  return map(shifts, shiftId => {
    if (!listinShifts[shiftId]) return
    const shift = listinShifts[shiftId]
    return {
      workerId: shiftBooking.workerId,
      shift: { ...pick(shift, 'id', 'startTime', 'endTime'), type },
    }
  })
}

/**
 * This will save all the workers shifts within the listings
 * @param {Array} roughShiftBookings - Worker shifts from unpacked worker
 * @param {Array} listings - Listings when fetched
 * @example
 * IN: { roughShiftBookings: [{ workerId: 1, shift: { id: 1, startTime, endTime },...] }
 * IN: { roughShiftBookings: [{ workerId: 1, shiftId: 1 }] }
 * IN: { roughShiftBookings: [{ workerId: 1, shiftIds: [1, 2] }] }
 * IN: { roughShiftBookings: [{ workerId: 1, shiftIds: [1, 2] }] }
 * IN: { listings: [{ tyle: listing }]
 * OUT: [{ $type: 'shiftBooking', workerId: 1, shift: { id: 1, startTime, endTime },...]
 */
export function* normalizeToShiftBookings({ roughShiftBookings, listings = [] } = {}) {
  let currentListings = listings.map(ele => ele)
  if (!currentListings.length) {
    currentListings = yield select(rootState => getEntityMap(rootState.listings))
  }
  if (!roughShiftBookings) {
    const workers = yield select(rootState => getEntityMap(rootState.workers))
    roughShiftBookings = map(workers, worker => ({
      workerId: worker.id,
      shiftIds: worker.shiftIds,
      softShiftIds: worker.softShiftIds,
    }))
  }
  const shifts = keyBy(flatMap(flatMap(currentListings, 'jobs'), 'shifts'), 'id')
  return flatMap(roughShiftBookings, shiftBooking => {
    const shiftIds =
      shiftBooking.shiftIds || (shiftBooking.shifts ? map(shiftBooking.shifts, 'id') : [shiftBooking.shiftId])
    return compact(
      getShiftWithType({
        shifts: shiftIds,
        listinShifts: shifts,
        shiftBooking,
        type: shiftBookingType.hard,
      }).concat(
        getShiftWithType({
          shifts: shiftBooking.softShiftIds,
          listinShifts: shifts,
          shiftBooking,
          type: shiftBookingType.soft,
        }),
      ),
    )
  })
}

/**
 * Assign new shiftIds to only syftForce workers ,
 * and increase shift.filledSpots in listings
 * after mutliple_bookings has succeeded.
 */
export function* postMultiBookingUpdateWorkerShifts(action) {
  yield takeEvery(types.MULTIPLE_BOOKING_CREATE_SUCCEEDED, function* (action) {
    if (get(action, 'meta.beginAction.meta.worker.workerPlatform', '') === workerPlatforms.syftForce) {
      const { payload = {}, meta } = action.meta.beginAction
      const bookings = get(payload, 'bookings', [])
      const softBookings = get(meta, 'bookingType') === shiftBookingType.soft && getSoftBooking(payload)
      yield updateDailyShiftBookings(softBookings || bookings, 'create')
      // I dont need the softBooking format so using the payload format
      yield updateBookingCountsInListings(bookings, 'create')
    }
  })
}

/**
 * Assign new shiftIds to workers,
 * and increase shift.filledSpots in listings
 * after mutliple_bookings has succeeded.
 */
export function* postCancelBookingUpdateWorkerShifts(action) {
  yield takeEvery(types.CANCEL_BOOKING_CREATE_SUCCEEDED, function* (action) {
    const { payload = {}, meta } = action.meta.beginAction
    const softBooking = get(meta, 'isSoftBooking') && getSoftBooking(payload)
    yield updateDailyShiftBookings(softBooking || [payload], 'delete')
    // I dont need the softBooking format so using the payload format
    yield updateBookingCountsInListings([payload], 'delete')
  })
}

/**
 * Updates dailyShiftBookings
 * @param {Array} bookings array of [{ workerId, jobId, shiftIds }]
 * @param {String} type 'create' | 'delete'
 */
export function* updateDailyShiftBookings(roughShiftBookings, type) {
  const allDailyShiftBookings = yield select(state => getEntityMap(state.dailyShiftBookings))
  const bookings = yield call(normalizeToShiftBookings, { roughShiftBookings })
  const dailyBookings = convertToDailyShiftBookings(bookings)
  const dailyBookingsByWorker = groupBy(dailyBookings, 'workerId')

  const updatedDailyBookings = flatMap(dailyBookingsByWorker, dailyBookingsForWorker => {
    // dailyBookingsForWorker here MUST be unique by day
    return map(dailyBookingsForWorker, dailyShiftBooking => {
      const { shifts, day } = dailyShiftBooking
      const existing =
        allDailyShiftBookings[
          idForEntity({ $type: 'dailyShiftBooking', workerId: dailyShiftBooking.workerId, day })
        ]
      const existingShifts = get(existing, 'shifts', [])
      const updatedShifts =
        type === 'create' ? unionBy(existingShifts, shifts, 'id') : differenceBy(existingShifts, shifts, 'id')

      return { ...dailyShiftBooking, shifts: updatedShifts }
    })
  })

  yield put({
    type: types.DAILY_SHIFT_BOOKINGS_FETCH_SUCCEEDED,
    payload: updatedDailyBookings,
  })
}

/**
 * Updates shift.filledSpots in listings
 * @param {Array} bookings array of [{ workerId, shiftIds }]
 * @param {String} type 'create' | 'delete'
 */
export function* updateBookingCountsInListings(bookings, type) {
  const allListings = yield select(rootState => getEntityMap(rootState.listings))

  // Update listing shifts
  const jobIds = uniq(map(bookings, 'jobId'))
  const listingIds = uniq(
    compact(
      map(jobIds, jobId =>
        get(
          find(allListings, listing => includes(map(get(listing, 'jobs'), 'id'), jobId)),
          'id',
        ),
      ),
    ),
  )

  yield all(
    listingIds.map(listingId => {
      const listing = allListings[listingId]
      const listingJobIds = map(get(listing, 'jobs'), 'id')
      const listingBookings = filter(bookings, ({ jobId }) => includes(listingJobIds, jobId))
      const bookingsShiftIds = flatMap(listingBookings, booking => get(booking, 'shiftIds', []))

      return put({
        type: types.LISTING_UPDATE_SUCCEEDED,
        payload: {
          ...listing,
          jobs: map(listing.jobs, job => ({
            ...job,
            shifts: map(job.shifts, shift => ({
              ...shift,
              bookingStatus: {
                ...shift.bookingStatus,
                filledSpots: includes(bookingsShiftIds, shift.id)
                  ? shift.bookingStatus.filledSpots + (type === 'create' ? 1 : -1)
                  : shift.bookingStatus.filledSpots,
              },
            })),
          })),
        },
      })
    }),
  )
}
