import React from 'react'

const PickerPacker = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Picker packer`}</title>
    <path
      d="M71 69l29-14v32l-29 14V69zm-4 0v32L38 87V55l29 14zm31.52-17.5L69 65.5l-29.52-14L69 37.458 98.52 51.5z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default PickerPacker
