import React from 'react'

import { TypographyContextType, TypographyProviderProps } from './Typography.types'

export const TypographyContext = React.createContext<TypographyContextType>({
  big: false,
})

export const TypographyContextProvider = ({ big, children }: TypographyProviderProps) => {
  return <TypographyContext.Provider value={{ big }}>{children}</TypographyContext.Provider>
}
