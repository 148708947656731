export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    id: {
      type: 'integer',
    },
    email: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    password: {
      type: 'string',
    },
    password_confirmation: {
      type: 'string',
      description: 'Confirm password',
    },
    picture: {
      type: 'object',
      description: 'Profile picture',
      properties: {
        uuid: {
          type: 'string',
        },
      },
    },
  },
  required: ['password_confirmation', 'password', 'email', 'name'],
  type: 'object',
}
