import { put, call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'

/**
 * fetch specific listing agency
 */
export function* fetchListingAgency(options = {}) {
  const actionsAffectingAgencyListings = [types.LISTING_AGENCY_EMAIL_CREATE_SUCCEEDED]
  yield takeLatestDeep(actionsAffectingAgencyListings, function* (action) {
    const listingId = action.payload.listingId || action.meta.requestOptions.entity.listingId
    const agencyId = action.payload.agencyId || action.meta.requestOptions.entity.agencyId

    yield put({
      type: types.LISTING_AGENCY_FETCH_BEGIN,
      payload: {
        listingId,
        agencyId,
      },
    })
  })
}

/**
 * fetch all listing agencies
 */
export function* fetchListingAgencies(options = {}) {
  const actionsAffectingAgencyListings = [
    types.LISTING_AGENCY_SHIFTS_CREATE_FAILED,
    types.LISTING_AGENCY_SHIFTS_CREATE_SUCCEEDED,
  ]
  yield takeLatestDeep(actionsAffectingAgencyListings, function* (action) {
    const listingId = action.payload.listingId || action.meta.requestOptions.entity.listingId
    if (!action.payload.manageAgencyAllocation) {
      yield call(entityActionRequest, {
        type: types.LISTING_AGENCIES_FETCH_BEGIN,
        payload: {
          listingId,
        },
        meta: {
          shouldCacheAllQueries: true,
        },
      })
    }
  })
}

/**
 * fetch all listing agencies
 */
export function* fetchListing(options = {}) {
  const actionsAffectingAgencyListings = [
    types.LISTING_AGENCY_DELETE_SUCCEEDED,
    types.LISTING_AGENCY_SHIFTS_CREATE_FAILED,
    types.LISTING_AGENCY_SHIFTS_CREATE_SUCCEEDED,
  ]
  yield takeLatestDeep(actionsAffectingAgencyListings, function* (action) {
    const listingId = action.payload.listingId || action.meta.requestOptions.entity.listingId
    if (!action.payload.manageAgencyAllocation) {
      yield put({
        type: types.LISTING_FETCH_BEGIN,
        payload: {
          id: listingId,
        },
      })
    }
  })
}
