import React from 'react'

const Close = props => (
  <svg viewBox="0 0 13 13" {...props}>
    <path d="M1.707 12.707l11-11A1 1 0 1 0 11.293.293l-11 11a1 1 0 0 0 1.414 1.414z" />
    <path d="M.293 1.707l11 11a1 1 0 0 0 1.414-1.414l-11-11A1 1 0 1 0 .293 1.707z" />
  </svg>
)

export default Close
