import { takeEvery, call, select } from 'redux-saga/effects'
import { remove } from 'lodash/fp'

import * as types from 'actions/action-types'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'
import { getIsInternalEmployer } from 'selectors/auth'
import { identity } from 'lodash'

const filterInternal = remove({ internal: true })

export function* fetchPublicIndustriesRolesSkills(options, action) {
  const isInternalEmployer = yield select(getIsInternalEmployer)
  yield call(
    entityActionRequest,
    {
      ...options,
      normalizePayload: isInternalEmployer ? identity : filterInternal,
    },
    action,
  )
}

export function* watchForIndustriesRolesSkillsFetch(options = {}) {
  yield takeEvery(
    [types.INDUSTRIES_FETCH_BEGIN, types.ROLES_FETCH_BEGIN, types.SKILLS_FETCH_BEGIN],
    fetchPublicIndustriesRolesSkills,
    options,
  )
}
