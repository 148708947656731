import listing from './listing'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'templateName',
  },
  properties: {
    ...listing.properties,
  },
  required: [...listing.required, 'template_name'],
}
