import React from 'react'

const BarBack = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Bar back`}</title>
    <path
      d="M81.674 63.16l-9.433 9.615a2 2 0 0 1-2.842.014l-.68-.68-3.032 3.033a3.48 3.48 0 0 1-4.154.58l-2.329-1.293 4.55 8.196a3.485 3.485 0 0 1-.581 4.164 3.486 3.486 0 0 1-4.146.575L52.93 83.98l4.414 5.87a3.483 3.483 0 0 1 .086 4.06 3.485 3.485 0 0 1-3.812 1.382l-6.309-1.772-7.55 7.55a2 2 0 1 1-2.828-2.829l7.765-7.764a3.476 3.476 0 0 1 3.4-.89l5.128 1.44-4.957-6.592a3.489 3.489 0 0 1 .157-4.387 3.474 3.474 0 0 1 4.312-.75l6.643 3.688-4.553-8.202a3.474 3.474 0 0 1 .579-4.15 3.476 3.476 0 0 1 4.153-.582l3.577 1.985 2.756-2.755-.68-.68a2 2 0 0 1-.014-2.815l9.458-9.646-9.291-9.29a6 6 0 0 1 8.485-8.486l26.163 26.163a6 6 0 1 1-8.485 8.485l-9.853-9.853z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default BarBack
