export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    amount: {
      type: 'number',
      description: 'Rate per hour',
    },
    currency: {
      type: 'string',
    },
  },
  required: ['amount', 'currency'],
}
