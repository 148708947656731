import { compact } from 'lodash'
import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer, createMigrate, KEY_PREFIX } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import { rehydrateKeyPrefix } from 'common/constants'

import { rootSaga } from 'sagas'
import { rootReducer } from 'reducers'
import migrations from 'reducers/persistMigrations'
import { history } from 'lib/history'
import { AuthTransform } from './transforms/auth'

const sagaMiddleware = createSagaMiddleware()
const routerMiddleware = createRouterMiddleware(history)

const middleware = compact([sagaMiddleware, routerMiddleware])

const persistConfig = {
  key: rehydrateKeyPrefix,
  storage,
  version: 8, // when making changes to the reducers below, make sure to increment version and write a migration for this version otherwise persisted store will be returned
  whitelist: [
    'auth',
    'platform',
    'routing',
    'form',
    'config',
    'joyride',
    'infoBanner',
    'preserveFeatureVisibility',
  ],
  migrate: createMigrate(migrations), // see https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
  transforms: [AuthTransform],
}

// Hack for OneHost
// Ensure that the `auth` key has previously been persisted into localStorage
// otherwise the "transforms" for persisting the redux state will not run
// and the oauthData that comes from OneHost (in localStorage) will be
// wiped out. This has to run before the `persistReducer` otherwise it will
// be set too late
const PERSIST_KEY = `${KEY_PREFIX}${rehydrateKeyPrefix}`
if (!localStorage.getItem(PERSIST_KEY)) {
  localStorage.setItem(PERSIST_KEY, JSON.stringify({ auth: '{}' }))
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)))

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
