import { merge, get, some, pickBy } from 'lodash'
import createDefaultPalette from '@material-ui/core/styles/createPalette'
import createDefaultTypography from '@material-ui/core/styles/createTypography'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import { getContrastRatio } from '@material-ui/core/styles/colorManipulator'
import platform from 'platform'
import { flex } from '@indeed/flex-ifl-theme'

import { basicColors, pastelRed } from './colors'

import { setHsl, computePalette } from './colorManipulator'
import createMuiOverrides from './createMuiOverrides'

// See https://github.com/callemall/material-ui/blob/next/src/styles/theme.js
const os = typeof platform.os === 'string' ? platform.os : get(platform, 'os.family') || ''
const hasSanFranciscoFont = some(['OS X', 'iOS'], name => os.match(name))

export const colorPaletteCreator = (palette = {}) => {
  const primary = palette.primary && computePalette(palette.primary)
  const warning = palette.warning && computePalette(palette.warning)
  const success = palette.success && computePalette(palette.success)
  const info = palette.info && computePalette(palette.info)
  const accent = palette.accent && computePalette(palette.accent)
  const error = computePalette(pastelRed) // pastel red
  return {
    colors: pickBy(
      {
        primary,
        warning,
        success,
        info,
        accent,
        error,
      },
      Boolean,
    ),
  }
}

const transformIDSToMui = theme => ({
  50: theme[100],
  100: theme[100],
  200: theme[200],
  300: theme[300],
  400: theme[400],
  500: theme[500],
  600: theme[600],
  700: theme[700],
  800: theme[800],
  900: theme[900],
  1000: theme[1000],
  A100: theme[100],
  A200: theme[200],
  A400: theme[400],
  A700: theme[700],
  light: theme[600],
  default: theme[800],
  main: theme[800],
  dark: theme[1000],
  contrastDefaultColor: 'light',
})

const createPalette = baseTheme => {
  const { colors } = colorPaletteCreator(baseTheme?.palette)
  const primary = transformIDSToMui(flex.colors.primary)
  const divider = setHsl(primary.main, { s: 0.1, l: 0.92 })

  const defaultPalette = createDefaultPalette({
    primary,
    secondary: { ...primary },
    error: colors.error,
    warning: flex.colors.warning,
    success: flex.colors.success,
    info: flex.colors.info,
    accent: flex.colors.accent,
  })

  // Analogous to material-ui/src/styles/createPalette #getContrastText
  // We use a higher bias towards light text on dark background
  function getContrastText(color) {
    if (getContrastRatio(color, '#000') < 10) {
      return 'rgba(255, 255, 255, 1)'
    }
    return defaultPalette.text.primary
  }

  // Color names here should only contain information about how they are used
  // (primary, secondary, error, divider) and not what they are (black, red, faintBlack).
  // See material-ui/src/styles/createPalette.js for more palette properties
  return {
    ...defaultPalette,
    colors: basicColors,
    text: {
      ...defaultPalette.text,
      primary: setHsl(primary.main, { s: 0.08, l: 0.2 }),
      hint: setHsl(primary.main, { s: 0.11, l: 0.62 }),
      divider,
      warning: flex.colors.warning.default,
      success: flex.colors.success.default,
      error: colors.error[500],
    },
    divider,
    lightDivider: 'rgba(0, 0, 0, 0.075)',
    input: {
      bottomLine: 'rgba(0, 0, 0, 0.1)',
      inputText: 'rgba(0, 0, 0, 1)',
    },
    action: {
      // The color of an active action like an icon button.
      active: defaultPalette.action.active,
      activeBackground: 'rgba(0, 0, 0, 0.1)',
      // The color of an hovered action.
      hover: defaultPalette.action.hover,
      hoverBackground: 'rgba(0, 0, 0, 0.03)',
      // The color of a selected action.
      selected: defaultPalette.action.selected,
      selectedBackground: 'rgba(0, 0, 0, 0.03)',
      // The color of a disabled action.
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    status: {
      incomplete: 'rgba(255, 201, 15, 1)',
      requiresAction: 'rgb(147,74,210)',
      done: 'rgba(2, 204, 125, 1)',
    },
    getContrastText,
  }
}

const createTypography = palette => {
  const defaultTypography = createDefaultTypography(palette, {
    fontFamily:
      '"Noto Sans","Helvetica Neue","Helvetica","Arial","Liberation Sans","Roboto","Noto",sans-serif;',
    fontSize: 14,
    ...(hasSanFranciscoFont
      ? {
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 500,
          fontWeightBold: 600,
        }
      : {
          fontWeightLight: 300,
          fontWeightRegular: 400,
          fontWeightMedium: 600,
          fontWeightBold: 700,
        }),
  })

  return merge(defaultTypography, {
    display2: {
      fontSize: defaultTypography.display1.fontSize,
      lineHeight: defaultTypography.display1.lineHeight,
      fontWeight: defaultTypography.fontWeightLight,
      color: palette.text.primary,
    },
    display1: {
      fontSize: defaultTypography.headline.fontSize,
      fontWeight: defaultTypography.fontWeightLight,
      lineHeight: defaultTypography.headline.lineHeight,
      color: palette.text.primary,
    },
    button: {
      ...defaultTypography.button,
      textTransform: 'initial',
    },
    body2: {
      ...defaultTypography.body2,
      // body2 is often used alongside body1. Same lineHeight
      // makes it e.g. easy to align different Grid items
      // with different typographt styles (different line height)
      lineHeight: defaultTypography.body1.lineHeight,
    },
  })
}

const createPaperShadow = (size, palette) => {
  // Data points from chips and dropdown designs
  // Interpolation[{0,3},{10,13}]
  const offsetY = size + 3
  // Interpolation[{0,7},{10,11}]
  const blurRadius = (2 * size) / 5 + 7
  // Interpolation[{0,0},{10,-10}]
  const spreadRadius = -size
  const makeBoxShadow = opacity =>
    `0 ${offsetY}px ${blurRadius}px ${spreadRadius}px hsla(0, 0%, 0%, ${opacity})`
  return {
    default: {
      border: 'solid 1px hsla(0, 0%, 0%, 0.1)',
      boxShadow: makeBoxShadow(0.1),
    },
    hover: {
      backgroundColor: palette.action.hoverBackground,
      borderColor: `hsla(0, 0%, 0%, 0.11)`,
      boxShadow: makeBoxShadow(0.13),
    },
    active: {
      backgroundColor: palette.action.activeBackground,
      borderColor: `hsla(0, 0%, 0%, 0.05)`,
      boxShadow: makeBoxShadow(0.13),
    },
  }
}

// All params optional unless specified.
//
// @param baseTheme.logo.url {String?} url
// @param baseTheme.palette.primary {String} hex color
export const createTheme = (baseTheme = {}) => {
  const palette = createPalette(baseTheme)
  const typography = createTypography(palette)

  const preppedTheme = createMuiTheme({
    palette,
    typography,
    name: baseTheme.name,
    navbar: baseTheme.navbar,
    paper0: createPaperShadow(0, palette),
    paper10: createPaperShadow(10, palette),
  })
  return {
    ...preppedTheme,
    overrides: createMuiOverrides(preppedTheme),
  }
}
