import { gutterSize } from '../styles'

export default ({ spacing, breakpoints }) => ({
  root: {
    padding: `0 ${gutterSize}px 24px ${gutterSize}px`,
    [breakpoints.down('sm')]: {
      padding: `0 ${spacing.unit}px ${spacing.unit}px`,
    },
  },
})
