import { map, get, filter, uniq, reduce, without, compact } from 'lodash'
import { compose } from 'lodash/fp'
import { getAllAreas } from './venue'
import { filterTree, createTreeMap, deepFlattenNodes, getAllAncestors } from 'lib/tree'
import { idForEntity } from 'api/schemas'

// @param {[Object]} tree List of work locations (tree nodes)
// @param {Object} venues Employer venues keyed by their identifier
// @return {[Object]} Work locations and all of their ancestors up to root
export const allNodesInWorkLocationTree = (workLocationIds = [], { workLocations }) => {
  const locations = new Set(workLocationIds)
  return compose(
    tree => createTreeMap(tree, idForEntity),
    filterTree(node => locations.has(node.value.id)),
  )(get(workLocations, '$root'))
}

// @param {Array} locations List of workLocations, as per schema
// @param {Object} options.venues Entity map of venues conforming to schema
// @returns {Array} Entity map of work locations (including areas) keyed by id
export const populateWorkLocations = (workLocations, { venues }) => {
  const allAreas = getAllAreas(venues)
  return map(workLocations, wl => venues[wl.id] || allAreas[wl.id] || wl)
}

// Based on trustedLocationIds, find which venues are:
// - (fully or partially) trusted
// - not trusted at all
export const segregateTrustedAndNonTrustedVenues = (trustedLocationIds, { workLocations }) => {
  const trustedLocations = allNodesInWorkLocationTree(trustedLocationIds, { workLocations })

  const trustedVenues = map(get(trustedLocations, ['$root', 'children']), 'value')
  const trustedVenuesMap = new Map(trustedVenues.map(v => [v.id, v]))

  const allVenues = map(get(workLocations, ['$root', 'children']), 'value')
  const nonTrustedVenues = filter(allVenues, venue => !trustedVenuesMap.has(venue.id))

  return { trustedVenues, nonTrustedVenues }
}

// Expand venue into its areas
// e.g. [v1] -> [v1, a1, a2]
export const expandLocations = (locIds, { workLocations }) =>
  compose(
    uniq,
    nodes => map(nodes, 'value.id'),
    // Include all areas under venues
    deepFlattenNodes,
    // Get locations
    locIds => map(locIds, id => get(workLocations, id)),
  )(locIds)

// Collapse areas if venues are present.
// The opposite of expandLocations.
// e.g. [a3, v1, a1, a2] -> [v1]
export const collapseLocations = (locIds, { workLocations }) =>
  reduce(
    locIds,
    (acc, locId) => {
      const location = get(workLocations, locId)
      if (!location) return acc

      // remove all sublocations
      const allChildrenIds = map(deepFlattenNodes(location.children), 'value.id')
      return without(acc, ...allChildrenIds)
    },
    locIds,
  )

// Turn a list of venues into a tree with .parent and .children
export const createWorkLocationTree = venues => {
  const $root = { value: { id: '$root' }, $type: 'root' }
  $root.children = map(venues, venue => {
    const venueNode = { $type: 'venue', parent: $root, value: venue }
    venueNode.children = compact(
      map(venue.areas, area => ({ parent: venueNode, $type: 'area', value: area })),
    )
    return venueNode
  })

  return createTreeMap($root, idForEntity)
}

/**
 * Gets a location's hierarchy, excluding the root location
 *
 * @param {LocationNode} location
 * @returns {[LocationNode]} a top-bottom list of location nodes
 * @example
 * // returns [venue, area]
 * getLocationHierarchy(area)
 */
export const getLocationHierarchy = compose(
  nodes => nodes.slice(1), // remove root
  node => getAllAncestors(node, 'top-bottom'),
)
