import React from 'react'

const ChefDePartie = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`Chef de partie`}</title>
    <defs>
      <path
        d="M88 228a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3.185 4h.625c3.133 0 5.727 2.146 6.176 5.357.045.324.078 1.643-1.49 1.643H86.51c-1.564 0-1.533-1.406-1.5-1.64.447-3.213 3.041-5.36 6.175-5.36z"
        id="a"
      />
      <path
        d="M88 228a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3.185 4h.625c3.133 0 5.727 2.146 6.176 5.357.045.324.078 1.643-1.49 1.643H86.51c-1.564 0-1.533-1.406-1.5-1.64.447-3.213 3.041-5.36 6.175-5.36z"
        id="b"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.95 27.751c-2.78-.272-4.95-2.58-4.95-5.386 0-2.99 2.462-5.413 5.5-5.413.626 0 1.227.103 1.787.293A7.526 7.526 0 0 1 31.5 14a7.526 7.526 0 0 1 6.213 3.245 5.573 5.573 0 0 1 1.787-.293c3.038 0 5.5 2.424 5.5 5.413 0 2.807-2.17 5.114-4.95 5.386v10.606h-17.1V27.751zm0 12.378h17.1V42.3a2.7 2.7 0 0 1-2.7 2.7h-11.7a2.7 2.7 0 0 1-2.7-2.7v-2.171z"
        fill="#003a9b"
      />
      <g strokeLinecap="round" strokeLinejoin="round" transform="translate(-50 -194)">
        <use fill="#003a9b" xlinkHref="#a" />
        <path
          stroke="#FFF"
          strokeWidth={1.7}
          d="M87.15 228a3.85 3.85 0 1 1 7.7 0 3.85 3.85 0 0 1-7.7 0zm4.035 3.15h.625c3.597 0 6.515 2.497 7.018 6.09.06.438.014.96-.22 1.435-.363.74-1.097 1.175-2.112 1.175H86.51c-1.019 0-1.75-.458-2.115-1.213a2.548 2.548 0 0 1-.228-1.394c.501-3.594 3.42-6.093 7.018-6.093z"
        />
      </g>
      <g strokeLinecap="round" strokeLinejoin="round" transform="translate(-50 -194)">
        <use fill="#003a9b" xlinkHref="#b" />
        <path
          stroke="#FFF"
          strokeWidth={1.7}
          d="M87.15 228a3.85 3.85 0 1 1 7.7 0 3.85 3.85 0 0 1-7.7 0zm4.035 3.15h.625c3.597 0 6.515 2.497 7.018 6.09.06.438.014.96-.22 1.435-.363.74-1.097 1.175-2.112 1.175H86.51c-1.019 0-1.75-.458-2.115-1.213a2.548 2.548 0 0 1-.228-1.394c.501-3.594 3.42-6.093 7.018-6.093z"
        />
      </g>
    </g>
  </svg>
)

export default ChefDePartie
