import React, { memo } from 'react'
import classnames from 'classnames'
import { IconButton, Slide, Dialog as MuiDialog } from '@material-ui/core'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'
import { SlideProps } from '@material-ui/core/Slide'
import { mediaQueries } from 'common/constants'
import { flowRight } from 'lodash'

import Icon from 'components/IconV2'

import injectSheet from 'styles/jss'
import unmountWhenDialogIsClosed from 'lib/unmountWhenDialogIsClosed'
import styles from './styles'

import { DialogUIProps, DialogProps } from './Dialog.types'

const DialogTransitionComponent = ({ direction = 'down', ...rest }: Partial<SlideProps>) => (
  <Slide direction={direction} {...rest} />
)

const DialogUI = ({
  children,
  classes,
  className,
  hasCloseIcon = true,
  maxWidth = 'md',
  onClose,
  open = true,
  overwriteClassName,
  fullWidth = true,
  ...rest
}: DialogUIProps) => {
  const lowerThanMd = useMediaQuery(mediaQueries.downMD)

  return (
    <MuiDialog
      className={classnames(overwriteClassName ? overwriteClassName : classes.root, className)}
      data-syft-locator="Dialog"
      fullScreen={lowerThanMd}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      TransitionComponent={DialogTransitionComponent}
      fullWidth={fullWidth}
      {...rest}
    >
      {hasCloseIcon && (
        <IconButton className={classes.closeButton} onClick={onClose} aria-label="Close">
          <Icon name="close" height={11} width={11} />
        </IconButton>
      )}

      {children}
    </MuiDialog>
  )
}

const DialogUIStyled = flowRight(memo, injectSheet(styles))(DialogUI)

const DialogUIUnmountWhenClosed = flowRight(memo, unmountWhenDialogIsClosed, injectSheet(styles))(DialogUI)

const Dialog = ({ unmountWhenClosed, ...props }: DialogProps) => {
  if (unmountWhenClosed !== false) {
    return <DialogUIUnmountWhenClosed unmountWhenClosed={unmountWhenClosed} {...props} />
  }

  return <DialogUIStyled unmountWhenClosed={false} {...props} />
}

export default Dialog
