import React from 'react'

const AddMessage = props => (
  <svg viewBox="0 0 32 33" {...props}>
    <g fill="none" fillRule="evenodd" stroke="#003a9b" strokeWidth={2}>
      <path d="M9.335 28.688a33.79 33.79 0 0 1-5.196 1.93c-1.177.326-1.73.53-2.187.251-.41-.25-.203-.96 0-1.232 1.196-1.599 2.143-2.991 2.906-4.258C2.46 22.791 1 19.363 1 15.602 1 7.538 7.716 1 16 1c8.284 0 15 6.538 15 14.602 0 8.065-6.716 14.603-15 14.603-2.394 0-4.657-.546-6.665-1.517z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M16.057 12v8m4-4h-8" />
    </g>
  </svg>
)

export default AddMessage
