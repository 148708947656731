import payRateSchema from './payRate'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    id: {
      type: 'integer',
    },
    email: {
      type: 'string',
    },
    first_name: {
      type: 'string',
    },
    last_name: {
      type: 'string',
    },
    unique_number: {
      description: 'Employee unique number',
      type: 'string',
    },
    telephoneNumber: {
      type: 'string',
    },
    industryId: {
      type: 'integer',
      $rel: 'industry',
    },
    subindustryId: {
      type: 'integer',
    },
    roles: {
      type: 'array',
    },
    pay_rate: payRateSchema,
    contract_type: {
      type: 'string',
      description: 'Contract type',
      enum: ['casual_hours', 'fixed_hours'],
    },
    availability: {
      items: {
        type: 'string',
      },
      type: 'array',
    },
  },
  required: ['first_name', 'last_name', 'unique_number', 'email', 'name', 'pay_rate', 'roles', 'type'],
  type: 'object',
}
