import skillSchema from './skill'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    id: {
      type: 'integer',
    },
    code: {
      type: 'string',
    },
    skills: {
      type: 'array',
      $rel: 'skill',
      items: skillSchema,
    },
  },
}
