import React from 'react'

const ArrowDown = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <title>{`arrow-down`}</title>
    <path
      d="M7 11.586V0h2v11.586l5.293-5.293 1.414 1.414L8 15.414.293 7.707l1.414-1.414L7 11.586z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default ArrowDown
