import { call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'
import {
  downloadAgencyTimesheet as downloadAgencyTimesheetApi,
  agencyTimesheetCsvDownload as agencyTimesheetCsvDownloadApi,
} from 'api'

export function* downloadAgencyTimesheet(options = {}) {
  yield takeLatestDeep(types.DOWNLOAD_AGENCY_TIMESHEET_FETCH_BEGIN, function* (action) {
    yield call(entityCall, downloadAgencyTimesheetApi, {}, action)
  })
}

export function* agencyTimesheetCsvDownload(options = {}) {
  yield takeLatestDeep(types.AGENCY_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN, function* (action) {
    yield call(entityCall, agencyTimesheetCsvDownloadApi, {}, action)
  })
}
