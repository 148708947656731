import React from 'react'

const ArrowRight = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <title>{`arrow-right`}</title>
    <path
      d="M11.586 9H0V7h11.586L6.293 1.707 7.707.293 15.414 8l-7.707 7.707-1.414-1.414L11.586 9z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default ArrowRight
