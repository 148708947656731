/* eslint-disable func-names */
/* eslint-disable import/order */

import { datadogRum } from '@datadog/browser-rum-slim'
import actions from 'actions'
import qs from 'qs'

import {
  entityApiRequest,
  fetchCurrentUser,
  getByResetToken,
  loginUser,
  logoutUser,
  recoverPassword,
  redeemOauthCode,
  refreshAuth,
  resetPassword,
} from 'api'
import { updateAuth } from 'api/call'
import { API_BASE, apiActions } from 'api/endpoints'
import { userTypes } from 'api/entities/user'
import { rehydrateKeyPrefix, AUTH_KEYS } from 'common/constants'
import { takeOnlyAfterFinishActions } from 'lib/sagaHelpers'
import { get, isEqual } from 'lodash'
import queryString from 'query-string'
import { eventChannel } from 'redux-saga'
import { call, cancel, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { getAccessToken, getAgencyId, isLoggedIn as isLoggedInSelector } from 'selectors/auth'
import { hasFeature } from 'selectors/config'
import * as types from '../actions/action-types'
import { getCurrentPath, isActivePath } from '../lib/history'
import { fetchCurrentUser as fetchCurrentUserEntity } from './employer'
import { entityCall, putFailureAction } from './entityCall'
import { redirectTo, redirectToExternal, replaceTo } from './navigation'

/**
 * Check whether current access token is expired
 *
 * @returns {Generator<SelectEffect, boolean, *>}
 */
export function* checkIsTokenExpired() {
  const { oauthData: oauth } = yield select(state => state.auth)
  const expires = (oauth.createdAt + oauth.expiresIn) * 1000
  const now = Date.now()
  return expires - now <= 0
}

/**
 * Refresh access token if expired.
 *
 * @returns {Generator<RaceEffect|CancelEffect|CallEffect|Generator<SelectEffect, boolean, *>|PutEffect<*>|PutEffect<{payload: {}, type: string}>, void, *>}
 */
export function* refreshAuthIfExpired() {
  const isUserLoggedIn = yield select(isLoggedInSelector)

  if (!isUserLoggedIn) {
    yield cancel()
  }

  const isExpired = yield call(checkIsTokenExpired)

  if (isExpired) {
    yield put(actions.auth.refresh())

    const { refreshFailed } = yield race({
      refreshSucceeded: take(types.AUTH_REFRESH_SUCCEEDED),
      refreshFailed: take(types.AUTH_REFRESH_FAILED),
    })

    if (refreshFailed) {
      yield cancel()
    }
  }
}

function buildSignInUrlWithAuthParams() {
  const searchUrlParams = qs.parse(window.location.search, { ignoreQueryPrefix: true })
  const authParams = qs.stringify(
    {
      [AUTH_KEYS.accessToken]: searchUrlParams[AUTH_KEYS.accessToken],
      [AUTH_KEYS.code]: searchUrlParams[AUTH_KEYS.code],
      [AUTH_KEYS.impersonatorId]: searchUrlParams[AUTH_KEYS.impersonatorId],
    },
    { addQueryPrefix: true },
  )

  return `/signin${authParams}`
}

/**
 * Saga for user login. When the login promise resolves, we redirect the user to the root path.
 */
export function* loginUserSaga(action) {
  // Support "login" with access token instead of email / password
  // TODO Refactor
  if (action.payload.accessToken) {
    const { impersonatorId } = action.payload

    yield put({ type: types.AUTH_LOG_OUT })

    const oauth = {
      tokenType: 'bearer',
      accessToken: action.payload.accessToken,
      createdAt: Math.floor(Date.now() / 1000),
      expiresIn: 7200,
      impersonatorId: parseInt(impersonatorId),
    }
    updateAuth(oauth, { id: oauth.impersonatorId }, !isNaN(oauth.impersonatorId))

    try {
      const { payload, meta } = yield call(fetchCurrentUser)
      // Assume that access token login is always login by admin user
      // into the employer account

      yield put({
        type: types.AUTH_LOGIN_SUCCEEDED,
        payload: { user: payload, oauth, isAdmin: !isNaN(oauth.impersonatorId) },
        meta: {
          ...meta,
          redirect: action.meta.redirect,
        },
      })
      const agencyId = yield select(rootState => getAgencyId(rootState))
      const employerId = get(payload, 'employerId')
      yield put({ type: types.USER_CHAT_VERIFICATION_FETCH_BEGIN, payload: { id: payload.id } })
      yield call(callCsatSurvay, { employerId, agencyId })
    } catch (error) {
      updateAuth({})
      yield call(redirectTo, buildSignInUrlWithAuthParams())
      yield putFailureAction({ type: types.AUTH_LOGIN_FAILED, error, beginAction: action })
    }
  } else {
    const response = yield call(entityCall, loginUser, {}, action)
    const agencyId = yield select(rootState => getAgencyId(rootState))
    const employerId = get(response, '[0].user.employerId')
    yield call(callCsatSurvay, { employerId, agencyId })
  }
}

export function* watchForUserLogin(options) {
  yield takeLatest(types.AUTH_LOGIN_BEGIN, loginUserSaga)
}
export function* fetchUserData() {
  const auth = yield select(root => root.auth)

  if (auth.oauthData.accessToken && !auth.userData?.id) {
    yield fetchCurrentUserEntity({
      *afterSuccess({ payload, meta }) {
        yield put({
          type: types.AUTH_LOGIN_SUCCEEDED,
          payload: {
            user: payload,
            oauth: auth.oauthData,
            impersonatorId: auth.oauthData.impersonatorId,
            isAdmin: !isNaN(auth.oauthData.impersonatorId),
          },
          meta: {
            ...meta,
            redirect: !isActivePath('/signin') ? getCurrentPath({ withSearch: true }) : undefined,
          },
        })
      },
      *afterFailure(action) {
        updateAuth({})
        yield call(redirectTo, '/signin')
        yield putFailureAction({
          type: types.AUTH_LOGIN_FAILED,
          error: action.error,
          beginAction: types.CURRENT_USER_FETCH_BEGIN,
        })
      },
    })
  }
}

export function* createWorkerSaga(action) {
  try {
    const requestOptions = {
      apiAction: apiActions.create,
      entityType: 'user',
      entity: action.payload,
    }
    const { payload } = yield call(entityApiRequest, requestOptions)

    yield put({ type: types.AUTH_CREATE_WORKER_SUCCEEDED, payload })
  } catch (e) {
    yield putFailureAction({
      type: types.AUTH_CREATE_WORKER_FAILED,
      error: e,
      requestOptions: { entity: action.payload },
      beginAction: action,
    })
  }
}

export function* watchForCreateWorker(options) {
  yield takeLatest(types.AUTH_CREATE_WORKER_BEGIN, createWorkerSaga)
}

export function* indeedLoginSaga(action) {
  if (action.payload.indeedToken) {
    let error
    try {
      const response = yield call(entityCall, redeemOauthCode, {}, action)
      const oauth = get(response, '[0].oauth')
      const user = get(response, '[0].user')
      if (!oauth || !user) {
        throw response[0] || new Error('No oauth or user data')
      }

      yield put({
        type: types.AUTH_LOGIN_SUCCEEDED,
        payload: { user, oauth, isAdmin: false },
        meta: action.meta,
      })
    } catch (err) {
      error = err
      yield putFailureAction({ type: types.AUTH_LOGIN_FAILED, error, beginAction: action })
      yield call(replaceTo, buildSignInUrlWithAuthParams())
    }

    yield call([datadogRum, datadogRum.addAction], 'auth.oauth_redeem.completed', {
      error: error?.message,
    })
  }
}

export function* watchForIndeedLogin(options) {
  yield takeLatest(types.AUTH_INDEED_LOGIN_BEGIN, indeedLoginSaga)
}

export function* indeedOauthRedirectSaga(action) {
  const query = queryString.stringify(action.payload)
  yield call([datadogRum, datadogRum.addAction], 'login.page.redirected')
  yield call(redirectToExternal, `${API_BASE}/indeed_auth/oauth_request?${query}`)
}

export function* watchForIndeedOauthRedirect(options) {
  yield takeLatest(types.AUTH_INDEED_OAUTH_REDIRECT, indeedOauthRedirectSaga)
}

export function* getByResetTokenSaga(action) {
  const { resetPasswordToken, resetEmail } = action.payload
  if (resetPasswordToken) {
    try {
      const [payload] = yield call(entityCall, getByResetToken, {}, action)
      const passwordTokenValid = get(payload, 'passwordTokenValid', false)
      yield put(
        actions.auth.setResetPasswordToken({
          resetPasswordToken: passwordTokenValid ? resetPasswordToken : false,
          resetEmail,
        }),
      )
    } catch (error) {
      yield put(actions.auth.setResetPasswordToken({ resetPasswordToken: false, resetEmail }))
    }
  } else {
    yield call(redirectTo, action.meta.redirect)
  }
}

export function* watchForGetByResetToken(options) {
  yield takeLatest(types.AUTH_GET_BY_RESET_TOKEN, getByResetTokenSaga)
}

export function* logoutUserSaga(action) {
  const token = yield select(getAccessToken)
  const newAction = { ...action, payload: { token } }
  yield call(entityCall, logoutUser, {}, newAction)

  yield put({
    type: types.AUTH_LOG_OUT,
  })

  if (window.embeddedservice_bootstrap) {
    try {
      window.embeddedservice_bootstrap.userVerificationAPI.clearSession()
    } catch (e) {
      // ignore error from the chat
    }
  }
}

export function* watchForUserLogout(options) {
  yield takeLatest(types.AUTH_LOG_OUT_BEGIN, logoutUserSaga)
}

export function* callCsatSurvay({ employerId, agencyId }) {
  if (employerId || agencyId) {
    yield call(
      entityCall,
      entityApiRequest,
      {
        apiAction: apiActions.get,
        entityType: 'csatSurvey',
        context: { initialCall: true, employerId, agencyId },
      },
      actions.csat.fetchCsatSurvey(),
    )
    yield put(actions.auth.authCsatSurvey(true))
  }
}

export function* loginSuccessSaga() {
  yield takeLatest(types.AUTH_LOGIN_SUCCEEDED, function* (action) {
    // Redirect to agency portal or employer portal depending on user type
    const isAgencyPortal = yield select(rootState => hasFeature(rootState, 'agencyPortal'))
    if ((get(action.payload, 'user.mainProfileType') === 'agency') !== !!isAgencyPortal) {
      yield put({
        type: types.CONFIG_TOGGLE_FEATURE,
        payload: {
          feature: 'agencyPortal',
        },
      })
      queueMicrotask(() => window.location.reload())
      return
    }

    const defaultMainRouteForProfileType = {
      [userTypes.agency]: () => '/jobs',
      [userTypes.employer]: userData => {
        if (!get(userData, 'employerComplete')) return '/complete-employer-signup'
        if (!get(userData, 'employerApproved')) return '/waiting-for-approval'

        return '/schedule'
      },
    }

    const accountType = get(action.payload, 'user.mainProfileType')
    const defaultMainRouteFn = defaultMainRouteForProfileType[accountType] || (() => {})

    const redirectPath =
      get(action.meta, 'redirect') ||
      get(action.meta, 'beginAction.meta.redirect') ||
      defaultMainRouteFn(action.payload.user)

    if (redirectPath) {
      yield call(redirectTo, redirectPath)
    }
  })
}

export function* extractPlatform(options) {
  const typesWithNewPlatform = [
    types.AUTH_LOGIN_SUCCEEDED,
    types.CURRENT_USER_FETCH_SUCCEEDED,
    types.AUTH_REFRESH_SUCCEEDED,
  ]
  yield takeLatest(typesWithNewPlatform, function* (action) {
    const platform = get(action.payload, 'user.platform') ?? get(action, 'payload.platform')
    if (platform) {
      yield put({ type: types.PLATFORM_UPDATE, payload: platform })
    }
  })
}

/**
 * @param {Object} payload
 */
export function* watchForRefreshAuthSaga() {
  yield takeOnlyAfterFinishActions(
    types.AUTH_REFRESH_BEGIN,
    [types.AUTH_REFRESH_FAILED, types.AUTH_REFRESH_SUCCEEDED],
    function* refreshAuthSaga(action) {
      const oauthData = yield select(rootState => rootState.auth.oauthData)

      // if refresh token is missing, then we failed refreshing the token and need to dispatch failure action
      if (!oauthData.refreshToken) {
        yield put(actions.auth.refreshFailed())
        return
      }

      const newAction = { ...action, payload: { refresh_token: oauthData.refreshToken } }
      const [payload] = yield call(entityCall, refreshAuth, {}, newAction)

      if (get(payload, 'error')) {
        yield put({ type: types.AUTH_LOG_OUT, payload: {} })
        yield call(redirectTo, '/signin')
      }

      if (get(action, 'meta.redirect')) {
        yield call(redirectTo, get(action, 'meta.redirect'))
      }
    },
  )
}

export function* recoverPasswordSaga() {
  yield takeLatest(types.AUTH_RECOVER_PASSWORD_BEGIN, function* (action) {
    yield call(entityCall, recoverPassword, {}, action)
  })
}

export function* resetPasswordSaga() {
  yield takeLatest(types.AUTH_RESET_PASSWORD_BEGIN, function* (action) {
    yield call(entityCall, resetPassword, {}, action)
  })
}

function storageAuthChangeChannel() {
  return eventChannel(emitter => {
    const storageListener = ev => {
      if (ev.key === `${rehydrateKeyPrefix}auth`) {
        const nextData = JSON.parse(ev.newValue)
        nextData && emitter(nextData)
      }
    }

    window.addEventListener('storage', storageListener)

    return () => {
      window.removeEventListener('storage', storageListener)
    }
  })
}

export function* listenToStoreAuthChange() {
  const channel = yield call(storageAuthChangeChannel)
  while (true) {
    const newAuthData = yield take(channel)
    const prevData = yield select(state => state.auth)
    if (!isEqual(newAuthData.oauthData, prevData.oauthData)) {
      const { oauthData: oauth, userData: user, isAdmin } = newAuthData
      yield put(actions.auth.forceRefresh({ oauth, user, isAdmin }))
    }
  }
}
