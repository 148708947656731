/* eslint-disable func-names */
/* eslint-disable import/order */

import { delay } from 'redux-saga'
import { get, merge } from 'lodash'
import { call, fork, takeEvery, cancel, select, takeLatest } from 'redux-saga/effects'
import { getLastUpdated } from 'lib/redux-crud/selectors'
import { format } from 'lib/date-fns'
import pluralize from 'pluralize'

import { apiActions } from 'api/endpoints'
import { actionTypeForRequest } from 'api/actionTypes'
import { entityApiRequest } from 'api'
import { entityCall } from './entityCall'
import { getPlatformVisibility } from 'selectors/platform'

function* fetch(options, action) {
  const { entityType, apiAction = apiActions.list, mapAction } = options

  const mappedAction = mapAction ? yield call(mapAction, action) : action.payload
  const newAction = merge(action, mappedAction, {
    type: actionTypeForRequest(entityType, apiAction, 'begin'),
  })

  return yield call(
    entityCall,
    options.entityApiRequest || entityApiRequest,
    {
      apiAction,
      entityType,
      context: options.context,
    },
    newAction,
  )
}

/**
 * @param {String} options.entityType
 * @param {String} options.entityApiRequest
 * @param {(startAction.payload) -> String} options.syncIdentifier
 * @param {(Object) -> Object} options.mapAction Decorate the fetch action with additional
 *    payload or meta attributes. The result can be a partial object (action) that will
 *    be merged with the original.
 * @param {Boolean} options.withLastUpdatedCursor use default lastSyncTime in call to limite returned data
 * @param {Number} startAction.syncInterval
 * @param {Boolean} startAction.leading Leading delay, false by default
 */
export default function* pollCollection(options) {
  const { entityType, withLastUpdatedCursor, mapAction } = options

  function* mapLastUpdatedCursorAction() {
    const lastUpdated = yield select(rootState => getLastUpdated(rootState[pluralize(entityType)]))

    return {
      meta: {
        params: lastUpdated ? { lastSyncTime: format(lastUpdated) } : {},
        shouldCacheAllQueries: true,
        shouldUnionResults: true,
      },
    }
  }

  // check if syncIdentifier/mapAction is defined and withLastUpdatedCursor is true
  const syncIdentifier = get(options, 'syncIdentifier')
    ? options.syncIdentifier
    : withLastUpdatedCursor
    ? startAction => startAction.meta.syncId
    : () => true
  const mapActionFunction = withLastUpdatedCursor && !mapAction ? mapLastUpdatedCursorAction : mapAction

  const startActionType = actionTypeForRequest(entityType, apiActions.sync, 'start')
  const stopActionType = actionTypeForRequest(entityType, apiActions.sync, 'stop')

  yield takeLatest(startActionType, function* (startAction) {
    const syncInterval = get(startAction.meta, 'syncInterval') || 5 * 1000
    const nonVisibleSyncInterval = get(startAction.meta, 'nonVisibleSyncInterval') || 300 * 1000
    const leadingDelay = get(startAction.meta, 'leadingDelay')

    const task = yield fork(function* () {
      if (leadingDelay) {
        yield delay(syncInterval)
      }
      while (true) {
        const isPlatformVisible = yield select(getPlatformVisibility)
        // eslint-disable-line no-constant-condition
        yield delay(isPlatformVisible ? syncInterval : nonVisibleSyncInterval)
        yield fetch({ ...options, mapAction: mapActionFunction }, startAction)
      }
    })

    yield takeEvery(stopActionType, function* (stopAction) {
      if (syncIdentifier(startAction) === syncIdentifier(stopAction)) {
        yield cancel(task)
      }
    })
  })
}
