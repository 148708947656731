export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    id: {
      type: 'integer',
    },
    title: {
      type: 'string',
    },
    code: {
      type: 'string',
    },
    auto: {
      type: 'boolean',
    },
    special: {
      type: 'boolean',
    },
    rateable: {
      type: 'boolean',
    },
  },
}
