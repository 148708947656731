import { get } from 'lodash'
import { put, select } from 'redux-saga/effects'

import { alert } from 'components/ConfirmationDialog'
import { humanizeErrors } from 'lib/syftApiErrors'
import { getSupportDetails } from 'selectors/platform'

const noop = () => {}

/**
 * Error is expected to be one of:
 * - error from api/call.js ({ payload: deserializedPayload, response, meta: { status, statusText, url, requestId } })
 *
 * Param type has action format of: ENTITY_*_FAILED
 *
 * @param { error, type, requestOptions, beginAction }
 */
export function* putFailureAction({ error, type, requestOptions, beginAction }) {
  const payload = error
  const meta = {
    requestOptions: requestOptions || get(error, 'requestOptions'),
    beginAction,
  }
  const failureAction = { type, payload, meta }
  yield put(failureAction)

  if (get(beginAction.meta, 'alertOnError')) {
    const supportDetails = yield select(getSupportDetails)

    alert({
      content: humanizeErrors([get(error, 'payload', error)], supportDetails),
      onClose: get(beginAction.meta, 'onClose', noop),
    })
  }
  return failureAction
}
