export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    id: {
      type: 'integer',
    },
    kind: {
      description: 'Report',
      type: 'string',
    },
    from: {
      description: 'From',
      type: 'string',
      format: 'date-time',
    },
    to: {
      description: 'To',
      type: 'string',
      format: 'date-time',
    },
    worker_type: {
      description: 'Worker type',
      enum: ['own', 'syft', 'agency'],
      type: 'string',
    },
    venue_id: {
      description: 'Venue',
      type: 'integer',
      $rel: 'lightVenue',
      relationshipType: 'belongsTo',
    },
    area_id: {
      description: 'Area',
      type: 'integer',
      $rel: 'area',
      relationshipType: 'belongsTo',
    },
    manager_id: {
      description: 'Manager',
      type: 'integer',
      $rel: 'employerAccount',
      relationshipType: 'belongsTo',
    },
    primary_worker_work_location_id: {
      description: 'Venue Workers',
      type: 'integer',
      $rel: 'lightVenue',
      relationshipType: 'belongsTo',
    },
    role_id: {
      description: 'Role',
      type: 'integer',
      $rel: 'role',
      relationshipType: 'belongsTo',
    },
    email: {
      description: 'Email Address',
      type: 'string',
      format: 'email',
    },
  },
  required: ['from', 'to', 'email'],
}
