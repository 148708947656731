/* eslint-disable func-names */
/* eslint-disable import/order */

import {
  castArray,
  differenceBy,
  filter,
  flatten,
  flow,
  get,
  includes,
  map,
  omit,
  partialRight,
  sortBy,
  values,
} from 'lodash'
import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { entityApiRequest } from 'api'
import { apiActions } from 'api/endpoints'
import { convertToDailyShiftBookings } from 'api/entities/dailyShiftBooking'
import { idForEntity, mapEachAssociation, pickSchemaProperties } from 'api/schemas'
import {
  flagDeletedEntities,
  serializeEachAssociation,
  serializeEachEntity,
  stripUnchangedEntities,
} from 'api/serialization'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { normalizeToShiftBookings } from './booking'
import { entityCall } from './entityCall'

const annotateListingWithAssociationTypes = listing =>
  mapEachAssociation(
    listing,
    (collection, path, { desc }) => {
      return map(collection, entity => ({ $type: desc.$rel, ...entity }))
    },
    { breadthFirst: true },
  )

export const flagEntitiesWithAllShiftsDeleted = updated => {
  return map(updated, entity => {
    const hasDestroyedAllShifts = entity?.shifts?.every(shift => shift?._destroy)
    return { ...entity, ...(hasDestroyedAllShifts && { _destroy: true }) }
  })
}

export const orderByIndexOf = (updated, comparator) => {
  return sortBy(updated, [
    entity => {
      // entities not found in comparator moved to end of array in stable order
      const index = comparator?.findIndex(e => idForEntity(e) === idForEntity(entity))
      return index > -1 ? index : Infinity
    },
  ])
}

// When deleting jobs and shifts from an existing listing, Syft API mandates
// to mark them with `_destroy: true`. In contrast redux-crud just accepts
// the updated entity with jobs (shifts) removed from the array.
//
// We're also going to exclude entities that were not updated. If we didn't do this,
// unchanged attributes would be validated by the backend even if they previously passed
// all validations.
//
// TODO Simplify
export const serializeListing = (updatedRaw, originalRaw) => {
  const [updated, original] = map([updatedRaw, originalRaw], listing => {
    const annotated = annotateListingWithAssociationTypes(listing)
    return serializeEachEntity(annotated, pickSchemaProperties)
  })
  return flow(
    partialRight(serializeEachAssociation, original, flagDeletedEntities, { breadthFirst: true }),
    partialRight(serializeEachAssociation, original, flagEntitiesWithAllShiftsDeleted),
    partialRight(serializeEachAssociation, original, stripUnchangedEntities, { breadthFirst: true }),
    partialRight(serializeEachAssociation, updated, orderByIndexOf), // sorts by order of initial `updated`
  )(updated)
}

// omit the reason if no difference
export const handleReason = (updatedRaw, originalRaw) => {
  if (updatedRaw?.reason?.listingPresetReasonId === originalRaw?.reason?.listingPresetReasonId) {
    return omit(updatedRaw, 'reason')
  } else {
    return updatedRaw
  }
}

// * Support updating listing with nested relationships (jobs and shifts) including
//   deletions
// * Sync cache with backend after updating listing – invalidate an existing wage preview
//   for the listing
//
// TODO Diff payload to send only dirty properties (?)
export function* updateListing(options) {
  yield takeLatestDeep(types.LISTING_UPDATE_BEGIN, function* (action) {
    const originalListing = yield select(rootState =>
      get(rootState.listings, ['entityMap', action.payload.id]),
    )
    const listingCorrectReason = handleReason(action.payload, originalListing)
    const newListing = serializeListing(listingCorrectReason, originalListing)

    const newAction = { ...action, payload: newListing }

    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.update,
        entityType: 'listing',
        context: options.context,
      },
      newAction,
    )

    yield put({ type: types.LISTING_FETCH_BEGIN, payload: { id: action.payload.id } })

    // refetch the listing templates after the listing is successfully saved
    if (newListing.templateName) {
      const { listingSucceeded } = yield race({
        listingSucceeded: take(types.LISTING_FETCH_SUCCEEDED),
        listingFailed: take(types.LISTING_FETCH_FAILED),
      })
      if (listingSucceeded) {
        yield put({ type: types.LISTING_TEMPLATES_FETCH_BEGIN })
      }
    }

    yield put({ type: types.JOB_CANDIDATES_INVALIDATE })
  })
}

export function* fulfilmentSuggestionThenUpdateListing(options) {
  yield takeLatestDeep(types.FULFILMENT_SUGGESTION_THEN_LISTING_UPDATE_BEGIN, function* (action) {
    yield put({
      type: types.SUGGESTION_FETCH_BEGIN,
      payload: action.payload,
    })
    yield put({
      type: types.LISTING_UPDATE_BEGIN,
      payload: action.meta.newListing,
      meta: {
        postToSyft: action.meta.postToSyft,
        isInternalResourcing: action.meta.isInternalResourcing,
      },
    })
  })
}

export function* updateShiftBookingsWhenFetchListings() {
  const listingFetchTypes = [types.LISTINGS_FETCH_SUCCEEDED, types.LISTING_FETCH_SUCCEEDED]
  yield takeLatest(listingFetchTypes, function* (action) {
    const listings = castArray(action.payload)

    const shiftBookings = yield call(normalizeToShiftBookings, { listings })
    const dailyShiftBookings = convertToDailyShiftBookings(shiftBookings)

    yield put({
      type: types.DAILY_SHIFT_BOOKINGS_FETCH_SUCCEEDED,
      payload: dailyShiftBookings,
    })
  })
}

// Extract nested entities from LISTINGS_FETCH_SUCCEEDED payload
// TODO Use normalizr instead
export function* extractListingsResponse() {
  const listingFetchTypes = [types.LISTINGS_FETCH_SUCCEEDED, types.LISTING_FETCH_SUCCEEDED]
  yield takeLatestDeep(listingFetchTypes, function* (action) {
    const listings = castArray(action.payload)
    const listingIds = map(listings, 'id')
    const jobs = flatten(
      map(listings, listing =>
        // TODO use normalizr instead
        map(listing.jobs, job => ({
          ...job,
          listing: omit(listing, 'jobs'),
        })),
      ),
    )

    // TODO Refactor
    const previousJobs = yield select(rootState => get(rootState.jobs, 'entityMap'))
    const missingJobs = differenceBy(values(previousJobs), jobs, 'id')
    const deletedJobs = filter(missingJobs, job => includes(listingIds, get(job, 'listing.id')))

    const deletions = map(deletedJobs, job => put({ type: types.JOB_DELETE_SUCCEEDED, payload: job }))

    const updates = put({ type: types.JOBS_FETCH_SUCCEEDED, payload: jobs })

    return yield all(flatten([deletions, updates]))
  })
}
