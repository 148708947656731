import React from 'react'

const Supervisor = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Supervisor`}</title>
    <path
      d="M69 75.782l-6.592-3.658a.948.948 0 0 0-1.408.83v8.093a.948.948 0 0 0 1.408.829L69 78.218l6.592 3.658a.948.948 0 0 0 1.408-.83v-8.093a.948.948 0 0 0-1.408-.829L69 75.782zM56 50c0-7.18 5.82-13 13-13s13 5.82 13 13-5.82 13-13 13-13-5.82-13-13zm11.645 17h2.69c13.496 0 24.67 8.584 26.604 21.43.195 1.295.335 6.57-6.42 6.57H47.505c-6.737 0-6.605-5.624-6.464-6.56C42.968 75.59 54.145 67 67.645 67z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default Supervisor
