/* eslint-disable func-names */
/* eslint-disable import/order */

import { call, takeLatest } from 'redux-saga/effects'
import * as types from 'actions/action-types'
import { resendInternalWorkerInvitation as resendInternalWorkerInvitationAPI } from 'api'
import { entityCall } from './entityCall'

export function* resendInternalWorkerInvitation(action) {
  yield call(entityCall, resendInternalWorkerInvitationAPI, {}, action)
}

export function* watchForResendInternalWorkerInvitation() {
  yield takeLatest(types.INTERNAL_WORKER_INVITATION_RESEND_BEGIN, resendInternalWorkerInvitation)
}
