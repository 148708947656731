export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    id: {
      type: 'integer',
    },
    title: {
      type: 'string',
    },
    country_code: {
      type: 'string',
    },
    currency_code: {
      type: 'string',
    },
    order_number: {
      type: 'integer',
    },
  },
}
