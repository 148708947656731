import React from 'react'

const Notifications = props => (
  <svg width={14} height={16} viewBox="0 0 14 16" {...props}>
    <title>{`Combined-Shape`}</title>
    <path
      d="M7.778.814a4.668 4.668 0 0 1 3.889 4.603v.1h.003V9.43a.75.75 0 0 0 .229.54l1.831 1.768c.173.166.27.396.27.635a.375.375 0 0 1-.375.375H.375A.375.375 0 0 1 0 12.374c0-.24.097-.47.269-.636L2.082 9.97a.75.75 0 0 0 .227-.537V5.516a.262.262 0 0 1 .024-.003v-.096A4.668 4.668 0 0 1 6.223.814V.778a.778.778 0 1 1 1.555 0v.036zM7 16c-.86 0-1.556-.672-1.556-1.5.874-.002 2.112 0 3.112 0 0 .828-.697 1.5-1.556 1.5z"
      fillRule="nonzero"
      fill="#212121"
    />
  </svg>
)

export default Notifications
