import { get, uniqBy, omit } from 'lodash'
import { put, select, takeEvery, call, all } from 'redux-saga/effects'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import * as types from 'actions/action-types'
import { putFailureAction } from './entityCall'

/**
 * Save workers on its remaining agency allocation.
 * It will help components to map through allocations/workers
 */
export function* saveWorkersInAllocation() {
  yield takeEvery(types.AGENCY_ALLOCATION_WORKERS_FETCH_SUCCEEDED, function* (action) {
    const workers = get(action, 'payload', [])
    if (workers.length) {
      yield put({
        type: types.AGENCY_ALLOCATION_UPDATE_SUCCEEDED,
        payload: {
          id: workers[0].allocationId,
          workers,
        },
      })
    }
  })
}

/**
 * Will decide the action to be performed after the saga is called
 * @param {String} type - Action executed
 */
const getActionToPerform = type => {
  return type === types.AGENCY_ALLOCATION_WORKER_CREATE_BEGIN
    ? {
        action: apiActions.create,
        type: types.AGENCY_ALLOCATION_WORKERS_CREATE_SUCCEEDED,
        typeOne: types.AGENCY_ALLOCATION_WORKER_CREATE_SUCCEEDED,
        failedAction: types.AGENCY_ALLOCATION_WORKER_CREATE_FAILED,
      }
    : {
        action: apiActions.update,
        type: types.AGENCY_ALLOCATION_WORKERS_UPDATE_SUCCEEDED,
        typeOne: types.AGENCY_ALLOCATION_WORKER_UPDATE_SUCCEEDED,
        failedAction: types.AGENCY_ALLOCATION_WORKER_UPDATE_FAILED,
      }
}

/**
 * Concatenated action if worker has id from response
 * Will keep agency allocation updated after a worker is added within that allocation
 * or a worker is updated
 * @param {Object} workerResponse - Response from API call
 */
export function* updateWorkersOnAgencyAllocation(workerResponse) {
  const agencyAllocations = yield select(rootState => rootState.agencyAllocations.entityMap)
  const payload = workerResponse
    .filter(worker => worker.id)
    .map(worker => {
      return {
        id: worker.allocationId,
        workers: uniqBy([worker].concat(get(agencyAllocations[worker.allocationId], 'workers', [])), 'id'),
      }
    })
  yield put({
    type: types.AGENCY_ALLOCATIONS_UPDATE_SUCCEEDED,
    payload,
  })
}

/**
 * Saga in charge of create/update a worker for agency allocation
 * It will save a worker on agencyAllocationWorker entity but also will update Agency allocation
 * with the new worker created/updated for a easy mapping on component level
 * @param {Object} options - Context
 */
export function* addWorkerInAllocation(options) {
  yield takeEvery(
    [types.AGENCY_ALLOCATION_WORKER_CREATE_BEGIN, types.AGENCY_ALLOCATION_WORKER_UPDATE_BEGIN],
    function* (action) {
      const requestWorker = omit(get(action, 'payload'), '$type')
      const currentAction = getActionToPerform(action.type)
      const { querySetKeys, requestHeaders, copy } = get(action, 'meta', {})
      try {
        const requestOptions = {
          apiAction: currentAction.action,
          entityType: 'agencyAllocationWorker',
          meta: {
            ...options.context,
            ...(copy && { copy }),
            ...(querySetKeys && { querySetKeys }),
            ...(requestHeaders && { requestHeaders }),
          },
        }
        const response = yield call(entityApiRequest, { ...requestOptions, entity: requestWorker })
        const workerResponse = [].concat(get(response, 'payload', [])) // Make sure response is an array
        // TODO: GONG ORA-2669 Investigate customEntityIds if it is able to use here
        yield put({
          type: currentAction.type,
          payload: workerResponse,
          meta: {
            requestOptions: {
              ...requestOptions,
              entity: workerResponse.map(worker => ({ ...worker, listingUuid: requestWorker.listingUuid })),
            },
          },
        })
        yield updateWorkersOnAgencyAllocation(workerResponse)
        yield put({
          type: currentAction.typeOne,
          payload: workerResponse,
        })
      } catch (e) {
        return yield putFailureAction({
          type: currentAction.failedAction,
          error: e,
          requestOptions: { entity: action.payload },
          beginAction: action,
        })
      }
    },
  )
}

/**
 * In charge of keep updating agency allocation workers afer worker removed
 */

export function* removeWorkerInAllocation() {
  yield takeEvery(types.AGENCY_ALLOCATION_WORKER_DELETE_SUCCEEDED, function* (action) {
    const entity = get(action, 'meta.requestOptions.entity', {})
    const { allocationId, id } = entity
    if (id && allocationId) {
      const agentyAllocation = yield select(rootState => rootState.agencyAllocations.entityMap[allocationId])
      yield put({
        type: types.AGENCY_ALLOCATION_UPDATE_SUCCEEDED,
        payload: {
          id: allocationId,
          workers: get(agentyAllocation, 'workers', []).filter(ele => ele.id !== id),
        },
      })
    }
  })
}

/**
 * In charge of keep updating agency allocation workers afer worker removed from multiple shifts
 */

export function* removeWorkerInMultipleAllocations() {
  yield takeEvery(types.REMOVE_WORKER_FROM_ALLOCATION_CREATE_SUCCEEDED, function* (action) {
    const entity = get(action, 'meta.requestOptions.entity', {})
    const { filteredAllocations, agencyShiftWorkerIds } = entity
    if (filteredAllocations.length && agencyShiftWorkerIds.length) {
      yield all(
        agencyShiftWorkerIds.map(workerId => {
          const agentyAllocation = filteredAllocations.find(allocation => {
            return !!allocation.workers.find(ele => ele.id === workerId)
          })
          return put({
            type: types.AGENCY_ALLOCATION_UPDATE_SUCCEEDED,
            payload: {
              id: agentyAllocation.id,
              workers: get(agentyAllocation, 'workers', []).filter(ele => ele.id !== workerId),
            },
          })
        }),
      )
    }
  })
}
