export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    referral_code: {
      type: 'integer',
      description: 'Voucher code',
    },
  },
  required: ['referral_code'],
}
