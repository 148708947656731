import { REHYDRATE } from 'redux-persist'
import { get, has, omit, merge } from 'lodash'
import { flex } from '@indeed/flex-ifl-theme'

import config from 'config'
import flexLogoUrlV2 from 'common/images/indeed-flex-logo-v2.svg'
import { brandDefaults } from 'common/constants'
import * as types from '../actions/action-types'

type currencyType = {
  symbol: string
  code: string
}

type linksType = {
  appLink: string
  termsAndConditions: string
  flexPlusTermsAndConditions: string
  privacyPolicy: string
  syftForceGuide: string
  syftGuide: string
}

export type platformBrandType = {
  key: string
  name: string
  internalResourcingName: string
  url: string
  links: linksType
  workersName: string
  assetPath?: string
  countryCode: string
  currency: currencyType
  locale: string
}

const { BRAND_KEY } = config

const baseInitialState = {
  id: undefined,
  internalResourcing: false,
  visible: true,
  theme: {
    name: 'IndeedFlex',
    logo: {
      url: flexLogoUrlV2,
    },
    palette: {
      primary: flex.colors?.primary?.[800] || '#003A9B',
      accent: flex.colors?.accent?.default || '#d003a0',
    },
  },
  links: {
    appLink: 'https://syft.app.link/download-app',
  },
}

export const initialState = merge({}, baseInitialState, brandDefaults[BRAND_KEY])

export const insertBrandIfMissing = (platform: platformBrandType) => {
  return has(platform, 'platformName')
    ? platform
    : {
        ...merge({}, omit(baseInitialState, 'theme'), platform, omit(brandDefaults[BRAND_KEY], 'theme')),
      }
}

type actionType = {
  type: string
  payload: any
}

const reducer = (state = initialState, { type, payload }: actionType = { type: '', payload: {} }) => {
  switch (type) {
    case types.PLATFORM_UPDATE:
      // Only PLATFORM_THEME_CHANGE can update theme

      return {
        // (theme is normalized individually in sagas)
        ...state,
        ...merge({}, initialState, payload),
        theme: state.theme,
      }
    case types.PLATFORM_THEME_CHANGE:
      const theme = get(state, 'internalResourcing', false)
        ? { ...payload, logo: get(payload, 'logo', {}) }
        : merge({}, payload, { logo: { ...state.theme.logo, ...get(payload, 'logo', {}) } })

      return {
        ...state,
        theme,
      }
    case types.PLATFORM_VISIBILITY_CHANGE:
      return {
        ...state,
        visible: payload,
      }
    case REHYDRATE:
      const platform = get(payload, 'platform')
      return platform ? insertBrandIfMissing(platform) : state

    case types.AUTH_LOG_OUT:
      return initialState
    default:
      return state
  }
}

export default reducer
