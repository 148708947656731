import React, { createFactory } from 'react'
import PropTypes from 'prop-types'
import setDisplayName from 'recompose/setDisplayName'
import wrapDisplayName from 'recompose/wrapDisplayName'

// Don't mount a dialog when it's closed.
// (A dialog is any component with a boolean `open` prop)
//
// This is used when the dialog is decorated with a number of HOCs
// that perform some tasks on component mount / unmount.
//
// Modals also often display heavy content that should be unmount
// when close to restore the page performance.
//
// TODO Refactor to make obsolete
//
// @param {Function} BaseComponent Any component with `open` prop
const unmountWhenDialogIsClosed = BaseComponent => {
  const factory = createFactory(BaseComponent)

  class UnmountWhenDialogIsClosed extends React.Component {
    static propTypes = {
      open: PropTypes.bool,
    }

    constructor(props) {
      super(props)
      this.state = { mount: props.open }
    }

    shouldComponentUpdate(nextProps, nextState) {
      return !!this.props.open || !!nextProps.open || this.state.mount || !!nextState.mount
    }

    componentDidUpdate(prevProps) {
      if (prevProps.open && !this.props.open) {
        this.timeout = setTimeout(() => {
          this.setState({ mount: false })
        }, 500)
      }
      if (!prevProps.open && this.props.open) {
        this.setState({ mount: true })
        clearTimeout(this.timeout)
      }
    }

    componentWillUnmount() {
      clearTimeout(this.timeout)
    }

    render() {
      return this.state.mount ? factory(this.props) : null
    }
  }

  return setDisplayName(wrapDisplayName(BaseComponent, 'unmountWhenDialogIsClosed'))(
    UnmountWhenDialogIsClosed,
  )
}

export default unmountWhenDialogIsClosed
