// user properties that are sent with every event
// Must be mapped to GTM Data Layer variables (GTM -> Variables -> All Data Layer variables)
export const userProperties = {
  IS_INTERNAL_RESOURCING: 'is_internal_resourcing',
  EMPLOYER_ID: 'employer_id',
  VENUE_ID: 'venue_id',
  JOB_ID: 'job_id',
  IP_ADDRESS: 'ip_address',
  USER_ID: 'user_id',
  WORKER_ID: 'worker_id',
}

export const customEvents = {
  LOGIN: 'login',
  PAGEVIEW: 'pageview',
}
