import React from 'react'

const SousChef = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" xmlnsXlink="http://www.w3.org/1999/xlink" {...props}>
    <title>{`Sous chef`}</title>
    <defs>
      <path
        d="M.35 0C.25 0 .154.055.087.15A.527.527 0 0 0 .003.5C.423 4.776 3.3 8 6.693 8h.613c3.394 0 6.27-3.224 6.691-7.5a.527.527 0 0 0-.084-.35.324.324 0 0 0-.263-.15H.35z"
        id="a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.95 29.751c-2.78-.272-4.95-2.58-4.95-5.386 0-2.99 2.462-5.413 5.5-5.413.626 0 1.227.103 1.787.293A7.526 7.526 0 0 1 31.5 16a7.526 7.526 0 0 1 6.213 3.245 5.573 5.573 0 0 1 1.787-.293c3.038 0 5.5 2.424 5.5 5.413 0 2.807-2.17 5.114-4.95 5.386v10.606h-17.1V29.751zm0 12.378h17.1V44.3a2.7 2.7 0 0 1-2.7 2.7h-11.7a2.7 2.7 0 0 1-2.7-2.7v-2.171z"
        fill="#003a9b"
      />
      <g transform="translate(33 39)" strokeLinecap="round" strokeLinejoin="round">
        <use fill="#003a9b" xlinkHref="#a" />
        <path
          stroke="#FFF"
          strokeWidth={1.7}
          d="M.35-.85h13.3c.392 0 .74.2.96.513.185.266.265.597.233.92-.46 4.678-3.662 8.267-7.537 8.267h-.613C2.82 8.85-.383 5.26-.843.584a1.375 1.375 0 0 1 .234-.92A1.17 1.17 0 0 1 .35-.85z"
        />
      </g>
    </g>
  </svg>
)

export default SousChef
