import React from 'react'

const CheckWaved = props => (
  <svg viewBox="0 0 94 94" {...props}>
    <g fill="none" fillRule="evenodd" strokeWidth={3.715}>
      <path
        stroke="#02CC7D"
        d="M37.488 86.656a2.94 2.94 0 0 1-.264-.408c-.594-1.125-1.52-2.534-2.556-2.849-.81-.25-2.054.043-3.704.853a3.031 3.031 0 0 1-.495.19c-3.62 1.573-7.778 1.185-10.982-1.062-3.173-2.222-4.823-5.87-4.382-9.616-.003-.155.006-.31.033-.47.603-3.67-.165-4.705-3.909-5.273a3.229 3.229 0 0 1-.525-.127c-3.863-.773-6.965-3.42-8.177-7.014-1.203-3.57-.312-7.437 2.319-10.22a2.71 2.71 0 0 1 .33-.383c2.703-2.605 2.703-3.955 0-6.56a2.61 2.61 0 0 1-.336-.387c-2.625-2.785-3.516-6.65-2.313-10.215 1.212-3.595 4.32-6.241 8.186-7.012a2.91 2.91 0 0 1 .513-.124c3.744-.573 4.509-1.608 3.909-5.279a3.138 3.138 0 0 1-.03-.475c-.438-3.74 1.212-7.386 4.385-9.607 3.21-2.25 7.38-2.636 11-1.055.162.048.32.112.477.185 1.65.81 2.895 1.103 3.704.853 1.035-.315 1.962-1.722 2.556-2.85.078-.146.168-.283.267-.413C39.441 4.033 43.034 2 47 2c3.972 0 7.574 2.042 9.52 5.36.094.122.18.254.253.392.597 1.125 1.524 2.534 2.562 2.849.8.247 2.051-.04 3.704-.853.156-.075.318-.14.483-.188 3.62-1.58 7.778-1.19 10.99 1.055 3.172 2.222 4.818 5.864 4.386 9.607.003.158-.009.318-.036.478-.603 3.67.165 4.706 3.909 5.277.18.028.35.067.522.123 3.863.774 6.968 3.418 8.18 7.012 1.2 3.572.312 7.442-2.322 10.226a2.808 2.808 0 0 1-.327.377c-2.7 2.604-2.7 3.954 0 6.559.123.12.234.247.333.382 2.628 2.785 3.519 6.652 2.316 10.22-1.209 3.595-4.317 6.245-8.186 7.015-.165.06-.336.099-.516.127-3.744.568-4.509 1.603-3.906 5.273.027.163.04.327.036.49.435 3.74-1.214 7.38-4.385 9.596-3.204 2.253-7.361 2.638-10.99 1.06a3.341 3.341 0 0 1-.484-.188c-1.656-.813-2.912-1.1-3.704-.852-1.038.312-1.962 1.72-2.562 2.849-.072.14-.16.272-.255.399C54.568 89.961 50.969 92 47 92c-3.969 0-7.565-2.036-9.512-5.344z"
      />
      <path
        stroke="#02CF82"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M39 47.054l5.594 5.514L55.316 42"
      />
    </g>
  </svg>
)

export default CheckWaved
