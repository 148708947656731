import { filter } from 'lodash'
import { put } from 'redux-saga/effects'

import { jobCandidateTypeEnum } from 'api/entities/jobCandidate'
import * as types from 'actions/action-types'

export function* getAgencyShiftWorkersFromCandidates({ payload: candidates }) {
  const agencyShiftWorkers = filter(candidates, {
    $candidateType: jobCandidateTypeEnum.agencyShift,
  })

  yield put({
    type: types.AGENCY_SHIFT_WORKERS_FETCH_SUCCEEDED,
    payload: agencyShiftWorkers,
    meta: {
      shouldUnionResults: true,
    },
  })
}
