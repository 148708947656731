// 'dailyShiftBookings' are shift bookings grouped by day – 'dailyShiftBookings'.
//
// This reduces the continuous availability intervals (shift bookings) into discrete
// chunks which are easier to efficiently cache and query.
//
// TODO In future replace daily shift bookings with availability intervals

import { flatMap, map, groupBy, uniqBy } from 'lodash'
import { eachDayOfInterval, format as formatDate } from 'lib/date-fns'

// Convert shift bookings – pairs of worker and shifts – to shift bookings indexed
// by day – 'dailyShiftBookings'.
// See comments above
//
// IN: [{ $type: 'shiftBooking', workerId: 1, shift: { id: 1, startTime, endTime },...]
// OUT: [{ $type: 'dailyShiftBooking', workerId: 1, day: '2018-01-01', shifts: [{ id, startTime, endTime }]
export function convertToDailyShiftBookings(shiftBookings) {
  const bookingsWithDays = flatMap(shiftBookings, shiftBooking => {
    // This assumes shifts spanning max 2 days
    const days = eachDayOfInterval({ start: shiftBooking.shift.startTime, end: shiftBooking.shift.endTime })
    return map(days, day => ({ ...shiftBooking, day: formatDate(day, 'YYYY-MM-DD') }))
  })
  const bookingsByDay = groupBy(bookingsWithDays, 'day')
  const dailyShiftBookings = flatMap(bookingsByDay, (bookingsPerDay, day) => {
    const bookingsByWorker = groupBy(bookingsPerDay, 'workerId')
    return flatMap(bookingsByWorker, (bookingsPerWorker, workerId) => {
      const shifts = uniqBy(map(bookingsPerWorker, 'shift'), 'id')
      return {
        $type: 'dailyShiftBooking',
        day,
        workerId: parseInt(workerId, 10),
        shifts,
      }
    })
  })
  return dailyShiftBookings
}
