import { call, put, takeLatest } from 'redux-saga/effects'

import {
  AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_BEGIN,
  AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_SUCCEEDED,
} from 'actions/action-types'

import { apiRequest, handleCall } from 'api/call'

import { entityCall } from './entityCall'

export const sendJobCards = ({ entity }) =>
  new Promise((resolve, reject) => {
    const req = apiRequest({
      path: '/agency_portal/send_job_cards',
      body: {
        job_id: parseInt(entity.jobId, 10),
        agency_worker_id: entity.cellData.id,
        agency_branch_ids: entity.cellData.selectedBranchesIds,
        search_query: entity.cellData.searchQuery,
      },
      method: 'POST',
    })
    handleCall(req, resolve, reject)
  })

export function* sendAgencyListingJobBookedEmail(action) {
  const [payload] = yield call(entityCall, sendJobCards, {}, action)

  yield put({
    type: AGENCY_LISTING_JOB_SEND_EMAIL_CREATE_SUCCEEDED,
    payload,
  })
}

export function* watchJobBookingSendEmail() {
  yield takeLatest(AGENCY_LISTING_JOB_SEND_EMAIL_TRIGGER_BEGIN, sendAgencyListingJobBookedEmail)
}
