/* eslint-disable func-names */
/* eslint-disable import/order */

import { get } from 'lodash'
import { call, select } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { currentUser } from 'selectors/auth'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'

export function* fetchCurrentUser(options = {}) {
  yield call(
    entityActionRequest,
    {
      apiAction: apiActions.get,
      entityType: 'currentUser',
      ...options,
    },
    {
      type: types.CURRENT_USER_FETCH_BEGIN,
    },
  )
}

// Refetch user as part of creating (signing up) a new employer
export function* createEmployer(options = {}) {
  yield takeLatestDeep(types.EMPLOYER_CREATE_BEGIN, function* (action) {
    const currentUserId = yield select(rootState => get(currentUser(rootState), 'id'))
    const countryCode = yield select(rootState => get(currentUser(rootState), 'countryCode'))

    const userId = action.payload.userId || currentUserId
    const requestPayload = { ...action.payload, userId, countryCode }

    yield call(
      entityActionRequest,
      {
        context: options.context,
        *afterSuccess() {
          yield fetchCurrentUser(options)
        },
      },
      { ...action, payload: requestPayload },
    )
  })
}

// Updating the employer (opaquely for the client) affects data coming as the current
// user (after /login or /refresh). Most notably this is `user.employer.employerComplete`.
// We need to fetch the current user always after updating the employer.
export function* updateEmployer(options = {}) {
  yield takeLatestDeep(types.EMPLOYER_UPDATE_BEGIN, function* (action) {
    yield call(
      entityActionRequest,
      {
        context: options.context,
        *afterSuccess() {
          yield fetchCurrentUser(options)
        },
      },
      action,
    )
  })
}
