import React from 'react'

const General = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`General`}</title>
    <path
      d="M56 53c0-7.18 5.82-13 13-13s13 5.82 13 13-5.82 13-13 13-13-5.82-13-13zm11.645 17h2.69c13.496 0 24.67 8.584 26.604 21.43.195 1.295.335 6.57-6.42 6.57H47.505c-6.737 0-6.605-5.624-6.464-6.56C42.968 78.59 54.145 70 67.645 70z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default General
