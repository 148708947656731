import { call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'
import { downloadRotaBooking as downloadRotaBookingApi } from 'api'

export function* downloadRotaCsv(options = {}) {
  yield takeLatestDeep(types.ROTA_DOWNLOAD_BOOKING_FETCH_BEGIN, function* (action) {
    yield call(entityCall, downloadRotaBookingApi, {}, action)
  })
}
