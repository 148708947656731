export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    worker_id: {
      type: 'integer',
    },
    shift_id: {
      type: 'integer',
    },
    break_params: {
      type: 'array',
      properties: {
        type: {
          type: 'string',
        },
        break_id: {
          type: 'string',
          clock_in_time: 'string',
          clock_out_time: 'string',
        },
      },
    },
  },
}
