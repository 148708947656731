/* eslint-disable func-names */

import 'lib/regeneratorRuntime'
import { map, get, isNil, includes, without } from 'lodash'
import {
  put,
  fork,
  race,
  takeLatest,
  all,
  takeEvery,
  select,
  take,
  setContext,
  call,
  spawn,
} from 'redux-saga/effects'
import { REHYDRATE } from 'redux-persist'

import * as types from 'actions/action-types'
import actions from 'actions'
import { entityApiRequest } from 'api'
import { deserializeActionType, serializeActionType, requestFlowStates } from 'api/actionTypes'
import { entityTypes } from 'api/schemas'
import { apiActions } from 'api/endpoints'

import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'
import { getAgencyId } from 'selectors/auth'

import { respondToBatchApiRequest } from './batchApiRequest'
import pollCollection from './pollCollection'
import {
  watchForUserLogin,
  watchForIndeedLogin,
  watchForIndeedOauthRedirect,
  loginSuccessSaga,
  watchForRefreshAuthSaga,
  recoverPasswordSaga,
  resetPasswordSaga,
  extractPlatform,
  listenToStoreAuthChange,
  checkIsTokenExpired,
  watchForUserLogout,
  refreshAuthIfExpired,
  watchForGetByResetToken,
  watchForCreateWorker,
} from './auth'
import { watchForResendInternalWorkerInvitation } from './internalWorkerInvitation'
import { createEmployer, updateEmployer } from './employer'
import { createBulkOffer } from './bulkOffer'
import { createOrUpdateVenue } from './venue'
import { fetchWorker, fetchWorkers, updateWorker, unpackWorker } from './worker'
import {
  updateListing,
  fulfilmentSuggestionThenUpdateListing,
  extractListingsResponse,
  updateShiftBookingsWhenFetchListings,
} from './listing'
import {
  fetchJobCandidates,
  updateJobCandidate,
  bulkDeleteJobCandidates,
  deleteJobCandidate,
} from './jobCandidate'
import { startSyncingMessageThreads, fetchMessages } from './message'
import { startSyncingNotifications, watchForMarkAllNotificationsAsRead } from './notification'
import { watchForMarkAllAgencyNotificationsAsRead } from './agencyNotification'
import { updateTimesheet } from './timesheet'
import { fetchTimesheetEntries, updateTimesheetEntry } from './timesheetEntry'
import {
  employerTimesheetApproved,
  employerTimesheetsApproved,
  downloadEmployerTimesheetCSV,
} from './employerTimesheet'
import { updateRatings, fetchRatings, refetchAfterChangingRatings } from './rating'
import { markThreadAsRead } from './thread'
import { submitAgencyListing } from './agencyShift'
import { createUser, changeEmailPassword } from './user'
import { fetchWorkerJobs } from './workerJob'
import { refetchWorkerAfterRoleIsChanged } from './workerRole'
import { bulkDeleteWorkerVenues } from './workerVenue'
import { fetchTimesheetApprovalStat } from './timesheetApprovalStat'
import { fetchEmployerAccountInvitation, updateEmployerAccountInvitation } from './employerAccountInvitation'
import { createBulkMessage } from './bulkMessage'
import { postMultiBookingUpdateWorkerShifts, postCancelBookingUpdateWorkerShifts } from './booking'
import { verifyPaymentSession } from './paymentSession'
import { fetchListingAgencies, fetchListing, fetchListingAgency } from './listingAgency'
import { watchJobBookingSendEmail } from './jobBookingSendEmail'
import { watchNoShowReasonDelete } from './noShowReasons'
import { updateAgencyListingEmailStatus } from './jobCard'
import { fetchEmployerAccounts } from './employerAccounts'
import { analyticsInitialize, analyticsTerminate } from './analytics'
import { trackActions } from './trackingActions'
import { watchForFetchDashboard } from './dashboard'
import { entityCall } from './entityCall'
import { downloadRotaCsv } from './rota'
import { downloadAgencyTimesheet, agencyTimesheetCsvDownload } from './agencyTimesheet'
import { watchForIndustriesRolesSkillsFetch } from './industryRoleSkill'
import { watchForJobDurationPreview } from 'sagas/jobDurationPreview'
import {
  saveWorkersInAllocation,
  addWorkerInAllocation,
  removeWorkerInAllocation,
  removeWorkerInMultipleAllocations,
} from 'sagas/agencyAllocation'
import { licenseManifest } from 'selectors/config'

/**
 * Whenever we receive action of type {ENTITY}_{FETCH|CREATE|...}_BEGIN, launch
 * the appropriate API request and handle the result. Equivalent of doing the following
 * for all entity types:
 * ```
 * takeLatest(VENUES_FETCH_BEGIN, entityCall, entityApiRequest, { apiAction: 'list' })
 * takeLatest(VENUE_CREATE_BEGIN, entityCall, entityApiRequest, { apiAction: 'create' })
 * ```
 * @param {string[]} entityTypes entityTypes a list of entity types used in construction of action creators
 * @param {Function} options.excludes api actions to be ignored for specific entity types
 */
function* respondToStandardCrudRequests(entityTypes, options = {}) {
  yield all(
    map(entityTypes, function (entityType) {
      return all(
        map(actions[entityType], function (actionCreator) {
          if (!actionCreator.toString().match(/BEGIN$/)) return
          // actionCreator is e.g. require('actions').listings.fetchListing
          // TODO Remove `actionCreator.apiAction`
          const { apiAction } = deserializeActionType(actionCreator)
          // Return if this is not a standard API action
          if (!apiAction) return
          // apiAction is e.g. `apiActions.list`
          const beginActionType = actionCreator.toString()
          // Don't respond to standard actions of entity types mentioned in `options.excludes`
          if (get(options, ['excludes', entityType, apiAction]) === false) return
          // beginActionType is e.g. types.VENUES_FETCH_BEGIN
          if (includes([apiActions.list, apiActions.get], apiAction)) {
            // Requests without side effects MIGHT be ignored
            // (takeLatestDeep may abandon consecutive requests with the same query)
            return takeLatestDeep(beginActionType, entityActionRequest)
          } else {
            // Mutating requests MUST be processed
            return takeEvery(beginActionType, entityActionRequest)
          }
        }),
      )
    }),
  )
}

export function* repeatRequestAfterFailure() {
  yield all(
    map(entityTypes, function* (entityType) {
      yield all(
        map(apiActions, function (apiAction) {
          const failedActionType = serializeActionType({
            entityType,
            apiAction,
            requestFlowState: requestFlowStates.failed,
          })

          return takeLatest(failedActionType, function* (action) {
            if (get(action, 'payload.body.error') !== 'invalid_access_token') return

            yield put(actions.auth.refresh())

            const { refreshFailed } = yield race({
              refreshSucceeded: take(types.AUTH_REFRESH_SUCCEEDED),
              refreshFailed: take(types.AUTH_REFRESH_FAILED),
            })

            // if failed to refresh, just ignore but not cancel so other api calls could be retried
            if (refreshFailed) return

            // otherwise, retry
            yield put(action.meta.beginAction)
          })
        }),
      )
    }),
  )
}

// Respond to all actions that request data from Syft API
// @option {Function} options.entityApiRequest Place to stub the entityApiRequest method.
//   See the method signature of entityApiRequest.
// @option {Function} options.context Enity API context. See entityActionRequest.
//   E.g. `{ userData: ..., oauthData: ...}`
export function* respondToApiRequestsWithAuth(options = {}) {
  yield setContext({
    entityApiRequest: options.entityApiRequest,
    entityApiContext: options.context,
  })
  yield fork(respondToStandardCrudRequests, without(entityTypes, ...withoutAuthSelectedEntityTypes), {
    ...options,
    excludes: {
      agencyShiftWorker: { list: false },
      venue: { update: false, create: false },
      listing: { update: false },
      bulkOffer: { create: false },
      bulkMessage: { create: false },
      worker: { list: false, update: false, get: false },
      employer: { create: false, update: false },
      jobCandidate: { list: false, update: false, delete: false },
      rating: { list: false },
      timesheet: { update: false },
      timesheetEntry: { list: false, update: false },
      timesheetApprovalStat: { get: false },
      message: { list: false },
      workerJob: { list: false },
      user: { create: false },
      employerAccountInvitation: { get: false, update: false },
      employerAccount: { list: false },
      listingAgencyShifts: { create: false },
      dashboard: { update: false, list: false, get: false },
      industry: { list: false },
      role: { list: false },
      skill: { list: false },
      jobDurationPreview: { create: false, update: false },
      agencyAllocationWorker: { create: false, update: false },
    },
  })

  yield fork(watchForResendInternalWorkerInvitation, options)
  yield fork(agencyTimesheetCsvDownload, options)
  yield fork(createBulkOffer, options)
  yield fork(createBulkMessage, options)
  yield fork(createOrUpdateVenue, options)
  yield fork(updateEmployer, options)
  yield fork(updateRatings, options)
  yield fork(fetchRatings, options)
  yield fork(refetchAfterChangingRatings)
  yield fork(updateListing, options)
  yield fork(fulfilmentSuggestionThenUpdateListing, options)
  yield fork(updateTimesheet, options)
  yield fork(employerTimesheetApproved)
  yield fork(employerTimesheetsApproved)
  yield fork(downloadEmployerTimesheetCSV, options)
  yield fork(refetchWorkerAfterRoleIsChanged, options)
  yield fork(fetchTimesheetEntries, options)
  yield fork(fetchTimesheetApprovalStat, options)
  yield fork(updateTimesheetEntry, options)
  yield fork(fetchJobCandidates, options)
  yield fork(extractListingsResponse)
  yield fork(updateShiftBookingsWhenFetchListings)
  yield fork(fetchWorkers)
  yield fork(unpackWorker)
  yield fork(markThreadAsRead, options)
  yield fork(fetchMessages, options)
  yield fork(downloadRotaCsv, options)
  yield fork(downloadAgencyTimesheet, options)
  yield fork(fetchWorker, options)
  yield fork(updateWorker, options)
  yield fork(fetchWorkerJobs, options)
  yield fork(submitAgencyListing, options)
  yield fork(updateJobCandidate, options)
  yield fork(postMultiBookingUpdateWorkerShifts, options)
  yield fork(postCancelBookingUpdateWorkerShifts, options)
  yield fork(deleteJobCandidate, options)
  yield fork(verifyPaymentSession, options)
  yield fork(fetchListingAgencies, options) // TODO: rename to refetchListingAgencies
  yield fork(fetchListingAgency, options) // TODO: rename
  yield fork(fetchListing, options) // TODO: rename
  yield fork(watchJobBookingSendEmail, options)
  yield fork(watchNoShowReasonDelete, options)
  yield fork(updateAgencyListingEmailStatus, options)
  yield fork(fetchEmployerAccounts, options)
  yield fork(watchForFetchDashboard, options)
  yield fork(watchForIndustriesRolesSkillsFetch, options)
  yield fork(watchForJobDurationPreview, options)
  yield fork(watchForUserLogout, options)
  yield fork(saveWorkersInAllocation)
  yield fork(addWorkerInAllocation, options)
  yield fork(removeWorkerInAllocation, options)
  yield fork(removeWorkerInMultipleAllocations, options)
  yield fork(watchForMarkAllAgencyNotificationsAsRead, options)
}

const withoutAuthSelectedEntityTypes = [
  'nonSyftExperience',
  'workerSignupInvitation',
  'country',
  'page',
  'authLoginType',
]
// Respond to API requests that don't require authenticated session.
// Unlike sagas for API request requiring auth, we won't cancel these
// on session change.
// @option {Function} options.entityApiRequest Place to stub the entityApiRequest method.
//   See the method signature of entityApiRequest.
export function* respondToApiRequestsWithoutAuth(options = {}) {
  yield setContext({
    entityApiRequest: options.entityApiRequest,
  })
  yield fork(respondToStandardCrudRequests, withoutAuthSelectedEntityTypes, options)
  yield fork(watchForUserLogin, options)
  yield fork(watchForCreateWorker, options)
  yield fork(watchForIndeedLogin, options)
  yield fork(watchForIndeedOauthRedirect, options)
  yield fork(watchForGetByResetToken, options)
  yield fork(loginSuccessSaga, options)
  yield fork(recoverPasswordSaga, options)
  yield fork(resetPasswordSaga, options)
  yield fork(watchForRefreshAuthSaga, options)
  yield fork(changeEmailPassword, options)
  yield fork(createUser, options)
  yield fork(createEmployer, options)
  yield fork(fetchEmployerAccountInvitation, options)
  yield fork(updateEmployerAccountInvitation, options)
  yield fork(extractPlatform, options)
  yield fork(analyticsInitialize, options)
  yield fork(analyticsTerminate, options)
  yield fork(trackActions, options)
  yield fork(respondToBatchApiRequest, {
    [types.WORKER_VENUE_DELETE_BEGIN]: bulkDeleteWorkerVenues,
    [types.JOB_CANDIDATE_DELETE_BEGIN]: bulkDeleteJobCandidates,
  })
}

// List of all possible actions that may change userId or employerId
//
// TODO This list of actions should be needed. Instead of actions we should react
// to changes in the data in the store (userId and employerId). E.g. if employerId
// changes we will restart all relevant sagas.
const actionsWithNewAuth = [
  types.AUTH_LOGIN_SUCCEEDED,
  types.AUTH_REFRESH_SUCCEEDED,
  types.CURRENT_USER_FETCH_SUCCEEDED,
  types.AUTH_LOG_OUT,
  types.AUTH_REFRESH_FORCE_UPDATE,
  REHYDRATE,
]

// TODO Remove (rename userData to currentUser everywhere)
const userFromAction = action => {
  if (action.type === REHYDRATE) return get(action, 'payload.auth.userData')
  if (action.type === types.CURRENT_USER_FETCH_SUCCEEDED) return action.payload
  return get(action.payload, 'user')
}

export function* logoutIfTokenExpired() {
  const isExpired = yield call(checkIsTokenExpired)

  if (isExpired) {
    // If the token expired, trigger logout action to cleanup auth data from the store
    yield put(actions.auth.logOut())
  } else {
    const { userData: user } = yield select(state => state.auth)
    const isUnverified = !user?.employerApproved
    // Agency portal has no employer
    const isAgency = user?.agencyId

    if (isUnverified && !isAgency) {
      // At this point we only loaded the previous session from the local storage.
      // If token is not expired, we will ask backend to refresh session to get a new auth token and more
      // recent data for the current user. We will do this in background for better UX.
      // If it turns out that the user / auth data changes it will trigger
      // respondToSessionChange again.
      // Agency does not have verification states the refresh token only for unverified employers
      yield put(actions.auth.refresh())
    }
  }
}

// TODO Functions passed to takeLatest shouldn't have any side effects
function* respondToEmployerChange() {
  let currentEmployerId = null

  const didChangeEmployer = function (action) {
    if (!includes(actionsWithNewAuth, action.type)) return false

    const previousEmployerId = currentEmployerId
    currentEmployerId = get(userFromAction(action), 'employerId')
    return previousEmployerId !== currentEmployerId
  }

  yield takeLatest(didChangeEmployer, function* (action) {
    const currentUser = userFromAction(action)
    const currentEmployer = get(currentUser, 'employer')
    const apiRequestOptions = {
      context: { userData: currentUser, beforeRequest: refreshAuthIfExpired },
      withLastUpdatedCursor: true,
    }

    // Logout (clearing of currentEmployerId) will cancel all ongoing
    // sagas set up here
    if (isNil(get(currentEmployer, 'id'))) return

    // In development this causes some noise in Network tab / console and causes
    // some unexpected rerenders
    if (process.env.NODE_ENV === 'production') {
      yield fork(pollCollection, {
        entityType: 'message',
        ...apiRequestOptions,
      })
      yield fork(pollCollection, {
        entityType: 'notification',
        ...apiRequestOptions,
      })

      yield fork(pollCollection, {
        entityType: 'thread',
        ...apiRequestOptions,
      })
    }

    yield fork(watchForMarkAllNotificationsAsRead)

    // In development this causes some noise in Network tab / console and causes
    // some unexpected rerenders
    if (process.env.NODE_ENV === 'production') {
      yield fork(startSyncingMessageThreads, apiRequestOptions)
      yield fork(startSyncingNotifications, apiRequestOptions)
    }
  })
}

function* handleUserOrEmployerChanges() {
  let currentUser = null

  const didChangeUserOrEmployer = function (action) {
    if (!includes(actionsWithNewAuth, action.type)) return false

    const previousUser = currentUser
    currentUser = userFromAction(action)

    return (
      get(previousUser, 'id') !== get(currentUser, 'id') ||
      get(previousUser, 'employerId') !== get(currentUser, 'employerId')
    )
  }
  yield takeLatest(didChangeUserOrEmployer, function* (action) {
    // Pass session context to API requests that need it
    const apiRequestOptions = { context: { userData: currentUser, beforeRequest: refreshAuthIfExpired } }
    yield fork(respondToSessionChange, action, apiRequestOptions)
    if (currentUser) {
      yield put(
        actions.enabledFeature.fetchEnabledFeature({
          featureNames: licenseManifest,
        }),
      )
      yield spawn(rehydrateCsatSurvey, action)
    }
  })
}

function* rehydrateCsatSurvey(action) {
  const employerId = get(action, 'payload.auth.userData.employerId')
  const agencyId = yield select(rootState => getAgencyId(rootState))
  const enabledAgencyCsat = get(action, 'payload.config.features')
  if (employerId || (enabledAgencyCsat && agencyId)) {
    yield call(
      entityCall,
      entityApiRequest,
      {
        apiAction: apiActions.get,
        entityType: 'csatSurvey',
        context: { initialCall: true, employerId, beforeRequest: refreshAuthIfExpired, agencyId },
      },
      actions.csat.fetchCsatSurvey(),
    )
    yield put(actions.auth.authCsatSurvey(true))
  }
}

// TODO Functions passed to takeLatest shouldn't have any side effects
function* respondToSessionChange(action, options) {
  const currentUser = userFromAction(action)

  if (isNil(get(currentUser, 'id'))) {
    if (action.type !== types.AUTH_LOG_OUT) {
      yield put({ type: types.AUTH_LOG_OUT })
    }
    // Logout will cancel all ongoing sagas set up here
    return
  }

  if (action.type === REHYDRATE && currentUser) {
    yield fork(logoutIfTokenExpired)
  }

  // The following API requests require an active user session (oauth token)
  yield fork(respondToApiRequestsWithAuth, options)
}

export default function* () {
  yield fork(respondToApiRequestsWithoutAuth)
  yield fork(repeatRequestAfterFailure)
  yield fork(handleUserOrEmployerChanges)
  yield fork(respondToEmployerChange)
  yield fork(listenToStoreAuthChange)
}

/* eslint-enable */
