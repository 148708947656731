import { get, head, tail, initial, last, isEmpty } from 'lodash'

// Same as `get(object, path, get(object, path2, get(object, path3, ...)))`
function tryPaths(object, path, ...paths) {
  return get(object, path, isEmpty(paths) ? undefined : tryPaths(object, head(paths), ...tail(paths)))
}

// Like tryPaths where the last argument is a default value instead of a path
function getWithDefaults(object, ...rest) {
  const [paths, defaultValue] = [initial(rest), last(rest)]
  const result = tryPaths(object, ...paths)
  return typeof result === 'undefined' ? defaultValue : result
}

export default getWithDefaults
