import React from 'react'

const BarStaff = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`bar staff`}</title>
    <path
      d="M87.084 59.669L71.921 75.877a4 4 0 0 1-.921.733V101h10a2 2 0 1 1 0 4H57a2 2 0 1 1 0-4h10V76.61a4 4 0 0 1-.921-.733l-22.93-24.51A2 2 0 0 1 44.61 48h8.125l-8.225-8.638H34v-4h11.366a2 2 0 0 1 1.449.62L58.257 48h22.967c.805-5.454 5.489-9.638 11.152-9.638 6.226 0 11.275 5.065 11.275 11.31 0 6.246-5.05 11.311-11.275 11.311a11.18 11.18 0 0 1-5.292-1.314zm2.89-3.09a7.26 7.26 0 0 0 2.402.404c4.014 0 7.275-3.271 7.275-7.31 0-4.04-3.261-7.31-7.275-7.31-3.445 0-6.331 2.403-7.084 5.637h8.098a2 2 0 0 1 1.46 3.366l-4.875 5.213z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default BarStaff
