import { includes } from 'lodash'

const isTestEnvironment = process.env.NODE_ENV === 'test'

// Wrapper for require.context that works in NODE environment (without Webpack)
//
// See usage
//
// @example
// ```
//   const requireSomething = webpackRequireContext(
//     () => require.context('./', false, /\.js$/),
//     (name, type) => require(`./${name}.${type}`), {})
//   requireSomething('modulename')
//   requireSomething('modulename', 'json')
// ```
//
// @param {Function} getRequireContext
// @param {Function} fallbackRequire
// @param {*} fallbackModule Fallback that will be returned in case the
// @param {Boolean} shouldThrow True by default. Throw when module is not found.
// @return {(name, type) -> *} require function
export default (getRequireContext, fallbackRequire, fallbackModule, shouldThrow = true) => {
  const requireContext = isTestEnvironment ? null : getRequireContext()
  const contextFileNames =
    !isTestEnvironment &&
    // in webpack 5, keys() returns an array of all possible requests that the context module can handle
    // not only relative ones as in webpack 4 so we need to filter those that doesn't match relatvie one
    requireContext
      .keys()
      .map(name => {
        // TODO Remove js|json|jpeg
        return name.match(/\.\/(.*)\.(js|ts|tsx|json|jpeg)$/)?.[1]
      })
      .filter(Boolean)

  const requireDefault = (name, type) => {
    const module = isTestEnvironment ? fallbackRequire(name, type) : requireContext(`./${name}.${type}`)
    return type === 'js' ? module.default : module
  }
  return (name, type = 'js') => {
    let hasFile
    if (isTestEnvironment || !shouldThrow) {
      try {
        hasFile = !!requireDefault(name, type)
      } catch (e) {
        hasFile = false
      }
    } else {
      hasFile = includes(contextFileNames, name)
    }
    // Return an empty schema by default
    /* eslint-disable global-require, import/no-dynamic-require */
    return hasFile ? requireDefault(name, type) : fallbackModule
    /* eslint-enable */
  }
}
