/** Constants that represent our supported action type aliases. */
export const ENTITIES_FETCH_BEGIN = 'ENTITIES_FETCH_BEGIN'
export const ENTITIES_FETCH_SUCCEEDED = 'ENTITIES_FETCH_SUCCEEDED'
export const ENTITIES_FETCH_FAILED = 'ENTITIES_FETCH_FAILED'

export const ENTITIES_CREATE_SUCCEEDED = 'ENTITIES_CREATE_SUCCEEDED'
export const ENTITIES_UPDATE_SUCCEEDED = 'ENTITIES_UPDATE_SUCCEEDED'

export const ENTITY_FETCH_BEGIN = 'ENTITY_FETCH_BEGIN'
export const ENTITY_FETCH_SUCCEEDED = 'ENTITY_FETCH_SUCCEEDED'
export const ENTITY_FETCH_FAILED = 'ENTITY_FETCH_FAILED'

export const ENTITY_CREATE_BEGIN = 'ENTITY_CREATE_BEGIN'
export const ENTITY_CREATE_SUCCEEDED = 'ENTITY_CREATE_SUCCEEDED'
export const ENTITY_CREATE_FAILED = 'ENTITY_CREATE_FAILED'

export const ENTITY_UPDATE_BEGIN = 'ENTITY_UPDATE_BEGIN'
export const ENTITY_UPDATE_SUCCEEDED = 'ENTITY_UPDATE_SUCCEEDED'
export const ENTITY_UPDATE_FAILED = 'ENTITY_UPDATE_FAILED'

export const ENTITY_DELETE_BEGIN = 'ENTITY_DELETE_BEGIN'
export const ENTITY_DELETE_SUCCEEDED = 'ENTITY_DELETE_SUCCEEDED'
export const ENTITY_DELETE_FAILED = 'ENTITY_DELETE_FAILED'

export const ENTITY_INVALIDATE = 'ENTITY_INVALIDATE'
export const ENTITIES_INVALIDATE = 'ENTITIES_INVALIDATE'
