import React, { memo } from 'react'
import { flowRight } from 'lodash'
import { DialogTitle } from '@material-ui/core'

import Typography from 'components/Typography'
import injectSheet from 'styles/jss'
import styles from './styles'

export default flowRight(
  memo,
  injectSheet(styles),
)(({ disableTypography, children, classes, ...rest }) => (
  <DialogTitle disableTypography classes={classes} {...rest}>
    {disableTypography ? children : <Typography type="title2">{children}</Typography>}
  </DialogTitle>
))
