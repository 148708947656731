import isLooselyEqual from 'lib/isLooselyEqual'
import { isEqual } from 'lodash'
import { mapEachAssociation, pickSchemaProperties, isEntityEqual, idForEntity, propertyLabel } from './index'

const isEntityLooselyEqual = (first, second) =>
  isLooselyEqual(first, second, { objectHash: obj => obj.id || obj.localId })

describe('mapEachAssociation', () => {
  it('maps each deeply nested association collection', () => {
    const entity = {
      $type: 'listing',
      jobs: [
        {
          $type: 'job',
          shifts: [
            {
              $type: 'shift',
            },
            {
              $type: 'shift',
            },
          ],
        },
      ],
    }
    const expected = {
      $type: 'listing',
      jobs: [
        {
          $type: 'job',
          shifts: [
            {
              $type: 'shift',
            },
            {
              $type: 'shift',
            },
            {
              path: 'jobs.0.shifts',
            },
          ],
        },
        {
          path: 'jobs',
        },
      ],
    }
    const mapped = mapEachAssociation(entity, (collection, path) => [...collection, { path }])
    expect(isEqual(mapped, expected)).toBe(true)
  })
})

describe('pickSchemaProperties', () => {
  it('removes fluff from the object', () => {
    const entity = { $type: 'shift', id: 1, startTime: 1, breakTime: 1, adventureTime: 9 }
    const expected = { $type: 'shift', id: 1, startTime: 1 }
    const picked = pickSchemaProperties(entity)
    expect(isEntityLooselyEqual(picked, expected)).toBe(true)
  })
})

describe('isEntityEqual', () => {
  it('returns false for entities of different type', () => {
    const first = { $type: 'shift' }
    const second = { $type: 'listing' }
    expect(isEntityEqual(first, second)).toBe(false)
  })

  it('compares nested associations', () => {
    const first = { $type: 'job', shifts: [{ $type: 'shift', id: 1 }] }
    const second = { $type: 'job', shifts: [{ $type: 'shift', id: 2 }] }
    expect(isEntityEqual(first, second)).toBe(false)
    const third = { $type: 'job', shifts: [{ $type: 'shift', id: 1 }] }
    expect(isEntityEqual(first, third)).toBe(true)
  })

  it('compares dates', () => {
    const first = { $type: 'shift', startTime: '2017-06-13T21:00:00.000+0100' }
    const second = { $type: 'shift', startTime: '2017-06-13T21:00:00.000+01:00' }
    expect(isEntityEqual(first, second)).toBe(true)
  })

  it('compares deep objects that are not associations', () => {
    const first = { $type: 'listing', uniform: { uuid: 'great' } }
    const second = { $type: 'listing', uniform: { uuid: 'great' } }
    expect(isEntityEqual(first, second)).toBe(true)
  })
})

describe('idForEntity', () => {
  it('serializes boolean values', () => {
    const customEntityIds = {
      animal: ['id', 'isMammal'],
    }
    const idProperties = entityType => customEntityIds[entityType]
    const id = idForEntity({ $type: 'animal', id: 1, isMammal: false }, { idProperties })
    expect(id).toBe('{"id":1,"isMammal":false}')
  })
})

describe('propertyLabel', () => {
  it('creates a label from the property key', () => {
    const label = propertyLabel({ key: 'first_name', desc: {} })
    expect(label).toEqual('First name')
  })

  it('creates a label from the description', () => {
    const label = propertyLabel({ desc: { description: 'Nama depan' }, key: 'first_name' })
    expect(label).toEqual('Nama depan')
  })
})
