import { useMemo } from 'react'
import { useFlexFlags } from '@indeed/flex-feature-flags'
import { useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { getAgencyId, getEmployerId, isLoggedIn } from 'selectors/auth'
import { serializeFeatures } from 'sagas/trackingActions'
import { getPlatformId } from '../../../selectors/platform'
import { properties } from '../constants'
import { getGlobalTrackingProperties } from '../utils/properties'

const useActiveFeatures = () => {
  const features = useFlexFlags()
  const transformedFeatures = useMemo(
    () =>
      features
        // feature could be enabled by evaluated to false for the specific entity
        // disabled features has null values so don't include disabled features
        ?.filter(({ value }) => value !== null)
        .reduce(
          (feat, { name, value }) => ({
            ...feat,
            [name]: !!value,
          }),
          {},
        ) || {},
    [features],
  )
  const flipperFeaturesOrLicenses = useSelector(state => state.config.features)

  return useMemo(
    () =>
      serializeFeatures({
        ...transformedFeatures,
        ...flipperFeaturesOrLicenses,
      }),
    [flipperFeaturesOrLicenses, transformedFeatures],
  )
}

const useUserSessionTrackingContext = () =>
  useSelector(state => {
    const userLoggedIn = isLoggedIn(state)
    const mainProfileType = state.auth.userData?.main_profile_type
    const userId = state.auth.userData?.id
    const agencyId = getAgencyId(state)
    const employerId = getEmployerId(state)
    const impersonatorId = state.auth.oauthData?.impersonatorId
    const platformId = getPlatformId(state)

    return userLoggedIn
      ? {
          id: userId,
          name: employerId ? 'Employer' : 'agency',
          [properties.employer_id]: employerId,
          [properties.agency_id]: agencyId,
          [properties.impersonating_id]: impersonatorId,
          [properties.main_profile_type]: mainProfileType,
          [properties.platform_id]: platformId,
        }
      : null
  }, isEqual)

export const useTrackingGlobalContext = () => {
  const features = useActiveFeatures()
  const user = useUserSessionTrackingContext()
  const global = useMemo(() => getGlobalTrackingProperties(), [])

  return useMemo(
    () => ({
      features,
      user,
      global,
    }),
    [features, global, user],
  )
}
