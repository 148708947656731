/* eslint-disable func-names */

import 'lib/regeneratorRuntime'
import { fork } from 'redux-saga/effects'
import { formActionSaga } from 'redux-form-saga'

import { watchForPersistRehydration } from './watchForPersistRehydration'
import { watchForApplyPlatformTheme } from './platform'
import respondToApiRequests from './api'
import { watchForRedirectTo } from './navigation'
import watchForUserIdle from 'sagas/userActivity'

/**
 * Root saga that yields all available sagas.
 */
export const rootSaga = function* root() {
  yield fork(watchForPersistRehydration)
  yield fork(formActionSaga)
  yield fork(respondToApiRequests)
  yield fork(watchForApplyPlatformTheme)
  yield fork(watchForRedirectTo)
  yield fork(watchForUserIdle)
}

/* eslint-enable */
