import {
  flatten,
  get,
  identity,
  includes,
  isEmpty,
  isEqual,
  isInteger,
  map,
  max,
  min,
  omit,
  some,
  sumBy,
  times,
  values,
} from 'lodash'
import { createSelector, createSelectorCreator, createStructuredSelector, defaultMemoize } from 'reselect'
import ApiCallError from 'lib/errors/ApiCallError'
import networkStatusEnum from './networkStatusEnum'

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

// These network states indicate that we have made a request for a query
// and received actual data and this data (entities) are now accessible
// from the store.
// `pageInfo.ids` key should normally be null when the page is not in these
// network states.
const networkStatesWithQueryResult = [
  networkStatusEnum.fetchMore,
  networkStatusEnum.refetch,
  networkStatusEnum.ready,
]

const getNow = createSelector(() => new Date())

const hasExpiredSelector = (lastUpdated, maxAge, now) => {
  if (!lastUpdated || !maxAge) {
    return null
  } else {
    const maxDate = lastUpdated + maxAge
    return maxDate > now * 1000
  }
}

/**
 * Returns a string that uniquely identifies a particular query.
 * @param {Object} query
 * @return {String} Unique string that identifies the filters
 */
export const keyForQuery = (query = {}) => JSON.stringify(omit(query, 'page'))

/**
 * @private
 * @param {Object} state Entity state
 * @param {Object} options.setKey
 * @returns {Object}
 */
export const getPaginationOrPage = (state, options = {}) => {
  const { setKey = keyForQuery(options.query) } = options
  return get(state.entitySets[setKey], 'pagination') || {}
}

/**
 * All information stored about the given query and page(s).
 *
 * @param {Object} state Entity state
 * @param {Number} options.page Page number
 * @param {Object} options.setKey
 * @returns {Object}
 */
export const getPageInfo = (state, options = {}) => {
  const { page } = options
  const paginationOrPage = getPaginationOrPage(state, options)
  return (page ? get(paginationOrPage, ['pages', page]) : paginationOrPage) || {}
}

/**
 * True the provided query (setKey) and page has been fetched, false otherwise.
 * Both `setKey` / `page` can be omitted, in that case we look at readiness
 * of all associated queries or pages.
 *
 * @param {Object} state Entity state
 * @param {Number} options.page Page number
 * @param {Object} options.setKey
 * @returns {Boolean}
 */
export const getHasData = (state, options = {}) => {
  const pageInfo = getPageInfo(state, options)
  return pageInfo.pages
    ? !isEmpty(pageInfo.pages) &&
        some(pageInfo.pages, inf => includes(networkStatesWithQueryResult, inf.networkStatus))
    : includes(networkStatesWithQueryResult, pageInfo.networkStatus)
}

/**
 * TODO: Docs
 */
export const getEntityMap = state => get(state, 'entityMap')

/**
 * TODO: Docs
 */
export const getEntityById = (state, { id }) => state.entityMap[id]

/**
 * TODO: Docs
 *
 * @param {*} options.id Entity identifier
 * @returns {{ networkStatus, lastError, lastStatus, lastUpdated }}
 */
export const getSingleEntityState = (state, { id }) =>
  state.entityMeta[id] || {
    networkStatus: networkStatusEnum.ready, // no request is in flight
    isSavingData: false,
    lastError: null,
    lastStatus: null,
    lastUpdated: null,
  }

/**
 * True if the single entity is being loaded
 *
 * @param {*} options.id Entity identifier
 * @param {*} options.refetchIsLoading when true, refetching counts as loading
 * @returns {Boolean}
 */
export const getEntityLoadingStatus = (state, { id, refetchIsLoading }) => {
  const networkStatus = getSingleEntityState(state, { id }).networkStatus
  const isLoadingThreshold = refetchIsLoading ? networkStatusEnum.refetch : networkStatusEnum.loading
  return networkStatus <= isLoadingThreshold
}

/**
 * True if the single entity is being saved
 *
 * @param {*} options.id Entity identifier
 * @returns {Boolean}
 */
export const getEntitySavingStatus = (state, { id }) => getSingleEntityState(state, { id }).isSavingData

// True if the page is being loaded for the first time (never had
// any data before for the given setKey + query)
// @private
const isInitiallyLoading = page => get(page, 'networkStatus') <= networkStatusEnum.fetchMore

/**
 * Returns true if any or the provided page is loading data, false otherwise.
 *
 * @param {Object} state Entity state
 * @param {Number} options.page Page number
 * @param {Object} options.setKey
 * @returns {Boolean}
 */
export const getLoadingStatus = (state, options = {}) => {
  const pageInfo = getPageInfo(state, options)
  return pageInfo.pages ? some(pageInfo.pages, isInitiallyLoading) : isInitiallyLoading(pageInfo)
}

/**
 * Network status for the given page / set
 *
 * @param {Object} state Entity state
 * @param {Number} options.page Page number
 * @param {Object} options.setKey
 * @returns {networkStatus}
 */
export const getNetworkStatus = (state, options = {}) => {
  const pageInfo = getPageInfo(state, options)
  return pageInfo.pages ? max(map(pageInfo.pages, 'networkStatus')) : get(pageInfo, 'networkStatus')
}

/**
 * @param {Object} state Entity collection state
 * @param {Number} options.page Page number
 * @param {Object} options.setKey
 * @returns {networkStatus}
 */
export const getLastUpdated = (state, options = {}) => {
  const pageInfo = getPageInfo(state, options)
  return pageInfo.pages ? max(map(pageInfo.pages, 'lastUpdated')) : get(pageInfo, 'lastUpdated')
}

/**
 * @param {Object} state Entity collection state
 * @param {Number} options.page Page number
 * @param {Object} options.setKey
 * @returns {networkStatus}
 */
export const getMaxAge = (state, options = {}) => {
  const pageInfo = getPageInfo(state, options)
  if (pageInfo.pages) {
    return min(map(pageInfo.pages, 'maxAge'))
  } else {
    return get(pageInfo, 'maxAge')
  }
}

/**
 * Returns memoized selectors for a given entity collection.
 *
 * Because most selectors are memoized, selector instances should be stored
 * per component or group of components. See application code for examples.
 *
 * (!) Make sure to use a set of selectors only with state for a given entity
 * for the correct memoization to take place
 *
 * networkStatus and lastUpdated are not included by default to prevent
 * rerenders on refetch
 */
export const makeCollectionSelectors = (options = {}) => {
  // Internal
  // `getPageIds` will compare the flattened IDs with lodash.isEqual
  const getUncachedPageIds = createSelector(
    [getPaginationOrPage, getPageInfo, getHasData],
    (paginationOrPage, pageInfo, hasData) => {
      // pageInfo.pages existence means we're selecting *all* pages instead
      // of a specific page.
      // If we loaded page no. 3 but not 1 or 2, we will pad the array of IDs
      // to contain null placeholders.
      if (pageInfo.pages) {
        const maxPageNo = max(
          map(pageInfo.pages, (page, pageNo) =>
            // Test for page.ids to include only pages that have data
            page.ids ? parseInt(pageNo, 10) : 0,
          ),
        )
        const pageIds = flatten(
          times(maxPageNo, idx => {
            // This relies on page.ids being null if we don't have canonical data
            // from the backend.
            return get(
              pageInfo.pages,
              [idx + 1, 'ids'],
              times(paginationOrPage.pageSize || 0, () => null),
            )
          }),
        )
        return hasData ? pageIds : null
      } else {
        return pageInfo.ids
      }
    },
  )

  /**
   * Returned value is strictly equal to any previous unless the list
   * of IDs for a given setKey / page has changed.
   *
   * The list of page IDs **may** contain nulls as placeholders for entities
   * (pages) that have not been loaded yet. This ensures that entity IDs will
   * never change their array index in entityList.
   *
   * @param {Object} state Entity state
   * @param {Number} options.page Page number
   * @param {Object} options.setKey
   * @returns {[Number|String|null]}
   */
  const getPageIds = createDeepEqualSelector(getUncachedPageIds, identity)

  /**
   * Sorted list of entities as returned from the API.
   * If pagination is in effect, returns a list of entities for any or the provided
   * setKey / page.
   *
   * If `page` is not provided, returns a list of entities for all pages. This list
   * **may** contain nulls as placeholders for entities (pages) that have not been
   * loaded yet. This ensures that entities will preserve their position (array index)
   * in entityList.
   *
   * Unlike `entityMap`, this selector preserves the entity order set in pagination.
   *
   * Returned value is strictly equal to any previous unless the list of IDs for
   * a given setKey / page has changed.
   *
   * TODO getEntityList for a given page will recalculate even if an item changes that's not
   * in the page.
   *
   * @param {Object} state Entity state
   * @param {Number} options.page Page number
   * @param {Object} options.setKey
   * @returns {Array?} - will only return the entries reminded on entityMap
   */
  const getEntityList = createSelector([getPageIds, getEntityMap], (pageIds, entityMap) =>
    map(pageIds, id => entityMap[id] || {}),
  )

  /**
   * The size of each page in the pagination for the given query.
   * If backend is not paginated, assume there's only 1 page
   * i.e. equal to totalCount.
   *
   * @param {Object} state Entity state
   * @param {Object} options.setKey
   * @returns {Number}
   */
  const getPageSize = (state, options = {}) => {
    const paginationOrPage = getPaginationOrPage(state, options)
    // If pagination
    if (typeof paginationOrPage.pageSize === 'number') {
      return paginationOrPage.pageSize
    }
    // If page
    else {
      return get(paginationOrPage.ids, 'length') || 0
    }
  }

  const hasEmptinessInfo = (state, options) =>
    includes(networkStatesWithQueryResult, getNetworkStatus(state, options))

  /**
   * True if the query is fetched and we know that the result is empty
   * False if the query is fetched and we know that the result is not empty
   * Null if the query is fetched but there is an error (we cannot decide emptiness)
   * Null if the query is not yet fetched, e.g. when the query is in flight (we cannot
   * decide emptiness)
   *
   * When using this selector in UI, you might want to sometimes write your condition
   * as `isEmpty !== false` istead of just `!!isEmpty`:
   * ```
   * // Disable button while loading or empty:
   * <SubmitButton disabled={ data.isEmpty !== false } />
   * // Don't show Onboarding while loading:
   * { collection.isEmpty && <Onboarding /> }
   * ```
   *
   * As a general rule we should try not to hide UI when isEmpty is null.
   *
   * @param {Object} state Entity state
   * @param {Number} options.page Page number
   * @param {Object} options.setKey
   * @returns {Boolean|null}
   */
  const getEmptyStatus = (state, options = {}) =>
    hasEmptinessInfo(state, options) ? isEmpty(getEntityList(state, options)) : null

  /**
   * Returns true if there's a next page.
   * Applicable both for standard and batch pagination.
   *
   * @param {Object} state Entity state
   * @param {Number} options.page Page number
   * @param {Object} options.setKey
   * @returns {Boolean}
   */
  const getHasNextPage = createSelector([getPageInfo, getPaginationOrPage], (pageInfo, paginationOrPage) => {
    const currentPageCount = pageInfo.pages && Object.keys(pageInfo.pages).length
    if (isInteger(paginationOrPage.totalPages)) {
      return currentPageCount < paginationOrPage.totalPages
    } else {
      const lastPage = get(pageInfo, ['pages', currentPageCount])
      return !!get(lastPage, 'nextPageUrl')
    }
  })

  /**
   * Total count gives a best approximation of a count all entities for a
   * given query, everywhere (backend and client). This is either the total
   * item count explicitly provided the backend (e.g. from response headers)
   * or a count approximated roughly from local cache length.

   * When the backend doesn't return pageInfo.totalCount but also informs us
   * that there is a next page (hasNextPage is true), we know for a fact that
   * totalCount is not lower than entityList.length (client-side count) but we
   * also know that it cannot possibly equal entityList.length – that would be untrue.
   * It must be higher. Therefore we arbitrarily increment the totalCount by +1.

   * In practice, this helps when building batch pagination UI. The batch pagination
   * component can use a difference between totalCount and client-side count
   * as an indicator that it has to fetch more pages.
   *
   * @param {Object} state Entity state
   * @param {Object} options.setKey
   * @returns {Number}
   */
  const getTotalCount = createSelector(
    [getPaginationOrPage, getHasNextPage, getPageSize],
    (paginationOrPage, hasNextPage, pageSize) => {
      // paginationOrPage selector can return either all pages (`pagination`)
      // or a just single `page`
      if (paginationOrPage.pages) {
        const pagination = paginationOrPage
        // pagination.totalCount is likely a flag return by the backend. It's thought
        // to be the canonical sum of all entities, everywhere.
        // TODO This doesn't include newly created entities on the client
        if (typeof pagination.totalCount === 'number') {
          return pagination.totalCount
        }
        // TODO We can also try to compute a rough sum using pageSize and totalPages
        else {
          // The backend indicates that there's a next page, therefore we don't have
          // all items.
          // TODO Why pageSize === 0 condition?
          const hasMoreItems = hasNextPage && pageSize === 0
          const sumOfCachedEntities = sumBy(values(pagination.pages), page => get(page.ids, 'length', 0))
          return sumOfCachedEntities + (hasMoreItems ? 1 : 0)
        }
      } else {
        const page = paginationOrPage
        return get(page.ids, 'length', 0)
      }
    },
  )

  /**
   * Returns true if the data has expired. See reducers/index
   *
   * @param {Object} state Entity state
   * @param {Number} options.page Page number
   * @param {Object} options.setKey
   * @returns {Boolean}
   */
  const getHasExpired = createSelector([getLastUpdated, getMaxAge, getNow], hasExpiredSelector)

  // TODO: Error should be query specific
  const getRawLastError = entityState => entityState.lastError

  // An error MUST exists when when networkStatus is 8 and vice versa
  const getLastError = createSelector([getNetworkStatus, getRawLastError], (networkStatus, rawLastError) => {
    return networkStatus === networkStatusEnum.error ? rawLastError || new ApiCallError() : null
  })

  return {
    getPageIds,
    getEntityList,
    all: createStructuredSelector({
      entityMap: getEntityMap,
      entityList: getEntityList,
      isLoading: getLoadingStatus,
      hasData: getHasData,
      hasNextPage: getHasNextPage,
      isEmpty: getEmptyStatus,
      isSaving: entityState => entityState.isSavingData,
      lastError: getLastError,
      pageSize: getPageSize,
      totalCount: getTotalCount,
      hasExpired: getHasExpired,
      maxAge: getMaxAge,
      ...(options.extended
        ? {
            networkStatus: getNetworkStatus,
            lastUpdated: getLastUpdated,
          }
        : {}),
    }),
  }
}

/**
 * Returns memoized selectors for a single entity.
 *
 * Analogous to `makeCollectionSelectors()`.
 *
 * (!) Make sure to call makeEntitySelectors
 *
 * networkStatus and lastUpdated are not included by default to prevent
 * rerenders on refetch
 */
export const makeEntitySelectors = (options = {}) => {
  const getHasEntityData = createSelector([getEntityById], entity => !!entity)

  const getEntityNetworkStatus = createSelector(
    [getSingleEntityState],
    singleEntityState => singleEntityState.networkStatus,
  )

  // An error MUST exists when when networkStatus is 8 and vice versa
  const getLastEntityError = createSelector([getSingleEntityState], singleEntityState =>
    singleEntityState.networkStatus === networkStatusEnum.error
      ? singleEntityState.lastError || new ApiCallError({})
      : null,
  )

  const getLastUpdated = createSelector(
    [getSingleEntityState],
    singleEntityState => singleEntityState.lastUpdated,
  )

  const getMaxAge = createSelector([getSingleEntityState], singleEntityState => singleEntityState.maxAge)

  const getHasExpired = createSelector([getLastUpdated, getMaxAge, getNow], hasExpiredSelector)

  return {
    all: createStructuredSelector({
      entity: getEntityById,
      hasData: getHasEntityData,
      isLoading: getEntityLoadingStatus,
      lastError: getLastEntityError,
      hasExpired: getHasExpired,
      maxAge: getMaxAge,
      ...(options.extended
        ? {
            networkStatus: getEntityNetworkStatus,
            lastUpdated: getLastUpdated,
          }
        : {}),
    }),
  }
}
