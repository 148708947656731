import React from 'react'

/**
 * Forwards ref as innerRef to wrapped component
 *
 * @param {function(=*)} hoc any HOC that may accept reference
 * @returns {function(*): React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const withForwardedRef = hoc => Component => {
  const WrappedComponent = hoc(Component)
  const ForwardedRefComponent = React.forwardRef((props, ref) => (
    <WrappedComponent {...props} {...(ref ? { innerRef: ref } : {})} />
  ))
  ForwardedRefComponent.displayName = WrappedComponent.displayName || Component.displayName
  return ForwardedRefComponent
}

export default withForwardedRef
