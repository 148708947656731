import React from 'react'

const ArrowUp = props => (
  <svg viewBox="0 0 16 16" {...props}>
    <title>{`arrow-up`}</title>
    <path
      d="M7 4.414L1.707 9.707.293 8.293 8 .586l7.707 7.707-1.414 1.414L9 4.414V16H7V4.414z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default ArrowUp
