import { styles as dialogActionsStyles } from '@material-ui/core/DialogActions/DialogActions'

import { gutterSize } from '../styles'

export default theme => {
  return {
    root: {
      ...dialogActionsStyles.root,
      margin: `${theme.spacing.unit}px ${gutterSize}px ${theme.spacing.unit * 3}px`,

      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing.unit,
      },
    },
    action: {
      ...dialogActionsStyles.action,
      '&:first-child': {
        marginLeft: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
    fixed: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        bottom: 0,
        right: 0,
      },
    },
  }
}
