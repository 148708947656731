import roleSchema from './role'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    id: {
      type: 'integer',
    },
    code: {
      type: 'string',
    },
    roles: {
      type: 'array',
      $rel: 'role',
      items: roleSchema,
    },
  },
}
