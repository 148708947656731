import shiftSchema from './shift'
import workerSchema from './worker'

const timesheetEntrySchema = {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    job_id: {
      type: 'integer',
    },
    role_id: {
      type: 'integer',
    },
    area_id: {
      type: 'integer',
    },
    editable: {
      type: 'boolean',
    },
    needs_approval: {
      type: 'boolean',
    },
    externally_created_shift_booking: {
      type: 'boolean',
    },
    clock_in: {
      type: 'object',
      properties: {
        time: {
          type: 'string',
        },
        editable: {
          type: 'boolean',
        },
        needs_approval: {
          type: 'boolean',
        },
        source: {
          type: 'string',
        },
        required: ['editable', 'needs_approval'],
      },
    },
    clock_out: {
      type: 'object',
      properties: {
        time: {
          type: 'string',
        },
        editable: {
          type: 'boolean',
        },
        needs_approval: {
          type: 'boolean',
        },
        source: {
          type: 'string',
        },
        reason: {
          type: 'string',
        },
        required: ['editable', 'needs_approval'],
      },
    },
    break: {
      type: 'object',
      properties: {
        duration: {
          type: 'integer',
        },
        editable: {
          type: 'boolean',
        },
        needs_approval: {
          type: 'boolean',
        },
        required: ['duration', 'editable', 'needs_approval'],
      },
    },
    shift: shiftSchema,
    worker: workerSchema,
    worker_status: {
      type: 'string',
    },
    no_show: {
      type: 'boolean',
    },
    no_show_reason: {
      comments: {
        type: 'string',
      },
      noShowReasonId: {
        type: 'integer',
      },
    },
  },
  required: [
    'job_id',
    'role_id',
    'area_id',
    'editable',
    'needs_approval',
    'clock_in',
    'clock_out',
    'break',
    'shift',
    'worker',
    'worker_status',
    'no_show',
    'externally_created_shift_booking',
  ],
}

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    entries: {
      type: 'array',
      $rel: 'entry',
      items: timesheetEntrySchema,
    },
  },
  required: ['entries'],
}
