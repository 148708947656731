/* eslint-disable func-names */
/* eslint-disable import/order */

import { takeLatest, put } from 'redux-saga/effects'

import * as types from 'actions/action-types'

// Refetch worker profile after creating / updating worker role
export function* refetchWorkerAfterRoleIsChanged(options) {
  const actions = [
    types.WORKER_ROLE_UPDATE_SUCCEEDED,
    types.WORKER_ROLE_CREATE_SUCCEEDED,
    types.WORKER_ROLE_DELETE_SUCCEEDED,
  ]
  yield takeLatest(actions, function* (action) {
    const workerId = action.meta.requestOptions.entity.workerId
    yield put({ type: types.WORKER_FETCH_BEGIN, payload: { id: workerId } })
  })
}
