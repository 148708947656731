import React from 'react'

const LeadChef = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <title>{`Lead chef`}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.95 29.751c-2.78-.272-4.95-2.58-4.95-5.386 0-2.99 2.462-5.413 5.5-5.413.626 0 1.227.103 1.787.293A7.526 7.526 0 0 1 31.5 16a7.526 7.526 0 0 1 6.213 3.245 5.573 5.573 0 0 1 1.787-.293c3.038 0 5.5 2.424 5.5 5.413 0 2.807-2.17 5.114-4.95 5.386v10.606h-17.1V29.751zm0 12.378h17.1V44.3a2.7 2.7 0 0 1-2.7 2.7h-11.7a2.7 2.7 0 0 1-2.7-2.7v-2.171z"
        fill="#003a9b"
      />
      <path d="M27 40.355V37M36 40.355V37M31.508 40.355V34" stroke="#FFF" />
    </g>
  </svg>
)

export default LeadChef
