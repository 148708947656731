import React from 'react'
import { uniqueId } from 'lodash'

const Steward = props => (
  <svg width={64} height={84} viewBox="0 0 64 84" {...props}>
    <title>{`Steward`}</title>
    <g id="steward" transform="translate(16.000000, 21.000000)" fill="#003a9b" fillRule="nonzero">
      <g transform="translate(0.205882, 0.789216)">
        <g id="Group">
          <path
            d="M26.3823529,5.6875 L20.8382353,5.6875 L20.8382353,9.84558824 L25.9203431,9.84558824 C26.1752451,9.84558824 26.3823529,10.0526961 26.3823529,10.307598 L26.3823529,35.7022059 C26.3823529,35.9571078 26.1752451,36.1642157 25.9203431,36.1642157 L4.66789216,36.1642157 C4.4129902,36.1642157 4.20588235,35.9571078 4.20588235,35.7022059 L4.20588235,10.307598 C4.20588235,10.0526961 4.4129902,9.84558824 4.66789216,9.84558824 L9.75,9.84558824 L9.75,5.6875 L4.20588235,5.6875 C1.91176471,5.6875 0.0477941176,7.55147059 0.0477941176,9.84558824 L0.0477941176,36.1642157 C0.0477941176,38.4583333 1.91176471,40.3223039 4.20588235,40.3223039 L26.3664216,40.3223039 C28.6605392,40.3223039 30.5246112,38.4583333 30.5246112,36.1642157 L30.5246112,9.84558824 C30.5404412,7.55147059 28.6764706,5.6875 26.3823529,5.6875 Z"
            id={uniqueId('Path')}
          />
          <path
            d="M16.6801471,0.143382353 L13.9080882,0.143382353 C12.3786765,0.143382353 11.1360294,1.38602941 11.1360294,2.91544118 L11.1360294,11.2316176 C11.1360294,12.7610294 12.3786765,14.0036765 13.9080882,14.0036765 L16.6801471,14.0036765 C18.2095588,14.0036765 19.4522059,12.7610294 19.4522059,11.2316176 L19.4522059,2.91544118 C19.4522059,1.38602941 18.2095588,0.143382353 16.6801471,0.143382353 Z M15.2941176,6.0379902 C14.3382353,6.0379902 13.557598,5.25735294 13.557598,4.30147059 C13.557598,3.34558824 14.3382353,2.56495098 15.2941176,2.56495098 C16.25,2.56495098 17.0306373,3.34558824 17.0306373,4.30147059 C17.0306373,5.25735294 16.25,6.0379902 15.2941176,6.0379902 Z"
            id={uniqueId('Shape')}
          />
        </g>
      </g>
      <path
        d="M11.75,20.75 C11.75,18.6776316 13.4276316,17 15.5,17 C17.5723684,17 19.25,18.6776316 19.25,20.75 C19.25,22.8223684 17.5723684,24.5 15.5,24.5 C13.4276316,24.5 11.75,22.8223684 11.75,20.75 Z M15.1412211,25.5 L15.8600053,25.5 C19.4750674,25.5 22.462955,27.7979323 22.9844259,31.237782 C23.0337542,31.5831767 23.0760357,33 21.2649812,33 C15.5428946,33 12.6677575,33 9.7432921,33 C7.9392845,33 7.974519,31.4915414 8.0097536,31.2448308 C8.5312245,27.7979323 11.526159,25.5 15.1412211,25.5 Z"
        id={uniqueId('Combined-Shape_5_')}
      />
    </g>
  </svg>
)

export default Steward
