import React from 'react'

const MessageBubbles = props => (
  <svg xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 113 114" {...props}>
    <defs>
      <path
        id="a"
        d="M59.935 100.22c-3.964 1.756-8.19 3.29-12.678 4.605-3.525 1.002-5.181 1.628-6.549.772-1.23-.769-.609-2.957 0-3.793 2.889-3.97 5.292-7.528 7.313-10.8A31.865 31.865 0 0 1 41 71c0-17.673 14.327-32 32-32 17.673 0 32 14.327 32 32 0 17.673-14.327 32-32 32-4.654 0-9.076-.993-13.065-2.78z"
      />
      <path
        id="b"
        d="M24.466 85.053c-4.683 2.25-9.753 4.174-15.209 5.772-3.525 1.002-5.181 1.628-6.549.772-1.23-.769-.609-2.957 0-3.793 3.535-4.859 6.343-9.1 8.614-12.957C4.278 66.904 0 56.452 0 45 0 20.147 20.147 0 45 0s45 20.147 45 45-20.147 45-45 45a44.808 44.808 0 0 1-20.534-4.947z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="matrix(-1 0 0 1 149 4)">
        <use fill="#F2F2F2" xlinkHref="#a" />
        <path
          stroke="#FFF"
          strokeWidth={4}
          d="M59.94 102.4c-3.812 1.646-7.853 3.094-12.136 4.348-.22.063-1.9.549-2.33.668-.813.225-1.412.37-1.964.463-1.519.259-2.733.12-3.863-.586-2.32-1.452-1.983-4.706-.556-6.666 2.494-3.428 4.643-6.572 6.499-9.507A33.854 33.854 0 0 1 39 71c0-18.778 15.222-34 34-34s34 15.222 34 34-15.222 34-34 34c-4.54 0-8.959-.892-13.06-2.6z"
        />
      </g>
      <g transform="translate(4 4)">
        <use fill="#F2F2F2" xlinkHref="#b" />
        <path
          stroke="#FFF"
          strokeWidth={4}
          d="M24.442 87.277c-4.533 2.118-9.408 3.94-14.638 5.471-.22.063-1.9.549-2.33.668-.813.225-1.412.37-1.964.463-1.519.259-2.733.12-3.863-.586-2.32-1.452-1.983-4.706-.556-6.666 3.086-4.241 5.644-8.05 7.775-11.569A46.812 46.812 0 0 1-2 45C-2 19.043 19.043-2 45-2s47 21.043 47 47-21.043 47-47 47a46.816 46.816 0 0 1-20.558-4.723z"
        />
      </g>
      <g fill="#FFF" transform="translate(24 45)">
        <circle cx={5} cy={5} r={5} />
        <circle cx={25} cy={5} r={5} />
        <circle cx={45} cy={5} r={5} />
      </g>
    </g>
  </svg>
)

export default MessageBubbles
