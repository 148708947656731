/**
 * Tree utility tools.
 */
import { identity, compact, map, get, flatMap } from 'lodash'

/**
 * Filters a tree.
 * For every matching node, all the ancestors of that node up to $root
 * are included in the returned tree.
 *
 * @param {Function} [filterFn=identity]
 * @param {Object} node the root of a tree
 * @returns a new tree
 */
export const filterTree =
  (filterFn = identity) =>
  node => {
    if (node == null || filterFn(node)) return node

    const filteredChildren = compact(map(node.children, filterTree(filterFn)))

    return get(filteredChildren, 'length')
      ? { ...node, children: filteredChildren, isPartialMatch: true }
      : null
  }

/**
 * Maps a tree, depth first.
 *
 * @param {Function} [mapFn=identity]
 * @param {Object} node the root of a tree
 * @returns a new tree
 */
export const mapTreeDF =
  (mapFn = identity) =>
  node => {
    return (
      node &&
      mapFn({
        ...node,
        ...(get(node, 'children') && { children: map(node.children, mapTreeDF(mapFn)) }),
      })
    )
  }

/**
 * Generates a map (object) of all nodes in a tree.
 *
 * @param {Object} node the ref to the root of the tree
 * @returns {Object} a map of all the elements in the tree
 */
export const createTreeMap = (node, idForNode) => {
  if (!node) return null

  const children = node.children || []

  return {
    [idForNode(node.value)]: node,
    ...children.reduce((acc, child) => ({ ...acc, ...createTreeMap(child, idForNode) }), {}),
  }
}

// Given a list of locationIds, expand the list to include all their children too
export const deepFlattenNodes = nodes =>
  flatMap(nodes, node => [node, ...deepFlattenNodes(get(node, 'children'))])

/**
 * Get all nodes up the tree, including the current node
 * Returns a list of nodes in the order specified
 *
 * @param {Node} node
 * @param {('bottom-top' | 'top-bottom')} order the hierarchy order of the returned value
 * @returns {Array} a list of nodes, including the input node
 *
 * @example
 * // returns [grandchildren, child, me]
 * getAllAncestors(grandchildren, 'bottom-top')
 */
export const getAllAncestors = (node, order) => {
  if (!node.parent) {
    return [node]
  }
  return order === 'bottom-top'
    ? [node, ...getAllAncestors(node.parent, order)]
    : [...getAllAncestors(node.parent, order), node]
}
