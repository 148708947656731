/* eslint-disable func-names */
/* eslint-disable import/order */

import { map, flatten, pick, isInteger, get } from 'lodash'
import { call, put, all } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityApiRequest } from 'api'
import { entityCall } from './entityCall'

// GET /listings/:listing_id/ratings returns an array of { jobId: ...,
// workerRatings: [{ stars }] }. We'll translate this to individual worker ratings
// per jobId and workerId (as per schema) plus team ratings (jobId without workerId)
const normalizeResponsePayload = payload => {
  const jobRatings = payload
  const workerRatings = flatten(
    map(jobRatings, jobRating =>
      map(jobRating.workerRatings, ({ worker, ...rating }) => ({
        ...rating,
        jobId: jobRating.jobId,
        workerId: worker.id,
      })),
    ),
  )
  const teamRatings = map(jobRatings, jobRating => pick(jobRating, 'jobId', 'stars', 'skillIds'))
  return teamRatings.concat(workerRatings)
}

// Extract individual worker ratings from GET /listings/:listing_id/ratings
export function* fetchRatings(options) {
  yield takeLatestDeep(types.RATINGS_FETCH_BEGIN, function* (action) {
    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.list,
        entityType: 'rating',
        context: options.context,
        normalizePayload: normalizeResponsePayload,
      },
      action,
    )
  })
}

// Bulk update ratings
// TODO Unused?
export function* updateRatings(options) {
  yield takeLatestDeep(types.RATINGS_UPDATE_BEGIN, function* (action) {
    const [payload, compoundMeta] = yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.update,
        entityType: 'rating',
        context: options.context,
      },
      action,
    )

    if (!payload.error) {
      yield all(
        map(action.payload, rating => {
          const meta = {
            ...compoundMeta,
            requestOptions: {
              ...compoundMeta.requestOptions,
              entity: rating,
            },
          }

          return put({ type: types.RATING_UPDATE_SUCCEEDED, payload: rating, meta })
        }),
      )
    } else {
      yield put({ type: types.RATING_UPDATE_FAILED, payload, compoundMeta })
    }
  })
}

// Refresh ratings
// E.g. updating team rating can affect individual worker ratings
export function* refetchAfterChangingRatings() {
  const successTypes = [types.RATING_UPDATE_SUCCEEDED, types.RATING_CREATE_SUCCEEDED]
  yield takeLatestDeep(successTypes, function* (action) {
    const listingId = get(action.meta, 'requestOptions.entity.listingId')
    // TODO: can we only refecth a job rather than an entire listing?
    if (isInteger(listingId)) {
      return yield put({ type: types.RATINGS_FETCH_BEGIN, payload: { listingId } })
    } else if (process.env.NODE_ENV !== 'production') {
      console.warn('Missing listingId in ratings update call. Ratings are not refreshed on success.')
    }
  })
}
