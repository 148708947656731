import React from 'react'

const IncreasePayAlert = props => (
  <svg width={26} height={25} viewBox="0 0 26 25" {...props}>
    <g>
      <path d="M19 0v7.273h-1.455V2.532l-5.818 6.544-2.909-1.909-4.363 3.371-1.091-1.09 5.454-4.463 2.91 1.91 4.817-5.416-.025-.024h-4.793V0H19z" />
      <g>
        <path d="M11 10H22V14H11z" transform="translate(0 7) rotate(90 16.5 12)" />
        <path d="M15 7H32V11H15z" transform="translate(0 7) rotate(90 23.5 9)" />
        <path d="M0.5 13.5L4.5 13.5 4.5 17.5 0.5 17.5z" transform="translate(0 7) rotate(90 2.5 15.5)" />
        <path d="M6 12H13V16H6z" transform="translate(0 7) rotate(90 9.5 14)" />
      </g>
    </g>
  </svg>
)

export default IncreasePayAlert
