import React from 'react'

const ForkliftOperator = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Forklift operator`}</title>
    <path
      d="M71.001 94H51.28c-.192 3.474-3.098 6.244-6.639 6.244-3.668 0-6.649-2.967-6.649-6.61 0-3.433 2.64-6.26 6.009-6.581V38h27l15 34V38h6v52h16v4H84.279c-.192 3.474-3.098 6.244-6.639 6.244-3.544 0-6.447-2.77-6.639-6.244zM48 69h14c1.624.152 2.983 1.515 3 3v4h18.5L69 42H48v27zm8.064-15L58 62.941h7V67H54.826L52 54h4.064z"
      fill="#003a9b"
      fillRule="nonzero"
    />
  </svg>
)

export default ForkliftOperator
