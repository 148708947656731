import { setHsl } from 'styles/colorManipulator'

const closeButtonSize = 20
export const gutterSize = 32
export const topMargin = 32

export default ({ palette, transitions, breakpoints, spacing }) => ({
  root: {
    '& .MuiDialog-container > .MuiDialog-paperWidthSm': {
      maxWidth: 350,
    },
    '& .MuiDialog-container > .MuiDialog-paperWidthMd': {
      maxWidth: 520,
    },
    '& .MuiDialog-container > .MuiDialog-paperWidthLg': {
      maxWidth: 940,
      [breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
  },

  paper: {
    [breakpoints.down('sm')]: {
      width: '100%',
      maxHeight: '100vh',
    },
    borderRadius: 10,
  },

  closeButton: {
    position: 'absolute',
    top: topMargin - 12, // visually aligned
    right: gutterSize - 2, // visually aligned
    width: closeButtonSize,
    height: closeButtonSize,
    transition: transitions.create(['color'], {
      duration: transitions.duration.short,
    }),
    color: setHsl(palette.divider, { l: 0.8 }),

    '& .MuiSvgIcon-root': {
      width: closeButtonSize,
      height: closeButtonSize,
    },

    '&:hover': {
      color: setHsl(palette.divider, { l: 0.5 }),
    },

    '&:active': {
      color: setHsl(palette.divider, { l: 0.4 }),
    },

    [breakpoints.down('sm')]: {
      top: 0,
    },
  },
})
