import { TypographyProps as MuiTypographyProps } from '@material-ui/core/Typography'

export const TYPOGRAPHY_EXTENDED_VARIANTS = [
  // TODO Used, comment when
  'subheading',
  // TODO Comment. Big body1
  'body3',
  // TODO Special use case, remove
  'title2',
  // TODO Page title style, swap with `title`
  'title3',
  // TODO Small title, special use case?
  'title4',
  'title4ghost',
  'titleCaption',
  'fieldLabel',
  // TODO Special use case, remove
  'newSectionTitle',
  // Big caption
  'caption2',
  // Big caption darker color
  'caption2dark',
] as const

export const TYPOGRAPHY_EXTENDED_COLORS = ['success', 'warning', 'accent', 'error'] as const

export const TYPOGRAPHY_EXTENDED_VARIANTS_MAPPING: Record<string, MuiTypographyProps['variant']> = {
  title4: 'h4',
} as const
