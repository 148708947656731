import React from 'react'

const Receptionist = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Receptionist`}</title>
    <g fill="#003a9b" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M77.348 102.97a2 2 0 1 1-.696-3.94C83.803 97.77 90 89.59 90 82V72a2 2 0 1 1 4 0v10c0 9.452-7.503 19.355-16.652 20.97z" />
        <path d="M78 101.5c0 3.589-2.911 6.5-6.5 6.5a6.502 6.502 0 0 1-6.5-6.5c0-3.589 2.911-6.5 6.5-6.5s6.5 2.911 6.5 6.5zM48 60h-4v-4.402C44 41.277 54.943 30 69 30c14.055 0 25 11.278 25 25.598V60h-4v-4.402C90 43.461 80.819 34 69 34c-11.82 0-21 9.46-21 21.598V60z" />
      </g>
      <path d="M90 56h1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15h-1V56zM48 86h-1c-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15h1v30z" />
    </g>
  </svg>
)

export default Receptionist
