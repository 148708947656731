export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    bookings: {
      items: {
        type: 'object',
        properties: {
          worker_id: {
            $rel: 'worker',
            type: 'integer',
          },
          job_id: {
            $rel: 'job',
            type: 'integer',
          },
          shift_ids: {
            type: 'array',
            items: {
              $rel: 'shift',
              type: 'integer',
            },
          },
        },
      },
      description: 'Bookings',
      type: 'array',
    },
  },
  required: ['job_id', 'body'],
  type: 'object',
}
