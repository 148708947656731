import { userProperties } from 'lib/analytics/googleAnalytics/constants'

const pattern = /(venue|worker|job|employer)(Id)?s?[=/](\d+)/gi

const toId = text => `${text.replace(/(s)$/, '').replace(/(Id$)/, '').toUpperCase()}_ID`

const extractAsArray = pathname => {
  const arr = []
  let m
  while ((m = pattern.exec(pathname)) !== null) {
    const [, key, , value] = m
    arr.push([toId(key), value])
  }
  return arr
}

export const extractDimensions = pathname =>
  extractAsArray(pathname).reduce((prev, item) => {
    const [k, v] = item
    return { ...prev, ...{ [k]: v } }
  }, {})

const isValidProperty = name => userProperties && userProperties[name]

export const extractDimensionsGA = pathname =>
  extractAsArray(pathname)
    .filter(item => isValidProperty(item[0]))
    .reduce((prev, item) => {
      const [k, v] = item
      return { ...prev, ...{ [userProperties[k]]: v } }
    }, {})
