import React from 'react'

const Loader = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Loader`}</title>
    <path
      d="M54.012 89.432A6.652 6.652 0 0 1 58.098 94h27.906a2 2 0 0 1 1.415.586l5.022 5.026a2 2 0 0 1-2.83 2.827L85.175 98H57.848a6.661 6.661 0 0 1-6.2 4.222c-3.667 0-6.648-2.967-6.648-6.61 0-3.086 2.134-5.683 5.012-6.41V37H42a2 2 0 0 1 0-4h10.012a2 2 0 0 1 2 2v54.432zM60 61h30a2 2 0 0 1 2 2v25a2 2 0 0 1-2 2H60a2 2 0 0 1-2-2V63a2 2 0 0 1 2-2zm0-20h22a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H60a2 2 0 0 1-2-2V43a2 2 0 0 1 2-2z"
      fill="#003a9b"
      fillRule="nonzero"
    />
  </svg>
)

export default Loader
