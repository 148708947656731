import React from 'react'

const Cleaner = props => (
  <svg width={65} height={65} viewBox="0 0 65 65" {...props}>
    <title>{`Cleaner`}</title>
    <g fill="none" fillRule="evenodd">
      <path
        d="M22.512 8.712L21.9 2.82a.96.96 0 0 0-.964-.846h-.632V-.761h-3.875v2.736l-.39.003a1.455 1.455 0 0 0-1.398 1.04l-1.54 5.694c.09.012 3.227.012 9.41 0z"
        fill="#003a9b"
        mask="url(#mask-2)"
        transform="translate(11 21.468)"
      />
      <path
        d="M40.355 20.555c-2.3-.226-4.354 1.403-4.587 3.639l-.624 5.985h9.868l.56-5.02c.233-2.236-1.443-4.232-3.743-4.46l-1.474-.144z"
        fill="#003a9b"
      />
      <path
        fill="#003a9b"
        d="M39.15 20.82l4.052.456.38-3.184-4.053-.457zM30.344 16.542l2.186 1.616c.619.457.982.612.982 1.368v.328c0 1.335-1.114 2.418-2.488 2.418h-4.949l-2.514-2.596h-3.587v-3.134h10.37"
      />
      <path
        d="M23.771 19.725s-.108 1.893-.675 2.847c-.568.954-1.613 1.758-1.613 1.758l.865.815s2.673-1.049 3.057-3.732l-1.634-1.688"
        fill="#003a9b"
      />
      <g transform="translate(13 32)" fill="#003a9b">
        <rect width={41} height={3.923} rx={1.962} />
        <path d="M3 3h35.794L35.54 16.077H6.254z" />
      </g>
    </g>
  </svg>
)

export default Cleaner
