import React from 'react'

const Letter = props => (
  <svg viewBox="0 0 20 20" {...props}>
    <path
      fill="#003a9b"
      fillRule="evenodd"
      d="M19.378 11.046L1.636 19.881c-.83.418-1.797-.326-1.613-1.256l1.474-7.253 11.06-1.163c.323-.046.323-.372 0-.418L1.498 8.628.023 1.375C-.161.445.806-.3 1.636.119l17.742 8.835c.83.465.83 1.674 0 2.092z"
    />
  </svg>
)

export default Letter
