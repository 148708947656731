export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  meta: {
    labelProperty: 'title',
  },
  properties: {
    noShowReasonId: {
      type: 'integer',
    },
    comments: {
      type: 'string',
      description: 'Comments (optional)',
    },
  },
  required: ['noShowReasonId'],
}
