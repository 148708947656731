import { createSelector } from 'reselect'
import { get, pick } from 'lodash'

export const getPlatform = state => state.platform

export const getPlatformId = createSelector([getPlatform], platform => get(platform, 'id'))

export const getPlatformVisibility = createSelector([getPlatform], platform => get(platform, 'visible', true))

export const getInternalResourcingStatus = state => get(state, 'platform.internalResourcing') === true

export const getSupportDetails = state => get(state, 'platform.supportDetails')

export const getPlatformBrand = createSelector([getPlatform], platform => {
  const {
    key,
    platformName: name,
    internalResourcingPlatformName: internalResourcingName,
    url,
    links,
    platformWorkersName: workersName,
    assetPath,
    countryCode,
    currency,
    locale,
  } = pick(platform, [
    'key',
    'platformName',
    'internalResourcingPlatformName',
    'url',
    'links',
    'platformWorkersName',
    'assetPath',
    'countryCode',
    'currency',
    'locale',
  ])

  return {
    key,
    name,
    internalResourcingName,
    url,
    links,
    workersName,
    assetPath,
    countryCode,
    currency,
    locale,
  }
})

export const getLogo = createSelector([getPlatform], platform => get(platform, 'theme.logo'))

export const getPalette = createSelector([getPlatform], platform => get(platform, 'theme.palette', {}))
