import { initial, last, compact, flatten } from 'lodash'
import pluralize from 'pluralize'

// Pluralize "Team leader" as "Team leaders"
// ('pluralize' method accepts one word only)
export function pluralizePhrase(phrase, ...args) {
  const words = phrase.split(' ')
  const lastWord = pluralize(last(words), ...args)
  return compact(flatten([initial(words), lastWord])).join(' ')
}

// Singularize "Team leader" as "Team leaders"
// ('pluralize.singular' method accepts one word only)
export function singularizePhrase(phrase, ...args) {
  const words = phrase.split(' ')
  const lastWord = pluralize.singular(last(words), ...args)
  return compact(flatten([initial(words), lastWord])).join(' ')
}
