import { put, call, takeLatest } from 'redux-saga/effects'
import { push, replace } from 'connected-react-router'
import * as types from 'actions/action-types'

export function* redirectTo(pageUrl) {
  yield put(push(pageUrl))
}

export function* replaceTo(pageUrl) {
  yield put(replace(pageUrl))
}

export function* redirectToHandler({ payload }) {
  yield call(redirectTo, payload)
}

export function* watchForRedirectTo() {
  yield takeLatest(types.NAVIGATION_REDIRECT, redirectToHandler)
}

export function* redirectToExternal(url) {
  yield call([window.location, window.location.assign], url)
}
