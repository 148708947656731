export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    name: {
      type: 'string',
    },
    desc: {
      type: 'string',
    },
    arrival_minutes: {
      type: 'integer',
    },
    access_instructions: {
      type: 'string',
    },
  },
  required: ['id', 'name', 'desc', 'arrival_minutes', 'access_instructions'],
}
