import React from 'react'

const Hostess = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Hostess`}</title>
    <path
      d="M69.055 31.024c2.332-.11 4.74.146 6.875 1.148 1.705.79 3.158 2.086 4.197 3.649.127.222.364.332.577.457 1.7.996 3.018 2.588 3.747 4.414 1.043 2.574 1.206 5.39 1.322 8.133.135 2.788.112 5.605.685 8.35.382 1.824 1.093 3.633 2.36 5.03.522.591 1.145 1.08 1.802 1.515-1.096 1.6-2.56 2.911-4.127 4.038-3.268 2.325-7.038 3.895-10.946 4.766.384 1.828 1.016 3.616 1.993 5.213 1.268 2.09 3.128 2.78 5.226 4.016 3.695 2.21 7.942 3.188 11.83 4.976 2.094.971 4.125 2.276 5.459 4.203.502.699.917 1.75 1.246 3.154a4 4 0 0 1-3.895 4.912H40.58a4 4 0 0 1-3.897-4.902c.31-1.338.699-2.345 1.168-3.02 1.328-2.005 3.41-3.354 5.557-4.349 3.985-1.83 8.352-2.819 12.11-5.143 2.146-1.319 4.022-2.132 5.236-4.354.815-1.464 1.354-3.07 1.7-4.706-3.909-.87-7.68-2.443-10.95-4.769-1.565-1.126-3.028-2.436-4.122-4.034a8.702 8.702 0 0 0 3.33-3.908c.958-2.194 1.216-4.613 1.342-6.98.192-3.001.112-6.03.623-9.004.337-1.948.975-3.902 2.222-5.465a8.922 8.922 0 0 1 2.623-2.217c.232-.11.36-.339.502-.54a10.26 10.26 0 0 1 5.274-3.922c1.85-.614 3.822-.748 5.758-.66z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default Hostess
