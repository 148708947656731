import React from 'react'

const WarehousingOperative = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Warehousing operative`}</title>
    <path
      d="M104.003 79H106a4 4 0 1 1 0 8H32a4 4 0 1 1 0-8h1.997a63.32 63.32 0 0 1 .024-2.387c1.61-13.595 10.077-24.575 22.005-29.219v10.607a1.999 1.999 0 1 0 3.998 0V44a2 2 0 0 1 2-2h4.996v6.001a1.999 1.999 0 1 0 3.997 0V42h4.996a2 2 0 0 1 2 2v14.001a1.999 1.999 0 1 0 3.998 0V47.404c11.917 4.647 20.37 15.614 21.969 29.209.018.452.026 1.248.023 2.387z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default WarehousingOperative
