import React from 'react'
import { uniqueId } from 'lodash'

const OnlineOrderAssistant = props => (
  <svg width={65} height={65} viewBox="0 0 65 65" {...props}>
    <title>{`Online order assistant`}</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="schwifty" transform="translate(-681.000000, -5274.000000)">
        <g id="Online-order-assistant" transform="translate(681.000000, 5274.000000)">
          <g id="Group-56" transform="translate(10.000000, 12.000000)" fill="#003a9b">
            <path
              d="M37.7616102,28.3157895 C38.3081849,28.3157895 38.8309464,28.5394832 39.2083382,28.9348557 L43.6795205,33.6190662 C44.4421892,34.418072 44.4127321,35.6840593 43.6137263,36.446728 C43.2416796,36.8018548 42.7471211,37 42.2327925,37 L2.67390389,37 C1.5693344,37 0.673903895,36.1045695 0.673903895,35 C0.673903895,34.4856715 0.872049072,33.991113 1.22717591,33.6190662 L5.69835822,28.9348557 C6.07574997,28.5394832 6.59851149,28.3157895 7.14508621,28.3157895 L37.7616102,28.3157895 Z M12.2769039,5.736 L9.94712469,5.73684211 C8.8927629,5.73684211 8.02895957,6.5527199 7.95261043,7.58757976 L7.94712469,7.73684211 L7.94712469,22.8421053 C7.94712469,23.8964671 8.76300248,24.7602704 9.79786235,24.8366195 L9.94712469,24.8421053 L34.9595717,24.8421053 C36.0139335,24.8421053 36.8777368,24.0262275 36.954086,22.9913676 L36.9595717,22.8421053 L36.9595717,7.73684211 C36.9595717,6.63227261 36.0641412,5.73684211 34.9595717,5.73684211 L33.2749039,5.736 L32.7231764,4 L36.6174258,4 C37.7219953,4 38.6174258,4.8954305 38.6174258,6 L38.6174258,24.5789474 C38.6174258,25.6835169 37.7219953,26.5789474 36.6174258,26.5789474 L8.28927058,26.5789474 C7.18470108,26.5789474 6.28927058,25.6835169 6.28927058,24.5789474 L6.28927058,6 C6.28927058,4.8954305 7.18470108,4 8.28927058,4 L12.8289271,4 L12.2769039,5.736 Z"
              id={uniqueId('Combined-Shape')}
            />
            <g id="Group-54" transform="translate(14.000000, 0.000000)">
              <path
                d="M16.9615385,8.5 C17.5350649,8.5 18,8.96493507 18,9.53846154 C18,10.111988 17.5350649,10.5769231 16.9615385,10.5769231 L16.7142857,10.5769231 L15.3726708,17.5 L2.62732919,17.5 L1.28571429,10.5769231 L1.03846154,10.5769231 C0.464935067,10.5769231 7.02367357e-17,10.111988 0,9.53846154 C-7.02367357e-17,8.96493507 0.464935067,8.5 1.03846154,8.5 L16.9615385,8.5 Z M9,11.2692308 C8.6449598,11.2692308 8.35714286,11.5570477 8.35714286,11.9120879 L8.35714286,11.9120879 L8.35714286,15.4725275 C8.35714286,15.8275677 8.6449598,16.1153846 9,16.1153846 C9.3550402,16.1153846 9.64285714,15.8275677 9.64285714,15.4725275 L9.64285714,15.4725275 L9.64285714,11.9120879 C9.64285714,11.5570477 9.3550402,11.2692308 9,11.2692308 Z M5.78571429,11.2692308 C5.43067409,11.2692308 5.14285714,11.5570477 5.14285714,11.9120879 L5.14285714,11.9120879 L5.14285714,15.4725275 C5.14285714,15.8275677 5.43067409,16.1153846 5.78571429,16.1153846 C6.14075448,16.1153846 6.42857143,15.8275677 6.42857143,15.4725275 L6.42857143,15.4725275 L6.42857143,11.9120879 C6.42857143,11.5570477 6.14075448,11.2692308 5.78571429,11.2692308 Z M12.2142857,11.2692308 C11.8592455,11.2692308 11.5714286,11.5570477 11.5714286,11.9120879 L11.5714286,11.9120879 L11.5714286,15.4725275 C11.5714286,15.8275677 11.8592455,16.1153846 12.2142857,16.1153846 C12.5693259,16.1153846 12.8571429,15.8275677 12.8571429,15.4725275 L12.8571429,15.4725275 L12.8571429,11.9120879 C12.8571429,11.5570477 12.5693259,11.2692308 12.2142857,11.2692308 Z"
                id={uniqueId('Combined-Shape')}
              />
              <g id="Group-48" transform="translate(2.000000, 0.500000)">
                <path
                  d="M7.84210526,0 C8.2826181,-1.69474398e-16 8.65660422,0.284834716 8.78993701,0.680377527 L10.1871367,0.680555556 C11.6104356,0.680555556 12.8378289,1.6806529 13.125306,3.07461731 L13.8947368,6.80555556 L12.6315789,6.80555556 L11.7690348,2.82965529 C11.6775794,2.40809133 11.328039,2.0972653 10.9079466,2.04838849 L10.7917676,2.04166667 L8.78993701,2.0418447 C8.65660422,2.43738751 8.2826181,2.72222222 7.84210526,2.72222222 L5.42105263,2.72222222 C4.9805398,2.72222222 4.60655367,2.43738751 4.47322088,2.0418447 L3.10296922,2.04166667 C2.67159901,2.04166667 2.29373239,2.31736237 2.15690231,2.71754248 L2.12570202,2.82965529 L1.26315789,6.80555556 L0,6.80555556 L0.769430873,3.07461731 C1.05690796,1.6806529 2.28430124,0.680555556 3.70760017,0.680555556 L4.47322088,0.680377527 C4.60655367,0.284834716 4.9805398,8.09208952e-17 5.42105263,0 L7.84210526,0 Z"
                  id={uniqueId('Combined-Shape')}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default OnlineOrderAssistant
