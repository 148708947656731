import React from 'react'

const UserCard = props => (
  <svg width={37} height={28} fill="none" {...props}>
    <rect x={1.5} y={0.5} width={34} height={27} rx={2.5} fill="#fff" />
    <rect x={1.5} y={0.5} width={34} height={27} rx={2.5} stroke="#A8A8A8" />
    <path fill="#000" d="M18.5 9.787H30v1.15H18.5zm0 3.737H30v1.15H18.5zm0 3.739H30v1.15H18.5z" />
    <circle cx={11.025} cy={11.915} r={2.415} fill="#000" />
    <mask id="a" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x={7} y={14} width={9} height={9}>
      <circle cx={11.025} cy={18.355} r={4.025} fill="#000" />
    </mask>
    <g mask="url(#a)">
      <path fill="#000" d="M4.987 12.317H15.05v6.037H4.987z" />
    </g>
  </svg>
)

export default UserCard
