export default ({ typography, palette }) => {
  const subheading = {
    ...typography.body1,
    textTransform: 'uppercase',
    fontSize: typography.pxToRem(14),
    fontWeight: typography.fontWeightMedium,
    letterSpacing: '0.05em',
    marginBottom: '1em',
    marginTop: '1em',
  }

  return {
    subheading,
    titleCaption: {
      ...typography.body1,
      marginTop: 16,
    },
    fieldLabel: {
      ...typography.body1,
      fontSize: typography.pxToRem(16),
      color: palette.text.secondary,
      marginBottom: 14,
    },
    title4: {
      ...typography.body1,
      fontSize: typography.pxToRem(18),
      fontWeight: typography.fontWeightMedium,
      letterSpacing: '-0.4px',
      textAlign: 'left',
      '&$withMargin': {
        marginBottom: '0.5em',
      },
    },
    title4ghost: {
      ...typography.body1,
      fontSize: typography.pxToRem(18),
      fontWeight: typography.fontWeightMedium,
      letterSpacing: '-0.4px',
      textAlign: 'left',
      '&$withMargin': {
        marginBottom: '0.5em',
      },
      color: (palette.colors || {}).middleGrey,
    },
    title3: {
      ...typography.body1,
      fontSize: typography.pxToRem(32),
      fontWeight: typography.fontWeightBold,
      lineHeight: 1.25,
      letterSpacing: '-0.4px',
    },
    title2: {
      ...typography.body1,
      fontSize: typography.pxToRem(22),
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.015em',
      lineHeight: '1.33',
    },
    newSectionTitle: {
      ...typography.body1,
      color: 'inherit',
      fontSize: typography.pxToRem(19),
      fontWeight: typography.fontWeightMedium,
      letterSpacing: '-0.4px', // TODO Replace px value
      textAlign: 'left',
    },
    body3: {
      ...typography.body1,
      color: '#7e7e7e', // TODO Extract color
      lineHeight: '1.33',
      fontWeight: typography.fontWeightLight,
    },
    inheritColor: {
      color: 'inherit',
    },
    body1: {
      '&$big': {
        fontSize: typography.pxToRem(16),
      },
    },
    body2: {
      '&$big': {
        fontSize: typography.pxToRem(16),
      },
    },
    caption2: {
      ...typography.caption,
      fontSize: typography.pxToRem(14),
    },
    caption2dark: {
      ...typography.body1,
      fontSize: typography.pxToRem(14),
    },
    big: {},
    withMargin: {},
    noMargin: {
      margin: 0,
    },
    wrapNewlines: {
      whiteSpace: 'pre-wrap',
    },
    noEllipsis: {},
    noWrap: {
      whiteSpace: 'nowrap',
      '&:not($noEllipsis)': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    colorSuccess: {
      color: palette.text.success,
    },
    colorWarning: {
      color: palette.text.warning,
    },
    colorError: {
      color: palette.text.error,
    },
    colorAccent: {
      color: palette.text.accent,
    },
  }
}
