/* eslint-disable func-names */
/* eslint-disable import/order */

import { put, takeEvery, select, call, all } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { markAllNotificationsAsRead as markAllNotificationsAsReadApi } from 'api'
import { getEntityMap } from 'lib/redux-crud/selectors'
import { currentUser } from 'selectors/auth'
import { putFailureAction } from './entityCall'
import { get } from 'lodash'

// Marking all existing notifications as read.
//
// Watch for NOTIFICATION_UPDATE_BEGIN with `payload.id === '[all]'.
// When such action is received we use a custom endpoint to mark all notifications
// as read. On success we manuallly update `read` property for each entity we've stored.
//
// Notes:
// Ideally we'd like tell redux-crud and entityForm to query all available
// notifications and change `read` to `true` (in SQL this would be
// `UPDATE notifications SET read = 1`). Currently, entityForm consumes
// an entity (or group of) instead of a query so this is not yet possible.
// Ideal interface might look like this:
// ```
// entityForm({
//   // Predicates
//   mapPropsToQuery: () => ({ $type: 'notification' }),
//   // Update statement
//   update: () => ({ read: true }),
// })
// ```
export function* markAllAgencyNotificationsAsRead(action) {
  try {
    const user = yield select(currentUser)
    // isAgency true
    yield call(markAllNotificationsAsReadApi, get(user, 'agencyId'), true)
    yield put({
      type: types.AGENCY_NOTIFICATION_MARK_ALL_AS_READ_SUCCEEDED,
    })

    const entityMap = yield select(rootState => getEntityMap(rootState.agencyNotifications))
    const ids = Object.keys(entityMap)

    yield all(
      ids.map(id =>
        put({
          type: types.AGENCY_NOTIFICATION_UPDATE_BEGIN,
          payload: { read: true, id: parseInt(id, 10) },
        }),
      ),
    )
  } catch (e) {
    return yield putFailureAction({
      type: types.AGENCY_NOTIFICATION_MARK_ALL_AS_READ_FAILED,
      error: e,
      beginAction: action,
    })
  }
}

export function* watchForMarkAllAgencyNotificationsAsRead() {
  yield takeEvery(types.AGENCY_NOTIFICATION_MARK_ALL_AS_READ_BEGIN, markAllAgencyNotificationsAsRead)
}
