import { call } from 'redux-saga/effects'
import { entityActionRequest } from 'lib/redux-crud/entityActionRequest'
import { putFailureAction as putFailureActionOrig } from './errorMessage'

// Deprecated.
export function* entityCall(requestFn, options, action) {
  const finalAction = yield call(
    entityActionRequest,
    {
      ...options,
      entityApiRequest: requestFn,
    },
    action,
  )
  return [finalAction.payload, finalAction.meta]
}

// Deprecated.
export function* putFailureAction(...args) {
  const failureAction = yield putFailureActionOrig(...args)
  return [failureAction.payload, failureAction.meta]
}
