import React from 'react'

const LockIcon = props => (
  <svg width={12} height={14} viewBox="0 0 12 14" {...props}>
    <title>{`Combined Shape`}</title>
    <path
      d="M10 5h1a1 1 0 011 1v7a1 1 0 01-1 1H1a1 1 0 01-1-1V6a1 1 0 011-1h1V3a3 3 0 013-3h2a3 3 0 013 3v2zM9 5V3.5C9 2.12 7.993 1 6.75 1h-1.5C4.007 1 3 2.12 3 3.5V5h6z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
)

export default LockIcon
