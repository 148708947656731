import { DiffPatcher } from 'jsondiffpatch/src/diffpatcher'
import { format as formatDiff } from 'jsondiffpatch/src/formatters/jsonpatch'
import { filter } from 'lodash'

// Version of lodash.isEqual that ignores moved items in arrays
//
// @param {object?} options Options for jsondiffpatch
export default function isLooselyEqual(first, second, options = {}) {
  const patcher = new DiffPatcher({
    objectHash: obj => obj.id,
    ...options,
  })
  const diff = formatDiff(patcher.diff(first, second))
  return filter(diff, item => item.op !== 'moved').length === 0
}
