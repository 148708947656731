import * as types from '../actions/action-types'

const initialState = {}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_JOB_POST_TYPE:
      return {
        ...state,
        [action.listingId]: {
          jobPostType: action.jobPostType,
          agencyAllocations: action.agencyAllocations || [],
        },
      }
    default:
      return state
  }
}

export default reducer
