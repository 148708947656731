import { reducer as formReducer } from 'redux-form'
import { get, has } from 'lodash'

import { FORM_CHANGE } from 'actions/action-types'

export const getNewVenues = (venues, payload) => {
  return venues.map(item => {
    if (item.venueId === payload.venueId) {
      return { ...item, ...payload }
    }
    return item
  })
}
export const extendedFormReducer = (state, action) => {
  if (action.type === FORM_CHANGE && action.meta.extendedForm === true) {
    const { payload, meta } = action
    const newVenues = has(meta, 'field')
      ? payload
      : getNewVenues(get(state[meta.form], `values.${meta.baseField}`, []), payload)
    const field = meta.field || meta.baseField
    return {
      ...state,
      [meta.form]: {
        ...state[meta.form],
        values: { [field]: newVenues },
      },
    }
  }
  return formReducer(state, action)
}
