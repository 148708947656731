/* eslint-disable func-names */
/* eslint-disable import/order */

import { call, put } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest, changeEmailPassword as changeEmailPasswordApi } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall, putFailureAction } from './entityCall'

export function* createUser(options = {}) {
  yield takeLatestDeep(types.USER_CREATE_BEGIN, function* (action) {
    try {
      const requestOptions = {
        apiAction: apiActions.create,
        entityType: 'user',
        entity: action.payload,
      }
      const { payload, meta } = yield call(options.entityApiRequest || entityApiRequest, requestOptions)

      // We have to log in now before creating the employer
      yield put({ type: types.AUTH_LOGIN_SUCCEEDED, payload })

      yield put({
        type: types.USER_CREATE_SUCCEEDED,
        payload,
        meta: {
          ...meta,
          requestOptions,
        },
      })
    } catch (e) {
      return yield putFailureAction({
        type: types.USER_CREATE_FAILED,
        error: e,
        requestOptions: { entity: action.payload },
        beginAction: action,
      })
    }
  })
}

export function* changeEmailPassword(options = {}) {
  yield takeLatestDeep(types.USER_CHANGE_EMAIL_PASSWORD_BEGIN, function* (action) {
    yield call(entityCall, changeEmailPasswordApi, {}, action)
  })
}
