import React from 'react'

const ExclamationMarkWarning = props => (
  <svg width="51" height="51" viewBox="0 0 51 51" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="25.5" cy="25.5" r="25.5" fill="#FFC724" />
      <text fill="#FFF" fontFamily="SFUIDisplay-Bold, SF UI Display" fontSize="30" fontWeight="bold">
        <tspan x="21" y="37">
          !
        </tspan>
      </text>
    </g>
  </svg>
)

export default ExclamationMarkWarning
