import React from 'react'

const Star = props => (
  <svg viewBox="0 0 30 27" {...props}>
    <path
      fillRule="evenodd"
      d="M14.969 20.886l-8.122 5.208a1.083 1.083 0 0 1-1.607-1.27l2.934-8.38-7.691-5.122a1.083 1.083 0 0 1 .592-1.985l9.694-.078L13.954.706a1.083 1.083 0 0 1 2.03 0l3.185 8.553 9.694.078a1.083 1.083 0 0 1 .592 1.985l-7.69 5.123 2.933 8.38a1.083 1.083 0 0 1-1.607 1.27l-8.122-5.21z"
    />
  </svg>
)

export default Star
