import { find } from 'lodash'
import { pluralizePhrase } from 'lib/pluralize'

import { entityLabel } from '../schemas'

// @param {Object} role Role as per schema. Deserialized.
// @param {Boolean} options.singular
// @returns {String?} Label for the role
export const label = (role, options = {}) => {
  if (options.singular) {
    return entityLabel(role)
  } else {
    return pluralizePhrase(entityLabel(role))
  }
}

// @param {Object} role As per schema (only skillIds is required)
// @param {Array ([Object])} allSkills Skills as per schema
export const skillsForRole = (role, allSkills) => {
  if (role) {
    const roleSkillsIds = role.skills.map(skill => skill.id)

    return allSkills.filter(skill => roleSkillsIds.includes(skill.id))
  }

  return []
}

// Return the first industry that contains the given role
// @param {Number} roleId
// @param {Object} options.industries Deserialized identity map containing
//   industries conforming to its schema. Must contain nested roles.
// @returns {Object?} Industry entity conforming to schema or undefined.
export const industryForRole = (roleId, { industries }) =>
  find(industries, industry => !!find(industry.roles, { id: roleId }))
