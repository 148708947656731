import * as types from '../actions/action-types'

const initialState = {
  alert: '',
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_SCREEN_READER_ALERT:
      return {
        ...state,
        ...payload,
      }
    default:
      return state
  }
}

export default reducer
