import { get, map, fromPairs, isEmpty, camelCase, compact, first } from 'lodash'

import ApiCallError from 'lib/errors/ApiCallError'
import { humanizeApiCallError } from 'api/call'
import config from 'config'

// Treeify invalid fields
// Applies to deserialized error response.
// TODO Sometimes we receive items when the parent is
// not an array, e.g. submitting wrong postcode to PATCH /employers/1
// TODO Tests :(
export const parseInvalidFields = fieldError => {
  if (!fieldError) return null
  const { fields, nested } = fieldError
  const rootErrors = fromPairs(map(fields, ({ field, message }) => [camelCase(field), message]))
  const nestedErrors = fromPairs(
    map(nested, nestedError => {
      const { property, items } = nestedError
      const itemErrors = map(items, parseInvalidFields)
      return [
        camelCase(property),
        {
          ...fromPairs(map(itemErrors, (error, idx) => [idx, error])),
          ...(itemErrors.length === 1 ? itemErrors[0] : {}),
        },
      ]
    }),
  )
  return { ...rootErrors, ...nestedErrors }
}

export const apology = 'Sorry, something went wrong.'

// @param {String} options.telephoneNumber
export const contactUs = (options = {}) =>
  `contact us by calling ${options.telephoneNumber || config.customerSupportPhone} or emailing us at `

// @param {String} options.telephoneNumber
export const tryLaterOrContactUs = (options = {}) =>
  `Please try again later or ${contactUs(options)}${options.clientEmail || config.customerSupportEmail}.`

/**
 * Friendly error message for an ApiCallError returned from Syft API.
 *
 * Syft API error comes from the Rails backend. It typically has a custom payload
 * and form submission can return field-level errors (we don't process them here).
 *
 * This method should be used directly in view layer (UI components).
 * @param {ApiCallError | ApiError | Error} error
 * @param {Object} options
 * @returns {string|undefined}
 */
export const humanizeSyftApiCallError = (error = {}, options) => {
  if (get(error, 'body.error') === 'invalid_credentials') {
    // A nicer error message than the default "The authentication details you provided
    // are incorrect. Please try again"
    return 'Sorry, you entered an incorrect email address or password'
  } else if (get(error, 'body.error') === 'not_found') {
    // In this case the API typically provides a reasonably human message
    // under `debug.message` attribute
    const backendMessage = get(error, 'body.debug.message') || 'This record does not exist.'
    return compact([apology, backendMessage]).join(' ')
  } else if (get(error, 'body.errorDescription')) {
    return get(error, 'body.errorDescription')
  }

  const message = humanizeApiCallError(error)
  if (!isEmpty(message)) {
    return `${message} If the problem persists, please ${contactUs(options)}${
      options.clientEmail || config.customerSupportEmail
    }.`
  } else {
    return message
  }
}

// Translate any Syft API errors or other exceptions that can appear
// as payload in redux-crud entity failure actions.
//
// This method should be used directly in view layer (UI components).
//
// TODO Process more than the first error
//
// @param {[Object]} errors
// @param {Boolean} options.shouldFallbackToApology True by default
// @param {String} options.telephoneNumber
// @returns {String|null} Null if errors are empty. However, [null] results
//   in an error message
export const humanizeErrors = (errors, options = {}) => {
  let message
  if (isEmpty(errors)) {
    return null
  } else {
    const error = first(compact(errors))
    if (error instanceof ApiCallError) {
      message = humanizeSyftApiCallError(error, options)
    }
    if (!message && options.shouldFallbackToApology !== false) {
      message = `${apology} ${tryLaterOrContactUs(options)}`
    }
    return message
  }
}
