/* eslint-disable func-names */
/* eslint-disable import/order */

import { put, takeLatest, call } from 'redux-saga/effects'

import * as types from 'actions/action-types'
import actions from 'actions'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'

export function* startSyncingMessageThreads() {
  yield put(
    actions.thread.sync.start(
      {},
      {
        syncId: 'global',
        syncInterval: 30 * 1000,
        nonVisibleSyncInterval: 300 * 1000,
      },
    ),
  )

  yield takeLatest(types.AUTH_LOG_OUT, function* () {
    yield put(actions.thread.sync.stop({}, { syncId: 'global' }))
  })
}

// TODO Add `lastSyncTime` attribute to every call to fetch messages
export function* fetchMessages(options) {
  return yield takeLatestDeep(types.MESSAGES_FETCH_BEGIN, function* (action) {
    yield call(
      entityCall,
      options.entityApiRequest || entityApiRequest,
      {
        apiAction: apiActions.list,
        entityType: 'message',
        context: options.context,
      },
      action,
    )
  })
}
