import React from 'react'

const FoodProductionOperative = props => (
  <svg viewBox="0 0 500 500" {...props}>
    <title>{`Syft Role Icons-Purple-inverted-Spring-2018`}</title>
    <g data-name="Layer 1" fill="#003a9b">
      <path d="M158.29 368.2a4.08 4.08 0 0 0 4.08 4.08 3.69 3.69 0 0 0 2.69-1.19l9.23-9.85a3.64 3.64 0 0 1 5.39 0l9.21 9.85a3.65 3.65 0 0 0 5.39 0l9.21-9.85a3.64 3.64 0 0 1 5.39 0l9.22 9.85a3.65 3.65 0 0 0 5.39 0l9.21-9.85a3.64 3.64 0 0 1 5.39 0l9.21 9.85a3.66 3.66 0 0 0 5.4 0l9.21-9.85a3.64 3.64 0 0 1 5.39 0l9.22 9.85a3.64 3.64 0 0 0 5.38 0l9.23-9.85a3.64 3.64 0 0 1 5.39 0l9.21 9.85a3.65 3.65 0 0 0 5.39 0l9.21-9.85a3.65 3.65 0 0 1 5.4 0l9.21 9.85a3.69 3.69 0 0 0 2.69 1.19 4.08 4.08 0 0 0 4.08-4.08v-20.38H158.29zM337.63 127.72a3.69 3.69 0 0 0-2.69 1.19l-9.21 9.85a3.65 3.65 0 0 1-5.4 0l-9.21-9.85a3.65 3.65 0 0 0-5.39 0l-9.21 9.85a3.64 3.64 0 0 1-5.39 0l-9.23-9.85a3.64 3.64 0 0 0-5.38 0l-9.22 9.85a3.64 3.64 0 0 1-5.39 0l-9.21-9.85a3.66 3.66 0 0 0-5.4 0l-9.21 9.85a3.64 3.64 0 0 1-5.39 0l-9.21-9.85a3.65 3.65 0 0 0-5.39 0l-9.22 9.85a3.64 3.64 0 0 1-5.39 0l-9.21-9.85a3.65 3.65 0 0 0-5.39 0l-9.21 9.85a3.64 3.64 0 0 1-5.39 0l-9.23-9.85a3.69 3.69 0 0 0-2.69-1.19 4.08 4.08 0 0 0-4.08 4.08v20.38h183.42V131.8a4.08 4.08 0 0 0-4.08-4.08zM219.75 200.06a5 5 0 1 0 7 .66 5 5 0 0 0-7-.66z" />
      <path d="M341.71 164.4H158.29a40.1 40.1 0 0 1 12.23 29.12v113a40.1 40.1 0 0 1-12.23 29.12h183.42a40.14 40.14 0 0 1-12.23-29.12v-113a40.1 40.1 0 0 1 12.23-29.12zM315.8 287.46l-25.94 5.9a11.87 11.87 0 0 0-8.18 6.68l-11.44 25.32a6 6 0 0 1-8 3.06 6.25 6.25 0 0 1-3.43-6.1l1.76-25.24a6.33 6.33 0 0 0-4.89-6.62c-12.93-3-27.9-12.87-40.24-27.8-12.92-15.62-26.34-40.86-31-60.16a5.07 5.07 0 0 1 8.34-4.9l11.77 10.66a2.93 2.93 0 0 0 4.57-3.5l-9.52-18.65a5.78 5.78 0 0 1 7.19-8c18.77 6.93 44 26.7 58.06 43.73 11.75 14.21 18.55 29.89 19.62 42.8a6.24 6.24 0 0 0 4.9 5.68l26.45 5.4a6.29 6.29 0 0 1 3.49 2c2.96 3.34.88 8.74-3.51 9.74z" />
    </g>
  </svg>
)

export default FoodProductionOperative
