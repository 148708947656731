export default [
  'add-message',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'bag',
  'bar-back',
  'bar-staff',
  'barista',
  'care-assistant',
  'care-worker',
  'chat',
  'check-circled',
  'check-waved',
  'chef-de-partie',
  'chef',
  'chevron-left',
  'chevron-right',
  'cleaner',
  'clock',
  'close',
  'commis-chef',
  'copy',
  'customer-service-rep',
  'delete',
  'delivery-driver-client-cover',
  'delivery-driver',
  'door-supervisor',
  'dropdown',
  'events-security',
  'exclamation-mark-warning',
  'exclamation-mark',
  'festival-staff',
  'food-prep-assistant',
  'food-production-operative',
  'forklift-operator',
  'general',
  'head-chef',
  'hostess',
  'housekeeping',
  'increase-pay-alert',
  'kitchen-porter',
  'lead-chef',
  'letter',
  'loader',
  'lock-icon',
  'mastercard',
  'message-bubbles',
  'minus-circled',
  'motorcycle-courier',
  'more',
  'notifications',
  'online-order-assistant',
  'picker-packer',
  'plus-circled',
  'receptionist',
  'replenishment-assistant',
  'search',
  'sorter',
  'sous-chef',
  'star-circle',
  'star',
  'steward',
  'stockroom-assistant',
  'supervisor',
  'syft-network',
  'team-leader',
  'user-card',
  'user',
  'visa-card',
  'waiting-staff',
  'warehousing-operative',
]
