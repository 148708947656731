import React from 'react'

const StockroomAssistant = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Stockroom assistant`}</title>
    <path
      d="M87 95v-6H73v6h14zm8 0h6a2 2 0 0 1 0 4H37a2 2 0 1 1 0-4h6v-6h-6a2 2 0 1 1 0-4h64a2 2 0 0 1 0 4h-6v6zm-30 0v-6H51v6h14zm-7-60h22a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H58a2 2 0 0 1-2-2V37a2 2 0 0 1 2-2zM43 60h22a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H43a2 2 0 0 1-2-2V62a2 2 0 0 1 2-2zm30 0h22a2 2 0 0 1 2 2v17a2 2 0 0 1-2 2H73a2 2 0 0 1-2-2V62a2 2 0 0 1 2-2z"
      fill="#003a9b"
      fillRule="nonzero"
    />
  </svg>
)

export default StockroomAssistant
