import React from 'react'
import { uniqueId } from 'lodash'

const CareAssistant = props => (
  <svg width={65} height={65} viewBox="0 0 65 65" {...props}>
    <title>{`care-assistant`}</title>
    <g id={uniqueId('Page-1')} stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="schwifty" transform="translate(-291.000000, -5274.000000)">
        <g id="care-assistant" transform="translate(291.000000, 5274.000000)">
          <g id="Group-33">
            <path
              d="M32.2886522,22.0816579 C36.0928182,18.2774918 42.2895467,18.3064485 46.1294326,22.1463344 C49.9693185,25.9862203 49.9982752,32.1829488 46.1941091,35.9871149 L42.9995831,39.1806927 L43,38 L35,38 L35,42 L31,42 L30.9995831,48.3446927 L18.5125483,35.8577618 C14.6726624,32.0178759 14.6437059,25.8211474 18.4478719,22.0169814 C22.2520379,18.2128154 28.4487663,18.2417721 32.2886522,22.0816579 Z"
              id={uniqueId('Combined-Shape')}
              fill="#003a9b"
            />
          </g>
          <g id="Group-34" transform="translate(33.000000, 40.000000)" fill="#003a9b">
            <path
              d="M8,3.63797881e-12 L8,3.999 L12,4 L12,8 L8,7.999 L8,12 L4,12 L4,7.999 L1.63424829e-13,8 L1.63669759e-13,4 L4,3.999 L4,3.63797881e-12 L8,3.63797881e-12 Z"
              id={uniqueId('Combined-Shape')}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default CareAssistant
