import { withStyles } from '@material-ui/core'
import { branch, compose, mapProps, renameProp, withPropsOnChange } from 'recompose'
import { fromPairs, omit, toPairs } from 'lodash'
import classnames from 'classnames'
import withForwardedRef from 'lib/hocs/withForwardedRef'

export const defaultIndex = 2

// Application-level stylesheets are allowed to override stylesheet rules
// from library components (material-ui components). Therefore we have
// to ensure that our stylesheets will be appended to the document with higher
// index (they will always come after any other stylesheets).
//
// https://github.com/cssinjs/jss/blob/master/docs/js-api.md#create-style-sheet
/**
 *
 * @param stylesOrCreator
 * @param {object} [options]
 * @param {number} [options.index]
 * @param {boolean} [options.allowCustomClasses]
 * @returns {function(*): React.ComponentType<React.ComponentPropsWithRef>}
 */
const injectSheet = (
  stylesOrCreator,
  { index = defaultIndex, allowCustomClasses = false, ...restOptions } = {},
) =>
  compose(
    branch(() => allowCustomClasses, renameProp('classes', 'customClasses')),
    withStyles(stylesOrCreator, { index, ...restOptions }),
    withForwardedRef(
      branch(
        () => allowCustomClasses,
        compose(
          withPropsOnChange(['classes', 'customClasses'], ({ classes, customClasses = {} }) => ({
            classes: fromPairs(
              toPairs(classes).map(([key, value]) => [key, classnames(value, customClasses[key])]),
            ),
          })),
          mapProps(props => omit(props, ['customClasses'])),
        ),
      ),
    ),
  )

export default injectSheet
