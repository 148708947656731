import * as types from 'actions/action-types'
import { put, takeLatest, call, all } from 'redux-saga/effects'
import { get } from 'lodash'

import { apiActions } from 'api/endpoints'
import ApiCallError from 'lib/errors/ApiCallError'
import { entityApiRequest } from 'lib/redux-crud/entityActionRequest'
import { putFailureAction } from './errorMessage'

export function* verifyPaymentSession(options) {
  yield takeLatest(types.PAYMENT_SESSION_VERIFY_BEGIN, function* (action) {
    const requestOptions = {
      apiAction: apiActions.update,
      entityType: 'paymentSession',
      context: options.context,
    }

    try {
      const result = yield call(entityApiRequest, {
        ...requestOptions,
        entity: action.payload,
      })

      if (get(result, 'payload.resultCode') !== 'Authorised') {
        const error = new ApiCallError({
          body: { errorDescription: get(result, 'payload.errorMessage') },
        })
        throw error
      }

      const userResult = yield call(entityApiRequest, {
        apiAction: apiActions.get,
        entityType: 'currentUser',
        context: options.context,
      })

      const userAction = {
        type: types.CURRENT_USER_FETCH_SUCCEEDED,
        ...userResult,
      }

      const paymentAction = {
        type: types.PAYMENT_SESSION_VERIFY_SUCCEEDED,
        result,
        meta: { requestOptions, beginAction: action },
      }

      yield all([put(userAction), put(paymentAction)])
    } catch (e) {
      return yield putFailureAction({
        type: types.PAYMENT_SESSION_VERIFY_FAILED,
        error: e,
        requestOptions,
        beginAction: action,
      })
    }
  })
}
