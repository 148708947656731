export const properties = {
  app: 'app',
  region: 'region',
  logged_in: 'logged_in',
  employer_id: 'employer_id',
  agency_id: 'agency_id',
  impersonating_id: 'impersonating_id',
  main_profile_type: 'main_profile_type',
  platform_id: 'platform_id',
  device_id: 'device_id',
  is_lemma: 'is_lemma',
  is_privileged: 'is_privileged',
  is_e2e: 'is_e2e',
}
