import React from 'react'

const StarCircle = props => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="140.488 103.299 67.277 67.277"
    width={18}
    height={18}
    {...props}
  >
    <defs>
      <path
        d="M178.91 130.96l13.48.34-10.71 8.21 3.84 12.93-11.11-7.65-11.12 7.65 3.84-12.93-10.7-8.21 13.48-.34 4.5-12.72 4.5 12.72z"
        id="b"
      />
      <path
        d="M195.42 110.9c7.54 6.17 12.35 15.54 12.35 26.04 0 10.31-4.64 19.54-11.95 25.71-5.86 4.94-13.43 7.93-21.69 7.93-18.58 0-33.64-15.06-33.64-33.64s15.06-33.64 33.64-33.64c8.08 0 15.49 2.85 21.29 7.6z"
        id="a"
      />
      <clipPath id="c">
        <use xlinkHref="#a" />
      </clipPath>
    </defs>
    <use xlinkHref="#b" fill="currentColor" />
    <use xlinkHref="#b" fillOpacity={0} stroke="currentColor" strokeOpacity={0} />
    <g>
      <use xlinkHref="#a" fill="currentColor" fillOpacity={0} />
      <g clipPath="url(#c)">
        <use xlinkHref="#a" fillOpacity={0} stroke="currentColor" strokeWidth={12} />
      </g>
    </g>
  </svg>
)

export default StarCircle
