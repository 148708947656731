import * as actionTypes from 'actions/action-types'
import entityReducer from 'lib/redux-crud/entityReducer'
import { ENTITY_FETCH_SUCCEEDED } from 'lib/redux-crud/actionTypes'

const baseReducer = entityReducer({
  name: 'wagePreviews',
  entityTypes: actionTypes,
})

const { types } = baseReducer

const reducer = (state, action) => {
  let decoratedPayload

  switch (action.type) {
    case types[ENTITY_FETCH_SUCCEEDED]:
      // TODO Refactor
      decoratedPayload = {
        ...action.meta.requestOptions.entity,
        ...action.payload,
      }
      return baseReducer(state, { ...action, payload: decoratedPayload })
    default:
      return baseReducer(state, action)
  }
}

export default reducer
