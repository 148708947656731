import PropTypes from 'prop-types'
import React, { memo } from 'react'
import classnames from 'classnames'
import { compact } from 'lodash'

import injectSheet from 'styles/jss'
import styles from './styles'

// This provides an alternative to default material-ui DialogActions.
// Unlike that one, this DialogActions will not wrap all of its direct their children
// in a suplerfluous <div> element that cannot by styled.
// (See material-ui/Dialog/DialogActions for to see the difference.)
const DialogActions = memo(({ children, className, classes, isFixed, ...rest }) => (
  <div
    data-mui-test="DialogActions"
    className={classnames([
      classes.root,
      className,
      {
        [classes.fixed]: !!isFixed,
      },
    ])}
    {...rest}
  >
    <React.Fragment>
      {compact(React.Children.toArray(children)).map(button =>
        React.cloneElement(button, {
          className: classnames(classes.action, button.props.className),
        }),
      )}
    </React.Fragment>
  </div>
))

DialogActions.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFixed: PropTypes.bool,
}

export default injectSheet(styles)(DialogActions)
