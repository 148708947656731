import React from 'react'

const Barista = props => (
  <svg width={138} height={138} viewBox="0 0 138 138" {...props}>
    <title>{`Barista`}</title>
    <path
      d="M89.772 60.701a8.52 8.52 0 0 1 4.821-1.485c4.707 0 8.528 3.796 8.528 8.485 0 4.69-3.821 8.485-8.528 8.485a8.526 8.526 0 0 1-6.248-2.71c-1.192 3.905-3.675 7.414-7.447 10.527a6.364 6.364 0 0 1-4.05 1.456H58.022c-1.41 0-2.78-.468-3.895-1.331-4.689-3.63-7.398-8.185-8.128-13.67a165.785 165.785 0 0 1-.982-15.847 2.121 2.121 0 0 1 2.12-2.152h40.715a2.121 2.121 0 0 1 2.12 2.158c-.035 2.073-.102 4.1-.2 6.084zM40.061 89.46h54.994a1.06 1.06 0 0 1 .588 1.943l-7.482 4.988a6.364 6.364 0 0 1-3.53 1.069H50.485a6.364 6.364 0 0 1-3.53-1.069l-7.483-4.988a1.06 1.06 0 0 1 .589-1.943zm24.732-40.867l-3.586-2.266c.411-.652.672-1.954.672-3.867 0-.415-.25-.894-.99-1.8a66.124 66.124 0 0 0-.517-.622c-1.741-2.085-2.493-3.462-2.493-5.578 0-3.1.84-5.622 2.581-7.459l3.08 2.918c-.926.977-1.419 2.455-1.419 4.54 0 .825.363 1.49 1.507 2.86.267.319.41.489.548.658 1.31 1.604 1.945 2.826 1.945 4.483 0 2.642-.406 4.674-1.328 6.133zm9.008-.013l-3.602-2.24c.443-.713.68-1.66.68-2.88 0-.667-.248-1.183-1.103-2.376l-.235-.328c-1.145-1.609-1.662-2.713-1.662-4.296 0-2.206.96-4.128 2.763-5.63l2.716 3.26c-.864.72-1.237 1.465-1.237 2.37 0 .455.206.894.876 1.836l.227.316c1.34 1.87 1.897 3.032 1.897 4.848 0 1.97-.43 3.69-1.32 5.12zm20.792 23.365c2.37 0 4.286-1.903 4.286-4.243 0-2.34-1.916-4.242-4.286-4.242-2.37 0-4.285 1.902-4.285 4.242s1.915 4.243 4.285 4.243z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default Barista
