import { InitialState } from '../server/utils/initialState'

export type MaintenanceMode = {
  title?: string
  body?: string
}
const isMaintenanceModeOn = (initialState: InitialState) => {
  const { REACT_APP_MAINTENANCE_MODE_REGION, REACT_APP_MAINTENANCE_MODE } = initialState.reactAppOverrides ?? {}
  const currentRegion = initialState.apiConfig.apiRegion
  return (
    (REACT_APP_MAINTENANCE_MODE_REGION && currentRegion === REACT_APP_MAINTENANCE_MODE_REGION) ||
    REACT_APP_MAINTENANCE_MODE === 'true'
  )
}
export const getMaintenanceMode = (initialState: InitialState): MaintenanceMode | null => {
  const isOn = isMaintenanceModeOn(initialState)

  if (!isOn) {
    return null
  }

  const { REACT_APP_MAINTENANCE_MODE_TITLE, REACT_APP_MAINTENANCE_MODE_BODY } = initialState.reactAppOverrides ?? {}

  return {
    title: REACT_APP_MAINTENANCE_MODE_TITLE,
    body: REACT_APP_MAINTENANCE_MODE_BODY,
  }
}
