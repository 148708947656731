import { REHYDRATE } from 'redux-persist'
import { mapValues, has, get } from 'lodash'

import config from 'config'
import { AGENCY_URL_PATTERN, MARVIN_AGENCY_URL_PATTERN } from 'common/constants'
import * as types from '../actions/action-types'

const { IS_AGENCY_PORTAL, IS_MANDATORY_CC_PAYMENT_FLOW, USER_IDLE_TIMEOUT } = config

const features = {
  workerVenues: true,
  weeklyAvailability: true,
  myStaffAreasFilter: false,
  individualAgencyBooking: false,
  agencyPortal:
    IS_AGENCY_PORTAL ||
    AGENCY_URL_PATTERN.test(window.location.hostname) ||
    MARVIN_AGENCY_URL_PATTERN.test(window.location.hostname),
  isMandatoryCCPaymentFlow: IS_MANDATORY_CC_PAYMENT_FLOW,
}

export const initialState = {
  // Feature toggles are persisted forever in local storage.
  // Whenever the feature default value is toggled on or off
  // we want to clear the user-defined setting.
  // Default feature values not changed dynamically:
  defaultFeatures: features,
  // User-defined features overrides:
  overrides: {},
  features,
  experiments: {},
  userIdleTimeout: USER_IDLE_TIMEOUT || 0.5,
  isIdlingEnabled: true,
}

const buildFlags = payload => ({
  ...payload.enabledFeatures.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
  ...payload.disabledFeatures.reduce((acc, curr) => ({ ...acc, [curr]: false }), {}),
})

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CONFIG_TOGGLE_FEATURE:
      const newValue =
        typeof action.payload.value === 'boolean'
          ? action.payload.value
          : !state.features[action.payload.feature]
      return {
        ...state,
        features: {
          ...state.features,
          [action.payload.feature]: newValue,
        },
        overrides: {
          ...state.overrides,
          [action.payload.feature]: newValue,
        },
      }
    case types.ENABLED_FEATURE_FETCH_BEGIN:
    case types.ENABLED_EXPERIMENTS_FETCH_BEGIN:
      return {
        ...state,
        isLoading: true,
      }
    case types.ENABLED_FEATURE_FETCH_FAILED:
    case types.ENABLED_EXPERIMENTS_FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        hasData: true,
      }
    case types.ENABLED_FEATURE_FETCH_SUCCEEDED:
      return {
        ...state,
        features: {
          ...state.features,
          ...buildFlags(action.payload),
          ...state.overrides,
        },
        isLoading: false,
        hasData: true,
      }
    case types.ENABLED_EXPERIMENTS_FETCH_SUCCEEDED:
      return {
        ...state,
        experiments: {
          ...state.experiments,
          ...buildFlags(action.payload),
        },
        isLoading: false,
      }

    case REHYDRATE:
      const savedOverrides = get(action, 'payload.config.overrides', {})
      const savedUserFeatures = get(action, 'payload.config.features', {})
      const isIdlingEnabled = get(action, 'payload.config.isIdlingEnabled', true)
      const savedDefaultFeatures = get(action, 'payload.config.defaultFeatures', {})
      const newDefaultFeatures = initialState.defaultFeatures
      return {
        ...state,
        defaultFeatures: initialState.defaultFeatures,
        features: mapValues(features, (defaultFeatValue, feat) => {
          // See initialState comments
          const didChangeDefaultChange = savedDefaultFeatures[feat] !== newDefaultFeatures[feat]
          const hasSavedDefaults = has(savedDefaultFeatures, feat)
          if (didChangeDefaultChange || !hasSavedDefaults) {
            return defaultFeatValue
          } else {
            return get(savedUserFeatures, feat, defaultFeatValue)
          }
        }),
        overrides: savedOverrides,
        isIdlingEnabled,
      }
    case types.TOGGLE_IDLING:
      return {
        ...state,
        isIdlingEnabled: action.payload.value,
      }
    default:
      return state
  }
}

export default reducer
