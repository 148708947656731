import { gutterSize, topMargin } from '../styles'

export default ({ spacing, breakpoints }) => ({
  root: {
    padding: `${topMargin}px ${gutterSize}px ${spacing.unit * 4}px ${gutterSize}px`,
    [breakpoints.down('sm')]: {
      padding: spacing.unit,
    },
  },
})
