import { compact, get, without, flatten, map, keyBy } from 'lodash'

// Venue address label
// @example
// ```
// addressLabel(venue, { omit: ['postCode'], divider: '\n' }) === `
//   3 Balls Pond\n
//   Pecker Road\n
//   London\n`
// ```
// @param {Object} venue as per schema
// @param {Array} options.omit Omit properties from address
// @param {String} options.divider String divider between address components
export const addressLabel = (venue, options = {}) => {
  const paths = without(['line1', 'line2', 'city', 'federatedStateCode', 'postCode'], ...(options.omit || []))
  const values = paths.map(path => get(venue, `address.${path}`))
  return compact(values).join(options.divider || ', ')
}

// @param {Object|Array} venues Entity map of venues conforming to schema
// @returns {Object} Entity map of the areas contained in the venues
export const getAllAreas = venues => {
  const areas = map(venues, 'areas')
  return keyBy(flatten(areas), 'id')
}
