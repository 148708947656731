import React from 'react'

const ExclamationMark = props => (
  <svg viewBox="0 0 18 18" {...props}>
    <g fill="#FFF" fillRule="evenodd">
      <text fontFamily="SFUIDisplay-Bold, SF UI Display" fontSize="13" fontWeight="bold">
        <tspan x="7" y="14">
          !
        </tspan>
      </text>
      <path d="M9 18c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9zm0-1.565c4.106 0 7.435-3.329 7.435-7.435S13.106 1.565 9 1.565 1.565 4.894 1.565 9 4.894 16.435 9 16.435z" />
    </g>
  </svg>
)

export default ExclamationMark
