import { RootState } from 'reducers/types'

// The purpose of this manifest is so that for local development we know which flags should be added
// Diligently remove flags from this manifest when feature flags are removed (calls to hasFeature)

export const licenseManifest = ['sainsburys_booking'] as const

const needsCodeCleanup = ['shift_booking_clockable_breaks']

export const localFeaturesManifest = [
  // not set in prod nor staging inspectors, so must come from the BE
  'agencyPortal',
  'individualAgencyBooking',
  'isMandatoryCCPaymentFlow',
  'myStaffAreasFilter',
  'removeSpots',
  'weeklyAvailability',
  'workerVenues',
] as const

export type EnabledLicense = (typeof licenseManifest)[number]
export type EnabledFeature = (typeof localFeaturesManifest)[number]

export const getExperimentVariant = (state: RootState, experiment: EnabledFeature) =>
  state?.config?.experiments?.[experiment]

const getFeature = (state: RootState, feature: EnabledFeature | EnabledLicense) =>
  state?.config?.features?.[feature]

/**
 * @deprecated
 * Flags going forward should be implemented using growthbook.
 * Use the useFlexFlagIsOn hook and add your flag to FEATURES in FeatureFlaggingProvider.tsx
 */
export const hasFeature = (state: RootState, feature: EnabledFeature) => {
  if (
    feature &&
    process.env.NODE_ENV !== 'production' &&
    !needsCodeCleanup.includes(feature) &&
    !localFeaturesManifest.includes(feature)
  ) {
    throw new Error(
      `"${feature}" does not exist in the featuresManifest, add it in config.js if you're introducing a new flag`,
    )
  }
  return !!getFeature(state, feature)
}

/**
 * Check whether the user has the given license
 */
export const hasLicense = (state: RootState, license: EnabledLicense) => {
  if (license && process.env.NODE_ENV !== 'production' && !licenseManifest.includes(license)) {
    throw new Error(`"${license}" does not exist in the licenseManifest`)
  }
  return !!getFeature(state, license)
}

export const hasFeatureData = (state: RootState) => !!state?.config?.hasData
