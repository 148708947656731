import { REHYDRATE } from 'redux-persist'
import { get } from 'lodash'

import * as types from '../actions/action-types'
import { updateAuth } from '../api'

/**
 *
 * @type {{oauthData: {accessToken?: string, refreshToken?: string}, userData: {id?: number, email?: string}, logoutWarning: boolean, isCheckingLogin: boolean, hasEverBeenLoggedIn: boolean, isLoggedIn: boolean, localCsatFlag: boolean, message: string, isAdmin?: boolean}}
 */
export const initialState = {
  isCheckingLogin: false,
  isLoggedIn: false,
  hasEverBeenLoggedIn: false,
  localCsatFlag: true,
  oauthData: {},
  userData: {
    email: '',
  },
  message: '',
  logoutWarning: false,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REHYDRATE:
      // Rehydrate the state. Normally this is done automatically, but in the case
      // of the auth reducer we must update the auth credentials elsewhere as well.
      const authStoreData = get(action, 'payload.auth')
      if (!authStoreData) {
        return state
      }

      const didSessionExpire = (() => {
        if (get(authStoreData, ['oauthData', 'createdAt'])) {
          const expiration =
            get(authStoreData, ['oauthData', 'createdAt']) + get(authStoreData, ['oauthData', 'expiresIn'])
          const now = new Date().getTime() / 1000
          return expiration < now
        } else {
          return false
        }
      })()

      if (didSessionExpire) {
        // If our token has expired, return the initial state to log out the user.
        return {
          ...initialState,
          hasEverBeenLoggedIn: authStoreData.hasEverBeenLoggedIn,
        }
      }

      // Integrate our rehydrated authentication tokens into the store.
      updateAuth(authStoreData.oauthData, authStoreData.userData, authStoreData.isAdmin)

      if (!authStoreData.isCheckingLogin) {
        return {
          ...state,
          ...authStoreData,
          isCheckingLogin: false,
          logoutWarning: false,
          // Remove the error message in case it's there.
          message: '',
        }
      }

      return {
        ...state,
        ...authStoreData,
      }

    case types.AUTH_LOG_OUT:
      // TODO This is not log out
      updateAuth(null)
      return {
        ...state,
        isLoggedIn: false,
        oauthData: {},
        userData: {
          email: '',
        },
        logoutWarning: false,
      }
    case types.AUTH_INDEED_LOGIN_BEGIN:
    case types.AUTH_LOGIN_BEGIN:
    case types.AUTH_REFRESH_BEGIN:
    case types.AUTH_INDEED_OAUTH_REDIRECT:
      return {
        ...state,
        isCheckingLogin: true,
      }

    case types.AUTH_LOGIN_SUCCEEDED:
    case types.AUTH_REFRESH_SUCCEEDED:
    case types.AUTH_REFRESH_FORCE_UPDATE:
      // TODO: we might want to find a way to subscribe our store directly.
      // Update our API credentials.
      updateAuth(action.payload.oauth, action.payload.user, action.payload.isAdmin)

      return {
        ...state,
        isCheckingLogin: false,
        isLoggedIn: true,
        isAdmin: action.payload.isAdmin,
        hasEverBeenLoggedIn: true,
        oauthData: action.payload.oauth,
        userData: action.payload.user,
        message: '',
        logoutWarning: false,
      }

    case types.AUTH_LOGIN_FAILED:
    case types.AUTH_REFRESH_FAILED:
      return {
        ...state,
        isCheckingLogin: false,
        isLoggedIn: false,
        oauthData: {},
        userData: {
          email: '',
        },
        message: action.payload?.message,
      }
    case types.AUTH_CSAT_SURVEY_CHANGE:
      return {
        ...state,
        localCsatFlag: action.payload,
      }
    case types.CURRENT_USER_FETCH_SUCCEEDED:
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
        isLoggedIn: true,
        isCheckingLogin: false,
        hasEverBeenLoggedIn: true,
        userData: action.payload,
      }

    case types.AUTH_TOGGLE_INTERNAL_RESOURCING:
      return {
        ...state,
        userData: {
          ...state.userData,
          platform: {
            ...get(state.userData, 'platform'),
            internalResourcing: !get(state.userData, 'platform.internalResourcing'),
          },
        },
      }
    case types.AUTH_USERDATA_UPDATE:
      return {
        ...state,
        userData: action.payload,
      }

    case types.AUTH_LOGOUT_WARNING:
      return {
        ...state,
        logoutWarning: action.payload,
      }

    case types.AUTH_SET_RESET_PASSWORD_TOKEN:
      return {
        ...state,
        resetPasswordToken: action.payload.resetPasswordToken,
        resetEmail: action.payload.resetEmail,
      }

    default:
      return state
  }
}

export default reducer
