import { flex } from '@indeed/flex-ifl-theme'
import { computePalette } from './colorManipulator'

// Universal colors
// Color names here should not contain information about how they are used
// (that's what ./theme is for)
// Most are already defined in material-ui/src/styles/colors

// Brand purple palette
// Generated by http://mcg.mbitson.com/#!?mcgpalette0=%23934ad2
export const defaultPrimaryColors = computePalette(flex.colors.primary[800])
export const defaultAccentColors = computePalette(flex.colors.accent.default)
export const syftPurplePalette = computePalette('#C544C5')
export const defaultSuccessColors = computePalette(flex.colors.success.default)
export const defaultWarningColors = computePalette(flex.colors.warning.default)

export const basicColors = {
  grey: '#7e7e7e',
  lightGrey: '#f7f7f7',
  middleGrey: '#cccccc',
  darkGrey: '#b4b4b4',
  dark: '#281e2b',
  black: '#000000',
  blue: '#4097f8',
  darkerGrey: '#5b5c60',
  lightBlue: '#c7d2f6',
}

export const pastelRed = '#FF6555'
