import React from 'react'

const Dropdown = props => (
  <svg width={10} height={6} viewBox="0 0 10 6" {...props}>
    <path
      d="M5 3.586L1.707.293A1 1 0 0 0 .293 1.707l4 4a1 1 0 0 0 1.414 0l4-4A1 1 0 0 0 8.293.293L5 3.586z"
      fillRule="nonzero"
      fill="#212121"
    />
  </svg>
)

export default Dropdown
