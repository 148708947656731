import React from 'react'

const MotorcycleCourier = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <title>{`Motorcycle courier`}</title>
    <path
      d="M36.42 20.59a5.402 5.402 0 0 1 4.125 5.248V41.11a2.7 2.7 0 0 1-2.7 2.7h-2.3v-3.596c0-2.482-2.035-4.494-4.545-4.494s-4.545 2.012-4.545 4.494v3.596h-2.3a2.7 2.7 0 0 1-2.7-2.7v-15.27a5.402 5.402 0 0 1 4.124-5.25A6.375 6.375 0 0 0 31 23.585c2.292 0 4.3-1.197 5.42-2.994zm.55-5.481l3.662.315a.9.9 0 0 1 .823.896v.523h3.636c.502 0 .909.402.909.899a.904.904 0 0 1-.91.898h-3.635v.449a.9.9 0 0 1-.9.9H36.75a6.21 6.21 0 0 0 .613-2.697c0-.768-.14-1.503-.394-2.183zm-11.94 0a6.216 6.216 0 0 0-.394 2.183c0 .965.22 1.88.613 2.697h-3.804a.9.9 0 0 1-.9-.9v-.449H16.91a.904.904 0 0 1-.909-.898c0-.497.407-.9.91-.9h3.635v-.522a.9.9 0 0 1 .823-.896l3.662-.315zM31 34.37c2.008 0 3.636-1.61 3.636-3.596 0-1.985-1.628-3.595-3.636-3.595s-3.636 1.61-3.636 3.595c0 1.986 1.628 3.596 3.636 3.596zm0 3.146c1.506 0 2.727 1.207 2.727 2.696v6.293c0 1.489-1.22 2.696-2.727 2.696-1.506 0-2.727-1.207-2.727-2.696v-6.293c0-1.489 1.22-2.696 2.727-2.696zm0-15.73c-2.51 0-4.545-2.013-4.545-4.495S28.49 12.798 31 12.798s4.545 2.012 4.545 4.494c0 2.482-2.035 4.495-4.545 4.495z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default MotorcycleCourier
