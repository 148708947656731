import React from 'react'
import { GridSpacing } from '@material-ui/core/Grid'

import getWithDefaults from 'lib/getWithDefaults'

import { GridContextTypes, GridProviderProps } from './Grid.types'
import { GRID_DEFAULT_SPACING } from './Grid.constants'

export const GridContext = React.createContext<GridContextTypes>({
  rowSpacing: GRID_DEFAULT_SPACING.ROW,
  columnSpacing: GRID_DEFAULT_SPACING.COLUMN,
})

export const GridContextProvider = (props: GridProviderProps) => {
  const rowSpacing: GridSpacing = getWithDefaults(props, 'rowSpacing', 'spacing', GRID_DEFAULT_SPACING.ROW)
  const columnSpacing: GridSpacing = getWithDefaults(
    props,
    'columnSpacing',
    'spacing',
    GRID_DEFAULT_SPACING.COLUMN,
  )

  return <GridContext.Provider value={{ rowSpacing, columnSpacing }}>{props.children}</GridContext.Provider>
}
