import actions from 'actions'
import { AUTH_LOGIN_SUCCEEDED, REHYDRATION_COMPLETE, TOGGLE_IDLING } from 'actions/action-types'
import { signoutDelay } from 'common/constants'
import { throttle } from 'lodash'
import { delay, eventChannel } from 'redux-saga'
import { call, cancelled, put, race, select, take, takeLatest } from 'redux-saga/effects'
import { isLoggedIn as isLoggedInSelector } from 'selectors/auth'
const events = ['keydown', 'mousedown', 'touchstart', 'MSPointerDown', 'scroll']

export const createEventsChannel = function () {
  return eventChannel(emitter => {
    const handle = throttle(event => emitter(event), 1000)
    events.forEach(event => window.addEventListener(event, handle, { passive: true, capture: true }))
    return () =>
      events.forEach(event => window.removeEventListener(event, handle, { passive: true, capture: true }))
  })
}

export const checkIfUserIdling = function* (userIdleTimeout) {
  const isLoggedIn = yield select(isLoggedInSelector)

  if (isLoggedIn) {
    const isIdlingEnabled = yield select(state => state.config.isIdlingEnabled)
    const idleTimeout = yield select(state => state.config.userIdleTimeout)
    const eventsChan = yield call(createEventsChannel)

    try {
      while (isIdlingEnabled) {
        const { idleWarning } = yield race({
          active: take(eventsChan),
          idleWarning: delay(
            Number(userIdleTimeout) || 1000 * 60 * 60 * (parseFloat(idleTimeout) || 0.5),
            true,
          ),
        })

        if (idleWarning) {
          yield put(actions.auth.logOutWarning(true))
        }

        const { idle } = yield race({
          active: take(eventsChan),
          idle: delay(userIdleTimeout || signoutDelay, true),
        })

        if (idleWarning && idle) {
          yield put(actions.auth.logOut())
          eventsChan.close()
          break
        }
      }
    } catch (e) {
      eventsChan.close()
    } finally {
      if (yield cancelled()) {
        eventsChan.close()
      }
    }
  }
}

export default function* watchForUserIdle() {
  yield takeLatest([REHYDRATION_COMPLETE, AUTH_LOGIN_SUCCEEDED, TOGGLE_IDLING], checkIfUserIdling)
}
