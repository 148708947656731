import {
  EXPERIMENT_VIEWED_EVENT_NAME,
  TrackingTriggerEvent,
  UseTrackingTrigger,
} from '@indeed/flex-tracking-context'
import { datadogRum } from '@datadog/browser-rum-slim'

const isProdEnv = process.env.NODE_ENV === 'production'

const mpLog = (event: string, data = {}) => {
  console.log(`Tracking Event :: ${event}`, data ? JSON.stringify(data) : '')
}

/**
 * Check if the given value of allowed `tags` type
 * @param value
 */
const isAllowedTagType = (value: unknown): value is string | number | boolean =>
  !!['string', 'number', 'boolean'].find(t => t === typeof value)

/**
 * Builds tags array from the additional attributes
 * Only string | number | boolean values allowed in tags. Arrays converted into a CSV string with only allowed values
 *
 * @param attributes
 */
const buildTagsFromAttributes = (attributes: { [k: string]: unknown }): string[] => {
  const outputTags: string[] = []

  // eslint-disable-next-line guard-for-in
  for (const key in attributes) {
    const rawValue = attributes[key]
    const value = Array.isArray(rawValue) ? rawValue.filter(isAllowedTagType).join(',') : rawValue

    if (!isAllowedTagType(value)) continue

    outputTags.push(`${key}=${value}`)
  }

  return outputTags
}

/**
 * Filters attributes to only the one that have not already been globally tracked with the same value (as globalTags context attribute)
 *
 * @param attributes
 */
const filterGloballyTracked = (attributes: Record<string, unknown>) => {
  const globalContext = datadogRum.getGlobalContext()
  const globalTags = globalContext.globalTags as Record<string, string | number | boolean> | undefined

  const notGloballyTracked = {} as Record<string, unknown>

  // eslint-disable-next-line guard-for-in
  for (const key in attributes) {
    const value = attributes[key]

    // Check the global tags for matches
    if (globalTags?.[key] && value === globalTags[key]) continue

    // If not in global tags check the global context for matches
    if (globalContext?.[key] && value === globalContext[key]) continue

    notGloballyTracked[key] = value
  }

  return notGloballyTracked
}

const trackDatadogEvent: TrackingTriggerEvent = (event, payload, options): void => {
  const [, , action] = event.split('.')

  if (!isProdEnv) {
    mpLog(event, payload)
  }

  const trackingPayload = {
    ...(Array.isArray(payload) ? { args: payload } : payload),
    ...(options?.isLegacyEvent ? { is_legacy_event: !!options?.isLegacyEvent } : {}),
  }
  const notGloballyTrackedAttributes = payload ? filterGloballyTracked(trackingPayload) : {}

  const rumOptions =
    payload || options?.isLegacyEvent
      ? {
          additionalRumContext: notGloballyTrackedAttributes,
          tags: buildTagsFromAttributes(notGloballyTrackedAttributes),
        }
      : undefined

  if (!action && !options?.isLegacyEvent && event !== EXPERIMENT_VIEWED_EVENT_NAME) {
    return
  }

  const cleared = event.replace(/^legacy_/i, '')

  datadogRum.addAction(cleared, rumOptions)
}

export const useTrigger: UseTrackingTrigger = () => trackDatadogEvent
