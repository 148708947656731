export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'object',
  properties: {
    id: {
      type: 'integer',
    },
    first_name: {
      type: 'string',
    },
    last_name: {
      type: 'string',
    },
    email: {
      type: 'string',
    },
    telephone_number: {
      type: 'string',
    },
  },
  required: ['first_name', 'email', 'telephone_number'],
}
