import React from 'react'

const Copy = props => (
  <svg viewBox="0 0 20 20" {...props}>
    <title>{`copy`}</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0L20 0 20 20 0 20z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M13.333.833h-10c-.916 0-1.666.75-1.666 1.667v11.667h1.666V2.5h10V.833zm2.5 3.334H6.667C5.75 4.167 5 4.917 5 5.833V17.5c0 .917.75 1.667 1.667 1.667h9.166c.917 0 1.667-.75 1.667-1.667V5.833c0-.916-.75-1.666-1.667-1.666zm0 13.333H6.667V5.833h9.166V17.5z"
      />
    </g>
  </svg>
)

export default Copy
