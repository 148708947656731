import deepMapKeys from 'deep-map-keys'
import { camelCaseKey, snakeCaseKey } from 'lib/keyCase'
import { compact, concat, filter, find, get, map, pick } from 'lodash'

import { idForEntity, idProperties, isEntityEqual, mapEachAssociation } from 'api/schemas'

// De/serialize request/response payload for Syft API

export const serialize = (entityType, actionCreator, payload) => deepMapKeys(payload, snakeCaseKey)

export const deserialize = payload => deepMapKeys(payload, camelCaseKey)

// TODO Deep map each association

// @param {Object} updatedEntity Valid entity
// @param {Object} originalEntity Valid entity
// @returns {Object} entity
export const serializeEachAssociation = (updated, original, callback, options) => {
  return mapEachAssociation(
    updated,
    (value, path) => {
      const originalValue = get(original, path)
      return callback(value, originalValue, path)
    },
    options,
  )
}

// Applies callback to the entity and every deeply nested associated entity
//
// @param {Object} entity Valid entity
// @param {Function (entity -> entity)} callback
// @returns {Object} entity
export const serializeEachEntity = (entity, callback) => {
  let result = mapEachAssociation(entity, collection => map(collection, callback))
  result = callback(result)
  return result
}

// Adds '_destroy' property to entities present in original by not present in 'updated',
// as well as to entities which have destroyed all 'shifts'. Preserves original entity
// order and appends new entities
//
// @param {[Object]} original Entity collection
// @param {[Object]} updated Entity collection
// @return {[Object]}
export const flagDeletedEntities = (updated, original) => {
  // flag existing entities present in original but not in updated
  // and append new entities present in updated but not in original
  return concat(
    map(original, originalEntity => {
      const updatedEntity = find(updated, x => idForEntity(x) === idForEntity(originalEntity))
      return (
        updatedEntity || {
          ...pick(originalEntity, [...idProperties(originalEntity.$type), '$type']),
          _destroy: true,
        }
      )
    }),
    filter(updated, updatedEntity => {
      const originalEntity = find(original, x => idForEntity(x) === idForEntity(updatedEntity))
      return !originalEntity
    }),
  )
}

// @param {[Object]} original Entity collection
// @param {[Object]} updated Entity collection
// @return {[Object]}
export const omitUnchangedEntities = (updated, original) => {
  return compact(
    filter(updated, updatedEntity => {
      const id = idForEntity(updatedEntity)
      const originalEntity = find(original, x => idForEntity(x) === id)
      const isDirty = !originalEntity || !isEntityEqual(updatedEntity, originalEntity)
      return isDirty ? updatedEntity : null
    }),
  )
}

// @param {[Object]} original Entity collection
// @param {[Object]} updated Entity collection
// @return {[Object]}
export const stripUnchangedEntities = (updated, original) => {
  return compact(
    map(updated, updatedEntity => {
      const id = idForEntity(updatedEntity)
      const originalEntity = find(original, x => idForEntity(x) === id)
      const isDirty = !originalEntity || !isEntityEqual(updatedEntity, originalEntity)
      return isDirty ? updatedEntity : null
    }),
  )
}
