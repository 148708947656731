export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  additionalProperties: false,
  definitions: {},
  properties: {
    name: {
      type: 'string',
    },
    email: {
      description: 'Email address',
      type: 'string',
    },
    telephone_number: {
      description: 'Telephone number',
      type: 'string',
    },
    password: {
      type: 'string',
    },
    current_password: {
      type: 'string',
    },
  },
  required: ['name', 'email', 'password'],
  type: 'object',
}
