import { put, all, call } from 'redux-saga/effects'

import { takeLatestDeep } from 'lib/sagaHelpers'
import * as types from 'actions/action-types'
import { apiActions } from 'api/endpoints'
import { entityApiRequest } from 'api'
import { putFailureAction } from './entityCall'

export function* createBulkMessage(options) {
  yield takeLatestDeep(types.BULK_MESSAGE_CREATE_BEGIN, function* (action) {
    try {
      const { workerIds, jobId, body } = action.payload
      const requestOptions = {
        apiAction: apiActions.create,
        entityType: 'message',
        meta: options.context,
      }
      yield all(
        workerIds.map(workerId =>
          call(options.entityApiRequest || entityApiRequest, {
            ...requestOptions,
            entity: { jobId, workerId, body },
          }),
        ),
      )
      const meta = { requestOptions, beginAction: action }
      yield put({
        type: types.BULK_MESSAGE_CREATE_SUCCEEDED,
        payload: {},
        meta,
      })
    } catch (e) {
      yield putFailureAction({
        type: types.BULK_MESSAGE_CREATE_FAILED,
        error: e,
        beginAction: action,
      })
    }
  })
}
