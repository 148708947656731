import React, { useContext } from 'react'
import { upperFirst } from 'lodash'
import classnames from 'classnames'

import MuiTypography from '@material-ui/core/Typography'
import injectSheet from 'styles/jss'

import { TypographyContext } from './Typography.context'

import styles from './styles'

import {
  TypographyProps,
  TypographyDefaultClassKey,
  TypographyExtendedClassKey,
  TypographyColor,
  TypographyColorClassKey,
} from './Typography.types'

import {
  TYPOGRAPHY_EXTENDED_VARIANTS,
  TYPOGRAPHY_EXTENDED_COLORS,
  TYPOGRAPHY_EXTENDED_VARIANTS_MAPPING,
} from './Typography.constants'

export const getIsTypeExtended = (type: any): type is TypographyExtendedClassKey => {
  return type ? TYPOGRAPHY_EXTENDED_VARIANTS.includes(type) : false
}

export const getIsTypeVariantDefault = (type: any): type is TypographyDefaultClassKey => {
  // Subheading is both extended and default
  return type && (type === 'subheading' || !getIsTypeExtended(type))
}

export const getMuiVariant = (type: TypographyProps['type']) => {
  if (getIsTypeExtended(type)) {
    return type ? TYPOGRAPHY_EXTENDED_VARIANTS_MAPPING[type] : undefined
  }

  if (getIsTypeVariantDefault(type)) {
    return type
  }

  return undefined
}

export const getIsColorExtended = (color?: TypographyColor): color is TypographyColor => {
  return color ? TYPOGRAPHY_EXTENDED_COLORS.includes(color) : false
}

const Typography = ({
  big = false,
  classes,
  color,
  inheritColor = false,
  noEllipsis = false,
  maxWidth,
  noMargin = false,
  noWrap = false,
  type = 'body1',
  style,
  withMargin = false,
  wrapNewlines = false,
  ...rest
}: TypographyProps) => {
  const { big: isContextBig } = useContext(TypographyContext)
  const isBig = isContextBig || big

  const typeExtended = getIsTypeExtended(type) ? type : undefined

  const isColorExtended = getIsColorExtended(color)

  const muiVariant = getMuiVariant(type)

  const colorClassKey = isColorExtended ? (`color${upperFirst(color)}` as TypographyColorClassKey) : undefined

  // props.classes overrides the default Typography classses
  // props.className adds our extended variants
  return (
    <MuiTypography
      classes={
        getIsTypeVariantDefault(type)
          ? {
              [type]: classes[type],
            }
          : undefined
      }
      variant={muiVariant}
      color={isColorExtended ? 'default' : color}
      {...rest}
      className={classnames(
        rest.className,
        typeExtended && classes[typeExtended],
        {
          [classes.big]: isBig,
          [classes.withMargin]: withMargin,
          [classes.noMargin]: noMargin,
          [classes.inheritColor]: inheritColor,
          [classes.wrapNewlines]: wrapNewlines,
          [classes.noEllipsis]: noEllipsis,
          [classes.noWrap]: noWrap,
        },
        colorClassKey && classes[colorClassKey],
      )}
      style={{
        ...(maxWidth ? { maxWidth } : {}),
        ...style,
      }}
    />
  )
}

export default injectSheet(styles)(Typography)
