export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'array',
  items: [
    {
      type: 'object',
      properties: {
        id: {
          type: 'integer',
        },
        job_id: {
          type: 'integer',
        },
        pay_rates: {
          type: 'array',
          items: [
            {
              type: 'object',
              properties: {
                pay_rate: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer',
                    },
                    currency: {
                      type: 'string',
                    },
                  },
                  required: ['amount', 'currency'],
                },
                label: {
                  type: 'string',
                },
              },
              required: ['pay_rate', 'label'],
            },
            {
              type: 'object',
              properties: {
                pay_rate: {
                  type: 'object',
                  properties: {
                    amount: {
                      type: 'integer',
                    },
                    currency: {
                      type: 'string',
                    },
                  },
                  required: ['amount', 'currency'],
                },
                label: {
                  type: 'string',
                },
              },
              required: ['pay_rate', 'label'],
            },
          ],
        },
      },
      required: ['id', 'job_id', 'pay_rates'],
    },
  ],
}
