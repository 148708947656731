import PropTypes from 'prop-types'
import React from 'react'
import Typography from 'components/Typography'
import { confirmable, createConfirmation } from 'react-confirm'
import Grid from 'components/Grid'
import { Dialog, DialogActions, DialogTitle, DialogContent } from 'components/Dialog'
import Button from 'components/Button'
import { LatestActiveThemeProvider } from 'styles/themeProvider'

// Usage:
// ```
// import { confirm } from 'components/ConfirmationDialog'
// confirm('Are you sure?')
//  .then(() => console.log('confirmed'))
//  .catch(() => console.log('dismissed'))
// ```
// You can also use async/await.
//
// This pattern of launching debatable in React however faster to use than keeping
// the confirmation dialog state in the parent component.
//
// https://material.io/guidelines/components/dialogs.html#dialogs-simple-dialogs

export const PlainConfirmationDialog = props => {
  const {
    show,
    proceed,
    dismiss,
    title,
    content,
    noCancel,
    body,
    cancel,
    confirmText = 'OK',
    cancelText = 'Cancel',
    onClose,
    confirmButtonColor,
  } = props
  const handleProceed = () => {
    onClose && onClose()

    proceed()
  }
  const handleCancel = () => {
    onClose && onClose()

    cancel()
  }

  return (
    <LatestActiveThemeProvider>
      <Dialog
        open={show}
        onClose={dismiss}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-content"
      >
        <DialogTitle disableTypography style={{ padding: '60px 60px 0px 20px' }}>
          {title ? (
            <Typography
              id="confirmation-dialog-title"
              type="body2"
              style={{ marginBottom: '1em', fontSize: 16 }}
            >
              {title}
            </Typography>
          ) : null}

          {content ? <Typography id="confirmation-dialog-content">{content}</Typography> : null}
        </DialogTitle>

        {/* TODO Docs, scrollable body */}
        {!body ? null : <DialogContent>{body}</DialogContent>}

        {noCancel ? (
          <DialogActions>
            <Grid container justify="flex-end">
              <Grid item>
                <Button primary autoFocus onClick={handleProceed} data-testid="ConfirmationDialogOKbutton">
                  {confirmText}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        ) : (
          <DialogActions>
            <Grid container justify="flex-end">
              <Grid item>
                {noCancel ? null : (
                  <Button onClick={handleCancel} data-testid="ConfirmationDialogCancelButton">
                    {cancelText}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  primary={!confirmButtonColor}
                  autoFocus
                  onClick={handleProceed}
                  color={confirmButtonColor}
                >
                  {confirmText}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </LatestActiveThemeProvider>
  )
}

PlainConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // Confirm button label
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // Cancel button label
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  noCancel: PropTypes.bool,
  body: PropTypes.node,
  // Optional button color
  confirmButtonColor: PropTypes.string,

  // Internal from confirmable. indicates if the dialog is shown or not.
  show: PropTypes.bool,
  // Internal from confirmable. call to close the dialog with promise resolved.
  proceed: PropTypes.func,
  onClose: PropTypes.func,
  // Internal from confirmable. call to close the dialog with promise rejected.
  cancel: PropTypes.func,
  // Internal from confirmable. call to only close the dialog.
  dismiss: PropTypes.func,
}

// Passes props `show`, `dismiss`, `cancel` and `proceed` to our component.
export const ConfirmationDialog = confirmable(PlainConfirmationDialog)

// Launches a simple confirmation dialog.
// Analogous to window.confirm.
// Provide `catch()` handler to avoid unhandled rejection.
//
// @param {Object} following props:
//  title {String} Optional dialog title
//  content {String} Optional dialog content – confirmation text
//  cancelText {String} Optional cancel button label
//  confirmText {String} Optional confirm button label
// @returns {Promise} resolves when the user confirms the dialog
export const confirm = createConfirmation(ConfirmationDialog)

// Launches an alert dialog.
// Analogous to window.alert.
//
// @param {Object} following props:
//  title {String} Optional dialog title
//  content {String} Optional dialog content – confirmation text
//  confirmText {String} Optional confirm button label
// @returns {Promise} resolves when the user confirms the dialog
export const alert = props => confirm({ ...props, noCancel: true }).catch()
