/**
 * v1. Added platform.links. Reset stored 'platform'
 * v2. Updated customer support for a platform
 * v3. Added platform name for internal resourcing platforms (SyftForce, Indeed Flex+)
 *
 * @param {Object} state
 * @returns {Promise<unknown>|object}
 */
const resetPlatformMigration = ({ platform, ...rest }) => rest

export default {
  1: resetPlatformMigration,
  2: resetPlatformMigration,
  3: resetPlatformMigration,
  4: resetPlatformMigration,
  5: resetPlatformMigration,
  6: resetPlatformMigration,
  7: resetPlatformMigration,
  8: resetPlatformMigration,
}
