import * as types from 'actions/action-types'
import { call, put, takeLatest } from 'redux-saga/effects'
import { takeLatestDeep } from 'lib/sagaHelpers'
import { entityCall } from './entityCall'
import { downloadEmployerTimesheet } from 'api'

export function* employerTimesheetsApproved() {
  yield takeLatestDeep(types.EMPLOYER_TIMESHEETS_APPROVE_ALL_CREATE_SUCCEEDED, function* (action) {
    return yield put({
      type: types.EMPLOYER_TIMESHEETS_FETCH_BEGIN,
      payload: action.meta.requestOptions.entity,
      meta: {
        refetch: true,
      },
    })
  })
}

export function* downloadEmployerTimesheetCSV(options = {}) {
  yield takeLatestDeep(types.EMPLOYER_TIMESHEETS_DOWNLOAD_CSV_FETCH_BEGIN, function* (action) {
    yield call(entityCall, downloadEmployerTimesheet, {}, action)
  })
}

export function* employerTimesheetApproved() {
  yield takeLatestDeep(types.EMPLOYER_TIMESHEETS_APPROVAL_CREATE_BEGIN, function* (action) {
    yield takeLatest(types.EMPLOYER_TIMESHEETS_APPROVAL_CREATE_SUCCEEDED, function* () {
      yield action.payload.onSuccess()
    })
  })
}
