import React from 'react'

const CommisChef = props => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <title>{`Commis chef`}</title>
    <path
      d="M27.277 19.372a5.032 5.032 0 0 1 4.267-2.32c1.757 0 3.303.88 4.194 2.21a5.036 5.036 0 0 1 4.195-2.21c2.761 0 5 2.171 5 4.85 0 2.322-1.684 4.263-3.933 4.737v12.202H22v-12.19c-2.277-.453-3.99-2.407-3.99-4.75 0-2.678 2.239-4.848 5-4.848 1.806 0 3.388.928 4.267 2.32zM22 40.587h19V43.3a2.7 2.7 0 0 1-2.7 2.7H24.7a2.7 2.7 0 0 1-2.7-2.7v-2.713z"
      fill="#003a9b"
      fillRule="evenodd"
    />
  </svg>
)

export default CommisChef
