// manage_employer_network is used on FLex+ platform only. FLex platform
// will never receive this permission.
export const MANAGE_EMPLOYER_NETWORK = 'manage_employer_network'
export const MANAGE_VENUES = 'manage_venues'
export const CREATE_VENUES = 'create_venues'
export const MANAGE_FINANCIAL = 'manage_financial'

export const employerPermissions = [MANAGE_EMPLOYER_NETWORK, MANAGE_VENUES, CREATE_VENUES, MANAGE_FINANCIAL]

export const MANAGE_TIMESHEET = 'manage_timesheet'
export const RATE_WORKERS = 'rate_workers'
export const SCAN_WORKERS = 'scan_workers'
export const MANAGE_LOCATION_NETWORK = 'manage_location_network'
export const MANAGE_JOBS = 'manage_jobs'
export const MANAGE_MANAGERS = 'manage_managers'
export const MANAGE_LOCATIONS = 'manage_locations'
export const VIEW_FINANCIAL = 'view_financial'

export const locationPermissions = [
  MANAGE_TIMESHEET,
  RATE_WORKERS,
  SCAN_WORKERS,
  MANAGE_LOCATION_NETWORK,
  MANAGE_JOBS,
  MANAGE_MANAGERS,
  MANAGE_LOCATIONS,
  VIEW_FINANCIAL,
]

export const allPermissions = [...locationPermissions, ...employerPermissions]
