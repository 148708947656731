import { head, isEmpty, map, tail, isArray, has } from 'lodash'

const _mapPath = (something, splitPath, mapFn, parent) => {
  if (isEmpty(splitPath)) {
    return map(something, (x, idx) => mapFn(x, idx, parent))
  } else {
    if (isArray(something)) {
      return map(something, x => _mapPath(x, splitPath, mapFn))
    } else {
      const attribute = head(splitPath)
      if (has(something, attribute)) {
        const next = something[attribute]
        return {
          ...something,
          [attribute]: _mapPath(next, tail(splitPath), mapFn, something),
        }
      } else {
        return something
      }
    }
  }
}

// @param {Object|Array} objectOrArray
// @param {String} path dot-separated path ('object.array.object.array')
// @param {Function} mapFn function to update objects at path
// @returns mapped object or array
const mapPath = (objectOrArray, path, mapFn) => _mapPath(objectOrArray, path.split('.'), mapFn)

export default mapPath
