import payRateSchema from './payRate'

export default {
  $schema: 'http://json-schema.org/draft-04/schema#',
  type: 'array',
  items: {
    $schema: 'http://json-schema.org/draft-04/schema#',
    type: 'object',
    properties: {
      agency_shift_id: {
        type: 'integer',
        description: 'Agency shift',
      },
      workers_booked: {
        type: 'number',
        description: 'Number of workers',
      },
      workers_requested: {
        type: 'number',
        description: 'Number of workers',
      },
      pay_rate: payRateSchema,
    },
    required: ['agency_shift_id'],
  },
}
